import { createReducer } from '@reduxjs/toolkit';
import { Reducer } from 'redux';
import merge from 'lodash/merge';
//
import { UserType } from 'types/users';
import { UserSortConfigs } from './entities';
import {
  getUsersAction,
  changeUsersRequestParamsAction,
  patchUserAction,
  getUserAction,
  usersSetIsStaleAction,
} from './actions';

const initialState = {
  meta: {
    isLoading: false,
    isResourcesLoaded: false,
    isStale: true,
    isUserLoading: false,
  },
  filters: {} as Record<string, string>,
  sort: UserSortConfigs.createdAtAsc,
  pagination: {
    page: 0,
    perPage: 10,
    pageCount: 0,
    total: 0,
  },
  resources: [] as UserType[],
  resource: null as UserType | null,
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getUsersAction.pending, (draft) => {
      draft.meta.isLoading = true;
      draft.meta.isResourcesLoaded = false;
    })
    .addCase(getUsersAction.fulfilled, (draft, { payload }) => {
      const { data, ...pagination } = payload;
      draft.meta.isLoading = false;
      draft.meta.isResourcesLoaded = true;
      draft.meta.isStale = false;

      draft.resources = pagination.page === initialState.pagination.page ? data : [...draft.resources, ...data];

      draft.pagination = {
        ...draft.pagination,
        ...pagination,
      };
    })
    .addCase(getUsersAction.rejected, (draft) => {
      draft.meta.isLoading = false;
    })
    .addCase(patchUserAction.pending, (draft) => {
      draft.meta.isLoading = true;
    })
    .addCase(patchUserAction.fulfilled, (draft, { payload }) => {
      draft.meta.isLoading = false;
      draft.resources = draft.resources.map((i) => (i.id === payload.id ? merge(i, payload) : i));
    })
    .addCase(patchUserAction.rejected, (draft) => {
      draft.meta.isLoading = false;
    })
    .addCase(changeUsersRequestParamsAction, (draft, { payload }) => {
      draft.meta.isStale = true;
      draft.filters = merge(draft.filters, payload.filters);
      draft.sort = merge(draft.sort, payload.sort);
      draft.resources = initialState.resources;
      draft.pagination = initialState.pagination;
    })
    ////////////////////////////////////////////////////////////////////////////////////////////////
    .addCase(getUserAction.pending, (draft) => {
      draft.meta.isUserLoading = true;
    })
    .addCase(getUserAction.fulfilled, (draft, { payload }) => {
      draft.meta.isUserLoading = false;

      draft.resource = payload;
    })
    .addCase(getUserAction.rejected, (draft) => {
      draft.meta.isUserLoading = false;
    })
    ////////////////////////////////////////////////////////////////////////////////////////////////
    .addCase(usersSetIsStaleAction, (draft, { payload }) => {
      draft.meta.isStale = payload;
    });
});

export const userReducer: Reducer<typeof initialState> = (draft = initialState, action) => {
  return reducer(draft, action);
};
