import React from 'react';
import { Avatar, Icon, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
// types
import { Notification, useI18NotificationMapping } from 'types/notification';
// utils
import { intl, IntlKeys } from 'localization';
// local
import { Classes } from './index';
import { getDate } from './utils';
// styles
import cx from 'clsx';
import useStyles from './styles';

type NotificationProps = {
  handleClick: () => void;
  notification: Notification;
} & Classes;

export const NotificationRow = ({ handleClick, notification, classes }: NotificationProps) => {
  const localClasses = useStyles();

  const NotificationActionEnumMappings = useI18NotificationMapping();

  return (
    <MenuItem onClick={handleClick} className={cx(localClasses.menuItem, classes?.menuItem)}>
      <ListItemIcon className={cx(localClasses.listIconContainer, classes?.listIcon)}>
        {!notification.creator.avatar ? (
          <Icon className={localClasses.listIcon}>{notification.creator.firstName.charAt(0)}</Icon>
        ) : (
          <Avatar src={notification.creator.avatar} className={localClasses.listIcon} />
        )}
      </ListItemIcon>

      <div className={localClasses.listTxtContainer}>
        <div className={localClasses.listTxtHolder}>
          <ListItemText primaryTypographyProps={{ className: cx(localClasses.menuItemLabel, classes?.menuItemLabel) }}>
            {`${notification.creator.firstName} ${notification.creator.lastName}`}
            <span className={`${localClasses.dot} ${notification.status}`}></span>
          </ListItemText>
          <ListItemText
            primaryTypographyProps={{ className: cx(localClasses.menuItemLabelSub, classes?.menuItemLabel) }}
          >
            {NotificationActionEnumMappings[`${notification.action}`]}
          </ListItemText>
        </div>
        <div className={localClasses.dateHolder}>
          <ListItemText primaryTypographyProps={{ className: cx(localClasses.dateText, classes?.menuItemLabel) }}>
            {getDate(notification.createdAt)}
          </ListItemText>
        </div>
      </div>
    </MenuItem>
  );
};

export const NotificationHeader = ({ onClick }: { onClick: () => void }) => {
  const localClasses = useStyles();
  return (
    <div className={localClasses.menuHeader} onClick={onClick}>
      <span>{intl.formatMessage({ id: IntlKeys.notificationsMarkAllAsRead })}</span>
    </div>
  );
};

export const EmptyNotifications = () => {
  const localClasses = useStyles();

  return (
    <div className={localClasses.empty}>
      <span>{intl.formatMessage({ id: IntlKeys.notificationsNoNotificationAvailable })}</span>
    </div>
  );
};
