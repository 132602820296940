import React from 'react';
import { Backdrop, CircularProgress, CircularProgressProps } from '@mui/material';
import cx from 'clsx';

import { makeStyles } from '@mui/styles';

interface OwnProps {
  filled?: boolean;
  absolute?: boolean;
  relative?: boolean;
  size?: CircularProgressProps['size'];
  nobg?: boolean;
  className?: string;
}

export const Loader: React.FC<OwnProps> = ({
  //
  filled = true,
  absolute = false,
  relative = false,
  size,
  nobg = false,
  className,
}) => {
  const classes = useStyles();

  return (
    <Backdrop
      className={cx(classes.backdrop, className, {
        [classes.filled]: filled,
        [classes.absolute]: absolute,
        [classes.relative]: relative,
        [classes.nobg]: nobg,
      })}
      open={true}
      timeout={0}
    >
      <CircularProgress color="primary" size={size} />
    </Backdrop>
  );
};

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
  filled: {
    backgroundColor: '#dcdcdc',
  },
  absolute: {
    position: 'absolute',
  },
  relative: {
    position: 'relative',
  },
  nobg: {
    backgroundColor: 'transparent !important',
  },
}));
