interface Buffer {
  type: 'Buffer';
  data: ArrayBuffer;
}

export const saveFile = (target: string | File | Blob | Buffer, name?: string) => {
  let url: string;

  if (typeof target === 'string') {
    url = target;
  } else if (target.type === 'Buffer') {
    url = URL.createObjectURL(new Blob([new Uint8Array((target as Buffer).data)]));
  } else {
    url = URL.createObjectURL(target as File | Blob);
  }

  const a = document.createElement('a');
  document.body.appendChild(a);
  a.href = url;

  if (name) {
    a.download = name;
  }

  a.click();

  // cleanup
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};
