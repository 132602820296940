import React from 'react';
import ReactDOM from 'react-dom';
//
import { currentPortalType, PortalTypes } from 'utils/portalCheck';
//
import reportWebVitals from './reportWebVitals';

const ApplicationRoot =
  currentPortalType === PortalTypes.Backoffice
    ? require('portal/backoffice').default
    : require('portal/workspace').default;

ReactDOM.render(
  <React.StrictMode>
    <ApplicationRoot />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
