// types
import { FilterFormValues } from 'components/filter-bar';
import { IntlKeys } from 'localization';
import { useIntl } from 'react-intl';
import { SortConfigType } from 'types/common';

const uSortConfigs = {
  courseNameAsc: {
    field: 'courseName',
    order: 'ASC',
  },
  courseNameDesc: {
    field: 'courseName',
    order: 'DESC',
  },
  authorNameAsc: {
    field: 'authorName',
    order: 'ASC',
  },
  authorNameDesc: {
    field: 'authorName',
    order: 'DESC',
  },
  updatedAtAsc: {
    field: 'updatedAt',
    order: 'ASC',
  },
  updatedAtDesc: {
    field: 'updatedAt',
    order: 'DESC',
  },
  userCountAsc: {
    field: 'userCount',
    order: 'ASC',
  },
  userCountDesc: {
    field: 'userCount',
    order: 'DESC',
  },
};

const internalSortConfig = {
  progressAsc: {
    field: 'progress',
    order: 'ASC',
  },
  progressDesc: {
    field: 'progress',
    order: 'DESC',
  },
};

export type CourseShareWorkspacesPayload = {
  courseIds: number[];
  workspaceIds: number[];
  teamIds?: number[];
};

export const CoursesSortConfigs = uSortConfigs as Record<keyof typeof uSortConfigs, SortConfigType>;
export type CourseFiltersFormValues = FilterFormValues<keyof typeof CoursesSortConfigs>;
export const EmployeeInternalSortConfig = internalSortConfig as Record<keyof typeof internalSortConfig, SortConfigType>;

export const CourseSortWithLabels = () => {
  const { formatMessage } = useIntl();
  return [
    {
      label: formatMessage({ id: IntlKeys.coursesCourseNameAToZ }),
      value: 'courseNameAsc',
    },
    {
      label: formatMessage({ id: IntlKeys.coursesCourseNameZToA }),
      value: 'courseNameDesc',
    },
    {
      label: formatMessage({ id: IntlKeys.coursesAuthorNameAToZ }),
      value: 'authorNameAsc',
    },
    {
      label: formatMessage({ id: IntlKeys.coursesAuthorNameZToA }),
      value: 'authorNameDesc',
    },
    {
      label: formatMessage({ id: IntlKeys.coursesLastUpdates }),
      value: 'updatedAtDesc',
    },
    {
      label: formatMessage({ id: IntlKeys.coursesOldUpdates }),
      value: 'updatedAtAsc',
    },
  ];
};
