import { AxiosRequestConfig } from 'axios';
import { Params, Method, ApiCall, PromisePayload } from './index';
import { buildPayload } from './buildPayload';
//

interface ApiCallTransformers {
  transformRequestData?: (data: Record<string, unknown>, method?: Method) => Record<string, unknown>;
  serializeSuccessPayload?: (...args: any[]) => any;
  serializeFailurePayload?: (...args: Record<string, unknown>[]) => Record<string, unknown> | string;
  prepareRequestConfig?: (...args: Record<string, unknown>[]) => Record<string, unknown>;
}

export type ApiFetcher<ResolvesTo = Record<string, unknown>> = (
  payload?: ApiFetcherPayload<ResolvesTo>,
) => Promise<PromisePayload<ResolvesTo>>;

export type ApiFetcherPayload<ResolvesTo = Record<string, unknown>> = Params & {
  axiosConfig?: AxiosRequestConfig<ResolvesTo>;
};
export type ApiFetcherPayloadBuilder<ResolvesTo = Record<string, unknown>> = (
  payload: ApiFetcherPayload<ResolvesTo>,
  config: UseApiConfig<ResolvesTo>,
) => ApiFetcherPayload<ResolvesTo>;

export type UseApiConfig<ResolvesTo> = ApiCallTransformers & {
  api: ApiCall<ResolvesTo>;
  configureUrlParams?: ApiFetcherPayloadBuilder<ResolvesTo>;
  headers?: Record<string, unknown>;
  preventDataTransform?: boolean;
};

export function wrapIntoAxiosStructure(
  { data, method }: { data?: Record<string, unknown>; method?: Method } = { method: 'get' },
) {
  switch (method) {
    case 'put':
    case 'delete':
    case 'patch':
    case 'post': {
      return { data };
    }
    case 'get':
    default:
      return { params: data };
  }
}

const transformerStubs: Required<ApiCallTransformers> = {
  transformRequestData: (d) => d,
  serializeSuccessPayload: (r) => r.data?.['resource'] || r.data?.['resources'] || r.data,
  serializeFailurePayload: (axiosError) => axiosError,
  prepareRequestConfig: wrapIntoAxiosStructure,
};

export const createApiCall = <ResolvesTo = Record<string, unknown>>(
  apiConfig: UseApiConfig<ResolvesTo>,
): ApiFetcher<ResolvesTo> => {
  const {
    api,
    transformRequestData = transformerStubs.transformRequestData,
    serializeSuccessPayload = transformerStubs.serializeSuccessPayload,
    serializeFailurePayload = transformerStubs.serializeFailurePayload,
    prepareRequestConfig = transformerStubs.prepareRequestConfig,
    headers,
    preventDataTransform,
    configureUrlParams,
  } = apiConfig;

  const _serializeFailurePayload: typeof serializeFailurePayload = (...args) => {
    try {
      return serializeFailurePayload(...args);
    } catch (e) {
      return 'Something went wrong...';
    }
  };

  return (payload) => {
    const requestPayload = buildPayload<ResolvesTo>({ payload, configureUrlParams, apiConfig });
    //
    const { params, meta, axiosConfig = {}, ...args } = requestPayload;

    const requestConfig = prepareRequestConfig({
      data: preventDataTransform ? args.data : transformRequestData(args),
      method: api.method,
    });

    return (
      api({
        urlParams: params,
        headers: headers,
        ...axiosConfig,
        ...requestConfig,
      })
        ///////////////////////////////////////////////////////////////
        .then((axiosResponse) => {
          //
          return {
            data: serializeSuccessPayload(axiosResponse, requestPayload),
            meta: { ...meta, ...axiosResponse.data?.meta },
            headers: axiosResponse.headers,
          };
        })
        ///////////////////////////////////////////////////////////////
        .catch((axiosError) => {
          //
          console.error(`Error occurred`, axiosError);
          const serializedError = _serializeFailurePayload(axiosError);

          throw {
            data: serializedError,
            requestPayload: requestPayload,
          };
        })
      ///////////////////////////////////////////////////////////////
    );
  };
};
