import { lazy } from 'react';
//
// import { PERMISSIONS } from 'domain/auth/permissions';
import { AccessFlags } from 'lib/acl/entities';
//
import { checkDuplicateRoutes, GenericChildRoute, GenericRoute } from 'routes';
import { generateAppRoutes } from 'routes/utils';
import { currentPortalType, PortalTypes } from 'utils/portalCheck';
import { IntlKeys } from 'localization';
//
import DefaultLayout from 'portal/workspace/components/layouts/default-layout';
import FullwidthLayout from 'portal/workspace/components/layouts/fullwidth-layout';
import FullscreenLayout from 'portal/workspace/components/layouts/fullscreen-layout';
//
const LoginPage = lazy(() => import('portal/workspace/pages/authPages/login/LoginPage'));
const ForgotPasswordPage = lazy(() => import('portal/workspace/pages/authPages/forgot-password/ForgotPasswordPage'));
const ResetPasswordPage = lazy(() => import('portal/workspace/pages/authPages/reset-password/ResetPasswordPage'));
const SignUpPage = lazy(() => import('portal/workspace/pages/authPages/signup/SignUpPage'));
const DashboardPage = lazy(() => import('portal/workspace/pages/dashboard/DashboardPage'));
const SettingsPage = lazy(() => import('portal/workspace/pages/settings/SettingsPage'));
const WorkspacePage = lazy(() => import('portal/workspace/pages/workspace'));
const CourseListPage = lazy(() => import('portal/workspace/pages/courses/list'));
const CourseDetailsPage = lazy(() => import('portal/workspace/pages/courses/details'));
const FavoritesPage = lazy(() => import('portal/workspace/pages/favorites'));
const FavoritesPitchesPage = lazy(() => import('portal/workspace/pages/favorites/pitches'));
const TeamListPage = lazy(() => import('portal/workspace/pages/teams/list'));
const ContentPage = lazy(() => import('portal/workspace/pages/content/combine-list'));
const ContentFilesPage = lazy(() => import('portal/workspace/pages/content/files-page'));
const ContentFoldersPage = lazy(() => import('portal/workspace/pages/content/folders-page'));
const ContentListsPage = lazy(() => import('portal/workspace/pages/content/lists-page'));
const TeamDetailsPage = lazy(() => import('portal/workspace/pages/teams/details'));
const MembersPage = lazy(() => import('portal/workspace/pages/teams/members'));
const PitchDetailsPage = lazy(() => import('portal/workspace/pages/pitches/details'));
const PitchTrainingPage = lazy(() => import('portal/workspace/pages/pitches/training'));
const TrainingDetailsPage = lazy(() => import('portal/workspace/pages/pitches/training-details'));
const MemberDetailsPage = lazy(() => import('portal/workspace/pages/teams/members/details'));
const UpdatesPage = lazy(() => import('portal/workspace/pages/updates'));
const FeedbacksPage = lazy(() => import('portal/workspace/pages/feedbacks'));
const RecordDetails = lazy(() => import('portal/workspace/pages/records/RecordDetails'));
const RecordsList = lazy(() => import('portal/workspace/pages/records/List'));
const UserRecordDetails = lazy(() => import('portal/workspace/pages/records/UserRecordDetails'));
const PitchRecordsUsersList = lazy(() => import('portal/workspace/pages/records/PitchRecordsUsersList'));
const CoachWorkspacePage = lazy(() => import('portal/workspace/pages/workspace/coach-company'));
const CoachCompanyDetailsPage = lazy(() => import('portal/workspace/pages/workspace/coach-company/details'));
const CoachCourseList = lazy(() => import('portal/workspace/pages/coach/courses/list'));
const CoachWorkspaceCourseList = lazy(() => import('portal/workspace/pages/coach/workspace-courses/list'));
const UserManagementTab = lazy(() => import('portal/workspace/pages/workspace/tabs/user-management'));

//

/**
 * path - partial url (relative to parent route path)
 * key - (should be unique) used to getUrl helper to find route object
 */
const workspaceNestedRoutes: Record<string, GenericRoute[]> = {
  [PortalTypes.Workspace]: [
    {
      path: '/',
      exact: true,
      key: 'home',
      title: IntlKeys.dashboard,
      isDefault: true,
      component: DashboardPage,
      routes: [
        {
          path: '/sign-in',
          key: 'signIn',
          isDefault: true,
          restrictions: [AccessFlags.UNAUTHORIZED],
          title: IntlKeys.signIn,
          component: LoginPage,
          Layout: FullscreenLayout,
          routes: [],
        },
        {
          path: '/sign-up',
          key: 'signUp',
          restrictions: [AccessFlags.UNAUTHORIZED],
          title: IntlKeys.forgotPassword,
          component: SignUpPage,
          Layout: FullscreenLayout,
          routes: [],
        },
        {
          path: '/forgot-password',
          key: 'forgotPassword',
          restrictions: [AccessFlags.UNAUTHORIZED],
          title: IntlKeys.forgotPassword,
          component: ForgotPasswordPage,
          Layout: FullscreenLayout,
          routes: [],
        },
        {
          path: '/reset-password',
          key: 'resetPassword',
          restrictions: [AccessFlags.UNAUTHORIZED],
          title: IntlKeys.forgotPassword,
          component: ResetPasswordPage,
          Layout: FullscreenLayout,
          routes: [],
        },
        {
          path: '/settings',
          key: 'settings',

          title: IntlKeys.settings,
          component: SettingsPage,
        },
        {
          path: '/courses',
          key: 'courseList',
          restrictions: [AccessFlags.COURSES],
          title: IntlKeys.courses,
          component: CourseListPage,
          routes: [
            {
              path: '/:courseId',
              key: 'courseDetails',
              restrictions: [AccessFlags.COURSES],
              title: IntlKeys.courses,
              component: CourseDetailsPage,
              routes: [
                {
                  path: '/pitches/:pitchId',
                  key: 'trainingDetails',
                  title: IntlKeys.courses,
                  component: TrainingDetailsPage,
                  routes: [
                    {
                      path: '/edit',
                      key: 'editPitch',
                      title: IntlKeys.courses,
                      component: PitchDetailsPage,
                      Layout: FullwidthLayout,
                    },
                    {
                      path: '/training',
                      key: 'pitchTraining',
                      title: IntlKeys.courses,
                      component: PitchTrainingPage,
                      Layout: FullwidthLayout,
                    },
                    {
                      path: '/training/:slideId',
                      key: 'slideTraining',
                      title: IntlKeys.courses,
                    },
                    {
                      path: '/training/completed',
                      key: 'pitchTrainingFinished',
                      title: IntlKeys.courses,
                    },
                    {
                      path: '/records',
                      key: 'pitchRecords',
                      title: IntlKeys.courses,
                      restrictions: [AccessFlags.TEAMS],
                      component: PitchRecordsUsersList,
                      routes: [
                        {
                          path: '/:userId',
                          key: 'userRecordDetails',
                          title: IntlKeys.courses,
                          component: UserRecordDetails,
                        },
                      ],
                    },
                  ],
                },
                {
                  path: '/records',
                  key: 'recordList',
                  title: IntlKeys.records,
                  component: RecordsList,
                  routes: [
                    {
                      path: '/:recordId',
                      key: 'recordDetails',
                      title: IntlKeys.records,
                      component: RecordDetails,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: '/favorites',
          key: 'favorites',
          restrictions: [AccessFlags.COURSES],
          title: IntlKeys.favourites,
          component: FavoritesPage,
          routes: [
            {
              path: '/pitches',
              key: 'favoritePitches',
              title: IntlKeys.pitches,
              component: FavoritesPitchesPage,
            },
          ],
        },
        {
          path: '/pitches/:pitchId',
          key: 'globalTrainingDetails',
          title: IntlKeys.courses,
          component: TrainingDetailsPage,
        },
        {
          path: '/records',
          key: 'globalRecordList',
          title: IntlKeys.records,
          restrictions: [AccessFlags.COURSES],
          component: RecordsList,
          routes: [
            {
              path: '/:recordId',
              key: 'globalRecordDetails',
              title: IntlKeys.records,
              component: RecordDetails,
            },
          ],
        },
        {
          path: '/teams',
          key: 'teamList',
          restrictions: [AccessFlags.TEAMS],
          title: IntlKeys.teams,
          component: TeamListPage,
          routes: [
            {
              path: '/members',
              key: 'members',
              title: IntlKeys.members,
              component: MembersPage,
              routes: [
                {
                  path: '/:userId',
                  key: 'memberDetails',
                  title: IntlKeys.members,
                  component: MemberDetailsPage,
                  routes: [
                    {
                      path: '/courses/:courseId/pitches/:pitchId',
                      key: 'userPitchRecordDetails',
                      title: IntlKeys.members,
                      component: UserRecordDetails,
                    },
                  ],
                },
              ],
            },
            {
              path: '/:id',
              key: 'teamDetails',
              title: IntlKeys.teams,
              component: TeamDetailsPage,
              routes: [
                {
                  path: '/members/:userId',
                  key: 'teamMemberDetails',
                  title: IntlKeys.teams,
                  component: MemberDetailsPage,
                  routes: [
                    {
                      path: '/courses/:courseId/pitches/:pitchId',
                      key: 'teamUserPitchRecordDetails',
                      title: IntlKeys.teams,
                      component: UserRecordDetails,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: '/updates',
          key: 'updates',
          restrictions: [AccessFlags.COURSES],
          title: IntlKeys.updates,
          component: UpdatesPage,
          routes: [
            {
              path: '/feedbacks',
              key: 'feedbacks',
              title: IntlKeys.feedbacks,
              component: FeedbacksPage,
            },
          ],
        },
        {
          path: '/workspace',
          key: 'workspace',
          restrictions: [AccessFlags.WORKSPACE],
          title: IntlKeys.workspace,
          component: WorkspacePage,
          routes: [],
        },
        {
          path: '/content',
          key: 'content',
          restrictions: [AccessFlags.CONTENT],
          title: IntlKeys.content,
          component: ContentPage,
          routes: [
            {
              path: '/files',
              key: 'contentFiles',
              title: IntlKeys.files,
              component: ContentFilesPage,
            },
            {
              path: '/lists',
              key: 'contentLists',
              title: IntlKeys.lists,
              component: ContentListsPage,
            },
            {
              path: '/folders',
              key: 'contentFolders',
              title: IntlKeys.folders,
              component: ContentFoldersPage,
              routes: [
                {
                  path: '/:folderId',
                  key: 'contentFolder',
                  title: IntlKeys.folder,
                  component: ContentPage,
                  routes: [
                    {
                      path: '/files',
                      key: 'contentFolderFiles',
                      title: IntlKeys.files,
                      component: ContentFilesPage,
                    },
                    {
                      path: '/lists',
                      key: 'contentFolderLists',
                      title: IntlKeys.lists,
                      component: ContentListsPage,
                    },
                    {
                      path: '/folders',
                      key: 'contentFolderFolders',
                      title: IntlKeys.folders,
                      component: ContentFoldersPage,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  [PortalTypes.Scorm]: [
    {
      path: '/courses/:courseId',
      key: 'courseDetails',
      isDefault: true,
      restrictions: [AccessFlags.AUTHORIZED],
      title: IntlKeys.courses,
      Layout: FullscreenLayout,
      component: CourseDetailsPage,
      routes: [
        {
          path: '/pitches/:pitchId',
          key: 'coursePitchDetails',
          restrictions: [AccessFlags.PITCHES],
          title: IntlKeys.courses,
          component: PitchDetailsPage,
          Layout: FullscreenLayout,
          routes: [
            {
              path: '/training',
              key: 'pitchTraining',
              title: IntlKeys.courses,
              component: PitchTrainingPage,
              Layout: FullwidthLayout,
            },
            {
              path: '/training/:slideId',
              key: 'slideTraining',
              title: IntlKeys.courses,
            },
            {
              path: '/training/completed',
              key: 'pitchTrainingFinished',
              title: IntlKeys.courses,
            },
          ],
        },
      ],
    },
    {
      path: '/pitches/:pitchId/training',
      key: 'pitchTrainingSingle',
      restrictions: [AccessFlags.AUTHORIZED],
      title: IntlKeys.pitches,
      component: PitchTrainingPage,
      Layout: FullscreenLayout,
      routes: [
        {
          path: '/:slideId',
          key: 'slideTrainingSingle',
          title: IntlKeys.pitches,
        },
        {
          path: '/completed',
          key: 'pitchTrainingFinishedSingle',
          title: IntlKeys.pitches,
        },
      ],
    },
    {
      //TODO: make screen for error
      path: '/courses/:courseId',
      key: 'courseDetailsUnAuthed',
      isDefault: true,
      restrictions: [AccessFlags.UNAUTHORIZED],
      title: IntlKeys.courses,
      Layout: FullscreenLayout,
      component: CourseDetailsPage,
    },

    {
      path: '/pitches/:pitchId',
      key: 'pitchDetailsUnAuthed',
      restrictions: [AccessFlags.UNAUTHORIZED],
      title: IntlKeys.pitches,
      component: PitchDetailsPage,
      Layout: FullscreenLayout,
    },
  ],
};

const coachNestedRoutes: GenericRoute[] = [
  {
    path: '/',
    key: 'home',
    exact: true,
    isDefault: true,
    title: IntlKeys.dashboard,
    component: DashboardPage,
    routes: [
      {
        path: '/sign-in',
        key: 'signIn',
        isDefault: true,
        restrictions: [AccessFlags.UNAUTHORIZED],
        title: IntlKeys.signIn,
        component: LoginPage,
        Layout: FullscreenLayout,
        routes: [],
      },
      {
        path: '/courses',
        key: 'courseList',
        restrictions: [AccessFlags.COURSES],
        title: IntlKeys.courses,
        component: CoachCourseList,
        routes: [
          {
            path: '/:courseId',
            key: 'courseDetails',
            restrictions: [AccessFlags.COURSES],
            title: IntlKeys.courses,
            component: CourseDetailsPage,
            routes: [
              {
                path: '/pitches/:pitchId/edit',
                key: 'editPitch',
                title: IntlKeys.courses,
                component: PitchDetailsPage,
                Layout: FullwidthLayout,
              },
            ],
          },
        ],
      },
      {
        path: '/pitches/:pitchId',
        key: 'globalTrainingDetails',
        title: IntlKeys.courses,
        component: TrainingDetailsPage,
      },
      {
        path: '/workspaces',
        key: 'workspaces',
        restrictions: [AccessFlags.WORKSPACE],
        title: IntlKeys.workspaces,
        component: CoachWorkspacePage,
        routes: [
          {
            path: '/:workspaceId',
            key: 'workspaceDetails',
            title: IntlKeys.dashboard,
            component: CoachCompanyDetailsPage,
            routes: [
              {
                path: '/users',
                key: 'workspaceUserList',
                title: IntlKeys.users,
                component: UserManagementTab,
                routes: [],
              },
              {
                path: '/teams',
                key: 'workspaceTeamList',
                restrictions: [AccessFlags.TEAMS],
                title: IntlKeys.teams,
                component: TeamListPage,
                routes: [
                  {
                    path: '/members',
                    key: 'coachMembers',
                    title: IntlKeys.members,
                    component: MembersPage,
                    routes: [
                      {
                        path: '/:userId',
                        key: 'coachMemberDetails',
                        title: IntlKeys.members,
                        component: MemberDetailsPage,
                        routes: [
                          {
                            path: '/courses/:courseId/pitches/:pitchId',
                            key: 'coachUserPitchRecordDetails',
                            title: IntlKeys.members,
                            component: UserRecordDetails,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: '/:id',
                    key: 'coachTeamDetails',
                    title: IntlKeys.teams,
                    component: TeamDetailsPage,
                    routes: [
                      {
                        path: '/members/:userId',
                        key: 'coachTeamMemberDetails',
                        title: IntlKeys.teams,
                        component: MemberDetailsPage,
                        routes: [
                          {
                            path: '/courses/:courseId/pitches/:pitchId',
                            key: 'coachTeamUserPitchRecordDetails',
                            title: IntlKeys.teams,
                            component: UserRecordDetails,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                path: '/courses',
                key: 'boundedCourseList',
                restrictions: [AccessFlags.COURSES],
                title: IntlKeys.courses,
                component: CoachWorkspaceCourseList,
                routes: [
                  {
                    path: '/:courseId',
                    key: 'boundedCourseDetails',
                    restrictions: [AccessFlags.COURSES],
                    title: IntlKeys.courses,
                    component: CourseDetailsPage,
                    routes: [
                      {
                        path: '/pitches/:pitchId',
                        key: 'boundedTrainingDetails',
                        title: IntlKeys.courses,
                        component: TrainingDetailsPage,
                        routes: [
                          {
                            path: '/training',
                            key: 'boundedPitchTraining',
                            title: IntlKeys.courses,
                            component: PitchTrainingPage,
                            Layout: FullwidthLayout,
                          },
                          {
                            path: '/training/:slideId',
                            key: 'boundedSlideTraining',
                            title: IntlKeys.courses,
                          },
                          {
                            path: '/training/completed',
                            key: 'boundedPitchTrainingFinished',
                            title: IntlKeys.courses,
                          },
                          {
                            path: '/records',
                            key: 'boundedPitchRecords',
                            title: IntlKeys.courses,
                            restrictions: [AccessFlags.TEAMS],
                            component: PitchRecordsUsersList,
                            routes: [
                              {
                                path: '/:userId',
                                key: 'boundedUserRecordDetails',
                                title: IntlKeys.courses,
                                component: UserRecordDetails,
                              },
                            ],
                          },
                        ],
                      },
                      {
                        path: '/records',
                        key: 'boundedCourseRecordList',
                        title: IntlKeys.records,
                        component: RecordsList,
                        routes: [
                          {
                            path: '/:recordId',
                            key: 'boundedCourseRecordDetails',
                            title: IntlKeys.records,
                            component: RecordDetails,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                path: '/pitches/:pitchId',
                key: 'workspaceTrainingDetails',
                title: IntlKeys.courses,
                component: TrainingDetailsPage,
              },
              {
                path: '/records',
                key: 'boundedRecordList',
                title: IntlKeys.records,
                component: RecordsList,
                routes: [
                  {
                    path: '/:recordId',
                    key: 'boundedRecordDetails',
                    title: IntlKeys.records,
                    component: RecordDetails,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: '/settings',
        key: 'coachSettings',

        title: IntlKeys.settings,
        component: SettingsPage,
      },
    ],
  },
];

const fallbackRoute = ({ restrictions = [], ...route }: GenericChildRoute) => {
  const hasAuthRestriction = restrictions.find(
    (restriction) => restriction === AccessFlags.AUTHORIZED || restriction === AccessFlags.UNAUTHORIZED,
  );

  // make all routes is authorized only if otherwise isn't mentioned
  if (!hasAuthRestriction) {
    restrictions.push(AccessFlags.AUTHORIZED);
  }

  return {
    restrictions,
    Layout: DefaultLayout,
    //
    ...route,
  };
};

const workspaceRoutes: GenericChildRoute[] = generateAppRoutes(workspaceNestedRoutes[currentPortalType]).map(
  fallbackRoute,
);
const coachRoutes: GenericChildRoute[] = generateAppRoutes(coachNestedRoutes).map(fallbackRoute);

if (process.env.NODE_ENV === 'development') {
  // Development only checks for route uniqueness (key/path)
  checkDuplicateRoutes(coachRoutes);
  checkDuplicateRoutes(workspaceRoutes);
}

export { workspaceRoutes, coachRoutes };
export default workspaceRoutes;
