import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
// types
import { CourseListResponse, CourseType, CoursePayloadType, TagType, FavoriteType } from 'types/courses';
// utils
import { Params } from 'lib/http';
import { createApiCall } from 'lib/http/utils/createApiCall';
import { saveFile } from 'lib/saveFile';
//
import { uploadImage } from 'portal/backoffice/lib/uploadFile';
import { backofficeRestClient } from 'portal/backoffice/lib/http';
import { CourseShareWorkspacesPayload } from 'types/courses/entities';

export const fetchCoursesWorker = async <T>(requestPayload: T) => {
  const callApi = createApiCall<CourseListResponse>({
    api: backofficeRestClient.getCourseList,
  });

  const { data } = await callApi(requestPayload);

  return data;
};

export const favoriteCourseAction = createAsyncThunk<{ success: boolean }, Params<{ id: number }>>(
  'courses/favoriteCourse',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<FavoriteType>({
      api: backofficeRestClient.favoriteCourse,
    });
    try {
      await callApi(requestPayload);
      return { success: true };
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const defavoriteCourseAction = createAsyncThunk<{ success: boolean }, Params<{ id: number }>>(
  'courses/defavoriteCourse',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<{ success: true }>({
      api: backofficeRestClient.defavoriteCourse,
    });
    try {
      const { data } = await callApi(requestPayload);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getCourseAction = createAsyncThunk<CourseType, Params>(
  'courses/getCourse',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<CourseType>({
      api: backofficeRestClient.getCourse,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const exportToScormAction = createAsyncThunk<Blob, Params<{ id: string | number }>>(
  'courses/exportToScorm',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<Blob>({
      api: backofficeRestClient.exportToScormCourse,
    });

    try {
      const { data } = await callApi(requestPayload);

      saveFile(data, `SCORM_Course_id-${requestPayload.params.id}.zip`);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const deleteCourseAction = createAsyncThunk<{ success: boolean }, Params>(
  'courses/deleteCourse',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<{ success: boolean }>({
      api: backofficeRestClient.deleteCourse,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const duplicateCourseAction = createAsyncThunk<{ success: boolean }, Params>(
  'courses/duplicateCourse',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<{ success: boolean }>({
      api: backofficeRestClient.duplicateCourse,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const duplicateCoursesAction = createAsyncThunk<{ success: boolean }, Params>(
  'courses/duplicateCourses',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<{ success: boolean }>({
      api: backofficeRestClient.duplicateCourses,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const ensureCoverUploaded = async (cover: CoursePayloadType['cover']) => {
  const coverUrl =
    cover instanceof Blob
      ? await uploadImage({
          image: cover,
          name: 'CourseCover',
        })
      : cover;

  return coverUrl || null;
};

export const createCourseAction = createAsyncThunk<CourseType, CoursePayloadType>(
  'courses/createCourse',
  async (requestPayload, { rejectWithValue }) => {
    const { cover, ...rest } = requestPayload as CoursePayloadType;

    const callApi = createApiCall<CourseType>({
      api: backofficeRestClient.createCourse,
    });

    try {
      const { data } = await callApi({
        ...rest,
        cover: await ensureCoverUploaded(cover),
      });

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateStatusCourseAction = createAsyncThunk<CourseType, Params>(
  'courses/updateStatusCourse',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<CourseType>({
      api: backofficeRestClient.patchCourse,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateCourseStatusAction = createAsyncThunk<CourseType, Params>(
  'courses/updateCourseStatus',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<CourseType>({
      api: backofficeRestClient.patchCourse,
    });
    try {
      const { data } = await callApi(requestPayload);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateCourseAction = createAsyncThunk<CourseType, Params>(
  'courses/updateCourse',
  async (requestPayload, { rejectWithValue }) => {
    const { cover, ...rest } = requestPayload as CoursePayloadType & { cover: Blob | string };

    const callApi = createApiCall<CourseType>({
      api: backofficeRestClient.updateCourse,
    });

    try {
      const { data } = await callApi({
        ...rest,
        cover: await ensureCoverUploaded(cover),
      });

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getTagListAction = createAsyncThunk<{ data: TagType[] }, Params | undefined>(
  'courses/getTagList',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<{ data: TagType[] }>({
      api: backofficeRestClient.getTagList,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const shareCourseAction = createAsyncThunk<CourseType, CourseShareWorkspacesPayload>(
  'courses/shareCourse',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<CourseType>({
      api: backofficeRestClient.shareCourse,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const unshareCourseAction = createAsyncThunk<{ success: boolean }, CourseShareWorkspacesPayload | Params>(
  'courses/shareCourse',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<CourseType>({
      api: backofficeRestClient.unshareCourse,
    });

    try {
      await callApi(requestPayload);
      return { success: true };
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const coursesSetIsStaleAction = createAction<boolean>('courses/setIsStale');
export const resetCourseAction = createAction('courses/reset');

export const updateSelectedCoursesAction = createAction<{
  teamId: number;
  courseId: number;
  action: 'add' | 'delete' | 'reset';
}>('courses/updateSelectedCourses');

export const resetSelectedCoursesAction = createAction<{
  teamId?: number | string;
}>('courses/resetSelectedCourses');

export const attachTeamsToCourseAction = createAsyncThunk<{ success: boolean }, Params>(
  'courses/attachTeamsToCourse',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<{ success: boolean }>({
      api: backofficeRestClient.attachTeamsToCourse,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const detachTeamsFromCourseAction = createAsyncThunk<{ success: boolean }, Params>(
  'courses/detachTeamsFromCourse',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<{ success: boolean }>({
      api: backofficeRestClient.detachTeamsFromCourse,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const attachMembersToCourseAction = createAsyncThunk<{ success: boolean }, Params>(
  'courses/attachMembersToCourse',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<{ success: boolean }>({
      api: backofficeRestClient.attachMembersToCourse,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const detachMembersFromCourseAction = createAsyncThunk<{ success: boolean }, Params>(
  'courses/detachMembersFromCourse',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<{ success: boolean }>({
      api: backofficeRestClient.detachMembersFromCourse,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
