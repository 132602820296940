import { createReducer } from '@reduxjs/toolkit';
import { Reducer } from 'redux';
import merge from 'lodash/merge';
import type { Draft } from 'immer';
// local
import { TeamType } from 'types/teams';
import { TeamSortConfigs } from 'portal/backoffice/domain/teams/entities';
import {
  changeTeamsRequestParamsAction,
  getTeamsAction,
  getTeamAction,
  updateTeamAction,
  createTeamAction,
  deleteTeamAction,
  detachCoursesFromTeamAction,
  attachCoursesToTeamAction,
  resetAtachDetachCoursesAction,
  attachCoursesAndTeamsAction,
  teamsSetIsStaleAction,
} from './actions';

const initialState = {
  meta: {
    isLoading: false,
    isLoaded: false,
    isStale: false,
    isTeamLoading: false,
    isDetachLoading: false,
    isAttachLoading: false,
    isAttachDetachSuccess: false,
  },
  filters: {} as Record<string, string>,
  sort: TeamSortConfigs.teamNameAsc,
  pagination: {
    page: 0,
    perPage: 10,
    pageCount: 0,
    total: 0,
  },
  resources: [] as TeamType[],
  resource: {} as TeamType,
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getTeamsAction.pending, (draft, {}) => {
      //
      draft.meta.isLoading = true;
      draft.meta.isLoaded = false;
    })
    .addCase(getTeamsAction.fulfilled, (draft, { payload }) => {
      const { data, ...pagination } = payload;

      draft.meta.isLoading = false;
      draft.meta.isLoaded = true;
      draft.meta.isStale = false;

      draft.resources = pagination.page === initialState.pagination.page ? data : [...draft.resources, ...data];

      draft.pagination = {
        ...draft.pagination,
        ...pagination,
      };
    })
    .addCase(getTeamsAction.rejected, (draft) => {
      draft.meta.isLoading = false;
      draft.meta.isLoaded = false;
    })
    .addCase(getTeamAction.pending, (draft, {}) => {
      draft.meta.isTeamLoading = true;
    })
    .addCase(getTeamAction.fulfilled, (draft, { payload }) => {
      draft.resource = payload as Draft<TeamType>;
      draft.meta.isTeamLoading = false;
    })

    .addCase(getTeamAction.rejected, (draft) => {
      draft.meta.isTeamLoading = false;
    })
    .addCase(changeTeamsRequestParamsAction, (draft, { payload }) => {
      draft.meta.isStale = true;
      draft.meta.isLoaded = false;
      draft.filters = merge(draft.filters, payload.filters);
      draft.sort = merge(draft.sort, payload.sort);
      draft.resources = initialState.resources;
      draft.pagination = initialState.pagination;
    })
    .addCase(updateTeamAction.fulfilled, (draft) => {
      draft.meta.isStale = true;
    })
    .addCase(createTeamAction.fulfilled, (draft) => {
      draft.meta.isStale = true;
    })
    .addCase(deleteTeamAction.fulfilled, (draft) => {
      draft.meta.isStale = true;
    })
    ///////////////////////////////////////////////////////////
    .addCase(detachCoursesFromTeamAction.pending, (draft, {}) => {
      draft.meta.isDetachLoading = true;
      draft.meta.isAttachDetachSuccess = false;
    })
    .addCase(detachCoursesFromTeamAction.fulfilled, (draft) => {
      draft.meta.isDetachLoading = false;
      draft.meta.isAttachDetachSuccess = true;
    })

    .addCase(detachCoursesFromTeamAction.rejected, (draft) => {
      draft.meta.isDetachLoading = false;
    })
    ///////////////////////////////////////////////////////////
    .addCase(attachCoursesToTeamAction.pending, (draft, {}) => {
      draft.meta.isAttachLoading = true;
      draft.meta.isAttachDetachSuccess = false;
    })
    .addCase(attachCoursesToTeamAction.fulfilled, (draft) => {
      draft.meta.isAttachLoading = false;
      draft.meta.isAttachDetachSuccess = true;
    })

    .addCase(attachCoursesToTeamAction.rejected, (draft) => {
      draft.meta.isAttachLoading = false;
    })
    ///////////////////////////////////////////////////////////
    .addCase(attachCoursesAndTeamsAction.pending, (draft, {}) => {
      draft.meta.isAttachLoading = true;
      draft.meta.isAttachDetachSuccess = false;
    })
    .addCase(attachCoursesAndTeamsAction.fulfilled, (draft) => {
      draft.meta.isAttachLoading = false;
      draft.meta.isAttachDetachSuccess = true;
    })

    .addCase(attachCoursesAndTeamsAction.rejected, (draft) => {
      draft.meta.isAttachLoading = false;
    })
    ///////////////////////////////////////////////////////////
    .addCase(resetAtachDetachCoursesAction, (draft) => {
      draft.meta.isAttachLoading = false;
      draft.meta.isAttachDetachSuccess = false;
      draft.meta.isAttachLoading = false;
    })
    ////////////////////////////////////////////////////////////////////////////////////////////////
    .addCase(teamsSetIsStaleAction, (draft, { payload }) => {
      draft.meta.isStale = payload;
    });
});

export const teamReducer: Reducer<typeof initialState> = (draft = initialState, action) => {
  return reducer(draft, action);
};
