import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
// types
import { CreateUserType, UserType } from 'types/users';
// utils
import { backofficeRestClient } from 'portal/backoffice/lib/http';
import { Params } from 'lib/http';
import { createApiCall } from 'lib/http/utils/createApiCall';
// local
import { UsersResponse } from './entities';
import { workspaceRestClient } from '../../../workspace/lib/http';

export const fetchUsersWorker = async <T>(requestPayload: T) => {
  const callApi = createApiCall<UsersResponse>({
    api: backofficeRestClient.getUsers,
  });

  const { data } = await callApi(requestPayload);

  return data;
};
export const getUsersAction = createAsyncThunk<UsersResponse, Params | undefined>(
  'users/getUsers',
  async (requestPayload, { rejectWithValue }) => {
    try {
      return await fetchUsersWorker(requestPayload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createUserAction = createAsyncThunk<{ success: boolean }, Params>(
  'users/createUsers',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<{ success: boolean }>({
      api: backofficeRestClient.createUser,
    });
    try {
      const { data } = await callApi(requestPayload);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const deleteUserAction = createAsyncThunk<{ success: boolean }, Params>(
  'users/deleteUsers',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<{ success: boolean }>({
      api: backofficeRestClient.deleteUser,
    });
    try {
      const { data } = await callApi(requestPayload);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
export const changeUsersRequestParamsAction = createAction<{
  sort: Record<string, unknown>;
  filters: Record<string, unknown>;
}>('users/changeUsersParams');

export const patchUserAction = createAsyncThunk<UserType, Params>(
  'users/patchUser',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<UserType>({
      api: backofficeRestClient.patchUser,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getUserAction = createAsyncThunk<UserType, Params>(
  'users/getUser',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<UserType>({
      api: backofficeRestClient.getUser,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const bulkUploadUsersAction = createAsyncThunk<UserType[], CreateUserType[]>(
  'users/import',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<UserType[]>({
      api: backofficeRestClient.importUsers,
    });

    try {
      const { data } = await callApi({ users: requestPayload });

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const usersSetIsStaleAction = createAction<boolean>('users/setIsStale');

export const updateUserStatusAction = createAsyncThunk<{ success: boolean }, Params>(
  'users/updateUserStatus',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<{ success: boolean }>({
      api: backofficeRestClient.updateUserStatus,
    });
    try {
      const { data } = await callApi(requestPayload);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateUserInfoAction = createAsyncThunk<Partial<UserType>, Params>(
  'users/updateUserInfo',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<Partial<UserType>>({
      api: backofficeRestClient.updateUser,
    });
    try {
      const { data } = await callApi(requestPayload);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
