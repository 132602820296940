import { ResourcesResponse } from 'lib/http';
import { Notification } from 'types/notification';

export type NotificationResponse = ResourcesResponse<Notification>;

export type NotificationStateType = {
  meta: {
    isLoading: boolean;
    isResourcesLoaded: boolean;
    isStale: boolean;
  };
  sort: SortConfigType;
  filters: Record<string, string>;
  pagination: {
    page: number;
    perPage: number;
    pageCount: number;
    total: number;
  };
  resources: Notification[];
  resource: Notification | null;
};

export type SortConfigType = {
  field: string;
  order: 'ASC' | 'DESC';
};

export const uSortConfigs = {
  createdAtDesc: {
    field: 'createdAt',
    order: 'DESC',
  },
};

export const NotificationSortConfigs = uSortConfigs as Record<keyof typeof uSortConfigs, SortConfigType>;
