import { FilterFormValues } from 'components/filter-bar';
import { ResourcesResponse } from 'lib/http';
import { IntlKeys } from 'localization';
import { useIntl } from 'react-intl';
import { SortConfigType } from './common';

export type NewTeamPayload = {
  name: string;
  emoji: string;
  users: number[];
  workspaceId?: number;
};

export type TeamType = {
  id: number;
  name: string;
  emoji: string;
  workspaceId: number;
  creatorId: number;
  coursesCount: number;
  users: { id: number; firstName: string; lastName: string; avatar: string }[];
  courses: [];
  newRecordsCount: number;
};

export type TeamsResponse = ResourcesResponse<TeamType>;

export const uSortConfigs = {
  teamNameAsc: {
    field: 'teamName',
    order: 'ASC',
  },
  teamNameDesc: {
    field: 'teamName',
    order: 'DESC',
  },
  createdAtAsc: {
    field: 'createdAt',
    order: 'ASC',
  },
  createdAtDesc: {
    field: 'createdAt',
    order: 'DESC',
  },
  memberCountAsc: {
    field: 'memberCount',
    order: 'ASC',
  },
  memberCountDesc: {
    field: 'memberCount',
    order: 'DESC',
  },
};

export const TeamSortWithLabels = () => {
  const { formatMessage } = useIntl();
  return [
    {
      label: formatMessage({ id: IntlKeys.teamsFilterTeamNameAtoZ }),
      value: 'teamNameAsc',
    },
    {
      label: formatMessage({ id: IntlKeys.teamsFilterTeamNameZtoA }),
      value: 'teamNameDesc',
    },
    {
      label: formatMessage({ id: IntlKeys.teamsFilterNumberOfMembersAsc }),
      value: 'memberCountAsc',
    },
    {
      label: formatMessage({ id: IntlKeys.teamsFilterNumberOfMembersDesc }),
      value: 'memberCountDesc',
    },
    {
      label: formatMessage({ id: IntlKeys.teamFilterCreatedAtAsc }),
      value: 'createdAtAsc',
    },
    {
      label: formatMessage({ id: IntlKeys.teamFilterCreatedAtDesc }),
      value: 'createdAtDesc',
    },
  ];
};

export const TeamSortConfigs = uSortConfigs as Record<keyof typeof uSortConfigs, SortConfigType>;
export type TeamFiltersFormValues = FilterFormValues<keyof typeof TeamSortConfigs>;

export type TeamListRequestParams = {
  sort: keyof typeof TeamSortConfigs;
  filters: Record<'search' | string, string>;
};
