import React from 'react';
import { useIntl } from 'react-intl';
import { NavigationPromptProps } from 'react-router-navigation-prompt';
// types
import { ConfirmDialogProps } from 'components/confirm-dialog';
// utils
import { IntlKeys } from 'localization';
// components
import NavigationPrompt from 'react-router-navigation-prompt';
import ConfirmDialog from 'components/confirm-dialog';

interface OwnProps extends Partial<Omit<ConfirmDialogProps, 'onConfirm' | 'onCancel' | 'isOpen' | 'title'>> {
  when: NavigationPromptProps['when'];
  promptMessage?: string;
  handleConfirm?: () => void;
  handleCancel?: () => void;
}

const LeavePageBlocker = ({
  when,
  promptMessage,
  handleConfirm = () => {},
  handleCancel = () => {},
  ...confirmDialogProps
}: OwnProps) => {
  const { formatMessage } = useIntl();
  const message = promptMessage || formatMessage({ id: IntlKeys.leavePageProomptMessage });

  return (
    <NavigationPrompt when={when}>
      {({ onConfirm, onCancel }) => (
        <ConfirmDialog
          isOpen={true}
          title={formatMessage({ id: IntlKeys.leavePageQuestion })}
          subText={message}
          onConfirm={() => {
            handleConfirm();
            onConfirm();
          }}
          onCancel={() => {
            handleCancel();
            onCancel();
          }}
          {...confirmDialogProps}
        />
      )}
    </NavigationPrompt>
  );
};

export default LeavePageBlocker;
