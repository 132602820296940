import { SortConfigType } from 'types/common';

export const uSortConfigs = {
  teamNameAsc: {
    field: 'teamName',
    order: 'ASC',
  },
  teamNameDesc: {
    field: 'teamName',
    order: 'DESC',
  },
  createdAtAsc: {
    field: 'createdAt',
    order: 'ASC',
  },
  createdAtDesc: {
    field: 'createdAt',
    order: 'DESC',
  },
  memberCountAsc: {
    field: 'memberCount',
    order: 'ASC',
  },
  memberCountDesc: {
    field: 'memberCount',
    order: 'DESC',
  },
};

export const TeamSortConfigs = uSortConfigs as Record<keyof typeof uSortConfigs, SortConfigType>;
