// Members Leaderboard
import { ResourcesResponse } from 'lib/http';
import { SortConfigType } from './common';

export interface MembersLeaderboardType {
  id: string;
  firstName?: string;
  lastName?: string;
  avatar?: string;
  jobTitle?: string;
  //
  completedCourseCount?: number;
  totalCourseCount?: number;
  completedPercent: number;
  score: number;
}

export type MembersLeaderboardResponse = ResourcesResponse<MembersLeaderboardType>;

// Teams Leaderboard
export interface TeamsLeaderboardType {
  id: string;
  name: string;
  emoji: string;
  score: number;
  completedPercent: number;
  jobTitle?: string;
}
export type TeamsLeaderboardResponse = ResourcesResponse<TeamsLeaderboardType>;

// Workspace Leaderboard
export interface WorkspacesLeaderboardType {
  id: string;
  name: string;
  logo: string;
  completedPercent: number;
  score: number;
  quizScore: number;
}

export type WorkspacesLeaderboardResponse = ResourcesResponse<WorkspacesLeaderboardType>;

export interface LeaderboardType {
  id: number;
  firstName?: string;
  lastName?: string;
  avatar?: string;
  jobTitle?: string;
  name?: string;
  emoji?: string;
  logo?: string;
  //
  completedCourseCount?: number;
  totalCourseCount?: number;
  completedPercent: number;
  score: number;
}
const teamsLeaderboardSortConfigs = {
  scoreAsc: {
    field: 'score',
    order: 'ASC',
  },
  scoreDesc: {
    field: 'score',
    order: 'DESC',
  },
  completedPercentAsc: {
    field: 'completedPercent',
    order: 'ASC',
  },
  completedPercentDesc: {
    field: 'completedPercent',
    order: 'DESC',
  },
};

export const TeamsLeaderboardSortConfigs = teamsLeaderboardSortConfigs as Record<
  keyof typeof teamsLeaderboardSortConfigs,
  SortConfigType
>;
