import { createReducer } from '@reduxjs/toolkit';
import { Reducer } from 'redux';
//
import { updateFileUploadProgressAction } from './actions';
import { FileProgress } from 'types/common';

const initialState = {
  uploadingFiles: {} as Record<string, FileProgress>,
};

const reducer = createReducer(initialState, (builder) => {
  builder
    ////////////////////////////////////////////////////////////////////////////////////////////////
    .addCase(updateFileUploadProgressAction, (draft, { payload }) => {
      if (payload.hasFinished) {
        const removed = { ...draft.uploadingFiles };
        delete removed[payload.id];
        draft.uploadingFiles = removed;
      } else {
        draft.uploadingFiles[payload.id] = payload;
      }
    });
});

export const generalReducer: Reducer<typeof initialState> = (draft = initialState, action) => {
  return reducer(draft, action);
};
