import { createSelector } from '@reduxjs/toolkit';
//
import { RootState } from '../store';

const teamsSelector = (state: RootState) => state.teams;

export const teamsMetaSelector = createSelector(teamsSelector, (teams) => teams.meta);
export const teamsIsLoadingSelector = createSelector(teamsSelector, (teams) => teams.meta.isLoading);
export const teamsIsLoadedSelector = createSelector(teamsSelector, (teams) => teams.meta.isLoaded);
export const teamsIsStaleSelector = createSelector(teamsSelector, (teams) => teams.meta.isStale);

export const teamsPaginationSelector = createSelector(teamsSelector, (teams) => teams.pagination);
export const teamsFiltersSelector = createSelector(teamsSelector, (teams) => teams.filters);
export const teamsSortSelector = createSelector(teamsSelector, (teams) => teams.sort);

export const teamsResourcesSelector = createSelector(teamsSelector, (teams) => teams.resources);
export const teamsResourceSelector = createSelector(teamsSelector, (teams) => teams.resource);

export const teamsListDataSelector = createSelector(
  [
    teamsIsStaleSelector,
    teamsIsLoadingSelector,
    teamsIsLoadedSelector,
    teamsResourcesSelector,
    teamsPaginationSelector,
    teamsFiltersSelector,
    teamsSortSelector,
  ],
  (isStale, isLoading, isLoaded, resources, pagination, filters, sort) => ({
    isStale,
    isLoading,
    isLoaded,
    resources,
    pagination,
    filters,
    sort,
  }),
);
