import AccessControlListsScheme from 'lib/acl';
//
import { authUserSelector } from 'portal/backoffice/domain/auth/selectors';
import { store } from 'portal/backoffice/domain/store';

class AccessControlLists extends AccessControlListsScheme {
  get me() {
    return authUserSelector(store.getState());
  }
}

const ACL = new AccessControlLists();

export default ACL;
