import { createAsyncThunk } from '@reduxjs/toolkit';
import { ContentParams } from 'types/content/general';
import { ContentFolderType, CreateFolderParams, ContentFolderShowType } from 'types/content/folders';
import { createApiCall } from 'lib/http/utils/createApiCall';
import { workspaceRestClient } from 'portal/workspace/lib/http';
import { Params, ResourceResponse } from 'lib/http';
import { toast } from 'react-toastify';
import { intl, IntlKeys } from 'localization';

export const getFoldersAction = createAsyncThunk<ResourceResponse<ContentFolderType[]>, ContentParams>(
  'content/getFolders',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<ResourceResponse<ContentFolderType[]>>({
      api: workspaceRestClient.getContentFolders,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createFolderAction = createAsyncThunk<ContentFolderType, CreateFolderParams>(
  'content/createFolder',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<ContentFolderType>({
      api: workspaceRestClient.createFolder,
    });

    try {
      const { data } = await callApi(requestPayload);
      toast(intl.formatMessage({ id: IntlKeys.alertFolderCreatingFulfilled }));

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

type ContentDeleteParams = {
  params: {
    id: number;
  };
};

export const deleteFolderAction = createAsyncThunk<number, ContentDeleteParams>(
  'content/deleteFolder',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<{ success: boolean }>({
      api: workspaceRestClient.deleteFolder,
    });

    try {
      await callApi(requestPayload);
      // TODO change to snackbar and replace network handling to hook
      toast(intl.formatMessage({ id: IntlKeys.alertFolderDeletingFulfilled }));

      return requestPayload!.params.id;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getFolderAction = createAsyncThunk<ContentFolderShowType, Params>(
  'content/getFolder',
  async (requestPayload: Params, { rejectWithValue }) => {
    const callApi = createApiCall<ContentFolderShowType>({
      api: workspaceRestClient.getFolder,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateFolderAction = createAsyncThunk<ContentFolderType, Params>(
  'content/updateFolder',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<ContentFolderType>({
      api: workspaceRestClient.updateFolder,
    });

    try {
      const { data } = await callApi(requestPayload);
      toast(intl.formatMessage({ id: IntlKeys.alertFolderUpdatingFulfilled }));

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
