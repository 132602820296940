import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    background: 'white',

    boxShadow: 'none',
    borderBottom: `1px solid ${theme.colors.stroke}`,
  },

  logo: {
    width: 165,
    margin: '0px 10px',

    [theme.breakpoints.down('md')]: {
      margin: 0,
    },
  },

  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: theme.dimensions.headerHeight,
    padding: '0 16px',

    fontFamily: theme.fonts.gilmer,
    fontSize: 16,
    color: theme.colors.oxfordBlue,
  },

  mobileContent: {
    width: '100%',
  },

  breadcrumbs: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
  },

  breadcrumbsOnMobile: {
    [theme.breakpoints.down('md')]: {
      borderTop: `1px solid ${theme.colors.stroke}`,
      padding: '2px 24px',
      fontSize: 12,
      maxHeight: 36,
      margin: '.1rem -2rem 0 -2rem',
    },
  },

  breadcrumb: {
    borderLeft: `1px solid ${theme.colors.secondary}`,
    paddingLeft: '0.5rem',
    margin: '0.5rem',
    color: theme.colors.secondary,
    '&:last-child': {
      fontWeight: 800,
      color: theme.colors.oxfordBlue,
    },

    '&:hover': {
      cursor: 'pointer',
    },
    [theme.breakpoints.down('md')]: {
      margin: '0 .5rem 0 0',
      paddingLeft: 0,
      paddingRight: '0.5rem',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      borderLeft: 'none',
      borderRight: `1px solid ${theme.colors.secondary}`,
      '&:last-child': {
        border: 'none',
      },
    },
  },

  back: {
    cursor: 'pointer',
    padding: '13px 13px 5px 13px',
    height: 'fit-content',
    marginRight: 16,
    border: `1px solid ${theme.colors.stroke}`,
    borderRadius: '50%',
    [theme.breakpoints.down('md')]: {
      border: 'none',
      height: 24,
      padding: 0,
      marginRight: 0,
    },
  },

  menuContainer: {
    display: 'flex',
    padding: '0 20px',
  },

  mobileMenuContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  notificationIcon: {
    width: 48,
    height: 48,
    marginRight: '1rem',
    padding: 12,
    borderRadius: '50%',
    border: `1px solid ${theme.colors.stroke}`,
  },

  mobileNotifictionIcon: {
    display: 'flex',
    width: 40,
    height: 40,
    padding: 10,
    borderRadius: '50%',
    border: `1px solid ${theme.colors.stroke}`,
  },

  avatar: {
    width: 48,
    height: 48,
    border: `1px solid ${theme.colors.stroke}`,
  },

  button: {
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
  },
  mobileToolbar: {
    width: '100%',
  },
  mobileToolbarTop: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));
