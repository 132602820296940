import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
//
import { PitchType } from 'types/pitches';
//
import { Params } from 'lib/http';
import { createApiCall } from 'lib/http/utils/createApiCall';
import { backofficeRestClient } from 'portal/backoffice/lib/http';
import { uploadImage } from 'portal/backoffice/lib/uploadFile';
//
import { PitchListResponse, NewPitchPayload } from './entities';
import { FavoriteType } from 'types/courses';
import { saveFile } from 'lib/saveFile';

export const fetchPitchListWorker = async <T>(requestPayload: T) => {
  const callApi = createApiCall<PitchListResponse>({
    api: backofficeRestClient.getPitchList,
  });

  const { data } = await callApi(requestPayload);

  return data;
};

export const favoritePitchAction = createAsyncThunk<{ success: boolean }, Params>(
  'pitches/favoritePitch',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<FavoriteType>({
      api: backofficeRestClient.favoritePitch,
    });
    try {
      await callApi(requestPayload);
      return { success: true };
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const defavoritePitchAction = createAsyncThunk<{ success: boolean }, Params>(
  'courses/defavoriteCourse',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<{ success: boolean }>({
      api: backofficeRestClient.defavoritePitch,
    });
    try {
      const { data } = await callApi(requestPayload);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createPitchAction = createAsyncThunk<PitchType, Params>(
  'pitches/createPitch',
  async (requestPayload, { rejectWithValue }) => {
    const { cover, ...rest } = requestPayload as NewPitchPayload;

    const callApi = createApiCall<PitchType>({
      api: backofficeRestClient.createPitch,
    });

    try {
      const coverUrl = await uploadImage({
        image: cover,
        name: 'PitchCover',
      });

      const { data } = await callApi({
        ...rest,
        cover: coverUrl,
      });

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updatePitchAction = createAsyncThunk<PitchType, Params>(
  'pitches/updatePitch',
  async (requestPayload, { rejectWithValue }) => {
    const { cover, ...rest } = requestPayload as NewPitchPayload;

    const callApi = createApiCall<PitchType>({
      api: backofficeRestClient.updatePitch,
    });

    try {
      const coverUrl = await uploadImage({
        image: cover,
        name: 'PitchCover',
      });

      const { data } = await callApi({
        ...rest,
        cover: coverUrl,
      });

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const patchPitchAction = createAsyncThunk<PitchType, Params>(
  'pitches/patchPitch',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<PitchType>({
      api: backofficeRestClient.patchPitch,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const deletePitchAction = createAsyncThunk<{ success: boolean }, Params>(
  'pitches/deletePitch',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<{ success: boolean }>({
      api: backofficeRestClient.deletePitch,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const copyPitchToCourseAction = createAsyncThunk<{ success: boolean }, Params>(
  'pitches/copyPitchToCourse',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<{ success: boolean }>({
      api: backofficeRestClient.pitchCopyToCourse,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const copyPitchesToCourseAction = createAsyncThunk<{ success: boolean }, Params>(
  'pitches/copyPitchesToCourses',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<{ success: boolean }>({
      api: backofficeRestClient.pitchesCopyToCourses,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const exportPitchToScormAction = createAsyncThunk<Blob, Params<{ id: number }>>(
  'pitches/exportToScorm',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<Blob>({
      api: backofficeRestClient.exportToScormPitch,
    });

    try {
      const { data } = await callApi(requestPayload);
      saveFile(data, `SCORM_Pitch_id-${requestPayload.params.id}.zip`);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getPitchAction = createAsyncThunk<PitchType | null, { pitchId: number }>(
  'pitches/getPitch',
  async (requestPayload, { rejectWithValue }) => {
    const { pitchId } = requestPayload;

    const callApi = createApiCall<PitchType>({
      api: backofficeRestClient.getPitch,
    });

    try {
      const { data } = await callApi({
        params: {
          pitchId,
        },
      });

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const uploadPPTAction = createAsyncThunk<{ success: boolean }, Params>(
  'pitches/uploadPPT',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<{ success: boolean }>({
      api: backofficeRestClient.uploadPPT,
      headers: {
        'content-type': `multipart/form-data;`,
      },
      preventDataTransform: true,
    });
    try {
      const { data } = await callApi({
        ...requestPayload,
      });
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const resetPitchesAction = createAction('pitches/resetPitchStale');
export const pitchesSetIsStaleAction = createAction<boolean>('pitches/setIsStale');
