import React, { FC } from 'react';
import { CircularProgress, Tabs, TabsProps } from '@mui/material';
import Tab from './Tab';

export type TabType = {
  label?: string;
  path: string | number;
  tooltip?: string;
  loading?: boolean;
  labelComponent?: React.ReactNode;
};

interface IOwnProps extends TabsProps {
  tabs: TabType[];
  activeTab: string | number;
  onChange: TabsProps['onChange'];
  classes?: Partial<Record<'root' | 'scroller', string>>;
}

const HorizontalTabs: FC<IOwnProps> = ({ tabs, activeTab, onChange, classes, ...rest }) => {
  return (
    <Tabs
      value={activeTab}
      onChange={onChange}
      //
      orientation="horizontal"
      sx={{ borderBottom: 1, borderColor: 'divider' }}
      classes={classes}
      //
      {...rest}
    >
      {tabs.map(({ path, loading, label, labelComponent, tooltip }) => (
        <Tab
          key={path}
          value={path}
          disabled={loading}
          label={
            labelComponent ? (
              labelComponent
            ) : (
              <React.Fragment>
                {label}
                {loading && <CircularProgress color="primary" size={16} style={{ marginLeft: '0.5rem' }} />}
              </React.Fragment>
            )
          }
          tooltip={tooltip}
        />
      ))}
    </Tabs>
  );
};

export default HorizontalTabs;
