type ResolveType<T> = (value: T | PromiseLike<T>) => void;
type RejectType = (reason?: any) => void;

class ResolvablePromise<T = void> {
  private readonly _promise: Promise<T>;
  resolve: ResolveType<T> = () => console.log('dummy resolve');
  reject: RejectType = () => {};
  //
  then: Promise<T>['then'];
  catch: Promise<T>['catch'];
  finally: Promise<T>['finally'];
  [Symbol.toStringTag]: string;

  constructor(callback?: (resolve: ResolveType<T>, reject: RejectType) => void) {
    this._promise = new Promise<T>((resolve, reject) => {
      // assign the resolve and reject functions to `this`
      // making them usable on the class instance
      this.resolve = resolve;
      this.reject = reject;

      callback?.(resolve, reject);
    });

    // bind `then` and `catch` to implement the same interface as Promise
    this.then = this._promise.then.bind(this._promise);
    this.catch = this._promise.catch.bind(this._promise);
    this.finally = this._promise.finally.bind(this._promise);
    this[Symbol.toStringTag] = 'Promise';
  }
}

export default ResolvablePromise;
