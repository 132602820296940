import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
//
import { workspaceRestClient } from 'portal/workspace/lib/http';
import { Params } from 'lib/http';
import { createApiCall } from 'lib/http/utils/createApiCall';
import { removeAuthTokensFromLS, removeWorkspace, saveAuthTokensToLS } from 'lib/localStorage';
import { uploadImage } from 'portal/workspace/lib/uploadFile';
//
import { UserType } from 'types/users';
import {
  InviteMemberPayload,
  InviteMemberResponse,
  AccountManipulationResponse,
  TokenSet,
  InviteLinkResponse,
  GenerateInviteMemberLinkPayload,
  TokenSetWithTime,
} from 'types/auth';
import { processTokens } from 'types/auth/utils';

type NetworkErrorType = {
  data: AxiosError;
  requestPayload: Params;
};

export const signInAction = createAsyncThunk<
  TokenSetWithTime,
  Params,
  {
    rejectValue: NetworkErrorType;
  }
>('auth/signIn', async (requestPayload, { rejectWithValue }) => {
  const callApi = createApiCall<TokenSet>({
    api: workspaceRestClient.signIn,
  });

  try {
    const { data } = await callApi(requestPayload);

    saveAuthTokensToLS(data);

    return processTokens(data);
  } catch (err) {
    return rejectWithValue(err as NetworkErrorType);
  }
});

export const signInScormAction = createAsyncThunk<
  TokenSetWithTime,
  Params,
  {
    rejectValue: NetworkErrorType;
  }
>('auth/signInScorm', async (requestPayload, { rejectWithValue }) => {
  const callApi = createApiCall<TokenSet>({
    api: workspaceRestClient.signInScorm,
  });

  try {
    const { data } = await callApi(requestPayload);

    saveAuthTokensToLS(data);

    return processTokens(data);
  } catch (err) {
    return rejectWithValue(err as NetworkErrorType);
  }
});

export const signUpAction = createAsyncThunk<AccountManipulationResponse, Params, { rejectValue: NetworkErrorType }>(
  'auth/signUpAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<AccountManipulationResponse>({
      api: workspaceRestClient.signUp,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err as NetworkErrorType);
    }
  },
);

export const forgotPasswordAction = createAsyncThunk<
  AccountManipulationResponse,
  Params,
  { rejectValue: NetworkErrorType }
>('auth/forgotPassword', async (requestPayload, { rejectWithValue }) => {
  const callApi = createApiCall<AccountManipulationResponse>({
    api: workspaceRestClient.forgotPassword,
  });

  try {
    const { data } = await callApi(requestPayload);

    return data;
  } catch (err) {
    return rejectWithValue(err as NetworkErrorType);
  }
});

export const resetPasswordAction = createAsyncThunk<
  AccountManipulationResponse,
  Params,
  { rejectValue: NetworkErrorType }
>('auth/resetPassword', async (requestPayload, { rejectWithValue }) => {
  const callApi = createApiCall<AccountManipulationResponse>({
    api: workspaceRestClient.resetPassword,
  });

  try {
    const { data } = await callApi(requestPayload);

    return data;
  } catch (err) {
    return rejectWithValue(err as NetworkErrorType);
  }
});

export const signOutAction = createAsyncThunk('auth/signOut', async () => {
  removeAuthTokensFromLS();
  removeWorkspace();
});

export const fetchCurrentUserAction = createAsyncThunk<UserType>(
  'auth/fetchCurrentUser',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<UserType>({
      api: workspaceRestClient.getCurrentUser,
    });

    try {
      const { data } = await callApi();

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateUserAction = createAsyncThunk<UserType, Params | undefined>(
  'auth/updateUser',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<UserType>({
      api: workspaceRestClient.updateCurrentUser,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const issueWorkspaceToken = createAsyncThunk<TokenSetWithTime, Params | undefined>(
  'auth/issueWorkspaceToken',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<TokenSet>({
      api: workspaceRestClient.issueWorkspaceToken,
    });

    try {
      const { data } = await callApi(requestPayload);

      saveAuthTokensToLS(data);

      return processTokens(data);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const issueCompanyToken = createAsyncThunk<TokenSetWithTime, Params | undefined>(
  'auth/issueCompanyToken',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<TokenSet>({
      api: workspaceRestClient.issueCompanyToken,
    });

    try {
      const { data } = await callApi(requestPayload);

      saveAuthTokensToLS(data);

      return processTokens(data);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateUserPasswordAction = createAsyncThunk<UserType, Params | undefined>(
  'auth/updateUserPassword',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<UserType>({
      api: workspaceRestClient.updateUserPassword,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateUserAvatarAction = createAsyncThunk<UserType, { avatar: Blob | null }>(
  'auth/updateUserAvatar',
  async ({ avatar }, { rejectWithValue }) => {
    try {
      const avatarUrl = avatar ? await uploadImage({ image: avatar, name: 'CourseCover' }) : null;
      const { data } = await createApiCall<UserType>({ api: workspaceRestClient.updateUserAvatar })({ url: avatarUrl });
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const inviteMemberAction = createAsyncThunk<InviteMemberResponse, InviteMemberPayload>(
  'auth/inviteMember',
  async (payload, { rejectWithValue }) => {
    const callApi = createApiCall<InviteMemberResponse>({
      api: workspaceRestClient.inviteMember,
    });

    try {
      const { data } = await callApi(payload);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const generateInviteLinkAction = createAsyncThunk<InviteLinkResponse, GenerateInviteMemberLinkPayload>(
  'auth/generateInviteLink',
  async (payload, { rejectWithValue }) => {
    const callApi = createApiCall<InviteLinkResponse>({
      api: workspaceRestClient.generateInviteLink,
    });

    try {
      const { data } = await callApi(payload);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const resetMetaNetwork = createAction('auth/resetMetaNetwork');
export const updateTokensAction = createAction<TokenSetWithTime>('auth/updateTokens');
