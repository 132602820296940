import { createReducer } from '@reduxjs/toolkit';
import { Reducer } from 'redux';
// types
import { QuizAnswersType } from 'types/answers';
// local
import { getQuizAnswersAction } from './actions';

const initialState = {
  meta: {
    isListLoading: false,
    isListLoaded: false,
    //
    isLoading: false,
    isLoaded: false,
  },
  resource: null as QuizAnswersType | null,
};

const reducer = createReducer(initialState, (builder) => {
  builder //
    .addCase(getQuizAnswersAction.pending, (draft) => {
      draft.meta.isLoading = true;
      draft.meta.isLoaded = false;
    })
    .addCase(getQuizAnswersAction.fulfilled, (draft, { payload }) => {
      draft.meta.isLoading = false;
      draft.meta.isLoaded = true;

      draft.resource = payload;
    })
    .addCase(getQuizAnswersAction.rejected, (draft) => {
      draft.meta.isLoading = false;
      draft.meta.isLoaded = false;
    });
});

export const quizAnswersReducer: Reducer<typeof initialState> = (draft = initialState, action) => {
  return reducer(draft, action);
};
