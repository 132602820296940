import React, { useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Redirect } from 'react-router-dom';

const AsyncRedirect: React.FC<{
  location: RouteComponentProps['location'];
  path: string;
}> = ({ location, path }) => {
  // Made redirects async to let components finish possible state updates
  // to not to have errors related to updating state on unmounted components
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const timerRef = useRef<number>(-1);

  useEffect(() => {
    timerRef.current = setTimeout(setShouldRedirect, 0, true) as number;

    return () => {
      clearTimeout(timerRef.current);
      setShouldRedirect(false);
    };
  }, []);

  return !shouldRedirect ? null : (
    <Redirect
      to={{
        pathname: path,
        state: { from: location },
      }}
    />
  );
};

export default AsyncRedirect;
