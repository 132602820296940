import { createSelector } from '@reduxjs/toolkit';
//
import { RootState } from '../store';

const coursesSelector = (state: RootState) => state.courses;
export const coursesMetaSelector = createSelector(coursesSelector, ({ meta }) => meta);
export const coursesIsTagsLoadingSelector = createSelector(coursesMetaSelector, ({ isTagsLoading }) => isTagsLoading);
export const coursesIsTagsLoadedSelector = createSelector(coursesMetaSelector, ({ isTagsLoaded }) => isTagsLoaded);

export const coursesResourceSelector = createSelector(coursesSelector, ({ resource }) => resource);
export const coursesResourcesSelector = createSelector(coursesSelector, ({ resources }) => resources);
export const coursesTagsSelector = createSelector(coursesSelector, ({ tags }) => tags.data);
export const selectedCoursesSelector = createSelector(coursesSelector, ({ selectedCourses }) => selectedCourses);
