import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Tab, { TabProps } from '@mui/material/Tab';
//
import { makeStyles } from '@mui/styles';

import { ReactComponent as InfoIcon } from 'assets/icons/info-outlined.svg';

interface OwnProps extends TabProps {
  tooltip?: string;
}
const CustomizeTab: React.FC<OwnProps> = ({ tooltip, ...rest }) => {
  const classes = useStyles();

  const icon = tooltip && (
    <Tooltip title={tooltip}>
      <InfoIcon />
    </Tooltip>
  );

  return <Tab disableRipple {...rest} icon={icon} iconPosition="end" classes={classes} />;
};

const useStyles = makeStyles((theme) => ({
  root: {
    textTransform: 'none',
    fontFamily: theme.fonts.gilmer,
    fontSize: '1em',
    fontWeight: 600,
    color: theme.colors.oxfordBlue,
    letterSpacing: '-0.02em',
    padding: '8px',
  },
}));

export default CustomizeTab;
