import { Reducer } from 'redux';
import { createReducer } from '@reduxjs/toolkit';
import {
  getFoldersAction,
  createFolderAction,
  updateFolderAction,
  deleteFolderAction,
  getFolderAction,
} from './actions';
import { FoldersStateType, ContentFolderShowType } from 'types/content/folders';

const initialState: FoldersStateType = {
  meta: {
    isLoading: false,
  },
  pagination: {
    page: 1,
    perPage: 8,
    pageCount: 0,
    total: 0,
  },
  resources: [],
  resource: {} as ContentFolderShowType,
};

const reducer = createReducer(initialState, (builder) => {
  builder
    // getFoldersAction
    .addCase(getFoldersAction.pending, (draft) => {
      draft.meta.isLoading = true;
    })
    .addCase(getFoldersAction.fulfilled, (draft, { payload }) => {
      const { data, ...pagination } = payload;
      draft.resources = data;
      draft.pagination = pagination;
      draft.meta.isLoading = false;
    })
    // createFolderAction
    .addCase(createFolderAction.pending, (draft) => {
      draft.meta.isLoading = true;
    })
    .addCase(createFolderAction.fulfilled, (draft, { payload }) => {
      draft.meta.isLoading = false;
      draft.resources = [payload, ...draft.resources];
    })
    // getFolderAction
    .addCase(getFolderAction.pending, (draft) => {
      draft.meta.isLoading = true;
    })
    .addCase(getFolderAction.fulfilled, (draft, { payload }) => {
      draft.meta.isLoading = false;
      draft.resource = payload;
    })
    // updateFolderAction
    .addCase(updateFolderAction.pending, (draft) => {
      draft.meta.isLoading = true;
    })
    .addCase(updateFolderAction.fulfilled, (draft, { payload }) => {
      draft.resources = draft.resources.map((item) => (item.id === payload.id ? payload : item));
      draft.meta.isLoading = false;
    })
    // deleteFolderAction
    .addCase(deleteFolderAction.pending, (draft) => {
      draft.meta.isLoading = true;
    })
    .addCase(deleteFolderAction.fulfilled, (draft, { payload }) => {
      draft.meta.isLoading = false;
      draft.resources = draft.resources.filter((item) => item.id !== payload);
    });
});

export const foldersReducer: Reducer<typeof initialState> = (draft = initialState, action) => {
  return reducer(draft, action);
};
