import { createReducer } from '@reduxjs/toolkit';
import { Reducer } from 'redux';
//
import { getReviewsAction } from './actions';
import { ReviewType, SortConfigs } from './entities';

const initialState = {
  meta: {
    isLoading: false,
    isLoaded: false,
  },
  filters: {} as Record<string, string>,
  sort: SortConfigs['updatedAtAsc'],
  pagination: {
    page: 1,
    perPage: 10,
    pageCount: 0,
    total: 0,
  },
  resource: null as ReviewType | null,
  resources: [] as ReviewType[],
};

const reducer = createReducer(initialState, (builder) => {
  builder
    ////////////////////////////////////////////////////////////////////////////////////////////////
    .addCase(getReviewsAction.pending, (draft) => {
      draft.meta.isLoading = true;
      draft.meta.isLoaded = false;
    })
    .addCase(getReviewsAction.fulfilled, (draft, { payload }) => {
      draft.meta.isLoading = false;
      draft.meta.isLoaded = true;

      const { data, ...pagination } = payload;

      draft.resources = pagination.page === 1 ? data : [...draft.resources, ...data];

      draft.pagination = {
        ...draft.pagination,
        ...pagination,
      };
    })
    .addCase(getReviewsAction.rejected, (draft) => {
      draft.meta.isLoading = false;
      draft.meta.isLoaded = false;
    });
});

export const reviewsReducer: Reducer<typeof initialState> = (draft = initialState, action) => {
  return reducer(draft, action);
};
