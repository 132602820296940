import { matchPath } from 'react-router-dom';
import { flattenDeep, merge } from 'lodash';
import qs from 'qs';
// utils
import { AbstractChildRoute, GenericRoute, GenericChildRoute } from 'routes';
import { AccessFlags } from 'lib/acl/entities';
import { omitNullable } from 'lib/object';
import { charCount } from 'utils/string';

export const combinePaths = (parent: string, child: string) =>
  `${parent.replace(/\/$/, '')}/${child.replace(/^\//, '')}`;

export const buildPaths = (routes: GenericRoute[], parentPath = ''): GenericRoute[] =>
  routes.map((route) => {
    const path = combinePaths(parentPath, route.path);

    return {
      ...route,
      path,
      ...(route.routes && { routes: buildPaths(route.routes, path) }),
    };
  });

export const setupParents = (routes: GenericRoute[], parentRoute?: GenericRoute): GenericChildRoute[] =>
  routes.map((route) => {
    const withParent = {
      ...route,
      ...(parentRoute && { parent: parentRoute }),
    };

    return {
      ...withParent,
      ...(withParent.routes && {
        routes: setupParents(withParent.routes, withParent),
      }),
    };
  });

export const flattenRoutes = (routes: GenericChildRoute[]): GenericChildRoute[] => {
  type NestedChildRoutes = (GenericChildRoute | NestedChildRoutes)[];

  const _flattenRoutes = (_routes: GenericChildRoute[]): NestedChildRoutes => {
    return _routes.map((route) => [route.routes ? _flattenRoutes(route.routes) : [], route]);
  };

  return flattenDeep(_flattenRoutes(routes));
};

export const generateAppRoutes = (routes: GenericRoute[]) => {
  const flattenedRoutes = flattenRoutes(setupParents(buildPaths(routes)));

  return flattenedRoutes.sort(({ path: path1 }, { path: path2 }) => charCount(path2, '/') - charCount(path1, '/'));
};

export const bindUrlGetters = (routes: GenericChildRoute[]) => {
  const getRoute = (key?: string): GenericChildRoute => {
    let desiredRoute: GenericChildRoute | undefined;

    if (key) {
      desiredRoute = routes.find((route) => route.key === key);
    } else {
      desiredRoute = routes.find(({ isDefault }) => isDefault);
    }

    if (!desiredRoute) {
      throw new Error(`Could find route for key: '${key}'`);
    }

    return desiredRoute;
  };

  /**
   *
   * @param key [optional] - key of desired route. if no key provided default route's url will be returned
   */
  const getUrl = (key?: string): string => {
    const desiredRoute = getRoute(key);

    return desiredRoute.path;
  };

  const getUrlWithParams = (
    key: string,
    { query, ...params }: Record<string, unknown> & { query?: Record<string, unknown> } = {},
  ): string => {
    const route = getRoute(key);
    const rawUrl = route.path;
    // extract params from current url
    const parentPath = matchPath(location.pathname, { path: route.parent?.path, exact: false });
    const derivedParams = parentPath?.params;

    const nonNullableParams = omitNullable(merge({}, derivedParams, params));
    //
    const strippedUrl = rawUrl.replace(/(\/?:([\w\d]+))\?/, (match, paramPath, paramName) => {
      // omit ? int optional params if value is present
      // or omit non required param completely if corresponding param value is absent
      // "/:id?" -> "/:id" - if id is in params
      //            ""     - if id isn't in params

      return paramName && nonNullableParams.hasOwnProperty(paramName) ? paramPath : '';
    });

    const pathWithParams = strippedUrl.replace(/:([\w\d]+)/g, (match, paramName) => {
      return String(nonNullableParams[paramName]);
    });

    if (pathWithParams) {
      if (query) {
        const nonNullableQuery = omitNullable(query);

        if (Object.keys(nonNullableQuery).length > 0) {
          return `${pathWithParams}?${qs.stringify(nonNullableQuery)}`;
        }
      }

      return pathWithParams;
    } else {
      console.error(`Failed to apply params to route: ${key}, params : ${JSON.stringify(params)}`);
      return '/';
    }
  };

  return { getRoute, getUrl, getUrlWithParams };
};

export const isCurrentRoute = (location: string, path: string, exact: boolean) => {
  return Boolean(matchPath(location, { path, exact: exact, strict: false }));
};

export const checkDuplicateRoutes = (routes: GenericChildRoute[]) => {
  // Validate routes uniqueness
  const errors: string[][] = [];

  //////////////////////////////////// check all keys and paths are unique ////////////////////////////////////////
  const keys = new Map(routes.map(({ key }) => [key, 0]));
  const paths = new Map(routes.map(({ path }) => [path, 0]));

  routes.forEach((route) => {
    keys.set(route.key, (keys.get(route.key) || 0) + 1);
    paths.set(route.path, (keys.get(route.path) || 0) + 1);
  });

  [keys, paths].forEach((_map) => {
    _map.forEach((val, key, map) => {
      if (val === 1) {
        // 1 entry is ok; more - duplicates; resolve manually
        map.delete(key);
      }
    });

    if (_map.size > 0) {
      errors.push([`Found possible duplicate routes: ${[..._map.keys()]}`]);
    }
  });
  //////////////////////////////////// check default route is only 1 //////////////////////////////////////////////
  //
  const { public: publicDefaultCount, private: privateDefaultCount } = routes.reduce(
    (acc, { restrictions, isDefault }) => {
      const access = restrictions?.includes(AccessFlags.AUTHORIZED) ? 'private' : 'public';
      acc[access] = isDefault ? acc[access] + 1 : acc[access];

      return acc;
    },
    {
      public: 0,
      private: 0,
    },
  );

  [
    [publicDefaultCount, 'public'],
    [privateDefaultCount, 'private'],
  ].forEach(([count, access]) => {
    if (count > 1) {
      errors.push([`Multiple ${access} default routes found. Check routes`]);
    }
  });

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  if (errors.length) {
    errors.forEach((error) => {
      throw new Error(...error);
    });
  }
};

export const isAbstractRoute = (route: GenericChildRoute): route is AbstractChildRoute => {
  return route.component == null;
};
