import React from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { LinearProgress } from '@mui/material';
//
import DefaultCardSkeleton from './skeletons/DefaultCardSkeleton';
//
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

interface OwnProps {
  list: unknown[];
  //
  isLoading: boolean;
  isLoaded?: boolean;
  isStale?: boolean;
  //
  page: number;
  pageCount: number;
  perPage?: number;
  itemCount?: number;
  //
  fetchNext: VoidFunction;
  //
  emptyState?: React.ReactNode;
  createCard?: React.ReactNode;
  //
  ListCard: React.ElementType<{ data: unknown }>;
  CardSkeleton?: React.ElementType;
  minWidth?: number;
  componentProps?: Record<string, unknown>;
}

const VerticalInfiniteGrid: React.FC<OwnProps> = ({
  list,
  //
  isLoading,
  isLoaded = false,
  isStale = false,
  //
  page,
  perPage = 10,
  pageCount,
  itemCount = 0,
  //
  fetchNext,
  //
  emptyState,
  createCard,
  //
  ListCard,
  minWidth = 300,
  componentProps = {},
  CardSkeleton = DefaultCardSkeleton,
}) => {
  const classes = useStyles({ minWidth: minWidth as number });
  //
  const resources = isStale ? [] : list;
  //
  const morePagesAvailable = isLoaded && pageCount > page;
  const shouldReload = !isLoaded && page === 0 && pageCount === 0;
  //
  // add one addition skeleton card on initial loading if create card present
  const createCardPresent = Boolean(createCard);
  const conditionalPerPage = isLoaded ? perPage : perPage + (createCardPresent ? 1 : 0);

  const itemsLeft = itemCount !== 0 ? itemCount - resources.length : conditionalPerPage;
  const itemsLoading = Math.min(itemsLeft, conditionalPerPage);
  const hasMore = !isLoading && (morePagesAvailable || shouldReload || isStale);

  //
  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: hasMore,
    onLoadMore: fetchNext,
  });

  return (
    <div className={classes.wrapper}>
      {!isLoading && isLoaded && list.length === 0 ? (
        <div className={classes.noDataContainer}>{emptyState}</div>
      ) : (
        <div className={clsx(classes.container)}>
          {createCard && isLoaded && <div className={classes.card}>{createCard}</div>}

          {resources.map((data, ix) => (
            <div key={(data as { id?: string })?.id || ix} className={classes.card}>
              <ListCard data={data} {...componentProps} />
            </div>
          ))}

          {isLoading &&
            new Array(itemsLoading).fill(0).map((_, ix) => (
              <div key={ix} className={classes.card}>
                <CardSkeleton />
              </div>
            ))}
        </div>
      )}

      {/* ///////////////////////// SENTRY ///////////////////////// */}
      {hasMore && <div className={classes.sentry} ref={sentryRef} />}

      {isLoading && <LinearProgress className={classes.linearProgress} />}
    </div>
  );
};

const useStyles = makeStyles<{ minWidth: number }>((theme) => ({
  wrapper: {
    position: 'relative',
  },

  noDataContainer: {
    height: '100%',
  },

  linearProgress: {
    marginTop: 16,
    marginBottom: 16,
  },

  ////////////////////////////////////////////////////////////////////////////////
  // useInfiniteScroll uses IntersectionObserver under the hood
  // when sentry element becomes visible - load
  sentry: {
    position: 'absolute',
    bottom: 0,
    height: 200,
    width: '100%',
    visibility: 'hidden',
  },

  container: {
    display: 'grid',
    gridTemplateColumns: ({ minWidth }) => `repeat(auto-fill, minmax(min(${minWidth || 300}px, 100%), 1fr))`,
    margin: '1rem auto',
    width: '100%',
    gap: '1rem',
  },

  card: {
    padding: '0 !important',
    margin: '0',
    width: '100%',

    [theme.breakpoints.down('md')]: {
      margin: '0 auto',
    },
  },
}));

export default React.memo(VerticalInfiniteGrid);
