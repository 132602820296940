import React, { FC, useCallback, useContext } from 'react';
import { Box, MenuList, Popover, Skeleton } from '@mui/material';
import { useHistory } from 'react-router-dom';
// types
import { Notification, NotificationActionEnum } from 'types/notification';
// utils
import usePopoverMenu from 'hooks/usePopoverMenu';
// local
import { NotificationsContext } from './NotificationContext';
import { EmptyNotifications, NotificationHeader, NotificationRow } from './components';
// styles
import useStyles from './styles';
// assets
import { ReactComponent as NotificationsOutlined } from 'assets/icons/notifications_outlined.svg';
import { ReactComponent as NewNotificationsOutlined } from 'assets/icons/newNotifications_outlined.svg';
import VerticalInfiniteGrid from 'components/infinite-lists/VerticalInfiniteGrid';

export type Classes = {
  classes?: Partial<Record<'popover' | 'menuItem' | 'menuList' | 'listIcon' | 'menuItemLabel', string>>;
};

interface IOwnProps extends Classes {
  className?: string;
  routeMap?: Record<NotificationActionEnum, (a?: number, b?: number) => string>;
}
const NotificationSkeleton = () => {
  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between">
      <Skeleton variant="rectangular" height={50} />
    </Box>
  );
};
const NotificationPopOver: FC<IOwnProps> = ({ className, routeMap }) => {
  const localClasses = useStyles();

  const stopPropagation = useCallback((e) => e.stopPropagation(), []);
  const { push } = useHistory();
  const {
    notifications,
    isLoading,
    fetchNextPage,
    pagination,
    updateNotification: update,
    markAllRead,
    isNewNotification,
  } = useContext(NotificationsContext);

  const { anchorEl, isMenuOpen, handleOpenMenu, handleCloseMenu } = usePopoverMenu();

  const updateNotification = (id: number) => update(id);

  const onNotificationPress = (notification: Notification) => {
    if (!routeMap) return;
    updateNotification(notification.id);
    push(routeMap[notification.action](notification.modelId, notification.workspaceId));
  };

  const markAllAsRead = () => {
    handleCloseMenu();
    markAllRead();
  };

  return (
    <>
      <button onClick={handleOpenMenu} className={localClasses.button}>
        {isNewNotification ? (
          <NewNotificationsOutlined className={className} />
        ) : (
          <NotificationsOutlined className={className} />
        )}
      </button>
      <Popover
        open={isMenuOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={handleCloseMenu}
        onBackdropClick={stopPropagation}
      >
        <MenuList className={localClasses.menuList} onClick={stopPropagation}>
          {notifications && notifications.length > 0 && <NotificationHeader onClick={markAllAsRead} />}
          <VerticalInfiniteGrid
            list={notifications}
            isLoading={isLoading}
            isLoaded={!isLoading}
            page={pagination.page}
            pageCount={pagination.pageCount}
            fetchNext={fetchNextPage}
            emptyState={<EmptyNotifications />}
            ListCard={(props) => {
              const handleClick = () => {
                handleCloseMenu();
                onNotificationPress(props.data as Notification);
              };
              return <NotificationRow handleClick={handleClick} notification={props.data as Notification} />;
            }}
            CardSkeleton={NotificationSkeleton}
          />
        </MenuList>
      </Popover>
    </>
  );
};

export default NotificationPopOver;
