import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import Portal from '@mui/material/Portal';
// styles
import { makeStyles } from '@mui/styles';

type HeaderContextType = {
  isCustomContent: boolean;
  setIsCustomContent: (isCustomContent: boolean) => void;
  contentRef: React.MutableRefObject<HTMLDivElement | null>;
  isRefConfigured: boolean;
  setIsRefConfigured: (isCustomContent: boolean) => void;
};
export const HeaderContext = React.createContext<HeaderContextType>({
  isCustomContent: false,
  setIsCustomContent: () => {},
  contentRef: { current: null },
  isRefConfigured: false,
  setIsRefConfigured: () => {},
});

export const HeaderContextProvider: React.FC = ({ children }) => {
  const [isCustomContent, setIsCustomContent] = useState(false);
  const [isRefConfigured, setIsRefConfigured] = useState(false);
  //
  const contentRef = useRef<HTMLDivElement | null>(null);

  return (
    <HeaderContext.Provider
      value={{
        isCustomContent,
        setIsCustomContent,
        contentRef,
        isRefConfigured,
        setIsRefConfigured,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};

export const DynamicContentContainer: React.FC = ({ children }) => {
  const classes = useDynamicContentStyles();
  //
  const { isCustomContent, contentRef, isRefConfigured, setIsRefConfigured } = useContext(HeaderContext);

  useLayoutEffect(() => {
    return () => {
      setIsRefConfigured(false);
    };
  }, []);

  return (
    <>
      {isCustomContent ? (
        <div
          data-content="custom"
          className={classes.content}
          ref={(ref) => {
            contentRef.current = ref;

            if (!isRefConfigured) {
              setIsRefConfigured(true);
            }
          }}
        />
      ) : (
        <div data-content="default" className={classes.content}>
          {children}
        </div>
      )}
    </>
  );
};

export const useDynamicContentStyles = makeStyles({
  content: {
    width: '100%',
    height: '100%',
  },
});

export const InnerHeaderContent: React.FC = ({ children }) => {
  const { contentRef, setIsCustomContent, isRefConfigured } = useContext(HeaderContext);

  useLayoutEffect(() => {
    setIsCustomContent(true);

    return () => {
      setIsCustomContent(false);
    };
  }, [setIsCustomContent]);

  return isRefConfigured ? <Portal container={contentRef.current}>{children}</Portal> : null;
};
