import { CourseStatuses, CourseType } from 'types/courses';
import { TeamType } from 'types/teams';
import { PitchType } from 'types/pitches';
//
import { AccessFlags } from './entities';
import BaseAccessSchema from './BaseAccessSchema';

import { CompanyType } from 'types/companies';
import { WorkspaceType } from 'types/workspaces';

//

abstract class AccessControlListsScheme extends BaseAccessSchema {
  ///////////////////////////////////     Courses    ///////////////////////////////////////
  canViewCourseList = () => this.canView(AccessFlags.COURSES);

  canUpdateCourseList = () => this.canUpdate(AccessFlags.COURSES);

  canViewCourse = (course?: CourseType): boolean => {
    const genericPermission = this.canView(AccessFlags.COURSES);
    if (!genericPermission) return false;

    if (!course) return genericPermission;

    // Possible different logic for specific entity
    return genericPermission;
  };

  doesAuthorHaveAccess = (course: CourseType): boolean => {
    const isAuthorVacant = course.authorId == null;
    const isAuthor = this.me!.id === course.authorId;

    return isAuthor || isAuthorVacant;
  };

  canUpdateCourse = (course: CourseType): boolean => {
    const genericPermission = this.canUpdate(AccessFlags.COURSES);

    if (!genericPermission) return false;

    const haveUnrestrictedAccess = this.isBackofficeAdmin || this.isBackofficeSuperAdmin;

    if (course.isSharedFromBackoffice || course.isSharedFromCompany) {
      return haveUnrestrictedAccess;
    }

    return (this.doesAuthorHaveAccess(course) || haveUnrestrictedAccess) && course.status !== CourseStatuses.disabled;
  };

  canDuplicateCourse = (): boolean => {
    const genericPermission = this.canUpdate(AccessFlags.COURSES);
    if (!genericPermission) return false;

    const haveUnrestrictedAccess = this.isBackofficeAdmin || this.isBackofficeSuperAdmin;

    const haveAccess = !this.isWorkspaceEmployee;
    return haveAccess || haveUnrestrictedAccess;
  };

  canDeleteCourse = (course: CourseType): boolean => {
    if (this.canDelete(AccessFlags.COURSES)) return true;
    const genericPermission = this.canUpdate(AccessFlags.COURSES);
    if (!genericPermission) return false;

    const isStatusOk = course.status === CourseStatuses.draft || course.status === CourseStatuses.disabled;

    const haveAccess =
      ((this.doesAuthorHaveAccess(course) && isStatusOk) || course.isSharedFromBackoffice) && !this.isWorkspaceEmployee;

    const haveUnrestrictedAccess = (this.isBackofficeAdmin || this.isBackofficeSuperAdmin) && isStatusOk;

    return haveAccess || haveUnrestrictedAccess;
  };

  canArchiveCourse = (course: CourseType): boolean => {
    const haveAccess =
      this.doesAuthorHaveAccess(course) && course.status === CourseStatuses.published && !this.isWorkspaceEmployee;
    const haveUnrestrictedAccess =
      (this.isBackofficeAdmin || this.isBackofficeSuperAdmin) && course.status === CourseStatuses.published;
    return haveAccess || haveUnrestrictedAccess;
  };

  canUnArchiveCourse = (course: CourseType): boolean => {
    const genericPermission = this.canUpdate(AccessFlags.COURSES);
    if (!genericPermission) return false;

    const haveAccess =
      (this.doesAuthorHaveAccess(course) || course.isSharedFromBackoffice) &&
      course.status === CourseStatuses.disabled &&
      !this.isWorkspaceEmployee;

    const haveUnrestrictedAccess =
      (this.isBackofficeAdmin || this.isBackofficeSuperAdmin) && course.status === CourseStatuses.disabled;

    return haveAccess || haveUnrestrictedAccess;
  };

  canShareCourse = (course: CourseType): boolean => {
    const haveGeneralAccess = this.isCoach;
    const haveUnrestrictedAccess = this.isBackofficeAdmin || this.isBackofficeSuperAdmin;

    return course.status === CourseStatuses.published && (haveGeneralAccess || haveUnrestrictedAccess);
  };

  canDeleteTeam = (team: TeamType): boolean => {
    const currentUserId = this.me!.id;
    const isAuthor = currentUserId === team.creatorId;
    const haveAccess = this.canUpdateTeam(team) && isAuthor;
    const haveUnrestrictedAccess = this.isBackofficeAdmin || this.isBackofficeSuperAdmin || this.isWorkspaceAdmin;
    return haveAccess || haveUnrestrictedAccess;
  };

  ///////////////////////////////////     Pitches    ///////////////////////////////////////
  canViewPitchList = () => this.canView(AccessFlags.PITCHES);

  canUpdatePitchList = (course?: CourseType) => {
    const genericPermission = this.canUpdate(AccessFlags.PITCHES);
    if (!genericPermission) return false;

    if (course) {
      return this.canUpdateCourse(course);
    }

    return genericPermission;
  };

  canViewPitch = (pitch?: PitchType): boolean => {
    const genericPermission = this.canView(AccessFlags.PITCHES);
    if (!genericPermission) return false;

    if (!pitch) return genericPermission;

    // Possible different logic for specific entity
    return genericPermission;
  };

  canUpdatePitch = (pitch: PitchType): boolean => {
    const genericPermission = this.canUpdate(AccessFlags.PITCHES);
    if (!genericPermission) return false;

    const currentUserId = this.me!.id;
    const isAuthor = currentUserId === pitch.authorId;

    const haveAccess = isAuthor;
    const haveUnrestrictedAccess = this.isBackofficeAdmin || this.isBackofficeSuperAdmin;

    return haveAccess || haveUnrestrictedAccess;
  };

  ///////////////////////////////////      Teams     ///////////////////////////////////////
  canViewTeamList = () => this.canView(AccessFlags.TEAMS);

  canUpdateTeamList = () => this.canUpdate(AccessFlags.TEAMS);

  canViewTeam = (team?: TeamType): boolean => {
    const genericPermission = this.canView(AccessFlags.TEAMS);
    if (!genericPermission) return false;

    if (!team) return genericPermission;

    // Possible different logic for specific entity
    return genericPermission;
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canUpdateTeam = (team: TeamType): boolean => {
    return this.canUpdate(AccessFlags.TEAMS);
  };

  // TODO: Later split into Coruse and Pitches seperately if needed
  canCreateScormPackages = (): boolean => {
    return this.canCreateScorm(AccessFlags.COURSES) || this.canCreateScorm(AccessFlags.PITCHES);
  };

  ///////////////////////////////////      Users     ///////////////////////////////////////
  // TODO

  ///////////////////////////////////     Content    ///////////////////////////////////////
  // TODO

  ///////////////////////////////////    Companies   ///////////////////////////////////////

  canViewCompanyList = () => this.canView(AccessFlags.COMPANIES);

  canUpdateCompanyList = () => this.canUpdate(AccessFlags.COMPANIES);

  canViewCompany = (team?: CompanyType): boolean => {
    const genericPermission = this.canView(AccessFlags.COMPANIES);
    if (!genericPermission) return false;

    if (!team) return genericPermission;

    // Possible different logic for specific entity
    return genericPermission;
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canUpdateCompany = (company: CompanyType): boolean => {
    return this.canUpdate(AccessFlags.COMPANIES);
  };

  ///////////////////////////////////    Workspaces   ///////////////////////////////////////
  canViewWorkspaceList = () => this.canView(AccessFlags.WORKSPACE);

  canUpdateWorkspaceList = () => this.canUpdate(AccessFlags.WORKSPACE);

  canViewWorkspace = (workspace: WorkspaceType): boolean => {
    const genericPermission = this.canView(AccessFlags.WORKSPACE);
    if (!genericPermission) return false;

    const haveAccess = this.me!.workspaceId === workspace.id;
    const haveUnrestrictedAccess = this.isBackofficeAdmin || this.isBackofficeSuperAdmin;

    return haveAccess || haveUnrestrictedAccess;
  };

  canUpdateWorkspace = (workspace: WorkspaceType): boolean => {
    const genericPermission = this.canUpdate(AccessFlags.WORKSPACE);
    if (!genericPermission) return false;

    const haveAccess = this.me!.workspaceId === workspace.id;
    const haveUnrestrictedAccess = this.isBackofficeAdmin || this.isBackofficeSuperAdmin;

    return haveAccess || haveUnrestrictedAccess;
  };
}

export default AccessControlListsScheme;
