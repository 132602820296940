import { TokenSet } from 'types/auth';
import { processTokens } from 'types/auth/utils';
import { WorkspaceType } from 'types/workspaces';
import { isScormPortal } from 'utils/portalCheck';

const accessTokenName = isScormPortal ? 'scormAccessToken' : 'accessToken';
const refreshTokenName = isScormPortal ? 'scormRefreshToken' : 'refreshToken';
const receivedAtTokenName = isScormPortal ? 'scormAuthTokensReceivedAt' : 'authTokensReceivedAt';
const workspaceName = 'workspace';

export const saveAuthTokensToLS = (data: TokenSet) => {
  const tokenSet = processTokens(data);

  localStorage.setItem(accessTokenName, tokenSet.accessToken);
  localStorage.setItem(refreshTokenName, tokenSet.refreshToken);
  //
  localStorage.setItem(receivedAtTokenName, tokenSet.receivedAt);

  return tokenSet;
};

export const getAuthTokens = () => {
  const accessToken = localStorage.getItem(accessTokenName);
  const refreshToken = localStorage.getItem(refreshTokenName);
  //
  const receivedAt = localStorage.getItem(receivedAtTokenName);

  return {
    accessToken,
    refreshToken,
    //
    receivedAt,
  };
};

export const removeAuthTokensFromLS = () => {
  localStorage.removeItem(accessTokenName);
  localStorage.removeItem(refreshTokenName);
  localStorage.removeItem(receivedAtTokenName);
};

export const getWorkspace = (): WorkspaceType => {
  const workspace = localStorage.getItem(workspaceName);
  return JSON.parse(workspace!);
};

export const selectWorkspace = (workspace: Partial<WorkspaceType>) => {
  localStorage.setItem(workspaceName, JSON.stringify(workspace));
};

export const removeWorkspace = () => {
  localStorage.removeItem(workspaceName);
};
