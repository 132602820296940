import { createReducer, Reducer } from '@reduxjs/toolkit';
import merge from 'lodash/merge';
// local
import {
  getWorkspacesAction,
  getWorkspaceAction,
  workspacesSetIsStaleAction,
  changeWorkspacesRequestParamsAction,
  selectWorkspaceAction,
  unSelectWorkspaceAction,
} from './actions';

import { getWorkspace } from 'lib/localStorage';
import { WorkspacesSortConfigs, WorkspaceType } from 'types/workspaces';

export type WorkspaceState = {
  meta: {
    isLoading: boolean;
    isLoaded: boolean;
    isStale: boolean;
  };
  filters: Record<string, string>;
  sort: typeof WorkspacesSortConfigs.nameAsc;
  pagination: {
    page: number;
    perPage: number;
    pageCount: number;
    total: number;
  };
  resources: WorkspaceType[];
  resource: WorkspaceType;
  selectedWorkspace?: WorkspaceType;
};

const staticInitialState: WorkspaceState = {
  meta: {
    isLoading: false,
    isLoaded: false,
    isStale: false,
  },
  filters: {} as Record<string, string>,
  sort: WorkspacesSortConfigs.nameAsc,
  pagination: {
    page: 0,
    perPage: 10,
    pageCount: 0,
    total: 0,
  },
  resources: [] as WorkspaceType[],
  resource: {} as WorkspaceType,
};

export const initialState = {
  get workspace() {
    const workspace = getWorkspace();
    return merge({}, staticInitialState, {
      selectedWorkspace: workspace,
    });
  },
};

const reducer = createReducer(staticInitialState, (builder) => {
  builder
    .addCase(getWorkspacesAction.pending, (draft, {}) => {
      //
      draft.meta.isLoading = true;
      draft.meta.isLoaded = false;
    })
    .addCase(getWorkspacesAction.fulfilled, (draft, { payload }) => {
      const { data, ...pagination } = payload;

      draft.meta.isLoading = false;
      draft.meta.isLoaded = true;
      draft.meta.isStale = false;

      draft.resources = pagination.page === staticInitialState.pagination.page ? data : [...draft.resources, ...data];

      draft.pagination = {
        ...draft.pagination,
        ...pagination,
      };
    })
    .addCase(getWorkspacesAction.rejected, (draft) => {
      draft.meta.isLoading = false;
      draft.meta.isLoaded = false;
    })
    ////////////////////////////////////////////////////////////////////////////////////////////////
    .addCase(getWorkspaceAction.pending, (draft, {}) => {
      draft.meta.isLoading = true;
    })
    .addCase(getWorkspaceAction.fulfilled, (draft, { payload }) => {
      draft.meta.isLoading = false;

      draft.resource = payload;
    })
    .addCase(getWorkspaceAction.rejected, (draft) => {
      draft.meta.isLoading = false;
    })
    ////////////////////////////////////////////////////////////////////////////////////////////////
    .addCase(changeWorkspacesRequestParamsAction, (draft, { payload }) => {
      draft.meta.isStale = true;
      draft.meta.isLoaded = false;
      draft.filters = merge(draft.filters, payload.filters);
      draft.sort = merge(draft.sort, payload.sort);
      draft.resources = staticInitialState.resources;
      draft.pagination = staticInitialState.pagination;
    })
    ////////////////////////////////////////////////////////////////////////////////////////////////
    .addCase(workspacesSetIsStaleAction, (draft, { payload }) => {
      draft.meta.isStale = payload;
    })
    ////////////////////////////////////////////////////////////////////////////////////////////////
    .addCase(selectWorkspaceAction.fulfilled, (draft, { payload }) => {
      draft.selectedWorkspace = payload;
    })
    .addCase(unSelectWorkspaceAction.fulfilled, (draft, {}) => {
      draft.selectedWorkspace = undefined;
    });
});

export const workspacesReducer: Reducer<typeof staticInitialState> = (draft = initialState.workspace, action) => {
  return reducer(draft, action);
};
