import { createAsyncThunk } from '@reduxjs/toolkit';
// utils
import { backofficeRestClient } from 'portal/backoffice/lib/http';
import { Params } from 'lib/http';
import { createApiCall } from 'lib/http/utils/createApiCall';
// local
import { MembersLeaderboardResponse } from './entities';
import { TeamsLeaderboardResponse, WorkspacesLeaderboardResponse } from 'types/leaderboards';

export const fetchMembersLeaderboard = async <T>(requestPayload: T) => {
  const callApi = createApiCall<MembersLeaderboardResponse>({
    api: backofficeRestClient.getMembersLeaderboard,
  });

  const { data } = await callApi(requestPayload);

  return data;
};

export const getMembersLeaderboardAction = createAsyncThunk<MembersLeaderboardResponse, Params | undefined>(
  'leaderboards/getMembersLeaderboard',
  async (requestPayload, { rejectWithValue }) => {
    try {
      return await fetchMembersLeaderboard(requestPayload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const fetchTeamsLeaderboard = async <T>(requestPayload: T) => {
  const callApi = createApiCall<TeamsLeaderboardResponse>({
    api: backofficeRestClient.getTeamsLeaderboard,
  });

  const { data } = await callApi(requestPayload);

  return data;
};

export const getTeamsLeaderboardAction = createAsyncThunk<TeamsLeaderboardResponse, Params | undefined>(
  'leaderboards/getMembersLeaderboard',
  async (requestPayload, { rejectWithValue }) => {
    try {
      return await fetchTeamsLeaderboard(requestPayload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const fetchWorkspacesLeaderboard = async <T>(requestPayload: T) => {
  const callApi = createApiCall<WorkspacesLeaderboardResponse>({
    api: backofficeRestClient.getWorkspacesLeaderboard,
  });

  const { data } = await callApi(requestPayload);

  return data;
};

export const getWorkspacesLeaderboardAction = createAsyncThunk<WorkspacesLeaderboardResponse, Params | undefined>(
  'leaderboards/getMembersLeaderboard',
  async (requestPayload, { rejectWithValue }) => {
    try {
      return await fetchWorkspacesLeaderboard(requestPayload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
