import React, { FC, MouseEvent, useCallback } from 'react';
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
  PopoverProps,
  PopoverOrigin,
  Typography,
  IconButton,
} from '@mui/material';
import cx from 'clsx';
import useStyles from './styles';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

export type ActionType = {
  label: string;
  Icon?: React.ElementType;
  className?: string;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
  subText?: string;
};

export type ActionConditionalType = ActionType & {
  visible: boolean;
};

export type PopoverMenuClasses = Partial<
  Record<'popover' | 'menuItem' | 'menuList' | 'listIcon' | 'menuItemLabel' | 'menuItemSubText', string>
>;

interface IOwnProps {
  actions: ActionType[] | ActionConditionalType[];
  isOpen: boolean;
  anchor: PopoverProps['anchorEl'];
  onClose: (e: MouseEvent<HTMLElement>) => void;
  popoverProps?: Partial<PopoverProps>;
  classes?: PopoverMenuClasses;
  anchorOrigin?: PopoverOrigin;
  title?: string;
}

const PopoverMenu: FC<IOwnProps> = ({
  //
  actions,
  anchor,
  isOpen,
  onClose,
  popoverProps,
  classes,
  anchorOrigin,
  title,
}) => {
  const localClasses = useStyles();
  const stopPropagation = useCallback((e) => e.stopPropagation(), []);

  return (
    <Popover
      open={isOpen}
      anchorEl={anchor}
      anchorOrigin={
        anchorOrigin || {
          vertical: 'bottom',
          horizontal: 'right',
        }
      }
      onClose={onClose}
      onBackdropClick={stopPropagation}
      classes={{
        root: classes?.popover,
      }}
      {...popoverProps}
    >
      {title && (
        <div className={localClasses.popoverHeader}>
          <Typography>{title}</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon className={localClasses.closeIcon} />
          </IconButton>
        </div>
      )}
      <MenuList className={cx(localClasses.menuList, classes?.menuList)} onClick={stopPropagation}>
        {actions.map(({ Icon, label, subText, onClick, className }, index) => {
          const handleClick = (e: MouseEvent<HTMLElement>) => {
            onClick && onClick(e);
            onClose(e);
          };

          return (
            <MenuItem
              key={index}
              onClick={handleClick}
              className={cx(localClasses.menuItem, classes?.menuItem, className)}
            >
              {Icon && (
                <ListItemIcon className={cx(localClasses.listIconContainer, classes?.listIcon)}>
                  <Icon className={localClasses.listIcon} />
                </ListItemIcon>
              )}

              <ListItemText
                primaryTypographyProps={{ className: cx(localClasses.menuItemLabel, classes?.menuItemLabel) }}
              >
                {label}
                {subText && (
                  <Typography className={cx(localClasses.menuItemSubText, classes?.menuItemSubText)}>
                    {subText}
                  </Typography>
                )}
              </ListItemText>
            </MenuItem>
          );
        })}
      </MenuList>
    </Popover>
  );
};

export default PopoverMenu;
