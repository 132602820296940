import { pitchesResourceSelector } from 'portal/workspace/domain/pitches/base/selectors';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { coursesResourceSelector } from '../domain/courses/selectors';
import { recordsResourceSelector } from '../domain/records/selectors';
import { RootState } from '../domain/store';
import { teamsResourceSelector } from '../domain/teams/selectors';
import { usersResourceSelector } from '../domain/users/selectors';

type OwnParams = {
  [x: string]: number;
};

type Breadcrumb = {
  title: string;
  url?: string;
};

const useBreadcrumb = (params: OwnParams, queryParams: URLSearchParams) => {
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);

  const { pitch, course, record, team, user } = useSelector((state: RootState) => mapStateToProps(state));

  const courseRequired = !!course && (!!params['courseId'] || !!queryParams.get('courseId'));
  const teamRequired = !!team && (!!params['id'] || !!queryParams.get('id'));
  const pitchRequired = !!pitch && (!!params['pitchId'] || !!queryParams.get('pitchId'));
  const recordRequired = !!record && (!!params['recordId'] || !!queryParams.get('record'));
  const userRequired = !!user && (!!params['userId'] || !!queryParams.get('userId'));

  const buildBreadCrumb = (result: Breadcrumb[], flag: boolean, title?: string) => {
    if (flag && title) result.push({ title });
  };

  const populate = useCallback(() => {
    const result: Breadcrumb[] = [];
    buildBreadCrumb(result, courseRequired, course?.name);
    buildBreadCrumb(result, teamRequired, team?.name);
    buildBreadCrumb(result, pitchRequired, pitch?.name);
    buildBreadCrumb(result, recordRequired, `${record?.author?.firstName} ${record?.author?.lastName}`);
    buildBreadCrumb(result, userRequired, `${user?.firstName} ${user?.lastName}`);
    setBreadcrumbs(result);
  }, [
    courseRequired,
    course?.name,
    teamRequired,
    team?.name,
    pitchRequired,
    pitch?.name,
    recordRequired,
    record?.author?.firstName,
    record?.author?.lastName,
    userRequired,
    user?.firstName,
    user?.lastName,
  ]);

  useEffect(() => {
    if (params || queryParams) populate();
  }, [params, populate, queryParams]);

  const removeBreadcrumb = useCallback(
    (breadCrumb?: Breadcrumb) => {
      if (breadCrumb && !breadcrumbs.find((i) => breadCrumb.url === i.url)) {
        setBreadcrumbs(breadcrumbs.splice(0, breadcrumbs.indexOf(breadCrumb)));
      } else {
        setBreadcrumbs([]);
      }
    },
    [breadcrumbs, setBreadcrumbs],
  );

  return { breadcrumbs, removeBreadcrumb };
};

const mapStateToProps = (state: RootState) => {
  return {
    pitch: pitchesResourceSelector(state),
    course: coursesResourceSelector(state),
    record: recordsResourceSelector(state),
    team: teamsResourceSelector(state),
    user: usersResourceSelector(state),
  };
};

export { useBreadcrumb };
export type { Breadcrumb };
