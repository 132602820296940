import { SortConfigType } from 'types/common';
import { ResourcesResponse } from 'lib/http';
import { UserType } from './users';
import { WorkspaceType } from './workspaces';
import { useIntl } from 'react-intl';
import { IntlKeys } from 'localization';
import { FilterFormValues } from 'components/filter-bar';

export type CompanyType = {
  id: number;
  name: string;
  status: CompanyStatusEnum;
  type: CompanyTypeEnum;
  mostActiveUser: UserType;
  mostActiveWorkspace: WorkspaceType;
  recordCount: number;
  activeUsers: number;
  activeWorkspaces: number;
  coursesCount: number;
  pitchesCount: number;
  newRecordsCount: number;
  oldestNotReviewedRecord: string | null;
  users: UserType[];
  sessionCount?: number;
  //
  createdAt: string;
  updatedAt: string;
};

export enum CompanyTypeEnum {
  Coach = 'coach',
  Common = 'common',
}

export enum CompanyActionEnum {
  Update = 'Update',
  Delete = 'Delete',
  Archive = 'Archive',
  UnArchive = 'UnArchive',
}

export enum CompanyStatusEnum {
  Lead = 'lead',
  Active = 'active',
  Archived = 'archived',
}

export type CompaniesResponse = ResourcesResponse<CompanyType>;

export const uSortConfigs = {
  nameAsc: {
    field: 'name',
    order: 'ASC',
  },
  nameDesc: {
    field: 'name',
    order: 'DESC',
  },
  usersCountAsc: {
    field: 'usersCount',
    order: 'ASC',
  },
  usersCountDesc: {
    field: 'usersCount',
    order: 'DESC',
  },
  activityAsc: {
    field: 'activity',
    order: 'ASC',
  },
  activityDesc: {
    field: 'activity',
    order: 'DESC',
  },
  updatedAtAsc: {
    field: 'updatedAt',
    order: 'ASC',
  },
  updatedAtDesc: {
    field: 'updatedAt',
    order: 'DESC',
  },
};

export const CompanySortWithLabels = () => {
  const { formatMessage } = useIntl();
  return [
    {
      value: 'nameAsc',
      label: formatMessage({ id: IntlKeys.workspaceNameAToZ }),
    },
    {
      value: 'nameDesc',
      label: formatMessage({ id: IntlKeys.workspaceNameZToA }),
    },
    {
      value: 'usersCountAsc',
      label: formatMessage({ id: IntlKeys.workspaceUserCountAsc }),
    },
    {
      value: 'usersCountDesc',
      label: formatMessage({ id: IntlKeys.workspaceUserCountDesc }),
    },
    {
      value: 'updatedAtDesc',
      label: formatMessage({ id: IntlKeys.workspaceLastUpdates }),
    },
    {
      value: 'updatedAtAsc',
      label: formatMessage({ id: IntlKeys.workspaceOldUpdates }),
    },
  ];
};

export const CompaniesSortConfigs = uSortConfigs as Record<keyof typeof uSortConfigs, SortConfigType>;
export type CompanyFiltersFormValues = FilterFormValues<keyof typeof CompaniesSortConfigs>;

export type CompaniesListRequestParams = {
  sort: keyof typeof CompaniesSortConfigs;
  filters: Record<'search' | string, string>;
};
