import React, { memo } from 'react';
import { Typography } from '@mui/material';
import { useIntl, FormattedMessage } from 'react-intl';
import { IntlKeys } from 'localization';
// components
import RoundedButton from 'components/buttons/rounded';
import ModalDialog from 'components/modal-dialog';
// styles
import cx from 'clsx';
import useStyles from './styles';

export type ConfirmDialogProps = {
  title: string;
  isOpen: boolean;
  isLoading?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  confirmBtnText?: React.ReactNode;
  imageSrc?: string;
  subText?: string;
  description?: string;
  children?: unknown;
};

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  title,
  isOpen,
  onConfirm,
  onCancel,
  imageSrc,
  subText,
  isLoading = false,
  confirmBtnText = <FormattedMessage id={IntlKeys.confirm} />,
  description,
  children,
}: ConfirmDialogProps) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  return (
    <ModalDialog
      title={title}
      maxWidth="sm"
      fullWidth
      isLoading={isLoading}
      isOpen={isOpen}
      handleClose={onCancel}
      classes={{
        topBar: classes.modalBarWithoutBorder,
        topBarTitle: classes.modalTopBarTitle,
        content: classes.modalContent,
        bottomBar: cx(classes.modalBarWithoutBorder, classes.modalBottomBar),
      }}
      disableTopBarCloseButton
      bottomBarContent={
        <>
          <RoundedButton className={cx(classes.modalButton, classes.modalLogoutButton)} onClick={onConfirm}>
            {confirmBtnText}
          </RoundedButton>
          <RoundedButton className={cx(classes.modalButton, classes.modalCancelButton)} onClick={onCancel}>
            {formatMessage({ id: IntlKeys.cancel })}
          </RoundedButton>
        </>
      }
    >
      <div className={classes.modalContentWrapper}>
        <Typography variant="h6" className={classes.subText}>
          {subText}
        </Typography>
        {description && (
          <Typography variant="body2" className={classes.description}>
            {description}
          </Typography>
        )}
        {imageSrc && <img className={classes.modalContentImage} src={imageSrc} alt="modal image" />}
        {children}
      </div>
    </ModalDialog>
  );
};

export default memo(ConfirmDialog);
