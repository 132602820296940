import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  menuList: {
    minWidth: 440,
    maxHeight: '80vh',

    '@media only screen and (min-device-width: 375px)': {
      minWidth: 350,
    },

    '@media only screen and (min-device-width: 768px)': {
      minWidth: 440,
    },
  },

  button: {
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
  },

  menuHeader: {
    padding: '.75rem 1rem',
    color: theme.colors.primary,
    display: 'flex',
    justifyContent: 'flex-end',
    cursor: 'pointer',
    borderBottom: `1px solid ${theme.colors.stroke}`,
    fontWeight: 600,
  },

  empty: {
    padding: '.75rem 1rem',
    color: theme.colors.primary,
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    fontWeight: 600,
  },

  menuItem: {
    width: '100%',
    padding: '.75rem 1rem',
    color: theme.colors.oxfordBlue,
    borderBottom: `1px solid ${theme.colors.stroke}`,

    '&:hover': {
      backgroundColor: theme.colors.lightBlue,
      color: theme.colors.primary,
    },
  },

  listIconContainer: {
    minWidth: 'unset',
    margin: '0 12px 0 0',
    color: theme.colors.primary,
  },

  listTxtContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },

  dateText: {
    fontSize: '13px',
    lineHeight: '16px',
    textAlign: 'right',
    color: theme.colors.secondary,
  },

  dateHolder: {
    flex: 1,
  },

  listTxtHolder: {
    display: 'flex',
    flexDirection: 'column',
  },

  listIcon: {
    width: 40,
    height: 40,
    borderRadius: 20,
    background: theme.colors.primary,
    color: theme.colors.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  menuItemLabel: {
    fontFamily: theme.fonts.gilmer,
    display: 'flex',
    alignItems: 'center',
  },

  dot: {
    margin: '0px 1rem',
    width: 10,
    height: 10,
    borderRadius: '50%',
    display: 'inline-block',

    '&.active': {
      background: theme.colors.warning,
    },
    '&.reviewed': {
      display: 'none',
    },
  },
  menuItemLabelSub: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: theme.colors.secondary,
  },
}));
