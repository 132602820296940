import { SortConfigType } from 'types/common';
import { UserType } from 'types/users';
//
import { IntlKeys } from 'localization';
import { ResourcesResponse } from 'lib/http';

export type UsersResponse = ResourcesResponse<UserType>;

export const uSortConfigs = {
  firstNameAsc: {
    field: 'firstName',
    order: 'ASC',
  },
  firstNameDesc: {
    field: 'firstName',
    order: 'DESC',
  },
  progressAsc: {
    field: 'progress',
    order: 'ASC',
  },
  progressDesc: {
    field: 'progress',
    order: 'DESC',
  },
  createdAtAsc: {
    field: 'createdAt',
    order: 'ASC',
  },
  createdAtDesc: {
    field: 'createdAt',
    order: 'DESC',
  },
  activityAsc: {
    field: 'activity',
    order: 'ASC',
  },
  activityDesc: {
    field: 'activity',
    order: 'DESC',
  },
  sessionsAsc: {
    field: 'sessionActivity',
    order: 'ASC',
  },
  sessionsDesc: {
    field: 'sessionActivity',
    order: 'DESC',
  },
};

export const usersSortOptions: Array<{ labelIntlKey: string; value: keyof typeof uSortConfigs }> = [
  {
    labelIntlKey: IntlKeys.usersNameAtoZ,
    value: 'firstNameAsc',
  },
  {
    labelIntlKey: IntlKeys.usersNameZtoA,
    value: 'firstNameDesc',
  },
  {
    labelIntlKey: IntlKeys.usersProgressAsc,
    value: 'progressAsc',
  },
  {
    labelIntlKey: IntlKeys.usersProgressDesc,
    value: 'progressDesc',
  },
  {
    labelIntlKey: IntlKeys.usersActivityAsc,
    value: 'activityAsc',
  },
  {
    labelIntlKey: IntlKeys.usersActivityDesc,
    value: 'activityDesc',
  },
  {
    labelIntlKey: IntlKeys.usersRecentlyAdded,
    value: 'createdAtAsc',
  },
  {
    labelIntlKey: IntlKeys.usersOldestUsers,
    value: 'createdAtDesc',
  },
];

export const UserSortConfigs = uSortConfigs as Record<keyof typeof uSortConfigs, SortConfigType>;
