import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
// types
import { CompanyType, CompaniesResponse } from 'types/companies';
import { DomainType } from 'types/domains';
// utils
import { Params } from 'lib/http';
import { backofficeRestClient } from 'portal/backoffice/lib/http';
import { createApiCall } from 'lib/http/utils/createApiCall';
import { uploadFile } from 'portal/backoffice/lib/uploadFile';
import { replaceSpaces } from 'utils/string';

export const fetchCompaniesWorker = async <T>(requestPayload: T) => {
  const callApi = createApiCall<CompaniesResponse>({
    api: backofficeRestClient.getCompanies,
  });

  const { data } = await callApi(requestPayload);

  return data;
};
export const getCompaniesAction = createAsyncThunk<CompaniesResponse, Params | undefined>(
  'companies/getCompanies',
  async (requestPayload, { rejectWithValue }) => {
    try {
      return await fetchCompaniesWorker(requestPayload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
export const updateCompanyAction = createAsyncThunk<CompanyType, Params | undefined>(
  'companies/patchCompany',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<CompanyType>({
      api: backofficeRestClient.updateCompany,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const archiveCompanyAction = createAsyncThunk<CompanyType, Params | undefined>(
  'companies/updateCompany',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<CompanyType>({
      api: backofficeRestClient.patchCompany,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const unArchiveCompanyAction = createAsyncThunk<CompanyType, Params | undefined>(
  'companies/updateCompany',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<CompanyType>({
      api: backofficeRestClient.patchCompany,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const deleteCompanyAction = createAsyncThunk<CompanyType, Params | undefined>(
  'companies/deleteCompany',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<CompanyType>({
      api: backofficeRestClient.deleteCompany,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createCompanyAction = createAsyncThunk<CompaniesResponse, Params | undefined>(
  'companies/createCompany',
  async (requestPayload, { rejectWithValue }) => {
    try {
      const callApi = createApiCall<CompaniesResponse>({
        api: backofficeRestClient.createCompany,
      });
      let logo = requestPayload?.logo;
      if (logo) {
        const blob = await fetch(logo).then((r) => r.blob());
        logo = await uploadFile({
          file: new File([blob], `${replaceSpaces(requestPayload?.name)}_logo`, {
            type: 'image/png',
          }),
          name: `${replaceSpaces(requestPayload?.name)}_logo`,
        });
        if (!logo) {
          throw new Error('Logo upload failed');
        }
      }
      const { data } = await callApi({
        ...requestPayload,
        logo,
      });
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getCompanyAction = createAsyncThunk<CompanyType, Params<{ id: string | number }>>(
  'companies/getCompany',
  async (requestPayload: Params, { rejectWithValue }) => {
    const callApi = createApiCall<CompanyType>({
      api: backofficeRestClient.getCompany,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const changeCompaniesRequestParamsAction = createAction<{
  sort: Record<string, unknown>;
  filters: Record<string, unknown>;
}>('companies/changeCompaniesParams');

export const companiesSetIsStaleAction = createAction<boolean>('companies/setIsStale');

export const getCompanyDomainAction = createAsyncThunk<
  DomainType[],
  Params<Record<'companyId' | 'domainId', string | number>>
>('companies/getCompanyDomain', async (requestPayload: Params, { rejectWithValue }) => {
  const callApi = createApiCall<DomainType[]>({
    api: backofficeRestClient.getCompanyDomain,
  });

  try {
    const { data } = await callApi(requestPayload);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getCompanyDomainListAction = createAsyncThunk<DomainType[], Params<Record<'companyId', string | number>>>(
  'companies/getCompanyDomainList',
  async (requestPayload: Params, { rejectWithValue }) => {
    const callApi = createApiCall<DomainType[]>({
      api: backofficeRestClient.getCompanyDomainList,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createCompanyDomainAction = createAsyncThunk<
  DomainType,
  DomainType & Params<Record<'companyId', string | number>>
>('companies/createCompanyDomain', async (requestPayload: Params, { rejectWithValue }) => {
  const callApi = createApiCall<DomainType>({
    api: backofficeRestClient.createCompanyDomain,
  });

  try {
    const { data } = await callApi(requestPayload);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateCompanyDomainAction = createAsyncThunk<
  DomainType,
  DomainType & Params<Record<'companyId', string | number>>
>('companies/updateCompanyDomain', async (requestPayload: Params, { rejectWithValue }) => {
  const callApi = createApiCall<DomainType>({
    api: backofficeRestClient.updateCompanyDomain,
  });

  try {
    const { data } = await callApi(requestPayload);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const deleteCompanyDomainAction = createAsyncThunk<
  { success: boolean },
  Params<Record<'domainId', string | number>>
>('companies/deleteCompanyDomain', async (requestPayload: Params, { rejectWithValue }) => {
  const callApi = createApiCall<{ success: boolean }>({
    api: backofficeRestClient.deleteCompanyDomain,
  });

  try {
    const { data } = await callApi(requestPayload);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});
