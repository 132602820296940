import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
// utils
import { isCurrentRoute } from 'routes';
import routes from 'portal/backoffice/routes/routes';
import { useSidebarItems } from 'utils/useSidebarItems';
import { IntlKeys } from 'localization/keys';
import { checkRouteAllowed } from 'portal/backoffice/lib/acl/checkRouteAllowed';
// domain
import { signOutAction } from 'portal/backoffice/domain/auth/actions';
// components
import ConfirmDialog from 'components/confirm-dialog';
// styles
import { makeStyles } from '@mui/styles';
import cx from 'clsx';
import logo from 'assets/logo.svg';
import { ReactComponent as LogOut } from 'assets/icons/logOut.svg';
import logoutImage from 'assets/generic-images/logout-modal-image.png';
import { getUrlWithParams } from 'portal/backoffice/routes';

interface OwnProps {
  isMenu?: boolean;
}
const Sidebar: React.FC<OwnProps> = ({ isMenu }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [isLogoutModalOpen, setLogoutModalOpen] = useState(false);
  const { mainNavigation, additionalNavigation } = useSidebarItems({ routes, checkRouteAllowed, getUrlWithParams });

  const openLogoutModal = useCallback(() => {
    setLogoutModalOpen(true);
  }, []);

  const closeLogoutModal = useCallback(() => {
    setLogoutModalOpen(false);
  }, []);

  const handleSignOut = useCallback(() => {
    dispatch(signOutAction());
    closeLogoutModal();
  }, [dispatch, closeLogoutModal]);

  return (
    <div className={classes.root}>
      {!isMenu && <img src={logo} alt="logo" className={classes.logo} />}

      <nav className={cx(classes.navigation, classes.navMain)}>
        {mainNavigation.map(({ routeKey, path, Icon, IconActive, text }) => {
          return (
            <NavLink
              key={path}
              to={path}
              exact={routeKey === 'home' ? true : false}
              className={classes.menuItem}
              activeClassName={classes.menuItemActive}
            >
              {isCurrentRoute(pathname, path, routeKey === 'home' ? true : false) ? IconActive : Icon} {text}
            </NavLink>
          );
        })}
      </nav>

      <nav className={cx(classes.navigation, classes.navSecondary)}>
        {additionalNavigation.map(({ path, Icon, IconActive, text }) => (
          <NavLink
            key={path}
            to={path}
            exact={false}
            className={classes.menuItem}
            activeClassName={classes.menuItemActive}
          >
            {isCurrentRoute(pathname, path, false) ? IconActive : Icon} {text}
          </NavLink>
        ))}

        <a onClick={openLogoutModal} className={classes.menuItem}>
          <LogOut />
          {formatMessage({ id: IntlKeys.logOut })}
        </a>
      </nav>

      <ConfirmDialog
        title="Log Out"
        subText="Are you sure you want to log out?"
        confirmBtnText="Yes, logout"
        imageSrc={logoutImage}
        isOpen={isLogoutModalOpen}
        onConfirm={handleSignOut}
        onCancel={closeLogoutModal}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => {
  const activeMenuItem = {
    backgroundColor: theme.colors.lightBlue,
    color: theme.colors.primary,

    '& > svg > path': {
      fill: 'currentColor',
    },
  };

  return {
    root: {
      height: '100vh',
      borderRight: `1px solid ${theme.colors.stroke}`,
      background: 'white',
    },

    logo: {
      width: 126,
      margin: '24px 20px',
    },

    navigation: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      padding: 8,
    },

    navMain: {},
    navSecondary: {
      position: 'absolute',
      paddingTop: 16,
      bottom: 0,
      height: 172,
      borderTop: `1px solid ${theme.colors.stroke}`,
      borderRight: `1px solid ${theme.colors.stroke}`,
      background: theme.colors.white,
    },

    menuItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: 13,

      fontFamily: theme.fonts.gilmer,
      fontSize: 14,

      borderRadius: 5,
      transition: 'all 0.3s ease-out',

      cursor: 'pointer',
      color: theme.colors.secondary,
      textDecoration: 'none',

      '& > svg': {
        marginRight: 10,
        marginTop: -1,
        width: 17,
        '& > path': {
          transition: 'all 0.3s ease-out',
        },
      },

      '&:hover': activeMenuItem,
    },

    menuItemActive: activeMenuItem,
  };
});

export default React.memo(Sidebar);
