// utils
import { ResourcesResponse } from 'lib/http';
// types
import { UserType } from 'types/users';
import { TeamType } from 'types/teams';

export enum CourseStatuses {
  draft = 'draft',
  published = 'published',
  disabled = 'disabled',
}
export type CourseStatusType = CourseStatuses.draft | CourseStatuses.published | CourseStatuses.disabled;

interface TeamMemberFromBack {
  teamIds: number[];
  user: UserType;
}
type SharedToWorkspace = {
  courseId: number;
  workspaceId: number;
};
export type CourseType = {
  id: number;
  name: string;
  description: string;
  cover: string | null;
  createdAt: string;
  updatedAt: string;
  workspaceId: number;
  author: UserType | null;
  authorId: number | null;
  authorWasDeleted: boolean;
  status: CourseStatusType;
  tags: Array<TagType>;
  teams: Array<TeamType>;
  members: Array<UserType>;
  sharedToWorkspaces: Array<SharedToWorkspace>;
  teamMembers: TeamMemberFromBack[];
  progress: number;
  recordCount: number;
  usersCount: number;
  isFavorite: boolean;
  // isSharedToWorkspace: boolean; // DEPRECATED
  isSharedFromCompany: boolean;
  isSharedToWorkspace: boolean;
  isSharedFromBackoffice: boolean;
};

export type CourseListResponse = ResourcesResponse<CourseType>;

export type TagType = {
  id: string;
  title: string;
  color: string;
};

export type FavoriteType = {
  id: number;
  createdAt: string;
  updatedAt: string;
  userId: number;
  favoriteResourceId: number;
};

export type CoursePayloadType = {
  name: string;
  description: string;
  status?: CourseStatusType;
  cover: Blob | string | null;
  workspaceId?: number;
  tags: string[];
  //
  newTags?: Omit<TagType, 'id'>[];
  teams?: number[];
  members?: number[];
};

export enum CourseActionEnum {
  Share = 'Share',
  Duplicate = 'Duplicate',
  Update = 'Update',
  Delete = 'Delete',
  Details = 'Details',
  Export = 'Export',
  Archive = 'Archive',
  UnArchive = 'UnArchive',
}
