import React, { useCallback, Suspense, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
// types
import { DomainWrongSubdomains } from 'types/domains';
// utils
import { GenericChildRoute } from 'routes';
import translations, { initializeIntl, LangCode } from 'localization';
import { useGlobalRoutes } from 'hooks/useGlobalRoutes';
import ACL from 'portal/workspace/lib/acl';
import { RXD, isScormPortal } from 'utils/portalCheck';
import { fallbackLocale } from 'localization/utils';
import { getRoutesForUser } from 'portal/workspace/routes';
import revalidateWorkspaceToken from 'portal/workspace/lib/http/revalidateWorkspaceToken';
// domain
import {
  authHeadersAvailableSelector,
  authIsPendingSelector,
  authUserSelector,
} from 'portal/workspace/domain/auth/selectors';
import { fetchCurrentUserAction, signInScormAction } from 'portal/workspace/domain/auth/actions';
import { notifyRouteMountedAction } from 'portal/workspace/domain/router/reducer';
import { fileUploadProgressSelector } from 'portal/workspace/domain/general/selectors';
import { fetchNotificationListWorker, updateNotificationAction } from 'portal/workspace/domain/notifications/actions';
import { domainsDomainSelector } from 'portal/workspace/domain/domain/selectors';
import { getPublicDomainAction } from 'portal/workspace/domain/domain/actions';
// components
import AsyncRedirect from 'routes/AsyncRedirect';
import { Loader } from 'components/loader';
import FileUploadProgressContainer from 'components/FileUploadProgressConatiner';
import { HeaderContextProvider } from 'components/layouts/common/dynamic-header';
import {
  NotificationsContextProvider,
  DomainProps as NotificationDomainProps,
} from 'components/notification/NotificationContext';
// styles
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material';
import { mainTheme } from 'theme';

const App: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  //
  const user = useSelector(authUserSelector);
  const authHeadersAvailable = useSelector(authHeadersAvailableSelector);
  const isAuthLoading = useSelector(authIsPendingSelector);
  const progressData = useSelector(fileUploadProgressSelector);
  const domain = useSelector(domainsDomainSelector);
  const locale: LangCode = user?.language ?? fallbackLocale;
  const [primaryColor, setPrimaryColor] = useState(mainTheme.colors.primary);

  const theme = useMemo(
    () =>
      createTheme(mainTheme, {
        palette: { primary: { main: primaryColor } },
        colors: { primary: primaryColor },
      }),
    [primaryColor],
  );
  const fetchDomain = useCallback(
    (domainName: string) => {
      dispatch(getPublicDomainAction({ params: { domainName: domainName } }));
    },
    [dispatch],
  );

  useEffect(() => {
    const host = window.location.host;
    const hostParts = host.split('.');
    if (hostParts.length >= 3 && !DomainWrongSubdomains.includes(hostParts[0])) {
      fetchDomain(hostParts[0]);
    }
  }, [fetchDomain]);

  const { allowedRoutes, defaultRoute } = useGlobalRoutes({
    user,
    //
    routes: getRoutesForUser(user),
    checkRouteAllowed,
    notifyRouteMountedAction,
  });

  useEffect(() => {
    initializeIntl(locale);
  }, [locale]);

  useEffect(() => {
    if (domain?.primaryColor && domain.primaryColor !== primaryColor) {
      setPrimaryColor(domain.primaryColor);
    }
  }, [domain, primaryColor]);

  useEffect(() => {
    if (isScormPortal && !authHeadersAvailable) {
      dispatch(
        signInScormAction({
          token: new URLSearchParams(window.location.search).get('token'),
          userId: RXD!.GetStudentID(),
          firstName: RXD!.GetStudentName(),
        }),
      );
    }

    authHeadersAvailable && dispatch(fetchCurrentUserAction());
  }, [authHeadersAvailable, dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <NotificationsContextProvider {...notificationDomainProps} user={user}>
        <HeaderContextProvider>
          <div className="App">
            <IntlProvider locale={locale} messages={translations[locale]}>
              <BrowserRouter>
                {/* TODO extract providers out*/}

                {isAuthLoading ? (
                  <Loader />
                ) : (
                  <Suspense fallback={<Loader />}>
                    <Switch>
                      {allowedRoutes.map((route) => (
                        <Route
                          //
                          key={route.path}
                          path={route.path}
                          exact={route.exact}
                          component={route.component}
                        />
                      ))}

                      <Route
                        render={({ location }) => {
                          // Made redirects async to let components finish possible state updates
                          // to not to have errors related to updating state on unmounted components
                          return <AsyncRedirect location={location} path={defaultRoute.path} />;
                        }}
                      />
                    </Switch>
                  </Suspense>
                )}

                {children}

                <FileUploadProgressContainer progressData={progressData} />
                {/* TODO extract providers out*/}
              </BrowserRouter>
            </IntlProvider>
          </div>
          <CssBaseline />
        </HeaderContextProvider>
      </NotificationsContextProvider>
    </ThemeProvider>
  );
};

const notificationDomainProps: NotificationDomainProps = {
  updateNotificationAction,
  revalidateToken: revalidateWorkspaceToken,
  fetchNotificationListWorker,
};

const checkRouteAllowed = (route: GenericChildRoute) => {
  // Every restriction in route.restriction has to match with ACL restrictions
  const restrictionEntries = route.restrictions || [];

  return restrictionEntries.every((permission) => ACL.canView(permission));
};

export default App;
