import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
// types
import { WorkspaceType, WorkspacesResponse } from 'types/workspaces';
// utils
import { Params } from 'lib/http';
import { backofficeRestClient } from 'portal/backoffice/lib/http';
import { createApiCall } from 'lib/http/utils/createApiCall';
import { uploadFile } from 'portal/backoffice/lib/uploadFile';
import { replaceSpaces } from 'utils/string';

export const fetchWorkspacesWorker = async <T>(requestPayload: T) => {
  const callApi = createApiCall<WorkspacesResponse>({
    api: backofficeRestClient.getWorkspaces,
  });

  const { data } = await callApi(requestPayload);

  return data;
};
export const getWorkspacesAction = createAsyncThunk<WorkspacesResponse, Params | undefined>(
  'workspaces/getWorkspaces',
  async (requestPayload, { rejectWithValue }) => {
    try {
      return await fetchWorkspacesWorker(requestPayload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getCoachesWorkspacesAction = createAsyncThunk<WorkspacesResponse, Params | undefined>(
  'companies/getCoachesCompanies',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<WorkspacesResponse>({
      // TODO companies -> workspaces -> end
      api: backofficeRestClient.getWorkspaces,
    });

    try {
      const { data } = await callApi(requestPayload);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateWorkspaceAction = createAsyncThunk<WorkspaceType, Params | undefined>(
  'workspaces/updateWorkspace',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<WorkspaceType>({
      api: backofficeRestClient.updateWorkspace,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateWorkspacesStatusAction = createAsyncThunk<WorkspaceType, Params | undefined>(
  'workspaces/updateWorkspacesStatus',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<WorkspaceType>({
      api: backofficeRestClient.updateWorkspaceStatus,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const deleteWorkspaceAction = createAsyncThunk<WorkspaceType, Params | undefined>(
  'workspaces/deleteWorkspace',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<WorkspaceType>({
      api: backofficeRestClient.deleteWorkspace,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createWorkspaceAction = createAsyncThunk<WorkspacesResponse, Params | undefined>(
  'workspaces/createWorkspace',
  async (requestPayload, { rejectWithValue }) => {
    try {
      const callApi = createApiCall<WorkspacesResponse>({
        api: backofficeRestClient.createWorkspace,
      });
      let logo = requestPayload?.logo;
      if (logo) {
        const blob = await fetch(logo).then((r) => r.blob());
        logo = await uploadFile({
          file: new File([blob], `${replaceSpaces(requestPayload?.name)}_logo`, {
            type: 'image/png',
          }),
          name: `${replaceSpaces(requestPayload?.name)}_logo`,
        });
        if (!logo) {
          throw new Error('Logo upload failed');
        }
      }
      const { data } = await callApi({
        ...requestPayload,
        logo,
      });
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getWorkspaceAction = createAsyncThunk<WorkspaceType, Params>(
  'workspaces/getWorkspace',
  async (requestPayload: Params, { rejectWithValue }) => {
    const callApi = createApiCall<WorkspaceType>({
      api: backofficeRestClient.getWorkspace,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const changeWorkspacesRequestParamsAction = createAction<{
  sort: Record<string, unknown>;
  filters: Record<string, unknown>;
}>('workspaces/changeWorkspacesParams');

export const workspacesSetIsStaleAction = createAction<boolean>('workspaces/setIsStale');
