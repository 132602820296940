import { createReducer } from '@reduxjs/toolkit';
import { Reducer } from 'redux';
//
import { getUserNotifications, deleteOutdatedNotificationsAction } from './actions';
import { Notification } from 'types/notification';
import { NotificationSortConfigs, NotificationStateType } from 'types/notifications';

const initialState: NotificationStateType = {
  meta: {
    isLoading: false,
    isResourcesLoaded: false,
    isStale: true,
  },
  filters: {},
  sort: NotificationSortConfigs.createdAtDesc,
  pagination: {
    page: 0,
    perPage: 10,
    pageCount: 0,
    total: 0,
  },
  resources: [] as Notification[],
  resource: null as Notification | null,
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getUserNotifications.pending, (draft) => {
      draft.meta.isLoading = true;
    })
    .addCase(getUserNotifications.fulfilled, (draft, { payload }) => {
      draft.meta.isLoading = false;
      draft.resources = payload.data;
    })
    .addCase(getUserNotifications.rejected, (draft) => {
      draft.meta.isLoading = false;
    })
    .addCase(deleteOutdatedNotificationsAction.pending, (draft) => {
      draft.meta.isLoading = true;
    })
    .addCase(deleteOutdatedNotificationsAction.fulfilled, (draft, { payload }) => {
      draft.meta.isLoading = false;
      draft.resources = payload.data;
    })
    .addCase(deleteOutdatedNotificationsAction.rejected, (draft) => {
      draft.meta.isLoading = false;
    });
});

export const notificationReducer: Reducer<typeof initialState> = (draft = initialState, action) => {
  return reducer(draft, action);
};
