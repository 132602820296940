//
export const IntlKeys = {
  //COMMON
  optional: 'common.optional',
  back: 'common.back',
  dashboard: 'common.dashboard',
  generalInfo: 'common.generalInfo',
  courses: 'common.courses',
  boundCourses: 'common.boundCourses',
  users: 'common.users',
  coach: 'common.coach',
  coaches: 'common.coaches',
  favourites: 'common.favourites',
  content: 'common.content',
  files: 'common.files',
  lists: 'common.lists',
  folders: 'common.folders',
  folder: 'common.folder',
  teams: 'common.teams',
  members: 'common.members',
  community: 'common.community',
  workspace: 'common.workspace',
  workspaces: 'common.workspaces',
  company: 'common.company',
  companies: 'common.companies',
  coachCompanies: 'common.coachCompanies',
  settings: 'common.settings',
  help: 'common.help',
  training: 'common.training',
  email: 'common.email',
  password: 'common.password',
  passwordPlaceholder: 'common.passwordPlaceholder',
  retypePassword: 'common.retypePassword',
  newPassword: 'common.newPassword',
  retypeNewPassword: 'common.retypeNewPassword',
  enterPassword: 'common.enterPassword',
  name: 'common.name',
  namePlaceholder: 'common.namePlaceholder',
  surname: 'common.surname',
  surnamePlaceholder: 'common.surnamePlaceholder',
  jobTitle: 'common.jobTitle',
  employee: 'common.employee',
  jobTitlePlaceholder: 'common.jobTitlePlaceholder',
  serverNotAvailableError: 'common.serverNotAvailableError',
  reviewsRequested: 'common.reviewsRequested.title',
  reviewsSearch: 'reviews.search',
  update: 'common.update.title',
  updates: 'common.updates.title',
  records: 'common.records.title',
  lastRecords: 'common.records.last.title',
  seeAll: 'common.seeAll',
  pitchesAndTrainings: 'common.pitchesAndTrainig',
  cancel: 'common.cancel',
  add: 'common.add',
  confirm: 'common.confirm',
  confirmQuestion: 'common.confirmQuestion',
  ok: 'common.ok',
  upload: 'common.upload',
  library: 'common.library',
  dragAndDrop: 'common.dragAndDrop',
  create: 'common.create',
  sortBy: 'common.sortBy',
  change: 'common.change',
  remove: 'common.remove',
  removeConfirm: 'common.removeConfirm',
  archiveConfirm: 'common.archiveConfirm',
  unArchiveConfirm: 'common.unArchiveConfirm',
  requiredItem: 'common.required',
  save: 'common.save',
  errorSomethingWentWrong: 'common.somethingWentWrong',
  title: 'common.title',
  enter: 'common.enter',
  edit: 'common.edit',
  apply: 'common.apply',
  filter: 'common.filter',
  filters: 'common.filters',
  status: 'common.status',
  share: 'common.share',
  delete: 'common.delete',
  timer: 'common.timer',
  text: 'common.text',
  choices: 'common.choices',
  quiz: 'common.quiz',
  enterText: 'common.enterText',
  addText: 'common.addText',
  or: 'common.or',
  other: 'common.other',
  selectFromList: 'common.addFromList',
  duplicate: 'common.duplicate',
  of: 'common.of',
  part: 'common.part',
  dontWorry: 'common.dontWorry',
  submit: 'common.submit',
  retake: 'common.retake',
  loading: 'common.loading',
  from: 'common.from',
  all: 'common.all',
  changesSaved: 'common.changesSaved',
  saveChanges: 'common.saveChanges',
  enterValidString: 'common.enterValidString',
  teamName: 'common.teamName',
  emoji: 'common.emoji',
  addTeam: 'common.addTeam',
  copyLink: 'common.copyLink',
  invalidEmail: 'common.invalidEmail',
  linkCopied: 'common.linkCopied',
  userName: 'common.userName',
  role: 'common.role',
  chooseRole: 'common.chooseRole',
  language: 'common.language',
  chooseLanguage: 'common.chooseLanguage',
  passwordsMustMatch: 'common.passwordsMustMatch',
  eightCharacterAtLeast: 'common.eightCharacterAtLeast',
  incorrectEmail: 'common.incorrectEmail',
  seeMore: 'common.seeMore',
  overview: 'common.overview',
  details: 'common.details',
  record: 'common.record',
  startRecording: 'common.startRecording',
  exportAsSCORMPackage: 'common.exportAsSCORMPackage',
  archive: 'common.archive',
  published: 'common.published',
  draft: 'common.draft',
  disabled: 'common.disabled',
  completed: 'common.completed',
  admin: 'common.admin',
  manager: 'common.manager',
  scormEmployee: 'common.scormEmployee',
  leavePageProomptMessage: 'common.leavePage.prompt.message',
  leavePageQuestion: 'common.leavePage.Question',
  uploadInProgress: 'common.upload.in.progress',
  uploadProgressPromptMessage: 'common.upload.progress.prompt.message',
  stopRecording: 'common.stopRecording',
  unArchive: 'common.unArchive',
  yes: 'yes',
  no: 'no',
  viewDetails: 'common.viewDetails',
  task: 'common.task',
  taskAudio: 'commont.taskAudio',
  taskInfo: 'common.taskInfo',
  taskText: 'commont.taskText',
  taskVideo: 'commont.taskVideo',
  accept: 'common.accept',
  reject: 'common.reject',
  phone: 'common.phone',
  enterPhone: 'common.enterPhone',
  enterSurname: 'common.enterSurname',
  summary: 'common.summary',
  timeline: 'common.timeline',
  showDesktop: 'common.showDesktop',
  showMobile: 'common.showMobile',
  logo: 'common.logo',
  type: 'common.type',
  search: 'common.search',

  //LOGIN PAGE
  loginTitle: 'login.title',
  loginSubtitle: 'login.subtitle',
  loginNoAccount: 'login.noAccount',
  signIn: 'login.signIn',
  signInError401: 'login.signIn.error.401',

  //FORGOT PASSWORD PAGE
  forgotPassword: 'forgotPassword.common',
  forgotPasswordQuestion: 'forgotPassword.question',
  forgotPasswordEnterEmail: 'forgotPassword.enterEmail',
  forgotPasswordSendLink: 'forgotPassword.sendLink',
  forgotPasswordBackToLogin: 'forgotPassword.backToLogin',
  forgotPasswordFulfilled: 'forgotPassword.fulfilled',
  forgotPasswordError404: 'forgotPassword.error.404',

  //RESET PASSWORD PAGE
  resetPassword: 'resetPassword.common',
  resetPasswordSubtitle: 'resetPassword.subTitle',
  resetPasswordChangePassword: 'resetPassword.changePassword',
  resetPasswordHaveAccount: 'resetPassword.haveAccount',
  resetPasswordError404: 'resetPassword.fulfilled',
  resetPasswordFulfilled: 'resetPassword.error.404',
  resetPasswordError422: 'resetPassword.error.422',

  //SIGN UP PAGE
  signUp: 'signUp.common',
  signUpTitle: 'signUp.title',
  signUpSubTitle: 'signUp.subTitle',
  signUpHaveAccount: 'signUp.haveAccount',
  signUpFulfilled: 'signUp.fulfilled',
  signUpError404: 'signUp.error.404',
  signUpError422: 'signUp.error.422',
  signUpError500: 'signUp.error.500',

  // Form validations
  formFieldFequired: 'form.field.required',
  formTimeExceedsTimer: 'form.field.timeExceedsTimer',

  // courses
  noCoursesAvailable: 'courses.empty.text',
  addNewCourse: 'courses.addNew',
  createCourse: 'courses.create.title',
  createCourseSubmit: 'courses.create.submit',
  createCourseFormLabelName: 'courses.crate.form.name',
  createCourseFormLabelDescription: 'courses.create.form.description',
  createCourseFormLabelTags: 'courses.create.form.tags',
  createCourseFormLabelTeams: 'courses.create.form.teams',
  createCourseFormLabelCover: 'courses.create.form.cover',
  courseDetails: 'courses.details',
  coursesSearch: 'courses.search',
  coursesDetachTeamTooltip: 'courses.teams.detach',
  coursesAttchTeamTooltip: 'courses.teams.attach',
  coursesDetachMemberTooltip: 'courses.members.detach',
  coursesAttachMemberTooltip: 'courses.members.attach',
  coursesDetachUnableMemberTooltip: 'courses.members.detachUnable',
  coursesRecordsView: 'courses.records.button.view',
  coursesAddCoursesToTeam: 'courses.addCoursesToTeam',
  coursesClearSelection: 'courses.clearSelection',
  coursesCourseNameAToZ: 'courses.courseNameAToZ',
  coursesCourseNameZToA: 'courses.courseNameZToA',
  coursesAuthorNameAToZ: 'courses.authorNameAToZ',
  coursesAuthorNameZToA: 'courses.authorNameZToA',
  coursesLastUpdates: 'courses.lastUpdates',
  coursesOldUpdates: 'courses.oldUpdates',
  courseClearFilter: 'course.clearfilter',
  coursesAddCourse: 'courses.addCourse',
  contentSearch: 'content.search',
  noContentAvailable: 'content.empty.text',
  copyToCourse: 'copy.to.course',
  courseCompletedStatus: 'course.completed.status',
  connectedCourses: 'course.connected.courses',
  coursesAttachToWorkspaces: 'courses.AttachToWorkspaces',

  // Course
  courseAttachDetachMember: 'course.attachDetachMember',
  courseAddTeam: 'course.addTeam',
  courseAddWorkspace: 'course.addWorkspace',
  courseAttachWorkspace: 'course.attachWorkspace',
  courseDetachWorkspace: 'course.detachWorkspace',
  courseOverview: 'course.overview',
  courseHistory: 'course.history',
  courseViewRecords: 'course.viewRecords',
  courseUpdateCourse: 'course.updateCourse',
  coursePublish: 'course.publish',
  coursePublishFailed: 'course.publishFailed',
  courseExportToSCORM: 'course.exportToSCORM',
  courseNoTeamAvailable: 'course.noTeamAvailable',
  courseCreatingTeamSuggestion: 'course.creatingTeamSuggestion',
  coursePublishCourseQuestionTitle: 'course.publishQuestionTitle',
  coursePublishCourseQuestionSubText: 'course.publishQuestionSubText',
  coursePublishCourseQuestionConfirmBtnText: 'course.publishQuestionConfirmBtnText',
  courseAddTags: 'courses.addTags',
  courseAddTeams: 'courses.addTeams',
  courseEnterTag: 'course.enterTag',
  courseAuthor: 'course.author',
  courseSendInvite: 'course.sendInvite',
  courseEnterEmail: 'course.enterEmail',
  courseRemoveCourse: 'course.removeCourse',
  courseArchiveCourse: 'course.archiveCourse',
  courseUnArchiveCourse: 'course.unArchiveCourse',
  courseRemovingCourseConfirmQuestion: 'course.removingCourseConfirmQuestion',
  courseRemovingCourseDescription: 'course.removingCourseDescription',
  courseArchivingCourseConfirmQuestion: 'course.archivingCourseConfirmQuestion',
  courseUnArchivingCourseConfirmQuestion: 'course.unArchivingCourseConfirmQuestion',
  courseShareCourse: 'course.shareCourse',
  courseCompleted: 'course.compeleted',
  courseDateCreated: 'course.dateCreated',
  courseDetachAttachTriggerAttachFulfilled: 'course.detachAttachTrigger.attachFulfilled',
  courseDetachAttachTriggerDetachFromTeam: 'course.detachAttachTrigger.detachFromTeam',
  courseDetachAttachTriggerAddToAnotherTeam: 'course.detachAttachTrigger.addToAnotherTeam',
  courseDetachAttachTriggerDetachFromTeamConfirmQuestion: 'course.detachAttachTrigger.detachFromTeam.confirmQuestion',
  courseDetachAttachTriggerDetachFromTeamConfirmBtnText: 'course.detachAttachTrigger.detachFromTeam.confirmBtnText',
  courseProgressTitle: 'course.progress.title',
  courseLeadershipBoardTitle: 'course.leadership.title',
  courseDeletionSuccess: 'course.deletion.success',
  courseDeletionFailure: 'course.deletion.failure',
  courseDuplicateTitle: 'course.dulicate.title',
  courseDuplicateDesc: 'course.duplicate.desc',
  courseDuplicateConfirm: 'course.duplicate.confirm',
  courseArchivingSuccess: 'course.archiving.success',
  courseArchivingFailure: 'course.archiving.failure',
  courseUnArchivingSuccess: 'course.unArchiving.success',
  courseUnArchivingFailure: 'course.unArchiving.failure',
  courseSearchForCompany: 'course.searchForCompany',
  courseScormLeavingQuestion: 'course.scorm.leave.question',
  courseScormLeavingSubtitle: 'course.scorm.leave.subtitle',
  courseScormLeavingSubmitText: 'course.scorm.leave.submitText',
  courseEmptyCourseSubText: 'course.emptyCourse.subText',

  // Teams
  teamSearch: 'team.search',
  teamsAndMembers: 'team.teamsAndMembers',
  teamManageMembers: 'team.manageMembers',
  teamEmptyTeam: 'team.emptyTeam.message',
  teamAccessLimited: 'team.accessLimited.message',
  teamNoLeaderBoardMembers: 'team.empty.leaderboard.members',
  teamNoLeaderBoardMembersSubTitle: 'team.empty.leaderboard.members.sub',
  teamMembersProgressTitle: 'team.members.progress.title',
  teamMembersLeadershipBoardTitle: 'team.members.leadership.title',

  // Admin Dashboard
  adminDashboardMyDrafts: 'adminDashboard.myDrafts',

  // Coach Dashboard
  dashboardActiveUsers: 'dashboard.activeUsers',
  dashboardActiveWorkspaces: 'dashboard.activeWorkspaces',
  dashboardCourseCompletion: 'dashboard.courseCompletion',
  dashboardCourseCompletionSubline: 'dashboard.courseCompletionSubline',
  dashboardCoursesPopular: 'dashboard.coursesPopular',
  dashboardLast30Days: 'dashboard.last30days',
  dashboardMostActiveUser: 'dashboard.mostActiveUser',
  dashboardMostActiveWorkspace: 'dashboard.mostActiveWorkspace',
  dashboardNewRecords: 'dashboard.newRecords',
  dashboardNoUsers: 'dashboard.noUsers',
  dashboardNoWorkspaces: 'dashboard.noWorkspaces',
  dashboardSessionsCount: 'dashboard.sessionsCount',
  dashboardUsersCount: 'dashboard.usersCount',
  dashboardUserLeaderboard: 'dashboard.userLeaderboard',
  dashboardWorkspaceLeaderboard: 'dashboard.workspaceLeaderboard',

  // Manager Dashboard
  managerDashboardTeamsActivity: 'managerDashboard.teamsActivity',
  managerDashboardMembersActivity: 'managerDashboard.membersActivity',
  managerDashboardNoLeaderboardAvailable: 'managerDashboard.noLeaderboardAvailabele',

  // Employee Dashboard
  employeeDashboardActiveCourses: 'employeeDashboard.activeCourses',
  employeeDashboardScoreDesc: 'employeeDashboard.scoreDesc',
  employeeDashboardScoreAsc: 'employeeDashboard.scoreAsc',
  employeeDashboardCompletedPercentDesc: 'employeeDashboard.completedPercentDesc',
  employeeDashboardCompletedPercentAsc: 'employeeDashboard.completedPercentAsc',
  employeeDashboardLeadersActivities: 'employeeDashboard.leadersActivities',

  // Feedback
  feedbacks: 'feedbacks.title',
  feedbackForTraining: 'feedback.forTraining',
  feedbackInTheCourse: 'feedback.inTheCourse',
  feedbackNoFeedbackProvided: 'feedback.noFeedbackProvided',
  feedbackEnterYourFeedback: 'feedback.enterYourFeedback',

  // Content
  contentPresentation: 'content.presentation',
  contentCreatedBy: 'content.createdBy',
  contentTypeOfContentFolders: 'content.typeOfContent.folders',
  contentTypeOfContentFiles: 'content.typeOfContent.files',
  contentTypeOfContentLists: 'content.typeOfContent.lists',
  contentUploadFile: 'content.uploadFile',
  contentAddFolder: 'content.addFolder',
  contentCreateList: 'content.createList',
  contentRecentFiles: 'content.recentFiles',
  contentAddContent: 'content.addContent',
  contentNothingToShow: 'content.nothingToShow',
  contentNoFileAvailable: 'content.noFileAvailable',
  contentFileEmpty: 'content.fileEmpty',
  contentListSubtitle: 'content.listSubtitle',
  contentNewFolder: 'content.newFolder',
  contentCreateFile: 'content.createFile',
  contentSearchContent: 'contnet.searchContent',
  contentSymbolQuantityLimited: 'content.symbolQuantityLimited',
  contentDownload: 'content.download',
  contentDetails: 'content.details',
  contentPreview: 'content.preview',
  contentAddAudio: 'content.addAudio',
  contentAddDocument: 'content.addDocument',
  contentReactionName: 'content.reactionName',
  contentSelectEmoji: 'content.selectEmoji',
  contentSaveReaction: 'content.saveReaction',
  contentEnterName: 'content.enterName',
  contentAddFiles: 'content.addFiles',
  contentSearchFiles: 'content.searchFiles',
  contentSearchFolder: 'content.searchFolder',
  contentCreateFolder: 'content.createFolder',

  // Content List
  contentListListName: 'contnet.list.listName',
  contentListEnterListName: 'contnet.list.enterlistName',
  contentListEvaluationCriterias: 'contnet.list.evaluatuonCriterias',
  contentListEvaluationCriteria: 'contnet.list.evaluatuonCriteria',
  contentListAddCriteria: 'contnet.list.addCriteria',
  contentListSaveCriteria: 'contnet.list.saveCriteria',
  contentListEnterDescription: 'contnet.list.enterDescription',
  contentListReactions: 'contnet.list.reactions',
  contentListDoWords: 'contnet.list.doWords',
  contentListDoWordsExplained: 'contnet.list.doWordsExplained',
  contentListDontWords: 'contnet.list.dontWords',
  contentListDontWordsExplained: 'contnet.list.dontWordsExplained',
  contentListEnterDoWords: 'contnet.list.enterDoWords',
  contentListEnterDontWords: 'contnet.list.enterDontWords',
  contentListEnterFileName: 'content.list.enterFileName',
  contentListEnterFolderName: 'content.list.enterFolderName',
  contentListAddContentList: 'content.list.addContentList',
  contentListSearchContentList: 'content.list.searchContentList',

  // DropzoneCard
  dropzoneCardLibrary: 'dropzoneCard.library',
  dropzoneCardLink: 'dropzoneCard.link',
  dropzoneCardEnterLink: 'dropzoneCard.enterLink',
  dropzoneCardAudio: 'dropzoneCard.contentType.audio',
  dropzoneCardImage: 'dropzoneCard.contentType.image',
  dropzoneCardVideo: 'dropzoneCard.contentType.video',
  dropzoneCardDocument: 'dropzoneCard.contentType.document',

  // LogOut
  logOut: 'logOut',
  logOutConfirmQuestion: 'logOut.confirmQuestion',
  logOutConfirmBtnText: 'logOut.logOutConfirmBtnText',

  // Alert messages
  alertFileCreatingFulfilled: 'alert.fileCreatingFulfilled',
  alertFileCreatingFailed: 'alert.fileCreatingFailed',
  alertFileDeletingFulfilled: 'alert.fileDeletingFulfilled',
  alertFileUpdatingFulfilled: 'alert.fileUpdatingFulfilled',
  alertFileUpdatingFailed: 'alert.fileUpdatingFailed',
  alertFolderCreatingFulfilled: 'alert.folderCreatingFulfilled',
  alertFolderDeletingFulfilled: 'alert.folderDeletingFulfilled',
  alertFolderUpdatingFulfilled: 'alert.folderUpdatingFulfilled',
  alertListCreatingFulfilled: 'alert.listCreatingFulfilled',
  alertListDeletingFulfilled: 'alert.listDeletingFulfilled',
  alertListUpdatingFulfilled: 'alert.listUpdatingFulfilled',
  alertNotSupportFileFormat: 'alert.notSupportFileFormat',
  alertCoursesAttachingFulfilled: 'alert.coursesAttachingFulfilled',
  alertSlideUpdatingFulfilled: 'alert.slideUpdatingFulfilled',
  alertSlidePublishingFulfilled: 'alert.slidePublishingFulfilled',
  alertSlidePublishingFailed: 'alert.slidePublishingFailed',
  alertReviewSubmittingFulfilled: 'alert.reviewSubmittingFulFilled',
  alertSomethingWentWrongTryAgain: 'alert.somethingWentWrongTryAgain',
  alertMembersEditingFulfilled: 'alert.membersEditingFulfilled',
  alertMembersUpdatingFailed: 'alert.membersUpdatingFailed',
  alertSavingChangesFulfilled: 'alert.savingChangesFulfilled',
  alertCloningCourseFulfilled: 'alert.cloningCourseFulfilled',
  alertUpdatingPitchFulfilled: 'alert.updatingPitchFulfilled',
  alertCreatingPitchFulfilled: 'alert.creatingPitchFulfilled',
  alertCopyingPitchToCourseFulfilled: 'alert.copyingPitchToCourseFulfilled',
  alertDeletingPitchFulfilled: 'alert.deletingPitchFulfilled',
  alertDeletingCourseFulfilled: 'alert.deletingCourseFulfilled',
  alertDeletingCourseFailed: 'alert.deletingCourseFailed',
  alertCloningCourseFailed: 'alert.cloningCourseFailed',
  alertUpdatingPitchFailed: 'alert.updatingPitchFailed',
  alertCreatingPitchFailed: 'alert.creatingPitchFailed',
  alertCopyingPitchToCourseFailed: 'alert.copyingPitchToCourseFailed',
  alertDeletingPitchFailed: 'alert.deletingPitchFailed',
  alertDeletingCommentFailed: 'alert.deletingCommentFailed',
  alertEditingCommentFailed: 'alert.editingCommentFailed',
  alertUsersCreatingFailed: 'alert.creationUsersFailed',
  alertDetachingMemberFulfilled: 'alert.detachingMemberFulfilled',
  alertDetachingMemberFailed: 'alert.detachingMemberFailed',
  alertFavoritingCourseFailed: 'alert.favoritingCourseFailed',
  alertFavoritingPitchFailed: 'alert.favoritingPitchFailed',
  alertCreatingTeamTrainingFailed: 'alert.creatingTeamTrainingFailed',
  alertCreatingTeamTrainingFulfilled: 'alert.creatingTeamTrainingFullfilled',
  alertCoursesAttachingToWorkspaceFulfilled: 'alert.coursesAttachingToWorkspaceFulfilled',
  alertSlideUploadingFileFailed: 'alert.slideUploadingFileFailed',
  alertSlideUploadingFileFulfilled: 'alert.slideUploadingFileFullfilled',
  alertDeletingUserFulfilled: 'alert.deletingUserFulfilled',
  alertDeletingUserFailed: 'alert.deletingUserFailed',
  alertArchivingUserFulfilled: 'alert.archivingUserFulfilled',
  alertArchivingUserFailed: 'alert.archivingUserFailed',
  alertArchivingUserFailedUserWorkspaceAdmin: 'alert.archivingUserFailed',
  alertUnArchivingUserFulfilled: 'alert.unArchivingUserFulfilled',
  alertUnArchivingUserFailed: 'alert.unArchivingUserFailed',
  alertSlideUploadingPresentationFailed: 'alert.slideUploadingPresentationFailed',
  alertSlideUploadingPresentationFulfilled: 'alert.slideUploadingPresentationFullfilled',
  alertEditingUserFulfilled: 'alert.editingUserFulfilled',
  alertEditingUserFailed: 'alert.editingUserFailed',
  alertSlideUpdatingFailed: 'alert.slideUpdatingFailed',
  alertMicrophoneIsDisabled: 'alet.microphoneIsDisabled',
  alertRevokeInvitationsFulfilled: 'alert.revokeInvitationsFulfilled',
  alertRevokeInvitationsFailed: 'alert.revokeInvitationsFailed',

  // Pitches
  pitches: 'pitches',
  pitchesNotAvailable: 'pitches.notAvailable',
  pitchesAddWord: 'pitches.addWord',
  pitchesSelectFromLists: 'pitches.selectFromLists',
  pitchesNoListAvailable: 'pitches.noListAvailable',
  pitchesAiFeedbackTab: 'pitches.aiFeedbackTab',
  pitchesEvaluationCriteriaLabel: 'pitches.evaluationCriteria.label',
  pitchesEvaluationCriteriaOverview: 'pitches.evaluationCriteria.overview',
  pitchesEvaluationCriteriaAddNew: 'pitches.evaluationCriteria.addNew',
  pitchesEvaluationCriteriaUpdate: 'pitches.evaluationCriteria.update',
  PitchesAddImage: 'pitches.addImage',
  PitchesAddVideo: 'pitches.addVideo',
  pitchesMaterialsTitle: 'pitches.materials.title',
  pitchesMaterialsAddMediaMessage: 'pitches.materials.addMediaMessage',
  pitchesMaterialsAddDocument: 'pitches.materials.addDocument',
  pitchesObjectives: 'pitches.objectives',
  pitchesObjectivesWillAppear: 'pitches.objectivesWillAppear',
  pitchesPlaybook: 'pitches.playbook',
  pitchesPlaybookCreatePlaybookFollowing: 'pitches.playbook.createplaybookFollowing',
  pitchesPlaybookAddPlaybookPart: 'pitches.playbook.addPlaybookPart',
  pitchesPlaybookEpisodeDuration: 'pitches.playbook.episodeDuration',
  pitchesPlaybookAddReactions: 'pitches.playbook.addReactions',
  pitchesPlaybookTime: 'pitches.playbook.time',
  pitchesPlaybookTimetoAppear: 'pitches.playbook.timeToAppear',
  pitchesPlaybookRandomReactionSelect: 'pitches.playbook.randomReactionSelect',
  pitchesPlaybookRandomReactionSecText: 'pitches.playbook.randomReactionSecText',
  pitchesReactionsAndObjections: 'pitches.reactionsAndObjections',
  pitchesTypeTabRecordVideoToTask: 'pitches.typeTab.recordVideoToTask',
  pitchesTypeTabRecordAudioToTask: 'pitches.typeTab.recordAudioToTask',
  pitchesTypeTabRecordTextToTask: 'pitches.typeTab.recordTextToTask',
  pitchesTypeTabTimeBasedQuiz: 'pitches.typeTab.timeBasedQuiz',
  pitchesTypeTabDisplayInformationImage: 'pitches.typeTab.displayInformationImage',
  pitchesTypeTabPlayLearningAudio: 'pitches.typeTab.playLearningAudio',
  pitchesTypeTabTask: 'pitches.typeTab.task',
  pitchesTypeTabTaskAskingUserToDoTask: 'pitches.typeTab.task.askingUserToDoTask',
  pitchesTypeTabKnowledgeSlide: 'pitches.typeTab.knowledgeSlide',
  pitchesTypeTabContentSlide: 'pitches.typeTab.contentSlide',
  pitchesTypeTabContentSlideSubtitle: 'pitches.typeTab.contentSlide.subtitle',
  pitchesTypeTabProvideKnowledgeSlide: 'pitches.typeTab.provideknowledgeSlide',
  pitchesTypeTabShowEducationalVideoRecordOrExample: 'pitches.typeTab.showEducationalVideoRecordOrExample',
  pitchesFormTabsType: 'pitches.formTabs.type',
  pitchesFormTabsContent: 'pitches.formTabs.content',
  pitchesFormTabsDynamicContent: 'pitches.formTabs.dynamicContent',
  pitchesFormTabsQuizAnswers: 'pitches.formTabs.quizAnswers',
  pitchesFormTabsAiFeedback: 'pitches.formTabs.aiFeedback',
  pitchesWordsTabAddNewWord: 'pitches.wordsTab.addNewWord',
  pitchesConditionsTabHeadlineLabel: 'pitches.conditionsTab.headline',
  pitchesConditionsTabEnterHeadline: 'pitches.conditionsTab.enterheadline',
  pitchesPublish: 'pitches.publish',
  pitchesPublishConfirmQuestion: 'pitches.publish.confirmQuestion',
  pitchesPublishSubText: 'pitches.publish.subText',
  pitchesPublishConfirmBtnText: 'pitches.publish.confirmBtnText',
  pitchesAddNewSlides: 'pitches.addNewSlides',
  pitchesAiFeedbackTabSpokenWords: 'pitches.aiFeedbackTab.spokenWords',
  pitchesAiFeedbackTabLongPause: 'pitches.aiFeedbackTab.longPause',
  pitchesAiFeedbackTabDoWords: 'pitches.aiFeedbackTab.doWords',
  pitchesAiFeedbackTabDontWords: 'pitches.aiFeedbackTab.dontWords',
  pitchedFeedbackLowVoice: 'pitches.aiFeedbackTab.lowVoice',
  pitchedFeedbackLowVoiceCount: 'pitches.aiFeedbackTab.lowVoice.count',
  pitchedFeedbackFacialExpression: 'pitches.aiFeedbackTab.facialExpression',
  pitchedFeedbackEmotionalAnalysis: 'pitches.aiFeedbackTab.emotionalAnalysis',
  pitcheDeviceSettingsTitle: 'pitches.deviceSettings.title',
  pitchesDeviceSettingsCamera: 'pitches.deviceSettings.camera',
  pitcheDeviceSettingsMicrophone: 'pitches.deviceSettings.microphone',
  pitchesTrainingWelcome: 'pitches.training.welcome',
  pitchesTrainingStart: 'pitches.training.start',
  pitchesTrainingAbort: 'pitches.training.abort',
  pitchesTrainingStartTask: 'pitches.training.startTask',
  pitchesTrainingEmptyTextTaskAlert: 'pitches.training.emptyTextTaskAlert',
  pitchesSlideSelectType: 'pitches.slide.selectType',
  pitchesTrainingTextTaskLabel: 'pitches.training.textTask.label',
  pitchesSlideInfoSlide: 'pitches.slideInfo.slide',
  pitchesSlideInfoInfo: 'pitche.slideInfo.info',
  pitchesTeamTrainingStart: 'pitches.teamTraining.start',
  pitchesTeamTrainingSubline: 'pitches.teamTraining.subline',
  pitchesEvaluationCriteriaDescription: 'pitches.evaluationCriteriaDescription',
  pitchesSlideValidationError: 'pitches.slideValidationError',
  pitchesSlideTrainingReviewLabel: 'pitches.slideTrainingReview.label',
  pitchesSlideTrainingReviewCompletedTask: 'pitches.slideTrainigReview.completedTask',
  pitchesSlideTrainingReviewEvaluateCompletedTask: 'pitches.slideTrainingReview.evaluateCompletedTask',
  pitchesSlideTrainigReviewCommentsInProgress: 'pitches.slideTrainigReview.commentsInProgress',
  pitchesSlideTrainingReviewCommentsLabel: 'pitches.slideTrainingReview.comments.label',
  pitchesSlideTrainingReviewAiFeedbackLabel: 'pitches.slideTrainingReview.aiFeedback.label',
  pitchesSlideTrainingReviewAllAIAnalytics: 'pitches.slideTrainingReview.allAiAnalytics',
  pitchesSlideTrainingReviewShowAIAnalyticsDetails: 'pitches.slideTrainingReview.showAIAnalyticsDetails',
  pitchesSlideTrainingLowSound: 'pitches.SlideTraining.LowSound',
  pitchesSlideTrainingLowSoundChart: 'pitches.SlideTraining.LowSoundChart',
  pitchesSlideTrainingFacialExpressionsChart: 'pitches.SlideTraining.FacialExpressionsChart',
  pitchesSlideTrainingWordsSpokenChart: 'pitches.SlideTraining.WordsSpokenChart',
  pitchesSlideTrainingWordsSpoken: 'pitches.SlideTraining.WordsSpoken',
  pitchesSlideTrainingLongPauseChart: 'pitches.SlideTraining.LongPauseChart',
  pitchesSlideTrainingTotalPauses: 'pitches.SlideTraining.TotalPauses',
  pitchesSlideTrainingEmotionalAnalyticsChart: 'pitches.SlideTraining.EmotionalAnalyticsChart',
  pitchesTimerPanelTimeLimit: 'pitches.timerPanel.timeLimit',
  pitchesSlideQuizType: 'pitches.slide.quizType',
  pitchesSlideSelectQuizType: 'pitches.slide.selectQuizType',
  pitchesSlideQuizChoiceSettings: 'pitches.slide.quizChoiceSettings',
  pitchesSlideQuizPreferences: 'pitches.slide.quiz.preferences',
  pitchesSlideQuizChoiceSettingsOther: 'pitches.slide.quiz.choice.settings.other',
  pitchesSlideQuizChoiceSettingsMultiple: 'pitches.slide.quiz.choice.settings.multiple',
  pitchesSlideQuizChoiceSettingsRandomize: 'pitches.slide.quiz.choice.settings.randomize',
  pitchesSlideQuizChoiceSettingsMaxCharactersLabel: 'pitches.slide.quiz.choice.settings.maxCharacters.label',
  pitchesSlideQuizChoiceSettingsMaxCharactersTooltip: 'pitches.slide.quiz.choice.settings.maxCharacters.tooltip',
  pitchesSlideQuizTypePlaceholder: 'pitches.slide.quiz.type.placeholder',
  pitchesSlideQuizOptionalCorrectAnswers: 'pitches.slide.quiz.optional.correctAnswers',
  pitchesSlideQuizEnterAnswerPlaceholder: 'pitches.slide.quiz.enter.answer.placeholder',
  pitchesSlideQuizSelectPair: 'pitches.slide.quiz.select.pair',
  pitchesSlideQuizPairInstruction: 'pitches.slide.quiz.pair.instruction',
  pitchesSlidesQuizPairLabel: 'pitches.slide.quiz.pair.label',
  pitchesSlidesQuizChoicesFirst: 'pitches.slide.quiz.choices.first',
  pitchesSlidesQuizChoicesSecond: 'pitches.slide.quiz.choices.second',
  pitchesSlidesBackgroundMedia: 'pitches.slide.backgroundMedia',
  pitchesSlidesBackgroundMediaDescription: 'pitches.slide.backgroundMediaDescription',
  pitchesPitchNameAToZ: 'pitches.pitchNameAToZ',
  pitchesPitchNameZToA: 'pitches.pitchNameZToA',
  pitchesSearchAPitch: 'pitches.searchAPitch',
  pitchesRemovePitch: 'pitches.removePitch',
  pitchesRemovePitchSubText: 'pitches.removePitch.subText',
  pitchesCreatePitch: 'pitches.createPitch',
  pitchesAddPitch: 'pitches.addPitch',
  pitchesPitchName: 'pitches.pitchName',
  pitchesCover: 'pitches.cover',
  pitchesUpdatePitch: 'pitches.updatePitch',
  pitchesNoPitchAvailable: 'pitches.noPitchAvailable',
  pitchesAddNewPitch: 'pitches.addNewPitch',
  pitchesProceedTraining: 'pitches.proceedTraining',
  pitchesFinishTraining: 'pitches.finishTraining',
  pitchesCompletedHeading: 'pitches.pitchesCompletedHeading',
  pitchesCompletedDescription: 'pitches.pitchesCompletedDescription',
  pitchesTrainingShare: 'pitches.training.share',
  pitchesSharePlaceholderRecords: 'pitches.share.placeholder.records',
  pitchesSharePlaceholderUsers: 'pitches.share.placeholder.users',
  pitchesShareConfirmButton: 'pitches.share.confirm.button',
  pitchesShareMessagesSuccess: 'pitches.share.messages.success',
  pitchesShareMessagesErrorApi: 'pitches.share.messages.error.api',
  pitchesShareMessagesErrorUnknown: 'pitches.share.messages.error.unknown',
  pitchesShareTitle: 'pitches.share.title',
  pitchTrainingEmptyComments: 'record.training.emptyComments',
  pitchesCopyToCourse: 'pitches.copyToCourse',
  pitchesTrainingSubmitTask: 'pitches.training.submitTask',
  pitchesTestTraining: 'pitches.training.test',
  pitchesTrainingLeavePagePromptMessage: 'pitches.training.leavePagePromptMessage',
  pitchesTrainingCanNotBeStartedSlidesDoesNotExist: 'pitches.training.slidesDoesNotExistError',
  pitchesPartialTrainingPreviewTitle: 'pitches.partial.training.preview.title',
  pitchesPartialTrainingPreviewCompletedTasks: 'pitches.partial.training.preview.completedTasks',
  pitchesPartialTrainingPreviewContinueTraining: 'pitches.partial.training.preview.continueTraining',
  pitchesPartialTrainingStartOver: 'pitches.partial.training.startOver',
  pitchesPartialTrainingCompletedTitle: 'pitches.partial.training.completed.title',
  pitchesPartialTrainingStartOverConfimTitle: 'pitches.partial.training.startOver.confim.title',
  pitchesPartialTrainingStartOverConfimText: 'pitches.partial.training.startOver.confim.text',
  pitchesPartialTrainingStartOverFailed: 'pitches.partial.training.startOver.confim.failed',
  pitchAddPitchToCourses: 'pitches.addPitchToCourses',
  pitchesImportPresentation: 'pitches.importPresentation',
  pitchesImportPresentationDescription: 'pitches.importPresentation.description',
  pitchesImportPresentationDocs: 'pitches.importPresentationDocs',
  pitchesTrainingCustomerReactionHeading: 'pitches.training.customerReaction.heading',
  pitchesImportIntoSingleSlideLabel: 'pitches.importIntoSingleSlideLabel',
  pitchesImportIntoMultipleSlidesLabel: 'pitches.importIntoMultipleSlidesLabel',
  pitchesImportIntoSingleSlideSubText: 'pitches.importIntoSingleSlideSubText',
  pitchesImportIntoMultipleSlidesSubText: 'pitches.importIntoMultipleSlidesSubText',
  pitchProgressTiltle: 'pitches.progress.title',
  pitchProgressCompletedTasks: 'pitches.progress.completedTasks',
  pitchRecordsEmptyRecords: 'pitches.records.emptyRecords',
  pitchesQuizCorrectAnswer: 'pitches.quiz.correctAnswer',
  pitchesQuizIncorrectAnswer: 'pitches.quiz.incorrectAnswer',
  pitchesQuizWaitingForReview: 'pitches.quiz.waitingForReview',
  pitchesQuizCorrectAnswersQuantity: 'pitches.quiz.correctAnswersQuantity',
  pitchesQuizEmptyQuizCard: 'pitches.quiz.emptyQuizCard',
  pitchesQuizYetToAnswer: 'pitches.quiz.yetToAnswer',
  pitchesPitchLeadershipBoard: 'pitches.pitchLeadershipBoard',
  pitchesQuizScaleChoiceStartValue: 'pitches.quiz.scaleChoice.startValue',
  pitchesQuizScaleChoiceMaxLimit: 'pitches.quiz.scaleChoice.maxLimit',
  pitchesQuizScaleChoiceStep: 'pitches.quiz.scaleChoice.step',
  pitchesQuizAddChoice: 'pitches.quiz.addChoice',
  pitchesQuizTip: 'pitches.quiz.tip',
  pitchesQuizPictureChoicePicture: 'pitches.quiz.pictureChoice.picture',
  pitchesQuizReviewAllQuizzes: 'pitches.quiz.reviewAllQuizzes',
  pitchesQuizIncorrectAnswers: 'pitches.quiz.incorrectAnswers',
  pitchesQuizQuizzesProgress: 'pitches.quiz.quizzesProgress',
  pitchesQuizNumberOfTry: 'pitches.quiz.numberOfTry',
  pitchesVideoAudioTaskRetakeConfimTitle: 'pitches.videoAudioTask.retakeConfirmTitle',
  pitchesVideoAudioTaskRetakeConfimText: 'pitches.videoAudioTask.retakeConfirmText',
  pitchesRecordYourselfOnVideo: 'pitches.record.video',
  pitchesRecordYourselfOnAudio: 'pitches.record.audio',
  pitchesRightAnswerInText: 'pitches.right.answer',

  // Quiz
  pitchesTrainingTimeUp: 'pitches.training.quiz.timeUp',
  pitchesTrainingEnterYourAnswer: 'pitches.training.quiz.enterYourAnswer',
  pitchesTrainingSelectYesOrNo: 'pitches.training.quiz.selectYesOrNo',
  pitchesTrainingMatchingListTip: 'pitches.training.quiz.matchingListTip',
  pitchesTrainingSelectOneOption: 'pitches.training.quiz.selectOneOption',
  pitchesTrainingSelectSeveralOptions: 'pitches.training.quiz.selectSeveralOptions',
  pitchesTrainingNextButton: 'pitches.training.quiz.nextButton',
  pitchesTrainingOtherAnswer: 'pitches.training.quiz.otherAnswer',
  pitchesTrainigResultWithWronAnswer: 'pitches.trainig.resultOfTraining',
  pitchesTrainingAllAnswersAreCorrect: 'pitches.training.allAnswersAreCorrect',
  pitchesTrainingRetryFailedQuizzes: 'pitches.training.retryFailedQuizzes',
  pitchesTrainigResultWithCorrectAnswer: 'pitches.trainig.resultWithCorrectAnswer',
  pitchesTrainigResultWithPendingAnswer: 'pitches.trainig.resultWithPendingAnswer',
  pitchesQuizOptionsMultipleChoice: 'pitches.quiz.options.multipleChoice',
  pitchesQuizOptionsPictureChoice: 'pitches.quiz.options.pictureChoice',
  pitchesQuizOptionsMatchingList: 'pitches.quiz.options.matchingList',
  pitchesQuizOptionsYesOrNo: 'pitches.quiz.options.yesOrNo',
  pitchesQuizOptionsScaleChoice: 'pitches.quiz.options.scaleChoice',
  pitchesQuizOptionsShortText: 'pitches.quiz.options.ShortText',
  pitchesQuizShortTextMaxValueMoreThanZero: 'pitches.quiz.shortText.maxValueMoreThanZero',

  // Records
  recordsNotAvailable: 'records.notAvailable',
  recordsAiFeedbackAuthorPutExtraCondition: 'records.aiFeedback.authorPutExtraCondition',
  recordsAiFeedbackGeneratedWithRealTimeSpeech: 'records.aiFeedback.generatedWithRealTimeSpeech',
  recordsPreviewPreviewComesHere: 'records.preview.previewComesHere',
  recordsReviewTabSuggestionToRate: 'records.reviewTab.suggestionToRate',
  recordsTaskInfoUsefulTrainingMaterials: 'records.taskInfo.usefulTrainingMaterials',
  recordsUserFeedbacksNoReviewAvailable: 'records.userFeedbacks.noReviewAvailable',
  recordsSearchARecord: 'records.searchARecord',
  recordsRecordDetailsSubmitted: 'records.recordDetails.submitted',
  recordsRecordDetailsTraining: 'records.recordDetails.training',
  recordsEmptyRecordsSubTitle: 'records.emptyRecordSubTitle',
  recordEmptyComments: 'record.emptyComments',
  recordSubmitted: 'record.submitted',
  recordsNew: 'recrod.new',
  noRecordsAvailable: 'records.unavailable',
  noRecordsAvailableForTask: 'records.norecord.task',
  newRecords: 'new.records',
  newTotalRecords: 'records.newTotal',

  // Quiz submissions
  quizSubmissionReviewed: 'quiz.submission.reviewed',
  quizSubmissionReviewRequired: 'quiz.submission.review.required',
  quizSubmissionCorrectAnswers: 'quiz.submission.correctAnswers',
  quizSubmissionNoAnswerYet: 'quiz.submission.noAnswerSubmittedYet',
  quizSubmissionIncorrectAnswer: 'quiz.submission.incorrectAnswer',
  quizSubmissionPendingEvaluation: 'quiz.submission.pendingEvaluation',
  quizSubmissionCorrectAnswer: 'quiz.submission.correctAnswer',
  quizSubmissionMarkAs: 'quiz.submission.markAs',
  quizSubmissionCorrect: 'quiz.submission.correct',
  quizSubmissionIncorrect: 'quiz.submission.incorrect',
  quizSubmissionNotYet: 'quiz.submission.notYet',
  quizSubmissionExpectedAnswer: 'quiz.submission.expectedAnswer',

  // Comments
  commentsPlaceholder: 'comments.placeholder',
  commentsCommentText: 'comments.commentText',

  // Settings
  settingsAccountSettingsAccountEmailTitle: 'settings.accountSettings.accountEmail.title',
  settingsAccountSettingsSetupNewPassword: 'settings.accountSettings.SetupNewPassword',
  settingsAccountSettingsOldPassword: 'settings.accountSettings.oldPassword',
  settingsAccountSettingsEnterYourPassword: 'settings.accountSettings.enterYourPassword',
  settingsAccountSettingsPasswordNotMatch: 'settings.accountSettings.passwordNotMatch',
  settingsAccountSettingsEnterNewPassword: 'settings.accountSettings.enterNewPassword',
  settingsNotificationSettingsNotification: 'settings.notificationSettings.notification',
  settingsNotificationSettingsPitchNotifications: 'settings.notificationSettings.pitchNotifications',
  settingsNotificationSettingsNewCommentsFromCoach: 'settings.notificationSettings.newCommentsFromCoach',
  settingsNotificationSettingsFeedbackFromCoach: 'settings.notificationSettings.feedbackFromCoach',
  settingsNotificationSettingsNewCommentsFromUsers: 'settings.notificationSettings.newCommentsFromUsers',
  settingsNotificationSettingsFeedbackFromUsers: 'settings.notificationSettings.feedbackFromUsers',
  settingsNotificationSettingsPersonalNotifications: 'settings.notificationSettings.personalNotifications',
  settingsNotificationSettingsNewBadges: 'settings.notificationSettings.newBadges',
  settingsNotificationSettingsLeaderboardUpdates: 'settings.notificationSettings.leaderboardUpdates',
  settingsNotificationSettingsCommunityNotifications: 'settings.notificationSettings.communityNotifications',
  settingsProfileSettingsChangeProfilePhoto: 'settings.profileSettings.changeProfilePhoto',
  settingsProfileSettingsUploadNewPhoto: 'settings.profileSettings.uploadNewPhoto',
  settingsProfileSettingsPersonalInfo: 'settings.profileSettings.personalInfo',
  settingsProfileSettingsTimeAndLanguage: 'settings.profileSettings.timeAndLanguage',
  settingsProfileSettingsTimeZone: 'settings.profileSettings.timeZone',
  settingsProfileSettingsRemoveProfilePhoto: 'settings.profileSettings.removeProfilePhoto',
  settingsProfileSettingsRemoveProfilePhotoSubText: 'settings.profileSettings.removeProfilePhoto.subText',
  settingsProfile: 'settings.profile',
  settingsProfileDescription: 'settings.profiledescription',
  settingsAccount: 'settings.account',
  settingsAccountDescription: 'settings.accountDescription',
  settingsNotifications: 'settings.notifications',
  settingsNotificationsDescription: 'settings.notificationsDescription',
  settingsCustomization: 'settings.customization',
  settingsCustomizationDescription: 'settings.customizationDescription',
  settingsDomain: 'settings.domain',
  settingsDomainTitle: 'settings.domainTitle',
  settingsDomainAppearance: 'settings.domainAppearance',
  settingsDomainLoginTitle: 'settings.domainLoginTitle',
  settingsDomainLoginDescription: 'settings.domainLoginDescription',
  settingsDomainLogo: 'settings.domainLogo',
  settingsDomainLogoDefault: 'settings.domainLogoDefault',
  settingsDomainImage: 'settings.domainImage',
  settingsDomainImageDefault: 'settings.domainImageDefault',
  settingsDomainThemeCustom: 'settings.domainThemeCustom',
  settingsDomainThemeActiveColor: 'settings.domainThemeActiveColor',
  settingsDomainThemeBGColor: 'settings.domainThemeBGColor',
  settingsPasswordMaxCharacter: 'settings.password.maxCharacter',
  domainNameValidationError: 'domain.nameValidationError',
  domainNameUpdateSuccess: 'domain.mameUpdateSuccess',
  domainNameUpdateFailure: 'domain.mameUpdateFailure',

  // Teams
  teamsCreateNewTeam: 'teams.createNewTeam',
  teamsSearchAndAddMembers: 'teams.searchAndAddMembers',
  teamsSelectMembers: 'teams.selectMembers',
  teamsNoMemberAvailable: 'teams.noMemberAvailable',
  teamsYouCanAddNewMember: 'teams.youCanAddNewMember',
  teamsConnectedCourses: 'teams.connectedCourses',
  teamsFilterTeamNameAtoZ: 'teams.filter.teamNameAtoZ',
  teamsFilterTeamNameZtoA: 'teams.filter.teamNameZtoA',
  teamsFilterNumberOfMembersAsc: 'teams.filter.numberOfMembersAsc',
  teamsFilterNumberOfMembersDesc: 'teams.filter.numberOfMembersDesc',
  teamFilterCreatedAtAsc: 'teams.filter.createdAtAsc',
  teamFilterCreatedAtDesc: 'team.filter.createdAtDesc',
  teamsEditTeam: 'teams.editTeam',
  teamsRemoveTeam: 'teams.removeTeam',
  teamsRemoveTeamSubText: 'teams.removeTeam.subText',
  teamsMemeberMemberNameAtoZ: 'teams.member.memberNameAtoZ',
  teamsMemeberMemberNameZtoA: 'teams.member.memberNameZtoA',
  teamsMemeberMemberProgressAsc: 'teams.member.memberProgressAsc',
  teamsMemeberMemberProgressDesc: 'teams.member.memberProgressDesc',
  teamsMemeberMemberNewRecords: 'teams.member.memberNewRecords',
  teamsMemeberMemberOldRecords: 'teams.member.memberOldRecords',
  teamsSearchAMember: 'teams.searchAMember',
  teamsProgress: 'teams.progress.title',
  teamsDetachFromTeam: 'teams.detachFromTeam',
  teamsRemoveMemberTitle: 'teams.removeMemver.title',
  teamsRemoveMemberSubText: 'teams.removeMemver.subText',

  // User Management
  usersCreateFulfilled: 'users.createFulfilled',
  usersInvitationFulfilled: 'users.invitationFulfilled',
  usersInviteUser: 'users.usersInviteUser',
  usersCreateUser: 'users.usersCreateUser',
  usersCreateUserModal: 'users.usersCreateUserModal',
  usersUploadCsv: 'users.uploadCsv',
  usersNameAtoZ: 'users.usersNameAtoZ',
  usersNameZtoA: 'users.usersNameZtoA',
  usersProgressAsc: 'users.usersProgressAsc',
  usersProgressDesc: 'users.usersProgressDesc',
  usersActivityAsc: 'users.usersActivityAsc',
  usersActivityDesc: 'users.usersActivityDesc',
  usersOldestUsers: 'users.oldestUsers',
  usersRecentlyAdded: 'users.recentlyAdded',
  usersInviteNewMembers: 'users.inviteNewMembers',
  usersWayOfMembersCreatingAccout: 'users.wayOfMembersCreatingAccout',
  usersDelete: 'users.delete',
  usersDeleteSubline: 'users.deleteSubline',
  usersDeactivate: 'users.deactivate',
  usersResendPassword: 'users.resendPassword',
  usersArchive: 'users.archive',
  usersUnArchive: 'users.unArchive',
  usersArchiveSubline: 'users.archiveSubline',
  usersArchiveDescription: 'users.archiveDescription',
  usersUnArchiveSubline: 'users.unArchiveSubline',
  usersDeleteDescription: 'users.deleteDescription',
  usersEditUser: 'users.editUser',
  usersActive: 'users.active',
  invitedBy: 'users.invitedBy',
  usersRevokeInvitations: 'users.revokeInvitations',
  usersRevokeInvitationsConfimTitle: 'users.rvokeInvitationsConfim.title',

  // Workspace
  workspaceAdd: 'workspace.add',
  workspaceEdit: 'workspace.edit',
  workspaceName: 'workspace.name',
  workspaceTabsUsersManagement: 'workspace.tabs.usersManagement',
  workspaceTabsUsersManagementDescription: 'workspace.tabs.usersManagement.description',
  workspaceTabsBilling: 'workspace.tabs.billing',
  workspaceTabsBillingDescription: 'workspace.tabs.billing.description',
  workspaceSearch: 'workspace.search',
  workspaceAttachCoursesToWorkspace: 'workspace.addCoursesToWorkspace',
  workspaceClearSelection: 'workspace.clearSelection',
  workspaceNameAToZ: 'workspace.workspaceNameAToZ',
  workspaceNameZToA: 'workspace.workspaceNameZToA',
  workspaceUserCountAsc: 'workspace.userCountAsc',
  workspaceUserCountDesc: 'workspace.userCountDesc',
  workspaceLastUpdates: 'workspace.lastUpdates',
  workspaceOldUpdates: 'workspace.oldUpdates',
  //
  workspaceRemoveWorkspace: 'workspace.removeWorkspace',
  workspaceArchiveWorkspace: 'workspace.archiveWorkspace',
  workspaceUnArchiveWorkspace: 'workspace.unArchiveWorkspace',
  workspaceRemovingWorkspaceConfirmQuestion: 'workspace.removingWorkspaceConfirmQuestion',
  workspaceRemovingWorkspaceDescription: 'workspace.removingWorkspaceDescription',
  workspaceArchivingWorkspaceConfirmQuestion: 'workspace.archivingWorkspaceConfirmQuestion',
  workspaceUnArchivingWorkspaceConfirmQuestion: 'workspace.unArchivingWorkspaceConfirmQuestion',
  workspaceConfirmationPhrase: 'workspace.confirmPhrase',
  workspaceCreateSuccess: 'workspace.create.success',
  workspaceCreateFailure: 'workspace.create.failure',
  workspaceUpdateSuccess: 'workspace.update.success',
  workspaceUpdateFailure: 'workspace.update.failure',
  workspaceDeletingSuccess: 'workspace.deleting.success',
  workspaceDeletingFailure: 'workspace.deleting.failure',
  workspaceAll: 'workspace.all',
  workspaceActive: 'workspace.active',
  workspaceArchived: 'workspace.archieved',
  workspaceLead: 'workspace.lead',

  // Company
  activeStatus: 'company.status.current',
  archivedStatus: 'company.status.archived',
  leadStatus: 'company.status.lead',
  addCompany: 'company.add',
  companyName: 'company.name',
  noOfEmployees: 'company.noOfEmployees',
  companyType: 'company.type',
  tabGeneralInfo: 'company.tabs.generalInfo',
  tabUsers: 'company.tabs.users',
  companyAcceptQuestionTitle: 'company.acceptQuestionTitle',
  companyAcceptQuestionSupText: 'company.acceptQuestionSupText',
  companyAcceptConfirmBtnText: 'company.acceptConfirmBtnText',
  companyRejectQuestionTitle: 'company.rejectQuestionTitle',
  companyRejectQuestionSupText: 'company.rejectQuestionSupText',
  companyRejectConfirmationFieldLabel: 'company.rejectConfirmationFieldLabel',
  companyRejectConfirmBtnText: 'company.rejectConfirmBtnText',
  companyAddContactPersonTitle: 'company.addContactPersonTitle',
  companySuperAdmin: 'company.superAdmin',
  companyEnterEmail: 'company.enterEmail',
  companyRejectCompanyNameMustMatch: 'company.rejectCompany.nameMustMatch',
  companyRejectCompanyFullfilled: 'company.rejectCompany.fullfilled',
  companyAcceptCompanyFullfilled: 'company.acceptCompany.fullfilled',
  companyRemove: 'company.remove',
  companyRemoveSubText: 'company.removeSubText',
  companyRemoveDescription: 'company.removeDescription',
  companyArchive: 'company.archive',
  companyArchiveSubText: 'company.archiveSubText',
  companyArchiveDescription: 'company.archiveDescription',
  companyUnArchive: 'company.unarchive',
  companyUnArchiveSubText: 'company.unarchiveSubText',

  //Mapping
  commentFromCoach: 'mapping.comment.coach',
  commentFromUser: 'mapping.comment.user',
  feedbackFromCoach: 'mapping.feedback.coach',
  feedbackFromUser: 'mapping.feedback.user',
  newBadges: 'mapping.new.badge',
  leaderboardUpdated: 'mapping.leaderboard.update',
  shareContentList: 'mapping.share.content.list',
  shareContentFile: 'mapping.share.content.file',
  shareContentFolder: 'mapping.share.content.folder',
  shareRecord: 'mapping.share.record',
  newCoursePublished: 'mapping.new.course.published',
  userAttachedToCourse: 'mapping.user.attached.course',

  //Face expression mapping
  happyFace: 'expression.happy',
  sadFace: 'expression.sad',
  neutralFace: 'expression.neutral',
  disgustFace: 'expression.disgust',
  fearFace: 'expression.fear',
  angryFace: 'expression.angry',
  supriseFace: 'expression.surprise',

  happy: 'expression.happyHeading',
  sad: 'expression.sadHeading',
  neutral: 'expression.neutralHeading',
  disgust: 'expression.disgustHeading',
  fear: 'expression.fearHeading',
  angry: 'expression.angryHeading',
  suprise: 'expression.surpriseHeading',

  //Emotional Analysis
  competitive: 'emotional.competitive',
  composed: 'emotional.composed',
  cooperative: 'emotional.cooperative',
  directing: 'emotional.directing',
  empathic: 'emotional.empathic',
  formal: 'emotional.formal',
  friendly: 'emotional.friendly',
  goal_oriented: 'emotional.goal_oriented',
  positive: 'emotional.positive',
  self_confident: 'emotional.self_confident',
  professional: 'emotional.professional',

  // Notifications
  notificationsMarkAllAsRead: 'notifications.MarkAllAsRead',
  notificationsNoNotificationAvailable: 'notifications.noNotificationAvailable',
};

export const extractIntlKey = (id: string) => {
  const isPropertyExist = IntlKeys.hasOwnProperty(id);

  return isPropertyExist ? IntlKeys[id as unknown as keyof typeof IntlKeys] : id;
};
