import React, { useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { RouteComponentProps } from 'react-router';

import { ChildRoute } from './index';

export const withCurrentRouteTracking =
  (route: ChildRoute, notifyRouteMountedAction: ActionCreatorWithPayload<string>) =>
  (Component: React.ComponentType<RouteComponentProps>) => {
    const Cmp: React.FC<RouteComponentProps> = (props) => {
      const dispatch = useDispatch();
      const [isNotified, setNotified] = useState(false);

      useLayoutEffect(() => {
        dispatch(notifyRouteMountedAction(route.key));
        setNotified(true);
      }, [dispatch]);

      return <>{isNotified && <Component {...props} />}</>;
    };

    return Cmp;
  };
