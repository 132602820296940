import { QuizChoice } from 'components/entities/slides/slides-editor/types';
import { QuizAnswer } from 'types/answers';
import { BackgroundMaterialType } from './contract';

export enum SlideStatusEnum {
  Draft = 'draft',
  Published = 'published',
}

export enum SlideTypeEnum {
  Task = 'task',
  Info = 'info',
  Quiz = 'quiz',
}

export enum SlideTaskTypeEnum {
  Text = 'text',
  Audio = 'audio',
  Video = 'video',
}
export enum SlideTaskQuizTypeEnum {
  MultipleChoice = 'multiple choice',
  PictureChoice = 'picture choice',
  MatchingList = 'matching list',
  YesOrNo = 'yes no',
  ScaleChoice = 'scale choice',
  ShortText = 'short text',
}

export enum SlideInfoTypeEnum {
  Image = 'image',
  Audio = 'audio',
  Video = 'video',
}

export enum HiddenObjectiveTypeEnum {
  Text = 'text',
  Image = 'image',
  Video = 'video',
}

export enum SlideConditionTypeEnum {
  Text = 'text',
  File = 'file',
  Link = 'link',
}

export enum SlideMaterialTypeEnum {
  Audio = 'audio',
  Video = 'video',
  Image = 'image',
  Document = 'document',
  Text = 'text',
}

export enum PlaybookReactionTypeEnum {
  Audio = 'audio',
  Image = 'image',
  Video = 'video',
  Text = 'text',
}

export enum SlideBackgroundMediaTypeEnum {
  Audio = 'audio',
  Video = 'video',
  Image = 'image',
}

export type SlideType = {
  id: number;
  pitchId: number;
  type: SlideTypeEnum | null;
  taskType: SlideTaskTypeEnum | null;
  infoType: SlideInfoTypeEnum | null;
  title: string;
  position: number;
  status: string;
  condition: SlideConditionType | null;
  quiz: QuizType | null;
  hiddenObjectiveEnabled: boolean | null;
  hiddenObjectives: HiddenObjectiveType[];
  playbookEnabled: boolean | null;
  playbooks: PlaybookType[];
  aiDoWordsEnabled: boolean | null;
  aiDontWordsEnabled: boolean | null;
  doWords: string[];
  dontWords: string[];
  timerEnabled: boolean; // computed field
  timer: number | null;
  evaluationCriteriasEnabled: boolean;
  evaluationCriterias: EvaluationCriteriaType[];
  materials: SlideMaterialType[];
  backgroundMaterial: BackgroundMaterialType | null;
  isCompleted?: boolean;
  createdAt: string;
  updatedAt: string;
  backgroundMaterialId?: number;
};

export type HiddenObjectiveType = {
  id: number;
  type: HiddenObjectiveTypeEnum;
  content?: string;
  fileId?: string;
  isContentBlurred: boolean;
};

export type PlaybookType = {
  id: string | number;
  title: string;
  position: number;
  duration: number;
  randomReactionSelect: boolean;
  reactionsEnabled: boolean; // computed field
  reactions: PlaybookReactionType[];
  timeStart: number; // sec
  timeEnd: number; // sec
};

export type PlaybookReactionType = {
  id: string | number;
  name?: string;
  emoji?: string;
  type: PlaybookReactionTypeEnum;
  link?: string;
  isExtraTimeAdded: boolean;
  timeToAppear: number;
  timeToDisappear: number;
  contentItemId?: number;
};

export type EvaluationCriteriaType = {
  id: number | string;
  description: string | null;
  icon: string | null;
  name: string;
  //
  contentItemId?: number | null;
  contentListId?: number;
  slideId?: number;
};

export type EvaluationCriteriaLeaderboardItem = {
  id: string;
  averageScore: number;
  name: string;
  color: string;
};

export type SlideConditionType = {
  type: SlideConditionTypeEnum;
  headline: string | null;
  description: string | null;
  link: string | null;
  fileId: number | null;
};

export type SlideMaterialType = {
  type: SlideMaterialTypeEnum;
  content: string;
  fileId?: number;
  fileName?: string;
  mimeType?: string;
  isSaved?: boolean;
  id: number;
  position: number;
};

export type ReviewScoreType = {
  evaluationCriteriaId: number | string;
  score: number | undefined;
  name: string;
  icon: string | null;
};

// made generic to make it easier configure form types
type QuizDataType<T extends Record<'Answers' | 'Question', unknown> = { Answers: string; Question: string }> = {
  id?: number;
  title: string;
  description: string;
  tip: string | null;
  type: SlideTaskQuizTypeEnum;
  answers?: QuizAnswer[];

  task: {
    multiple: boolean | null; // MultipleChoice and PictureChoice Type
    randomize: boolean | null; // MultipleChoice and PictureChoice Type
    other: boolean | null; // MultipleChoice and PictureChoice Type
    min: number | null; // Scale and ShortText Type
    max: number | null; // Scale and ShortText Type
    maxEnabled?: boolean | null; // ShortText Type
    step: number | null; // only Scale Type
    //
    answer: 'yes' | 'no' | null; // YesNo Type
    answers: Array<T['Answers']>; // MatchingList, MultipleChoice, PictureChoice
    questions: Array<T['Question']>; // MatchingList
  };
  taskAnswer: {
    correctAnswers: number[]; // MultipleChoice, PictureChoice
    pairs: Array<{ answer: number; question: number }>; // MatchingList
  };
  slideId?: number;
};

export type QuizType = QuizDataType<{ Answers: string; Question: string }>;
export type QuizPayloadType = QuizDataType<{ Answers: QuizChoice; Question: string }>;
export type QuizFormType = QuizDataType<{ Answers: QuizChoice; Question: QuizChoice }>;

export enum MatchingListOptionType {
  Option = 'option',
  MatchOption = 'matchOption',
}

export type TransformedMatchingListOptionType = {
  id: string;
  label: string;
  type: MatchingListOptionType;
  color?: string;
};

export type MatchingLineType = {
  id?: string;
  start: TransformedMatchingListOptionType | null;
  end: TransformedMatchingListOptionType | null;
  color?: string;
};
