import {
  //
  ApiFetcherPayload,
  ApiFetcherPayloadBuilder,
  UseApiConfig,
} from './createApiCall';

type Config<P> = {
  payload?: ApiFetcherPayload<P>;
  configureUrlParams?: ApiFetcherPayloadBuilder<P>;
  apiConfig: UseApiConfig<P>;
};
export const buildPayload = <P = Record<string, unknown>>({
  payload = {},
  configureUrlParams,
  apiConfig,
}: Config<P>): ApiFetcherPayload<P> => {
  if (typeof configureUrlParams !== 'function') return payload;

  return configureUrlParams(payload, apiConfig);
};
