import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ContentParamsWithSort } from 'types/content/general';
import { ContentFileType } from 'types/content/files';
import { createApiCall } from 'lib/http/utils/createApiCall';
import { backofficeRestClient } from 'portal/backoffice/lib/http';
import { ResourceResponse } from 'lib/http';

export const getRecentFilesAction = createAsyncThunk<ResourceResponse<ContentFileType[]>, ContentParamsWithSort>(
  'content/getRecentFiles',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<ResourceResponse<ContentFileType[]>>({
      api: backofficeRestClient.getContentFiles,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const changeContentRequestParamsAction = createAction<{
  filters: Record<string, string | number | string[] | number[]>;
}>('content/changeContentRequestParams');

export const resetContentRequestParamsAction = createAction('content/resetContentRequestParams');
