import { createAsyncThunk } from '@reduxjs/toolkit';
// types
import { SlideType } from 'types/slides';
import { SlideWorkerPayloadType } from 'types/slides/utils';
import { serializePitchSlides, serializePitchSlide } from 'types/slides/serializers';
// utils
import { ApiCall } from 'lib/http';
import { createApiCall } from 'lib/http/utils/createApiCall';
import { backofficeRestClient } from 'portal/backoffice/lib/http';
// local
import { modifySlide } from './utils';

export const getPitchSlidesAction = createAsyncThunk<SlideType[], { pitchId: number }>(
  'pitches/getSlides',
  async (requestPayload, { rejectWithValue }) => {
    const { pitchId } = requestPayload;

    const callApi = createApiCall<SlideType[]>({
      api: backofficeRestClient.getPitchSlides,
    });

    try {
      const { data } = await callApi({
        params: {
          pitchId,
        },
      });

      return serializePitchSlides(data);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getPitchSlideAction = createAsyncThunk<SlideType, { pitchId: number; slideId: number }>(
  'pitches/getSlide',
  async (requestPayload, { rejectWithValue }) => {
    const { pitchId, slideId } = requestPayload;

    const callApi = createApiCall<SlideType>({
      api: backofficeRestClient.getPitchSlide,
    });

    try {
      const { data } = await callApi({
        params: {
          pitchId,
          slideId,
        },
      });

      return serializePitchSlide(data);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createPitchSlideAction = createAsyncThunk<SlideType, SlideWorkerPayloadType>(
  'pitches/createSlide',
  async (requestPayload, { rejectWithValue }) => {
    try {
      return await modifySlide({
        slideApi: backofficeRestClient.createPitchSlide,
        requestPayload,
      });
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updatePitchSlideAction = createAsyncThunk<SlideType, SlideWorkerPayloadType>(
  'pitches/updateSlide',
  async (requestPayload, { rejectWithValue }) => {
    try {
      return await modifySlide({
        slideApi: backofficeRestClient.updatePitchSlide,
        requestPayload,
      });
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

// TODO await slides update endpoint
export const updatePitchSlideListAction = createAsyncThunk<
  SlideType[],
  {
    newSlides?: SlideWorkerPayloadType[];
    deletedSlides?: SlideWorkerPayloadType[];
    updatedSlides?: SlideWorkerPayloadType[];
  }
>('pitches/updateSlideList', async (requestPayload, { rejectWithValue }) => {
  type LocalActions = 'create' | 'delete' | 'update';

  const actionsMapping: Array<{ type: LocalActions; slides?: SlideWorkerPayloadType[]; api: ApiCall }> = [
    { type: 'create' as LocalActions, slides: requestPayload.newSlides, api: backofficeRestClient.createPitchSlide },
    {
      type: 'delete' as LocalActions,
      slides: requestPayload.deletedSlides,
      api: backofficeRestClient.deletePitchSlide,
    },
    {
      type: 'update' as LocalActions,
      slides: requestPayload.updatedSlides,
      api: backofficeRestClient.updatePitchSlide,
    },
  ];

  const modifyConfig = actionsMapping.reduce((acc, { type, slides, api }) => {
    slides?.forEach((slide) => {
      acc.push({ type, slide, api });
    });

    return acc;
  }, [] as Array<{ type: LocalActions; slide: SlideWorkerPayloadType; api: ApiCall }>);

  try {
    const data = await Promise.all<SlideType | void>(
      modifyConfig.map(({ type, slide, api }, ix) => {
        switch (type) {
          case 'create': {
            return new Promise<SlideType>((resolve) => {
              setTimeout(() => {
                modifySlide({
                  slideApi: api,
                  requestPayload: slide,
                }).then(resolve);
              }, 100 * ix);
            });
          }

          case 'update': {
            return new Promise<SlideType>((resolve) => {
              setTimeout(() => {
                modifySlide({
                  slideApi: api,
                  requestPayload: slide,
                }).then(resolve);
              }, 100 * ix);
            });
          }

          case 'delete': {
            return new Promise<void>((resolve) => {
              setTimeout(async () => {
                const callApi = createApiCall<SlideType>({
                  api: backofficeRestClient.deletePitchSlide,
                });
                const { pitchId, id: slideId } = slide;

                await callApi({
                  params: {
                    slideId,
                    pitchId,
                  },
                });

                resolve();
              }, 100 * ix);
            });
          }
        }
      }),
    );

    return data.filter(Boolean) as SlideType[];
  } catch (err) {
    return rejectWithValue(err);
  }
});
export const deletePitchSlideAction = createAsyncThunk<
  SlideType,
  {
    pitchId: string | number;
    slideId: string | number;
  }
>('pitches/deleteSlide', async (requestPayload, { rejectWithValue }) => {
  const callApi = createApiCall<SlideType>({
    api: backofficeRestClient.deletePitchSlide,
  });

  try {
    const { pitchId, slideId } = requestPayload;

    const { data } = await callApi({
      params: {
        slideId,
        pitchId,
      },
    });

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});
