import { createReducer } from '@reduxjs/toolkit';
import { Reducer } from 'redux';
// types
import { SlideType } from 'types/slides';
import { alignSlidesPositions, updateSlideWithOrder } from 'types/slides/utils';
// local
import {
  getPitchSlidesAction,
  createPitchSlideAction,
  updatePitchSlideAction,
  deletePitchSlideAction,
  getPitchSlideAction,
  updatePitchSlideListAction,
} from './actions';

const initialState = {
  meta: {
    isListLoading: false,
    isListLoaded: false,
    //
    isLoading: false,
    isLoaded: false,
  },
  resource: null as SlideType | null,
  resources: [] as SlideType[],
};

const reducer = createReducer(initialState, (builder) => {
  builder //
    ////////////////////////////////////////////////////////////////////////////////////////////////
    .addCase(getPitchSlidesAction.pending, (draft) => {
      draft.meta.isListLoading = true;
      draft.meta.isListLoaded = false;
    })
    .addCase(getPitchSlidesAction.fulfilled, (draft, { payload }) => {
      draft.meta.isListLoading = false;
      draft.meta.isListLoaded = true;

      draft.resources = payload;
    })
    .addCase(getPitchSlidesAction.rejected, (draft) => {
      draft.meta.isListLoading = false;
      draft.meta.isListLoaded = false;
    })
    ////////////////////////////////////////////////////////////////////////////////////////////////
    .addCase(getPitchSlideAction.pending, (draft) => {
      draft.meta.isLoading = true;
      draft.meta.isLoaded = false;
    })
    .addCase(getPitchSlideAction.fulfilled, (draft, { payload }) => {
      draft.meta.isLoading = false;
      draft.meta.isLoaded = true;

      draft.resource = payload;
    })
    .addCase(getPitchSlideAction.rejected, (draft) => {
      draft.meta.isLoading = false;
      draft.meta.isLoaded = false;
    })
    ////////////////////////////////////////////////////////////////////////////////////////////////
    .addCase(createPitchSlideAction.pending, (draft) => {
      draft.meta.isLoading = true;
      draft.meta.isLoaded = false;
    })
    .addCase(createPitchSlideAction.fulfilled, (draft, { payload }) => {
      draft.meta.isLoading = false;
      draft.meta.isLoaded = true;

      draft.resources = updateSlideWithOrder(draft.resources, payload);
    })
    .addCase(createPitchSlideAction.rejected, (draft) => {
      draft.meta.isLoading = false;
      draft.meta.isLoaded = false;
    })
    ////////////////////////////////////////////////////////////////////////////////////////////////
    .addCase(updatePitchSlideAction.pending, (draft) => {
      draft.meta.isLoading = true;
    })
    .addCase(updatePitchSlideAction.fulfilled, (draft, { payload }) => {
      draft.meta.isLoading = false;

      draft.resources = updateSlideWithOrder(draft.resources, payload);
    })
    .addCase(updatePitchSlideAction.rejected, (draft) => {
      draft.meta.isLoading = false;
    })
    ////////////////////////////////////////////////////////////////////////////////////////////////
    .addCase(updatePitchSlideListAction.pending, (draft) => {
      draft.meta.isLoading = true;
    })
    .addCase(updatePitchSlideListAction.fulfilled, (draft, { payload }) => {
      draft.meta.isLoading = false;

      draft.resources = payload;
    })
    .addCase(updatePitchSlideListAction.rejected, (draft) => {
      draft.meta.isLoading = false;
    })
    ////////////////////////////////////////////////////////////////////////////////////////////////
    .addCase(deletePitchSlideAction.pending, (draft) => {
      draft.meta.isLoading = true;
    })
    .addCase(deletePitchSlideAction.fulfilled, (draft, { meta }) => {
      draft.meta.isLoading = false;

      draft.resources = alignSlidesPositions(draft.resources.filter(({ id }) => id !== meta.arg.slideId));
    })
    .addCase(deletePitchSlideAction.rejected, (draft) => {
      draft.meta.isLoading = false;
    });
  ////////////////////////////////////////////////////////////////////////////////////////////////
});

export const pitchSlidesReducer: Reducer<typeof initialState> = (draft = initialState, action) => {
  return reducer(draft, action);
};
