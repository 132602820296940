import { createReducer } from '@reduxjs/toolkit';
import { Reducer } from 'redux';
// types
import { MembersLeaderboardType, TeamsLeaderboardSortConfigs, TeamsLeaderboardType } from 'types/leaderboards';
// local
import { getMembersLeaderboardAction } from './actions';

const initialState = {
  meta: {
    isLoading: false,
    isLoaded: false,
  },
  filters: {} as Record<string, string>,
  sort: TeamsLeaderboardSortConfigs['scoreDesc'],
  pagination: {
    page: 1,
    perPage: 10,
    pageCount: 0,
    total: 0,
  },
  resources: [] as MembersLeaderboardType[] | TeamsLeaderboardType[],
};

const reducer = createReducer(initialState, (builder) => {
  builder
    ////////////////////////////////////////////////////////////////////////////////////////////////
    .addCase(getMembersLeaderboardAction.pending, (draft) => {
      draft.meta.isLoading = true;
      draft.meta.isLoaded = false;
    })
    .addCase(getMembersLeaderboardAction.fulfilled, (draft, { payload }) => {
      draft.meta.isLoading = false;
      draft.meta.isLoaded = true;

      const { data, ...pagination } = payload;

      draft.resources = pagination.page === 1 ? data : [...draft.resources, ...data];

      draft.pagination = {
        ...draft.pagination,
        ...pagination,
      };
    })
    .addCase(getMembersLeaderboardAction.rejected, (draft) => {
      draft.meta.isLoading = false;
      draft.meta.isLoaded = false;
    });
});

export const leaderboardReducer: Reducer<typeof initialState> = (draft = initialState, action) => {
  return reducer(draft, action);
};
