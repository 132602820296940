import { formatDate } from 'utils/date';

export const getDate = (date: string) => {
  const today = new Date();
  const someDate = new Date(date);
  return someDate.getDate() == today.getDate() &&
    someDate.getMonth() == today.getMonth() &&
    someDate.getFullYear() == today.getFullYear()
    ? 'Today'
    : someDate.getDate() == today.getDate() - 1 &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
    ? 'Yesterday'
    : formatDate(someDate.toISOString());
};
