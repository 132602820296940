import React from 'react';
// styles
import { useLayoutsStyles } from '../common/styles';

interface OwnProps {
  children?: React.ReactNode;
}

const FullscreenLayout: React.FC<OwnProps> = ({ children }) => {
  const layoutsStyles = useLayoutsStyles({
    withHeader: false,
    withSidebar: false,
  });

  return <div className={layoutsStyles.content}>{children}</div>;
};

export default FullscreenLayout;
