import { GenericChildRoute } from 'routes';
//
import ACL from './index';

export const checkRouteAllowed = (route: GenericChildRoute) => {
  // Every restriction in route.restriction has to match with ACL restrictions
  const restrictionEntries = route.restrictions || [];

  return restrictionEntries.every((permission) => ACL.canView(permission));
};
