import { store } from 'portal/workspace/domain/store';
import { authUserSelector, authVirtualRoleSelector } from 'portal/workspace/domain/auth/selectors';
import { merge, get } from 'lodash';
// types
import { UserRoles } from 'types/users';
// utils
import {
  //
  ApiFetcherPayload,
  UseApiConfig,
} from 'lib/http/utils/createApiCall';
// local
import { workspaceRestClient } from './workspaceRestClient';

type EndpointKeys = keyof typeof workspaceRestClient;

export const configureWorkspacePrefixes = <ResolvesTo = Record<string, unknown>>(
  payload: {} = {},
  apiConfig: UseApiConfig<ResolvesTo>,
): ApiFetcherPayload<ResolvesTo> => {
  const state = store.getState();
  const user = authUserSelector(state);
  const virtualRole = authVirtualRoleSelector(state);
  //
  if (!user || !virtualRole) return payload;
  //
  const userRole = virtualRole;
  const { api } = apiConfig;

  const endpointKey = api.selfName as EndpointKeys;

  return merge(payload, {
    params: {
      prefix: get(prefixConfig, [endpointKey, userRole]),
    },
  });
};

const prefixConfig: Partial<Record<EndpointKeys, Partial<Record<UserRoles, string>>>> = {
  // courses
  getCourseList: {
    [UserRoles.Coach]: '/company',
  },
  getCourse: {
    [UserRoles.Coach]: '/company',
  },
  createCourse: {
    [UserRoles.Coach]: '/company',
  },
  patchCourse: {
    [UserRoles.Coach]: '/company',
  },
  updateCourse: {
    [UserRoles.Coach]: '/company',
  },
  deleteCourse: {
    [UserRoles.Coach]: '/company',
  },
  // pitches
  getPitch: {
    [UserRoles.Coach]: '/company',
  },
  getPitchList: {
    [UserRoles.Coach]: '/company',
  },
  createPitch: {
    [UserRoles.Coach]: '/company',
  },
  updatePitch: {
    [UserRoles.Coach]: '/company',
  },
  patchPitch: {
    [UserRoles.Coach]: '/company',
  },
  deletePitch: {
    [UserRoles.Coach]: '/company',
  },
  pitchCopyToCourse: {
    [UserRoles.Coach]: '/company',
  },
  pitchesCopyToCourse: {
    [UserRoles.Coach]: '/company',
  },
  getPitchSlides: {
    [UserRoles.Coach]: '/company',
  },
  getPitchSlide: {
    [UserRoles.Coach]: '/company',
  },
  createPitchSlide: {
    [UserRoles.Coach]: '/company',
  },
  updatePitchSlide: {
    [UserRoles.Coach]: '/company',
  },
  deletePitchSlide: {
    [UserRoles.Coach]: '/company',
  },
  shareCourse: {
    [UserRoles.Coach]: '/company',
  },
  unshareCourse: {
    [UserRoles.Coach]: '/company',
  },
  // leaderboards
  getMembersLeaderboard: {
    [UserRoles.Coach]: '/company',
  },
  getWorkspaceLeaderboard: {
    [UserRoles.Coach]: '/company',
  },
  // users
  getUsers: {
    [UserRoles.Coach]: '/company',
  },
  getUser: {
    [UserRoles.Coach]: '/company',
  },
};
