import { createAsyncThunk } from '@reduxjs/toolkit';
// network
import { createApiCall } from 'lib/http/utils/createApiCall';
import { backofficeRestClient } from 'portal/backoffice/lib/http';
import { Params, ResourceResponse, ResourcesResponse } from 'lib/http';
import { uploadFile } from 'portal/backoffice/lib/uploadFile';
// utils
import { replaceSpaces } from 'utils/string';
// types
import { ContentDeleteParams, ContentParams } from 'types/content/general';
import { ContentListType, CreateListPayload, ListTypesEnum } from 'types/content/lists';
import { ContentFileType } from 'types/content/files';
import { toast } from 'react-toastify';
import { intl, IntlKeys } from 'localization';

export const getListsAction = createAsyncThunk<ResourceResponse<ContentListType[]>, ContentParams>(
  'content/getLists',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<ResourceResponse<ContentListType[]>>({
      api: backofficeRestClient.getContentLists,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getListsByTypeAction = createAsyncThunk<ResourcesResponse<ContentListType>, ListTypesEnum>(
  'content/getListsByType',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<ResourcesResponse<ContentListType>>({
      api: backofficeRestClient.getContentLists,
    });

    try {
      const { data } = await callApi({
        filters: {
          listType: requestPayload,
        },
      });

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

const getTransformedListItems = async (reactionList: CreateListPayload) => {
  const promises = reactionList.listItems.map(async (item, index) => {
    if (item.sound) {
      const soundUrl = await uploadFile({
        file: item.sound as File | Blob,
        name: `${replaceSpaces(reactionList.name)}_sound_${index}`,
      });

      return {
        ...item,
        sound: soundUrl,
      };
    } else {
      return item;
    }
  });

  return await Promise.all(promises);
};

export const createListAction = createAsyncThunk<ContentListType, CreateListPayload>(
  'content/createList',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<ContentListType>({
      api: backofficeRestClient.createList,
    });

    try {
      const transformedListItems = await getTransformedListItems(requestPayload);

      const { data } = await callApi({
        ...requestPayload,
        listItems: transformedListItems,
      });
      toast(intl.formatMessage({ id: IntlKeys.alertListCreatingFulfilled }));

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const deleteListAction = createAsyncThunk<number, ContentDeleteParams>(
  'content/deleteList',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<ContentFileType>({
      api: backofficeRestClient.deleteList,
    });

    try {
      await callApi(requestPayload);
      // TODO change to snackbar and replace network handling to hook
      toast(intl.formatMessage({ id: IntlKeys.alertListDeletingFulfilled }));

      return requestPayload.params.id;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateListAction = createAsyncThunk<ContentListType, CreateListPayload & Params>(
  'content/updateList',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<ContentListType>({
      api: backofficeRestClient.updateList,
    });

    try {
      const { data } = await callApi(requestPayload);
      toast(intl.formatMessage({ id: IntlKeys.alertListUpdatingFulfilled }));

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
