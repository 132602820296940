import { AxiosRequestConfig } from 'axios';
import { merge } from 'lodash';
//
import { getAuthTokens } from 'lib/localStorage';

export const injectAuthHeaders = (config: AxiosRequestConfig, getTokens = getAuthTokens) => {
  const { accessToken } = getTokens();

  return {
    ...config,
    headers: merge(
      {},
      config.headers,
      accessToken && {
        Authorization: `Bearer ${accessToken}`,
      },
    ),
  };
};
