import { makeStyles } from '@mui/styles';

type LayoutStylesProps = {
  withHeader?: boolean;
  withSidebar?: boolean;
};
export const useLayoutsStyles = makeStyles<LayoutStylesProps>((theme) => ({
  layout: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 10,
  },

  header: {
    position: 'sticky',
    background: 'white',
    zIndex: 10,

    width: ({ withSidebar }) => (withSidebar ? `calc(100% - ${theme.dimensions.sidebarWidth}px)` : '100%'),
    marginLeft: ({ withSidebar }) => (withSidebar ? theme.dimensions.sidebarWidth : 0),
  },

  drawer: {
    border: 'none',
  },

  sidebar: {
    width: theme.dimensions.sidebarWidth,
  },

  mobileSidebar: {
    right: '-100%',
    bottom: 0,
    top: 81,
    position: 'fixed',
    width: '100%',
    zIndex: 12,
    transition: 'all 0.5s ease-in-out',

    '&.open': {
      right: 0,
      transition: 'all 0.5s ease-in-out',
    },
  },

  content: {
    width: ({ withSidebar }) => (withSidebar ? `calc(100% - ${theme.dimensions.sidebarWidth}px)` : '100%'),
    height: ({ withHeader }) =>
      `calc(100vh - calc(100vh - 100%) - ${withHeader ? theme.dimensions.headerHeight : 0}px)`,
    marginLeft: ({ withSidebar }) => (withSidebar ? theme.dimensions.sidebarWidth : 0),
    overflow: 'auto',
  },
}));
