import { useState, useCallback, MouseEvent } from 'react';

const usePopoverMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isMenuOpen = !!anchorEl;

  const handleOpenMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return { anchorEl, isMenuOpen, handleOpenMenu, handleCloseMenu };
};

export default usePopoverMenu;
