import { UserRoles } from 'types/users';

// Basically just flags
export enum AccessFlags {
  AUTHORIZED = 'AUTHORIZED',
  UNAUTHORIZED = 'UNAUTHORIZED',
  //
  COURSES = 'COURSES',
  PITCHES = 'PITCHES',
  TEAMS = 'TEAMS',
  COMPANIES = 'COMPANIES',
  CONTENT = 'CONTENT',
  WORKSPACE = 'WORKSPACE',
}

export enum PermissionEnum {
  VIEW = 'VIEW',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  CREATE_SCORM = 'CREATE_SCORM',
}

export type PermissionsEntry = Partial<Record<AccessFlags, PermissionEnum[]>>;

export const unauthorizedPermissions: PermissionsEntry = {
  [AccessFlags.UNAUTHORIZED]: [PermissionEnum.VIEW],
};

// Respective levels for Permission flags for each role
export const rolePermissions = (isWorkspace?: boolean): Record<UserRoles, PermissionsEntry> => ({
  [UserRoles.WorkspaceEmployee]: {
    [AccessFlags.AUTHORIZED]: [PermissionEnum.VIEW],
    //
    [AccessFlags.COURSES]: [PermissionEnum.VIEW],
    [AccessFlags.PITCHES]: [PermissionEnum.VIEW],
  },
  [UserRoles.ScormEmployee]: {
    [AccessFlags.AUTHORIZED]: [PermissionEnum.VIEW],
    //
    [AccessFlags.COURSES]: [PermissionEnum.VIEW],
    [AccessFlags.PITCHES]: [PermissionEnum.VIEW],
  },
  [UserRoles.WorkspaceManager]: {
    [AccessFlags.AUTHORIZED]: [PermissionEnum.VIEW],
    //
    [AccessFlags.COURSES]: [PermissionEnum.VIEW, PermissionEnum.UPDATE, PermissionEnum.CREATE_SCORM],
    [AccessFlags.PITCHES]: [PermissionEnum.VIEW, PermissionEnum.UPDATE, PermissionEnum.CREATE_SCORM],
    [AccessFlags.TEAMS]: [PermissionEnum.VIEW, PermissionEnum.UPDATE],
    [AccessFlags.CONTENT]: [PermissionEnum.VIEW, PermissionEnum.UPDATE],
  },
  [UserRoles.WorkspaceAdmin]: {
    [AccessFlags.AUTHORIZED]: [PermissionEnum.VIEW],
    //
    [AccessFlags.COURSES]: [PermissionEnum.VIEW, PermissionEnum.UPDATE, PermissionEnum.CREATE_SCORM],
    [AccessFlags.PITCHES]: [PermissionEnum.VIEW, PermissionEnum.UPDATE, PermissionEnum.CREATE_SCORM],
    [AccessFlags.TEAMS]: [PermissionEnum.VIEW, PermissionEnum.UPDATE],
    [AccessFlags.CONTENT]: [PermissionEnum.VIEW, PermissionEnum.UPDATE],
    [AccessFlags.WORKSPACE]: [PermissionEnum.VIEW, PermissionEnum.UPDATE],
  },

  // Backoffice
  [UserRoles.BackofficeAdmin]: {
    [AccessFlags.AUTHORIZED]: [PermissionEnum.VIEW],
    //
    [AccessFlags.COURSES]: [PermissionEnum.VIEW, PermissionEnum.UPDATE, PermissionEnum.CREATE_SCORM],
    [AccessFlags.PITCHES]: [PermissionEnum.VIEW, PermissionEnum.UPDATE, PermissionEnum.CREATE_SCORM],
    [AccessFlags.TEAMS]: [PermissionEnum.VIEW, PermissionEnum.UPDATE],
    [AccessFlags.COMPANIES]: [PermissionEnum.VIEW, PermissionEnum.UPDATE],
    [AccessFlags.CONTENT]: [PermissionEnum.VIEW, PermissionEnum.UPDATE],
    [AccessFlags.WORKSPACE]: [PermissionEnum.VIEW, PermissionEnum.UPDATE],
  },
  [UserRoles.BackofficeSuperAdmin]: {
    [AccessFlags.AUTHORIZED]: [PermissionEnum.VIEW],
    //
    [AccessFlags.COURSES]: [PermissionEnum.VIEW, PermissionEnum.UPDATE, PermissionEnum.CREATE_SCORM],
    [AccessFlags.PITCHES]: [PermissionEnum.VIEW, PermissionEnum.UPDATE, PermissionEnum.CREATE_SCORM],
    [AccessFlags.TEAMS]: [PermissionEnum.VIEW, PermissionEnum.UPDATE],
    [AccessFlags.COMPANIES]: [PermissionEnum.VIEW, PermissionEnum.UPDATE],
    [AccessFlags.CONTENT]: [PermissionEnum.VIEW, PermissionEnum.UPDATE],
    [AccessFlags.WORKSPACE]: [PermissionEnum.VIEW, PermissionEnum.UPDATE],
  },
  //
  [UserRoles.Coach]: {
    [AccessFlags.AUTHORIZED]: [PermissionEnum.VIEW],
    //
    [AccessFlags.COURSES]: [PermissionEnum.VIEW, ...(!isWorkspace ? [PermissionEnum.UPDATE] : [PermissionEnum.DELETE])],
    [AccessFlags.PITCHES]: [PermissionEnum.VIEW, ...(!isWorkspace ? [PermissionEnum.UPDATE] : [])],
    [AccessFlags.TEAMS]: [PermissionEnum.VIEW, PermissionEnum.UPDATE],
    [AccessFlags.COMPANIES]: [PermissionEnum.VIEW, PermissionEnum.UPDATE],
    [AccessFlags.CONTENT]: [PermissionEnum.VIEW, PermissionEnum.UPDATE],
    [AccessFlags.WORKSPACE]: [PermissionEnum.VIEW, PermissionEnum.UPDATE],
  },
});
