import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
// types
// utils
import { Params } from 'lib/http';
import { workspaceRestClient } from 'portal/workspace/lib/http';
import { createApiCall } from 'lib/http/utils/createApiCall';
import { uploadFile } from 'portal/workspace/lib/uploadFile';
import { replaceSpaces } from 'utils/string';
import { WorkspacesResponse, WorkspaceType } from 'types/workspaces';

export const fetchWorkspacesWorker = async <T>(requestPayload: T) => {
  const callApi = createApiCall<WorkspacesResponse>({
    api: workspaceRestClient.getWorkspaces,
  });

  const { data } = await callApi(requestPayload);

  return data;
};
export const getWorkspacesAction = createAsyncThunk<WorkspacesResponse, Params | undefined>(
  'workspaces/getWorkspaces',
  async (requestPayload, { rejectWithValue }) => {
    try {
      return await fetchWorkspacesWorker(requestPayload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createWorkspaceAction = createAsyncThunk<WorkspacesResponse, Params | undefined>(
  'workspaces/createWorkspace',
  async (requestPayload, { rejectWithValue }) => {
    try {
      const callApi = createApiCall<WorkspacesResponse>({
        api: workspaceRestClient.createWorkspace,
      });
      let logo = requestPayload?.logo;
      if (logo) {
        const blob = await fetch(logo).then((r) => r.blob());
        logo = await uploadFile({
          file: new File([blob], `${replaceSpaces(requestPayload?.name)}_logo`, {
            type: 'image/png',
          }),
          name: `${replaceSpaces(requestPayload?.name)}_logo`,
        });
        if (!logo) {
          throw new Error('Logo upload failed');
        }
      }
      const { data } = await callApi({
        ...requestPayload,
        logo,
      });
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateWorkspaceAction = createAsyncThunk<WorkspacesResponse, Params | undefined>(
  'workspaces/updateWorkspace',
  async (requestPayload, { rejectWithValue }) => {
    try {
      const callApi = createApiCall<WorkspacesResponse>({
        api: workspaceRestClient.updateWorkspace,
      });
      let logo = requestPayload?.logo;
      if (logo) {
        const blob = await fetch(logo).then((r) => r.blob());
        logo = await uploadFile({
          file: new File([blob], `${replaceSpaces(requestPayload?.name)}_logo`, {
            type: 'image/png',
          }),
          name: `${replaceSpaces(requestPayload?.name)}_logo`,
        });
        if (!logo) {
          throw new Error('Logo upload failed');
        }
      }
      const { data } = await callApi({
        ...requestPayload,
        logo,
      });
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getWorkspaceAction = createAsyncThunk<WorkspaceType, Params>(
  'workspaces/getWorkspace',
  async (requestPayload: Params, { rejectWithValue }) => {
    const callApi = createApiCall<WorkspaceType>({
      api: workspaceRestClient.getWorkspace,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateWorkspaceStatusAction = createAsyncThunk<WorkspaceType, Params>(
  'workspaces/updateWorkspaceStatus',
  async (requestPayload: Params, { rejectWithValue }) => {
    const callApi = createApiCall<WorkspaceType>({
      api: workspaceRestClient.patchWorkspace,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const deleteWorkspaceAction = createAsyncThunk<WorkspaceType, Params>(
  'workspaces/deleteWorkspace',
  async (requestPayload: Params, { rejectWithValue }) => {
    const callApi = createApiCall<WorkspaceType>({
      api: workspaceRestClient.deleteWorkspace,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const selectWorkspaceAction = createAsyncThunk<WorkspaceType, Params>(
  'workspaces/selectWorkspace',
  async (requestPayload: Params, {}) => {
    return requestPayload.workspace;
  },
);

export const unSelectWorkspaceAction = createAsyncThunk<Params>('workspaces/deSelectWorkspace', async () => {
  return null;
});

export const changeWorkspacesRequestParamsAction = createAction<{
  sort: Record<string, unknown>;
  filters: Record<string, unknown>;
}>('workspaces/changeWorkspacesParams');

export const workspacesSetIsStaleAction = createAction<boolean>('workspaces/setIsStale');
