import React from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from 'react-query';
//
import StylesProvider from '@mui/material/StyledEngineProvider';

import 'react-toastify/dist/ReactToastify.css';
//
import { store } from 'portal/workspace/domain/store';
//
import App from './App';

import './index.css';
import 'assets/fonts/Gilmer-Font/GilmerMedium.otf';
import 'assets/fonts/Gilmer-Font/GilmerRegular.otf';
import 'assets/fonts/HandoTrial/HandoTrial-Bold.otf';
import 'assets/fonts/HandoTrial/HandoTrial-Regular.otf';

const queryClient = new QueryClient();

const WorkspaceRoot = () => {
  return (
    <Provider store={store}>
      <StylesProvider injectFirst>
        <QueryClientProvider client={queryClient}>
          <App>
            <ToastContainer position="bottom-center" />
          </App>
        </QueryClientProvider>
      </StylesProvider>
    </Provider>
  );
};

export default WorkspaceRoot;
