import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export const defaultDateFormat = 'D MMM, YYYY';
export const formatDate = (dateStr: string, format = defaultDateFormat) => {
  return dayjs(dateStr).format(format);
};

export const getRelativeTime = (dateStr: string) => {
  return dayjs(dateStr).fromNow();
};

/**
 *
 * Converts seconds in number to HH:mm:ss format
 * usecase: display duration based on the seconds from api
 */
export const secondsToTime = (seconds = 0): string => {
  const date = new Date();
  date.setHours(0, 0, seconds, 0);
  const formattedTime = date.toTimeString().substring(0, 8);
  const [hours, ...rest] = formattedTime.split(':');
  return hours === '00' ? rest.join(':') : formattedTime;
};

/**
 * Transform time components into seconds
 * @return string HH:MM:SS
 * @param hour number
 * @param min number
 * @param sec number
 */
export const transformTimeComponentsIntoSeconds = ({
  hour = 0,
  min = 0,
  sec = 0,
}: Partial<Record<'hour' | 'min' | 'sec', number>>): number => {
  return sec + min * 60 + hour * 60 * 60;
};

/**
 * Transform number of seconds into time string
 * @param seconds seconds
 * @param config {
 *  showHour?: boolean;
 *  showMin?: boolean;
 *  showSec?: boolean;
 * }
 */
export const formatSecondsIntoTimeStr = (
  seconds: number,
  {
    showHour = true,
    showMin = true,
    showSec = true,
  }: {
    showHour?: boolean;
    showMin?: boolean;
    showSec?: boolean;
  } = {},
) => {
  const oneHourSec = 60 * 60;

  const vHour = String(Math.floor(seconds / oneHourSec)).padStart(2, '0');
  const vHourStr = showHour ? vHour : '';

  const vMin = String(Math.floor((seconds % oneHourSec) / 60)).padStart(2, '0');
  const vMinStr = showMin ? vMin : '';

  const vSec = String(Math.floor(seconds % 60)).padStart(2, '0');
  const vSecStr = showSec ? vSec : '';

  return [vHourStr, vMinStr, vSecStr].filter(Boolean).join(':');
};
