import { createReducer } from '@reduxjs/toolkit';
import { Reducer } from 'redux';
//
import {
  createPitchAction,
  updatePitchAction,
  deletePitchAction,
  getPitchAction,
  resetPitchesAction,
  pitchesSetIsStaleAction,
  favoritePitchAction,
  defavoritePitchAction,
} from './actions';
import { PitchType } from 'types/pitches';

const initialState = {
  meta: {
    isLoading: false,
    isLoaded: false,
    //
    isStale: true,
  },
  resource: null as PitchType | null,
};

const reducer = createReducer(initialState, (builder) => {
  builder //
    ////////////////////////////////////////////////////////////////////////////////////////////////
    .addCase(createPitchAction.fulfilled, (draft) => {
      draft.meta.isStale = true;
    })
    ////////////////////////////////////////////////////////////////////////////////////////////////
    .addCase(updatePitchAction.fulfilled, (draft, { payload }) => {
      draft.resource = payload;
      //
      draft.meta.isStale = true;
    })
    ////////////////////////////////////////////////////////////////////////////////////////////////
    .addCase(deletePitchAction.fulfilled, (draft) => {
      draft.meta.isStale = true;
    })
    ////////////////////////////////////////////////////////////////////////////////////////////////
    .addCase(getPitchAction.pending, (draft) => {
      draft.meta.isLoading = true;
      draft.meta.isLoaded = false;
    })
    .addCase(getPitchAction.fulfilled, (draft, { payload }) => {
      draft.meta.isLoading = false;
      draft.meta.isLoaded = true;
      draft.meta.isStale = false;

      draft.resource = payload;
    })
    .addCase(getPitchAction.rejected, (draft) => {
      draft.meta.isLoading = false;
      draft.meta.isLoaded = false;
    })
    ////////////////////////////////////////////////////////////////////////////////////////////////
    .addCase(resetPitchesAction, (draft) => {
      draft.meta.isStale = true;
      draft.resource = null;
    })
    .addCase(pitchesSetIsStaleAction, (draft, { payload }) => {
      draft.meta.isStale = payload;
    })
    .addCase(favoritePitchAction.pending, (draft) => {
      draft.meta.isLoading = true;
      draft.meta.isLoaded = false;
    })
    .addCase(favoritePitchAction.fulfilled, (draft) => {
      draft.meta.isLoading = false;
      draft.meta.isLoaded = true;
    })
    .addCase(defavoritePitchAction.pending, (draft) => {
      draft.meta.isLoading = true;
      draft.meta.isLoaded = false;
    })
    .addCase(defavoritePitchAction.fulfilled, (draft) => {
      draft.meta.isLoading = false;
      draft.meta.isLoaded = true;
    });
});

export const pitchBaseReducer: Reducer<typeof initialState> = (draft = initialState, action) => {
  return reducer(draft, action);
};
