import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
// utils
import { getAuthTokens } from 'lib/localStorage';
// types
import { TokenSetWithTime } from 'types/auth';
import { Notification, NotificationStatusEnum } from 'types/notification';

const MAX_RETRY_ATTEMPTS = 5;
const HEARTBEAT_INTERVAL_SECS = 20;

type INotificationStatus = { statusCode: number };

const useNotification = (
  data: Notification[],
  onNotificationReviewed: (id: number) => void,
  revalidateToken: () => Promise<Nullable<TokenSetWithTime>>,
) => {
  const [active, setActive] = useState(false);
  const [notifications, setNotifications] = useState<Notification[]>(data);

  const ws = useRef<WebSocket | null>(null);
  const { accessToken } = getAuthTokens();

  const update = (id: number) => {
    setNotifications(
      (notifications as Notification[]).map((i) => {
        if (i.id === id) {
          onNotificationReviewed(id);
        }
        return {
          ...i,
          status: i.id === id ? NotificationStatusEnum.Reviewed : i.status,
        };
      }),
    );
  };

  useEffect(() => setNotifications(data), [data]);

  const isActive = useMemo(() => {
    if (notifications && notifications.length > 0) {
      return notifications.filter((i) => i.status === NotificationStatusEnum.Active).length > 0;
    }
    return false;
  }, [notifications]);

  useEffect(() => setActive(isActive), [isActive]);

  const markAllRead = () => {
    setNotifications(
      (notifications as Notification[]).map((i) => {
        if (i.status === NotificationStatusEnum.Active) {
          onNotificationReviewed(i.id);
        }
        return {
          ...i,
          status: NotificationStatusEnum.Reviewed,
        };
      }),
    );
  };

  const heartbeat = useCallback(() => {
    if (!ws.current) return;
    if (ws.current.readyState !== 1) return;
    ws.current.send('heartbeat');
    setTimeout(heartbeat, HEARTBEAT_INTERVAL_SECS * 1000);
  }, [ws]);

  const initSocket = useCallback(
    (retryCount: number) => {
      ws.current = new WebSocket(
        `${
          process.env.REACT_WSS_API_URL ? process.env.REACT_WSS_API_URL : 'wss://api.dev.heypractice.com'
        }/ws/notifications?token=${accessToken}`,
      );
      ws.current.onopen = () => {
        heartbeat();
        console.warn('ws opened');
      };
      ws.current.onclose = (ev: CloseEvent) => {
        if (ev.code !== 1000 && ev.code !== 1005 && retryCount < MAX_RETRY_ATTEMPTS) {
          initSocket(retryCount + 1);
          console.warn(`Retrying..... : ${retryCount + 1} of ${MAX_RETRY_ATTEMPTS}`);
        }
        if (ev.code === 1000) {
          console.warn(`ws closed : ${ev.code}`);
        }
        return null;
      };

      ws.current.onerror = (ev: Event) => {
        console.warn(`ws error : ${ev}`);
        return null;
      };

      const wsCurrent = ws.current;

      wsCurrent.onmessage = async (e) => {
        const message: Notification | INotificationStatus = JSON.parse(e.data);
        if ((message as INotificationStatus).statusCode === 401) {
          await revalidateToken();
          return;
        }
        setNotifications([message as Notification].concat(notifications));
      };
    },
    [accessToken, heartbeat, notifications, revalidateToken],
  );

  useEffect(() => {
    if (!accessToken) return;

    initSocket(0);
    return () => {
      ws?.current?.close(1000);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  return { notifications, update, markAllRead, active };
};

export default useNotification;
