import { createAsyncThunk } from '@reduxjs/toolkit';
//
import { createApiCall } from 'lib/http/utils/createApiCall';
import { workspaceRestClient } from 'portal/workspace/lib/http';
import { Params } from 'lib/http';
import { QuizAnswersType } from 'types/answers';

export const getQuizAnswersAction = createAsyncThunk<QuizAnswersType, Params>(
  'pitches/getQuizAnswers',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<QuizAnswersType>({
      api: workspaceRestClient.getQuizAnswers,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const markQuizCorrectOrIncorrectAction = createAsyncThunk<QuizAnswersType, Params>(
  'pitches/markQuizCorrectOrIncorrect',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<QuizAnswersType>({
      api: workspaceRestClient.markQuizCorrectOrIncorrect,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
