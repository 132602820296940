import axios, { AxiosError, AxiosResponse } from 'axios';
import dayjs from 'dayjs';
//
import { TokenSetWithTime } from 'types/auth';
import { processTokens } from 'types/auth/utils';
//
import ResolvablePromise from 'lib/ResolvablePromise';

type CreateRevalidateTokenConfig = {
  getAuthTokens: () => { [K in keyof TokenSetWithTime]: TokenSetWithTime[K] | null };
  //
  getRevalidationUrl: () => string;
  onRevalidationSuccess: (payload: AxiosResponse) => void;
  onRevalidationFailure: (payload: AxiosError<{ message: string; statusCode: number }>) => void;
  //
  tokenExpiryTime?: number;
};

export const createTokenRevalidationWorker = ({
  getAuthTokens,
  //
  getRevalidationUrl,
  onRevalidationSuccess,
  onRevalidationFailure,
  //
  tokenExpiryTime = 300,
}: CreateRevalidateTokenConfig) => {
  // shared among concurrent requests - access by reference
  const sharedState = {
    isRefreshing: false,
    refreshEndListeners: [] as ResolvablePromise<Nullable<TokenSetWithTime>>[],
  };

  const createListenerPromise = () => {
    const waitingPromise = new ResolvablePromise<Nullable<TokenSetWithTime>>();
    sharedState.refreshEndListeners.push(waitingPromise);

    return waitingPromise;
  };

  const resolveListenerPromises = (tokens: Nullable<TokenSetWithTime>) => {
    sharedState.refreshEndListeners.forEach((resolvablePromise) => {
      resolvablePromise.resolve(tokens);
    });

    sharedState.refreshEndListeners = [];
  };

  const rejectListenerPromises = () => {
    sharedState.refreshEndListeners.forEach((resolvablePromise) => {
      resolvablePromise.reject();
    });

    sharedState.refreshEndListeners = [];
  };

  return async () =>
    await new Promise<Nullable<TokenSetWithTime>>(async (resolve, reject) => {
      const tokens = getAuthTokens();
      const { refreshToken, receivedAt } = tokens;

      if (!refreshToken) {
        resolve(tokens);
        return;
      }

      const someTimeAgo = dayjs().subtract(tokenExpiryTime - 5, 'seconds');
      const tokenValidityIsNearlyExpired = dayjs(receivedAt).isBefore(someTimeAgo);

      switch (true) {
        // Concurrent request when refresh is already in progress
        case sharedState.isRefreshing: {
          await createListenerPromise() // waiting for tokens refreshed in another api call;
            .then(resolve)
            .catch(reject);

          return;
        }

        // token exist, nearly expired and not currently refreshing
        case tokenValidityIsNearlyExpired && !sharedState.isRefreshing: {
          sharedState.isRefreshing = true;
          const revalidationUrl = getRevalidationUrl();

          await axios
            .post(
              revalidationUrl,
              {
                refreshToken,
              },
              {
                baseURL: process.env.REACT_APP_API_URL,
              },
            )
            .then((res) => {
              res = {
                ...res,
                data: processTokens(res.data),
              };

              resolve(res.data);
              resolveListenerPromises(res.data);
              onRevalidationSuccess(res);

              return res;
            })
            .catch((res) => {
              reject(res);
              rejectListenerPromises();
              onRevalidationFailure(res);

              return res;
            })
            .finally(() => {
              sharedState.isRefreshing = false;
            });

          break;
        }

        // token is fine
        default: {
          resolve(tokens as TokenSetWithTime);
          break;
        }
      }
    });
};
