import NavigationUrls from './index';
import { NotificationActionEnum } from 'types/notification';

export const NotificationActionRouteEnumMappings = {
  [NotificationActionEnum.CommentFromCoach]: (id?: number, workspaceId?: number) =>
    `${NavigationUrls.toRecordDetails({ recordId: id as number, workspaceId })}?tab=comments`,
  [NotificationActionEnum.CommentFromUser]: (id?: number, workspaceId?: number) =>
    `${NavigationUrls.toRecordDetails({ recordId: id as number, workspaceId })}?tab=comments`,
  [NotificationActionEnum.FeedbackFromCoach]: (id?: number, workspaceId?: number) =>
    `${NavigationUrls.toRecordDetails({ recordId: id as number, workspaceId })}?tab=review`,
  [NotificationActionEnum.FeedbackFromUser]: (id?: number, workspaceId?: number) =>
    `${NavigationUrls.toRecordDetails({ recordId: id as number, workspaceId })}?tab=review`,
  [NotificationActionEnum.NewBadges]: () => `/`, // No triggers from backend yet and FE doesn't have dedivated page for it
  [NotificationActionEnum.LeaderboardUpdated]: () => `/`, // No triggers from backend yet and FE doesn't have dedivated page for it
  [NotificationActionEnum.ShareContentFile]: () => `/content/files`,
  [NotificationActionEnum.ShareContentFolder]: () => `/content/folders`,
  [NotificationActionEnum.ShareContentList]: () => `/content/lists`,
  [NotificationActionEnum.QuizAnswerCorrected]: (id?: number, workspaceId?: number) =>
    NavigationUrls.toPitchDetails({ pitchId: id as number, workspaceId }),
  [NotificationActionEnum.SharedRecord]: (id?: number, workspaceId?: number) =>
    NavigationUrls.toRecordDetails({ recordId: id as number, workspaceId }),
  [NotificationActionEnum.NewCoursePublished]: (id?: number, workspaceId?: number) =>
    NavigationUrls.toCourseDetails({ courseId: id as number, workspaceId }),
  [NotificationActionEnum.UserAttachedToCourse]: (id?: number, workspaceId?: number) =>
    NavigationUrls.toCourseDetails({ courseId: id as number, workspaceId }),
};
