import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { NavigationPromptProps } from 'react-router-navigation-prompt';
// types
import { FileProgress } from 'types/common';
// utils
import { IntlKeys } from 'localization';
// components
import LeavePageBlocker from 'components/LeavePageBlocker';
// styles
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { BsChevronDown } from 'react-icons/bs';

interface OwnProps {
  progressData: Record<string, FileProgress>;
}

const FileUploadProgressContainer = ({ progressData }: OwnProps) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const [isExpanded, setIsExpanded] = useState(true);
  //
  const activeUploadsCount = Object.keys(progressData).length;
  const isUploadActive = activeUploadsCount > 0;

  const checkWhen = useCallback<Exclude<NavigationPromptProps['when'], boolean>>(
    (currentLocation, nextLocation, action) => {
      const isReload = Boolean(!nextLocation && !action);

      if (!isUploadActive) return false;

      return isReload;
    },
    [isUploadActive],
  );

  return (
    <>
      {isUploadActive && (
        <div
          className={clsx(classes.container, {
            [classes.expanded]: isExpanded,
            [classes.collapsed]: !isExpanded,
          })}
        >
          <div className={clsx(classes.expandedSection, { [classes.hidden]: !isExpanded })}>
            <div className={classes.header}>
              <span>{formatMessage({ id: IntlKeys.uploadInProgress }, { count: activeUploadsCount })}</span>
              <BsChevronDown onClick={() => setIsExpanded(false)} />
            </div>

            {Object.entries(progressData).map(([, { entity, fileName, progress }], ix) => (
              <div key={ix} className={classes.eachItem}>
                <div className={classes.name}>
                  {entity} - {fileName}{' '}
                </div>
                -<div className={classes.progress}> {progress} %</div>
              </div>
            ))}
          </div>
          <div
            className={clsx(classes.collapsedSection, { [classes.hidden]: isExpanded })}
            onClick={() => setIsExpanded(true)}
          >
            {activeUploadsCount}
          </div>
        </div>
      )}

      <LeavePageBlocker when={checkWhen} />
    </>
  );
};

export default FileUploadProgressContainer;

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'fixed',
    borderRadius: 4,
    zIndex: 100,
  },
  expanded: {
    border: `1px solid ${theme.colors.stroke}`,
    bottom: 0,
    right: 0,
    borderRadius: '3px',
    overflow: 'hidden',
    width: '300px',
  },
  collapsed: {
    right: 0,
    bottom: 0,
  },
  expandedSection: {
    background: theme.colors.white,
  },
  collapsedSection: {
    padding: '.75rem 0rem 0rem .75rem',
    cursor: 'pointer',
    color: theme.colors.white,
    borderTopLeftRadius: '100%',
    boxShadow: `0px 0px 4px 1px ${theme.colors.white}`,
    fontWeight: 600,
    opacity: 0.9,
    width: '40px',
    height: '40px',
    textAlign: 'center',
    animation: '$colorChange 1s infinite ease',

    '&:hover': {
      opacity: 1,
    },
  },
  hidden: {
    width: 0,
    height: 0,
    overflow: 'hidden',
    visibility: 'hidden',
    padding: 0,
  },
  header: {
    padding: '.5rem 1rem',
    background: theme.colors.oxfordBlue,
    color: theme.colors.white,
    lineHeight: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& svg': {
      cursor: 'pointer',
      borderRadius: '100%',
      padding: '6px',
      boxSizing: 'content-box',

      '&:hover': {
        background: '#17265e',
      },
    },
  },
  eachItem: {
    padding: '.5rem 1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '.5rem',
  },
  name: {
    overflow: 'hidden',
    flex: 1,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontSize: '14px',
  },
  progress: {
    color: theme.colors.primary,
    fontWeight: 600,
  },

  '@keyframes colorChange': {
    '0%': { background: theme.colors.oxfordBlue },
    '50%': { background: '#111' },
    '100%': { background: theme.colors.oxfordBlue },
  },
}));
