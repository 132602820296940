import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../store';

const workspacesSelector = (state: RootState) => state.workspaces;

export const workspacesMetaSelector = createSelector(workspacesSelector, ({ meta }) => meta);
export const workspacesIsLoadingSelector = createSelector(workspacesMetaSelector, ({ isLoading }) => isLoading);
export const workspacesIsLoadedSelector = createSelector(workspacesMetaSelector, ({ isLoaded }) => isLoaded);
export const workspacesIsStaleSelector = createSelector(workspacesMetaSelector, ({ isStale }) => isStale);

export const workspacesPaginationSelector = createSelector(workspacesSelector, ({ pagination }) => pagination);
export const workspacesFiltersSelector = createSelector(workspacesSelector, ({ filters }) => filters);
export const workspacesSortSelector = createSelector(workspacesSelector, ({ sort }) => sort);

export const workspacesResourcesSelector = createSelector(workspacesSelector, ({ resources }) => resources);
export const workspacesResourceSelector = createSelector(workspacesSelector, ({ resource }) => resource);

export const selectedWorkspaceSelector = createSelector(
  workspacesSelector,
  ({ selectedWorkspace }) => selectedWorkspace,
);
