import { createSelector } from '@reduxjs/toolkit';
// utils
import { decodeJwt } from 'utils/decodeJwt';
//
import { RootState } from '../store';

const authSelector = (state: RootState) => state.auth;
export const authHeadersSelector = createSelector(authSelector, (auth) => auth.headers);
export const authHeadersAvailableSelector = createSelector(authSelector, ({ headers }) => {
  return Object.values(headers).every(Boolean);
});
export const authMetaSelector = createSelector(authSelector, (auth) => auth.meta);
export const authIsPendingSelector = createSelector(authMetaSelector, (meta) => meta.isLoading);
export const authUserRoleSelector = createSelector(authSelector, (auth) => auth.user?.role);
export const authVirtualRoleSelector = createSelector(authSelector, ({ headers }) => {
  return decodeJwt(headers.accessToken)?.role;
});
export const authCurrentWorkspaceIdSelector = createSelector(authSelector, ({ headers }) => {
  return decodeJwt(headers.accessToken)?.workspaceId;
});
export const authRolesSelector = createSelector(
  authUserRoleSelector,
  authVirtualRoleSelector,
  (realRole, virtualRole) => ({ realRole, virtualRole }),
);
export const authUserSelector = createSelector(authSelector, (auth) => auth.user);
export const authUserAvatarSelector = createSelector(authUserSelector, (user) => user?.avatar);

export const authIsAuthorizedSelector = createSelector(
  authUserSelector,
  authHeadersAvailableSelector,
  (user, headersAvailable) => {
    return user && headersAvailable;
  },
);
