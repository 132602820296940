import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';

const usersSelector = (state: RootState) => state.users;

export const usersResourcesSelector = createSelector(usersSelector, (users) => users.resources);
export const usersResourceSelector = createSelector(usersSelector, (users) => users.resource);

export const usersPaginationSelector = createSelector(usersSelector, (users) => users.pagination);
export const usersFiltersSelector = createSelector(usersSelector, (users) => users.filters);
export const usersSortSelector = createSelector(usersSelector, (users) => users.sort);

export const usersIsLoadingSelector = createSelector(usersSelector, (users) => users.meta.isLoading);
export const usersIsResourcesLoadedSelector = createSelector(usersSelector, (users) => users.meta.isResourcesLoaded);
export const usersIsStaleSelector = createSelector(usersSelector, (users) => users.meta.isStale);

export const usersMetaSelector = createSelector(usersSelector, (users) => users.meta);

export const usersListDataSelector = createSelector(
  [
    usersIsStaleSelector,
    usersIsLoadingSelector,
    usersIsResourcesLoadedSelector,
    usersResourcesSelector,
    usersPaginationSelector,
    usersFiltersSelector,
    usersSortSelector,
  ],
  (isStale, isLoading, isLoaded, resources, pagination, filters, sort) => ({
    isStale,
    isLoading,
    isLoaded,
    resources,
    pagination,
    filters,
    sort,
  }),
);
