export const moveElementInArray = <T,>(arr: T[], from: number, to: number) => {
  const resultArr = [...arr];

  const moveElement = resultArr[from];

  resultArr.splice(from, 1);
  resultArr.splice(to, 0, moveElement);

  return resultArr;
};

export const getUniqueListBy = <T extends Record<string, unknown>>(arr: T[], key: string) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};

export const transformNumberIdxToLetterIdx = (numberIndex: number) => String.fromCharCode(numberIndex + 65);
