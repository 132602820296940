import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    background: 'white',

    boxShadow: 'none',
    borderBottom: `1px solid ${theme.colors.stroke}`,
  },

  logo: {
    width: 165,
    margin: '0px 10px',
  },

  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: theme.dimensions.headerHeight,
    padding: '0 20px',

    fontFamily: theme.fonts.gilmer,
    fontSize: 16,
    color: theme.colors.oxfordBlue,
  },

  mobileContent: {
    width: '100%',
  },

  breadcrumbs: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
  },

  breadcumb: {
    borderLeft: `1px solid ${theme.colors.secondary}`,
    paddingLeft: '0.5rem',
    margin: '0.5rem',
    color: theme.colors.secondary,
    '&:last-child': {
      fontWeight: 800,
      color: theme.colors.oxfordBlue,
    },

    '&:hover': {
      cursor: 'pointer',
    },
  },

  back: {
    cursor: 'pointer',
    padding: '13px 13px 5px 13px',
    height: 'fit-content',
    marginRight: 16,
    border: `1px solid ${theme.colors.stroke}`,
    borderRadius: '50%',
  },

  menuContainer: {
    display: 'flex',
    padding: '0 20px',
  },

  mobileMenuContainer: {
    display: 'flex',
  },

  notificationIcon: {
    width: 48,
    height: 48,
    marginRight: '1rem',
    padding: 12,
    borderRadius: '50%',
    border: `1px solid ${theme.colors.stroke}`,
  },

  mobileNotifictionIcon: {
    width: 48,
    height: 48,
    marginRight: '0.2rem',
    padding: 12,
    borderRadius: '50%',
    border: `1px solid ${theme.colors.stroke}`,
  },

  avatar: {
    width: 48,
    height: 48,
    border: `1px solid ${theme.colors.stroke}`,
  },

  button: {
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
  },
}));
