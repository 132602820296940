import { merge, sortBy } from 'lodash';
//
import { PlaybookType, SlideType, PlaybookReactionType, QuizType } from 'types/slides';
//
import { omitNullable } from 'lib/object';
//
import { createEmptyQuiz } from './utils';

export const serializePitchSlide = ({
  //
  evaluationCriteriasEnabled,
  evaluationCriterias,
  playbooks = [],
  materials = [],
  timer,
  quiz,
  ...slide
}: SlideType): SlideType => ({
  ...slide,
  playbooks: sortPlaybooksByPosition(playbooks).map(serializePlaybook),
  materials: sortBy(materials, 'position'),
  evaluationCriteriasEnabled:
    evaluationCriteriasEnabled == null ? evaluationCriterias.length > 0 : evaluationCriteriasEnabled,
  evaluationCriterias,
  timerEnabled: timer !== null, // computed field
  timer,
  isCompleted: slide?.isCompleted || false,
  quiz: quiz && serializeQuiz(quiz),
});

export const serializePitchSlides = (slides: SlideType[]): SlideType[] => {
  //
  return slides.map(serializePitchSlide);
};

export const sortPlaybooksByPosition = (playbooks: PlaybookType[]) => {
  const copyPlaybooks = [...playbooks];

  return copyPlaybooks.sort((a, b) => a.position - b.position);
};

export const serializePlaybook = (
  { reactionsEnabled, reactions, duration, ...playbook }: PlaybookType,
  idx: number,
  playbookList: PlaybookType[],
) => {
  const { timeStart, timeEnd } = extractPlaybookTimeStamps(idx, playbookList);

  return {
    ...playbook,
    reactions: serializePlaybookReaction(timeStart, reactions),
    duration,
    reactionsEnabled: reactionsEnabled == null ? reactions.length > 0 : reactionsEnabled,
    timeStart,
    timeEnd,
  };
};

export const playbookReactionDuration = 7; // sec
const serializePlaybookReaction = (playbookTimeStart: number, playbookReactions: PlaybookReactionType[]) => {
  return playbookReactions.map((item) => ({
    ...item,
    timeToAppear: item.timeToAppear,
    timeToDisappear: item.timeToAppear + playbookReactionDuration,
  }));
};

const extractPlaybookTimeStamps = (idx: number, playbookList: PlaybookType[]) => {
  let timeStart = 0;

  for (let i = 0; i < idx; i++) {
    timeStart += playbookList[idx - 1].duration;
  }

  return {
    timeStart,
    timeEnd: timeStart + playbookList[idx].duration,
  };
};

export const serializeQuiz = ({ task, taskAnswer, ...quiz }: QuizType): QuizType => {
  return merge(
    createEmptyQuiz(),
    quiz,
    //
    omitNullable({
      task: task && omitNullable(task),
      taskAnswer: taskAnswer && omitNullable(taskAnswer),
    }),
  );
};
