export function makeUrl(strings: TemplateStringsArray, ...values: string[]) {
  function generate(params: Record<string, string | number>) {
    //
    return values
      .reduceRight((accumulator, paramName, index) => {
        const currentParam = params?.[paramName] !== undefined ? params[paramName] : '';

        return `${strings[index]}${currentParam}${accumulator}`;
      }, strings[strings.length - 1])
      .replace(/\/+/, '/');
  }
  generate.paramNames = values;

  return generate;
}
