import { createReducer, Reducer } from '@reduxjs/toolkit';
import merge from 'lodash/merge';
// local
import { WorkspaceType, WorkspacesSortConfigs } from 'types/workspaces';
import {
  getWorkspacesAction,
  getCoachesWorkspacesAction,
  getWorkspaceAction,
  workspacesSetIsStaleAction,
  changeWorkspacesRequestParamsAction,
  updateWorkspaceAction,
} from './actions';

const initialState = {
  meta: {
    isLoading: false,
    isLoaded: false,
    isStale: false,
  },
  filters: {} as Record<string, string>,
  sort: WorkspacesSortConfigs.nameAsc,
  pagination: {
    page: 0,
    perPage: 10,
    pageCount: 0,
    total: 0,
  },
  resources: [] as WorkspaceType[],
  resource: {} as WorkspaceType,
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getWorkspacesAction.pending, (draft, {}) => {
      //
      draft.meta.isLoading = true;
      draft.meta.isLoaded = false;
    })
    .addCase(getWorkspacesAction.fulfilled, (draft, { payload }) => {
      const { data, ...pagination } = payload;

      draft.meta.isLoading = false;
      draft.meta.isLoaded = true;
      draft.meta.isStale = false;

      draft.resources = pagination.page === initialState.pagination.page ? data : [...draft.resources, ...data];

      draft.pagination = {
        ...draft.pagination,
        ...pagination,
      };
    })
    .addCase(getWorkspacesAction.rejected, (draft) => {
      draft.meta.isLoading = false;
      draft.meta.isLoaded = false;
    })

    ////////////////////////////////////////////////////////////////////////////////////////////////
    .addCase(getCoachesWorkspacesAction.pending, (draft, {}) => {
      draft.meta.isLoading = true;
      draft.meta.isLoaded = false;
    })
    .addCase(getCoachesWorkspacesAction.fulfilled, (draft, { payload }) => {
      const { data, ...pagination } = payload;

      draft.meta.isLoading = false;
      draft.meta.isLoaded = true;
      draft.meta.isStale = false;

      draft.resources = pagination.page === initialState.pagination.page ? data : [...draft.resources, ...data];

      draft.pagination = {
        ...draft.pagination,
        ...pagination,
      };
    })
    .addCase(getCoachesWorkspacesAction.rejected, (draft) => {
      draft.meta.isLoading = false;
      draft.meta.isLoaded = false;
    })

    ////////////////////////////////////////////////////////////////////////////////////////////////
    .addCase(getWorkspaceAction.pending, (draft, {}) => {
      draft.meta.isLoading = true;
    })
    .addCase(getWorkspaceAction.fulfilled, (draft, { payload }) => {
      draft.meta.isLoading = false;

      draft.resource = payload;
    })
    .addCase(getWorkspaceAction.rejected, (draft) => {
      draft.meta.isLoading = false;
    })
    ////////////////////////////////////////////////////////////////////////////////////////////////
    .addCase(updateWorkspaceAction.pending, (draft, {}) => {
      draft.meta.isLoading = true;
    })
    .addCase(updateWorkspaceAction.fulfilled, (draft, { payload }) => {
      draft.meta.isLoading = false;

      draft.resource = payload;
    })
    .addCase(updateWorkspaceAction.rejected, (draft) => {
      draft.meta.isLoading = false;
    })
    ////////////////////////////////////////////////////////////////////////////////////////////////
    .addCase(changeWorkspacesRequestParamsAction, (draft, { payload }) => {
      draft.meta.isStale = true;
      draft.meta.isLoaded = false;
      draft.filters = merge(draft.filters, payload.filters);
      draft.sort = merge(draft.sort, payload.sort);
      draft.resources = initialState.resources;
      draft.pagination = initialState.pagination;
    })
    ////////////////////////////////////////////////////////////////////////////////////////////////
    .addCase(workspacesSetIsStaleAction, (draft, { payload }) => {
      draft.meta.isStale = payload;
    });
});

export const workspacesReducer: Reducer<typeof initialState> = (draft = initialState, action) => {
  return reducer(draft, action);
};
