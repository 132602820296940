import { combineReducers } from '@reduxjs/toolkit';
import { filesReducer } from './files/reducer';
import { foldersReducer } from './folders/reducer';
import { listsReducer } from './lists/reducer';
import { generalContentReducer } from './general/reducer';

const contentReducer = combineReducers({
  files: filesReducer,
  folders: foldersReducer,
  lists: listsReducer,
  general: generalContentReducer,
});

export default contentReducer;
