import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  menuList: {
    minWidth: 160,
  },

  menuItem: {
    padding: '.75rem 1rem',
    color: theme.colors.oxfordBlue,

    '&:hover': {
      backgroundColor: theme.colors.lightBlue,
      color: theme.colors.primary,
    },
  },

  listIconContainer: {
    minWidth: 'unset',
    margin: '0 12px 0 0',
    color: theme.colors.primary,
  },

  listIcon: {
    width: 20,
    height: 20,
  },

  menuItemLabel: {
    fontFamily: theme.fonts.gilmer,
  },
  menuItemSubText: {
    fontSize: 12,
    color: theme.colors.secondary,
  },

  popoverHeader: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '1rem 1rem 0 1rem',
    },
  },

  closeIcon: {
    '& path': {
      fill: theme.colors.primary,
    },
  },
}));
