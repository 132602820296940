import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AsyncThunk } from '@reduxjs/toolkit';
// types
import { TokenSetWithTime } from 'types/auth';
import { UserType } from 'types/users';
import { Notification, NotificationStatusEnum } from 'types/notification';
import { NotificationResponse } from 'types/notifications';
// utils
import useNotification from 'hooks/useNotification';
import { Params } from 'lib/http';
import { useInfiniteData } from 'hooks/useInfiniteData';

export type NotificationsContextType = {
  notifications: Notification[];
  isLoading: boolean;
  fetchNextPage: () => void;
  pagination: { page: number; perPage: number; total: number; pageCount: number };
  updateNotification: (id: number) => void;
  markAllRead: () => void;
  isNewNotification: boolean;
};
export const NotificationsContext = React.createContext<NotificationsContextType>({
  notifications: [],
  isLoading: false,
  fetchNextPage: () => {},
  pagination: { page: 0, perPage: 0, total: 0, pageCount: 0 },
  updateNotification: () => {},
  markAllRead: () => {},
  isNewNotification: false,
});

export interface DomainProps {
  fetchNotificationListWorker: (requestPayload: Record<string, unknown>) => Promise<NotificationResponse>;
  updateNotificationAction: AsyncThunk<Notification, Params | undefined, Record<string, unknown>>;
  revalidateToken: () => Promise<Nullable<TokenSetWithTime>>;
}
interface OwnProps extends DomainProps {
  user?: UserType;
  children?: React.ReactNode;
}

export const NotificationsContextProvider: React.FC<OwnProps> = ({
  fetchNotificationListWorker,
  updateNotificationAction,
  revalidateToken,
  //
  user,
  children,
}) => {
  const dispatch = useDispatch();

  const onNotificationReviewed = useCallback(
    (id: number) => {
      dispatch(updateNotificationAction({ params: { id }, status: NotificationStatusEnum.Reviewed }));
    },
    [updateNotificationAction, dispatch],
  );

  const {
    isLoading,
    resources,
    fetchNextPage,
    refetch,
    //
    pagination,
    //
  } = useInfiniteData({
    queryKey: 'notificationList',
    //
    fetchResources: fetchNotificationListWorker,
    initialSort: '',
    initialFilters: {},
    //
  });

  const {
    notifications,
    update,
    markAllRead,
    active: isNewNotification,
  } = useNotification(resources, onNotificationReviewed, revalidateToken);

  useEffect(() => {
    if (user) {
      refetch();
    }
  }, [refetch, user]);
  return (
    <NotificationsContext.Provider
      value={{
        isLoading,
        notifications: notifications,
        fetchNextPage,
        pagination,
        updateNotification: update,
        markAllRead,
        isNewNotification,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};
