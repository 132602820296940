import { configureStore } from '@reduxjs/toolkit';
// middlewares
import { routerMiddleware } from 'connected-react-router';
// reducers
import { createRootReducer } from './createRootReducer';
import { history } from 'portal/backoffice/domain/router/reducer';

export const createStore = () => {
  const rootReducer = createRootReducer();

  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
      const defaultMiddleware = getDefaultMiddleware();

      return [...defaultMiddleware, routerMiddleware(history)];
    },
    devTools: process.env.NODE_ENV === 'development',
    enhancers: [],
  });
  //
  return store;
};

export const store = createStore();
export const storeSelector = (state: RootState) => state;

export { history };
export type RootState = ReturnType<typeof store.getState>;
