import React from 'react';
// local
import DynamicHeader from '../common/dynamic-header';
// styles
import { useLayoutsStyles } from '../common/styles';

const FullwidthLayout: React.FC = ({ children }) => {
  const layoutsStyles = useLayoutsStyles({
    withHeader: true,
    withSidebar: false,
  });

  return (
    <div className={layoutsStyles.layout}>
      <div className={layoutsStyles.header}>
        <DynamicHeader />
      </div>

      <div className={layoutsStyles.content}>{children}</div>
    </div>
  );
};

export default React.memo(FullwidthLayout);
