import axios from 'axios';
import qs from 'qs';
// utils
import { injectAuthHeaders } from 'lib/http/utils/injectAuthHeaders';
import revalidateBackofficeToken from 'portal/backoffice/lib/http/revalidateBackofficeToken';

export const Axios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  paramsSerializer: (params) =>
    qs.stringify(params, {
      skipNulls: true,
      encode: false,
      arrayFormat: 'brackets',
    }),
});

Axios.interceptors.request.use(
  async (config) => {
    await revalidateBackofficeToken();

    return injectAuthHeaders(config);
  },
  (err) => Promise.reject(err),
);
