// utils
import { getAuthTokens, saveAuthTokensToLS } from 'lib/localStorage';
import { createTokenRevalidationWorker } from 'lib/http/utils/createTokenRevalidationWorker';
import { NetworkErrors } from 'lib/http/index';
// domain
import { store } from 'portal/backoffice/domain/store';
import { signOutAction } from 'portal/backoffice/domain/auth/actions';

const revalidateBackofficeToken = createTokenRevalidationWorker({
  getAuthTokens: () => {
    return getAuthTokens();
  },
  //
  getRevalidationUrl: () => require('./backofficeRestClient').backofficeRestClient.refreshToken.url as string,
  onRevalidationSuccess: ({ data }) => {
    saveAuthTokensToLS(data);
  },
  onRevalidationFailure: ({ response }) => {
    if (response?.data) {
      const { statusCode } = response.data;

      // refreshToken expired
      if (statusCode === 401) {
        store.dispatch(signOutAction());
        throw NetworkErrors.RefreshTokenExpired;
      }
    }
  },
  //
  tokenExpiryTime: 300, // seconds
});

export default revalidateBackofficeToken;
