import React, { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
// utils
import backofficeRoutes from 'portal/backoffice/routes';
import translations, { initializeIntl, LangCode } from 'localization';
import { useGlobalRoutes } from 'hooks/useGlobalRoutes';
import { RXD, isScormPortal } from 'utils/portalCheck';
import { fallbackLocale } from 'localization/utils';
import { checkRouteAllowed } from 'portal/backoffice/lib/acl/checkRouteAllowed';
import revalidateBackofficeToken from 'portal/backoffice/lib/http/revalidateBackofficeToken';
// domain
import {
  authHeadersAvailableSelector,
  authIsPendingSelector,
  authUserSelector,
} from 'portal/backoffice/domain/auth/selectors';
import { fetchCurrentUserAction, signInScormAction } from 'portal/backoffice/domain/auth/actions';
import { notifyRouteMountedAction } from 'portal/backoffice/domain/router/reducer';
import { fileUploadProgressSelector } from 'portal/backoffice/domain/general/selectors';
import { fetchNotificationListWorker, updateNotificationAction } from 'portal/backoffice/domain/notifications/actions';
// components
import AsyncRedirect from 'routes/AsyncRedirect';
import { Loader } from 'components/loader';
import FileUploadProgressContainer from 'components/FileUploadProgressConatiner';
import {
  DomainProps as NotificationDomainProps,
  NotificationsContextProvider,
} from 'components/notification/NotificationContext';
import { HeaderContextProvider } from 'components/layouts/common/dynamic-header';

const App: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  //
  const user = useSelector(authUserSelector);
  const authHeadersAvailable = useSelector(authHeadersAvailableSelector);
  const isAuthLoading = useSelector(authIsPendingSelector);
  const progressData = useSelector(fileUploadProgressSelector);

  const locale: LangCode = user?.language ?? fallbackLocale;

  const { allowedRoutes, defaultRoute } = useGlobalRoutes({
    user,
    //
    routes: backofficeRoutes,
    checkRouteAllowed,
    notifyRouteMountedAction,
  });

  useEffect(() => {
    initializeIntl(locale);
  }, [locale]);

  useEffect(() => {
    if (isScormPortal && !authHeadersAvailable) {
      dispatch(
        signInScormAction({
          token: new URLSearchParams(window.location.search).get('token'),
          userId: RXD!.GetStudentID(),
          firstName: RXD!.GetStudentName(),
        }),
      );
    }

    authHeadersAvailable && dispatch(fetchCurrentUserAction());
  }, [authHeadersAvailable, dispatch]);

  return (
    <NotificationsContextProvider {...notificationDomainProps} user={user}>
      <HeaderContextProvider>
        <div className="App">
          <IntlProvider locale={locale} messages={translations[locale]}>
            <BrowserRouter>
              {/* TODO extract providers out*/}

              {isAuthLoading ? (
                <Loader />
              ) : (
                <Suspense fallback={<Loader />}>
                  <Switch>
                    {allowedRoutes.map((route) => (
                      <Route
                        //
                        key={route.path}
                        path={route.path}
                        exact={route.exact}
                        component={route.component}
                      />
                    ))}

                    <Route
                      render={({ location }) => {
                        // Made redirects async to let components finish possible state updates
                        // to not to have errors related to updating state on unmounted components
                        return <AsyncRedirect location={location} path={defaultRoute.path} />;
                      }}
                    />
                  </Switch>
                </Suspense>
              )}

              {children}

              <FileUploadProgressContainer progressData={progressData} />
              {/* TODO extract providers out*/}
            </BrowserRouter>
          </IntlProvider>
        </div>
      </HeaderContextProvider>
    </NotificationsContextProvider>
  );
};

const notificationDomainProps: NotificationDomainProps = {
  fetchNotificationListWorker,
  updateNotificationAction,
  revalidateToken: revalidateBackofficeToken,
};

export default App;
