// utils
import { ResourcesResponse } from 'lib/http';
import { EvaluationCriteriaType } from 'types/slides';
import { RecordType } from '../records/entities';

interface AuthorType {
  avatar: string;
  fullName: string;
  jobTitle: string;
}

export interface ReviewType {
  id: number;
  recordId: number;
  authorId: 4;
  author: AuthorType;
  feedback: string;
  reviewScores: {
    id: number;
    score: number;
    icon: string;
    name: string;
    evaluationCriteria: EvaluationCriteriaType;
    evaluationCriteriaId: number;
  }[];
  createdAt: string;
  record: RecordType;
}

export type ReviewResponse = ResourcesResponse<ReviewType>;

export type SortConfigType = {
  field: string;
  order: 'ASC' | 'DESC';
};

const uSortConfigs = {
  updatedAtAsc: {
    field: 'updatedAt',
    order: 'ASC',
  },
  updatedAtDesc: {
    field: 'updatedAt',
    order: 'DESC',
  },
};

export const SortConfigs = uSortConfigs as Record<keyof typeof uSortConfigs, SortConfigType>;
