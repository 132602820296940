import { createReducer } from '@reduxjs/toolkit';
import { Reducer } from 'redux';
import { merge } from 'lodash';
// types
import { TokenSetWithTime } from 'types/auth';
import { UserType } from 'types/users';
// utils
import { getAuthTokens } from 'lib/localStorage';
import { isScormPortal } from 'utils/portalCheck';
// local
import {
  fetchCurrentUserAction,
  issueCompanyToken,
  issueWorkspaceToken,
  signInAction,
  signInScormAction,
  updateUserAction,
  updateUserAvatarAction,
  updateUserPasswordAction,
  updateTokensAction,
} from './actions';

export interface AuthStateType {
  meta: {
    isLoading: boolean;
    updateInProgress: boolean;
  };
  headers: TokenSetWithTime; // Current set of tokens - either root tokens or workspace-specific ones
  user: UserType | undefined;
}

const staticInitialState: AuthStateType = {
  meta: {
    isLoading: false,
    updateInProgress: false,
  },
  headers: {
    accessToken: '',
    refreshToken: '',
    //
    receivedAt: '',
  },
  user: undefined,
};

export const initialState = {
  get auth() {
    const { accessToken = '', refreshToken = '', receivedAt = '' } = getAuthTokens();

    return merge({}, staticInitialState, {
      headers: {
        accessToken: isScormPortal ? '' : accessToken,
        refreshToken: isScormPortal ? '' : refreshToken,
        receivedAt: isScormPortal ? '' : receivedAt,
      },
      user: null,
    });
  },
};

const reducer = createReducer(staticInitialState, (builder) => {
  builder // signInAction
    ////////////////////////////////////////////////////////////////////////////////////////////////
    .addCase(signInAction.fulfilled, (draft, { payload }) => {
      draft.headers = payload;
    })
    .addCase(signInAction.rejected, (draft) => {
      draft.user = staticInitialState.user;
      draft.headers = staticInitialState.headers;
    })
    // signInScormAction
    .addCase(signInScormAction.fulfilled, (draft, { payload }) => {
      draft.headers = payload;
    })
    .addCase(signInScormAction.rejected, (draft) => {
      draft.user = staticInitialState.user;
      draft.headers = staticInitialState.headers;
    })
    // fetchCurrentUserAction
    .addCase(fetchCurrentUserAction.pending, (draft) => {
      draft.meta.isLoading = true;
    })
    .addCase(fetchCurrentUserAction.fulfilled, (draft, { payload }) => {
      draft.meta.isLoading = false;
      draft.user = payload;
    })
    .addCase(fetchCurrentUserAction.rejected, (draft) => {
      draft.meta.isLoading = false;
      draft.user = staticInitialState.user;
      draft.headers = staticInitialState.headers;
    })
    .addCase(updateUserAction.pending, (draft) => {
      draft.meta.updateInProgress = true;
    })
    .addCase(updateUserAction.fulfilled, (draft, { payload }) => {
      draft.meta.updateInProgress = false;
      draft.user = payload;
    })
    .addCase(updateUserAction.rejected, (draft) => {
      draft.meta.updateInProgress = false;
    })
    .addCase(updateUserPasswordAction.pending, (draft) => {
      draft.meta.updateInProgress = true;
    })
    .addCase(updateUserPasswordAction.fulfilled, (draft) => {
      draft.meta.updateInProgress = false;
    })
    .addCase(updateUserPasswordAction.rejected, (draft) => {
      draft.meta.updateInProgress = false;
    })
    // updateUserAvatarAction
    .addCase(updateUserAvatarAction.fulfilled, (draft, { payload }) => {
      draft.user = merge(draft.user, { avatar: payload.avatar });
    })
    .addCase(issueWorkspaceToken.pending, (draft, {}) => {
      draft.meta.isLoading = true;
    })
    .addCase(issueWorkspaceToken.fulfilled, (draft, { payload }) => {
      draft.meta.isLoading = false;
      draft.headers = payload;
    })
    .addCase(issueWorkspaceToken.rejected, (draft) => {
      draft.meta.isLoading = false;
    })
    .addCase(issueCompanyToken.pending, (draft, {}) => {
      draft.meta.isLoading = true;
    })
    .addCase(issueCompanyToken.fulfilled, (draft, { payload }) => {
      draft.meta.isLoading = false;
      draft.headers = payload;
    })
    .addCase(issueCompanyToken.rejected, (draft) => {
      draft.meta.isLoading = false;
    })
    ////////////////////////////////////////////////////////////////////////////////////////////////
    .addCase(updateTokensAction, (draft, { payload }) => {
      draft.headers = payload;
    });
});

export const authReducer: Reducer<typeof staticInitialState> = (draft = initialState.auth, action) => {
  return reducer(draft, action);
};
