// types
import { bindPitchSlideUtils } from 'types/slides/utils';
// utils
import { uploadFile } from 'portal/workspace/lib/uploadFile';

const { modifySlide } = bindPitchSlideUtils({
  uploadFile,
});

export { modifySlide };

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
