export const replaceSpaces = (string = '', replacer = '_', replacedValue: string | RegExp = ' ') => {
  return string.replaceAll(replacedValue, replacer);
};

export const charCount = (str: string, char: string) => {
  let count = 0;

  char = char.charAt(0); // only single character

  for (let i = 0; i < str.length; ++i) {
    if (char === str.charAt(i)) {
      ++count;
    }
  }

  return count;
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////

const lowerACode = 'a'.charCodeAt(0);
const upperACode = 'A'.charCodeAt(0);

/**
 * @param position - starts with 1 ( = A/a)
 * @param uppercase
 */
export const alphabetLetterAt = (position: number, uppercase = false) => {
  const baseIndex = uppercase ? upperACode - 1 : lowerACode - 1;

  return String.fromCharCode(baseIndex + position);
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
