import { store } from 'portal/workspace/domain/store';
import { getUrlWithParams } from 'portal/workspace/routes';
import { authCurrentWorkspaceIdSelector, authRolesSelector } from 'portal/workspace/domain/auth/selectors';
//
import { isScormPortal } from 'utils/portalCheck';
//
import { UserRoles } from 'types/users';

class NavigationRules {
  isScormPitchPackage = isScormPortal && !location.pathname.includes('courses');

  get isCoachInWorkspaceAdminRole() {
    const { realRole, virtualRole } = authRolesSelector(store.getState());
    return realRole === UserRoles.Coach && virtualRole === UserRoles.WorkspaceAdmin;
  }

  get currentWorkspaceId() {
    return authCurrentWorkspaceIdSelector(store.getState());
  }

  public toTrainingDetails = ({
    pitchId,
    courseId,
    workspaceId,
  }: {
    pitchId: number | string;
    courseId: number | string;
    workspaceId?: string | number;
  }) =>
    getUrlWithParams(workspaceId || this.isCoachInWorkspaceAdminRole ? 'boundedTrainingDetails' : 'trainingDetails', {
      pitchId,
      courseId,
      workspaceId: workspaceId || this.currentWorkspaceId,
    });

  public toTrainingPage = ({
    pitchId,
    courseId,
    workspaceId,
  }: {
    pitchId: number | string;
    courseId: number | string;
    workspaceId?: string | number;
  }) =>
    getUrlWithParams(workspaceId || this.isCoachInWorkspaceAdminRole ? 'boundedPitchTraining' : 'pitchTraining', {
      pitchId,
      courseId,
      workspaceId: workspaceId || this.currentWorkspaceId,
    });

  public toPitchEditor = ({ pitchId, courseId }: { pitchId: number | string; courseId: number | string }) =>
    getUrlWithParams('editPitch', {
      pitchId,
      courseId,
    });

  public toPitchDetails = ({
    pitchId,
    workspaceId,
    buildQueryParams,
  }: {
    pitchId: number | string;
    workspaceId?: string | number;
    buildQueryParams?: { query: Record<string, unknown> };
  }) => {
    return getUrlWithParams(
      workspaceId || this.isCoachInWorkspaceAdminRole ? 'workspaceTrainingDetails' : 'globalTrainingDetails',
      {
        pitchId,
        workspaceId: workspaceId || this.currentWorkspaceId,
        ...buildQueryParams,
      },
    );
  };

  public toCourseDetails = ({
    courseId,
    buildQueryParams,
    workspaceId,
  }: {
    courseId: number | string;
    buildQueryParams?: { query: Record<string, unknown> };
    workspaceId?: string | number;
  }) => {
    return getUrlWithParams(
      workspaceId || this.isCoachInWorkspaceAdminRole ? 'boundedCourseDetails' : 'courseDetails',
      {
        courseId,
        workspaceId: workspaceId || this.currentWorkspaceId,
        ...buildQueryParams,
      },
    );
  };

  public toSlideTraining = ({
    pitchId,
    courseId,
    slideId,
    buildQueryParams,
  }: {
    pitchId: number | string;
    courseId?: number | string;
    slideId: number | string;
    buildQueryParams?: { query: Record<string, unknown> };
  }) => {
    const path = this.isCoachInWorkspaceAdminRole ? 'boundedSlideTraining' : 'slideTraining';
    const pathWithScormCheck = this.isScormPitchPackage ? 'slideTrainingSingle' : path;
    return getUrlWithParams(pathWithScormCheck, {
      ...buildQueryParams,
      courseId,
      pitchId,
      slideId,
      workspaceId: this.currentWorkspaceId,
    });
  };

  public toPitchFinished = ({
    pitchId,
    courseId,
    buildQueryParams,
  }: {
    pitchId: number | string;
    courseId?: number | string;
    buildQueryParams?: { query: Record<string, unknown> };
  }) => {
    const path = this.isCoachInWorkspaceAdminRole ? 'boundedPitchTrainingFinished' : 'pitchTrainingFinished';
    const pathWithScormCheck = this.isScormPitchPackage ? 'pitchTrainingFinishedSingle' : path;
    return getUrlWithParams(pathWithScormCheck, {
      ...buildQueryParams,
      courseId,
      pitchId,
      workspaceId: this.currentWorkspaceId,
    });
  };

  public toCourseRecordDetails = ({
    recordId,
    courseId,
    workspaceId,
  }: {
    recordId: number | string;
    courseId?: number | string;
    workspaceId?: string | number;
  }) =>
    getUrlWithParams(workspaceId || this.isCoachInWorkspaceAdminRole ? 'boundedCourseRecordDetails' : 'recordDetails', {
      recordId,
      courseId,
      workspaceId: workspaceId || this.currentWorkspaceId,
    });

  public toRecordDetails = ({ recordId, workspaceId }: { recordId: number | string; workspaceId?: string | number }) =>
    getUrlWithParams(workspaceId || this.isCoachInWorkspaceAdminRole ? 'boundedRecordDetails' : 'globalRecordDetails', {
      recordId,
      workspaceId: workspaceId || this.currentWorkspaceId,
    });

  public toUserPitchRecordDetails = ({
    userId,
    pitchId,
    courseId,
    id, // teamId
    workspaceId,
  }: {
    userId: string | number;
    pitchId: string | number;
    courseId: string | number;
    id?: string | number;
    workspaceId?: string | number;
  }) => {
    const getRouteKey = () => {
      if (this.isCoachInWorkspaceAdminRole || workspaceId) {
        if (id) {
          return 'coachTeamUserPitchRecordDetails';
        } else {
          return 'coachUserPitchRecordDetails';
        }
      } else {
        if (id) {
          return 'teamUserPitchRecordDetails';
        } else {
          return 'userPitchRecordDetails';
        }
      }
    };
    const routeKey = getRouteKey();
    return getUrlWithParams(routeKey, {
      userId: userId,
      pitchId: pitchId,
      courseId: courseId,
      id,
      workspaceId: workspaceId || this.currentWorkspaceId,
    });
  };

  public toUserRecordDetails = ({
    userId,
    pitchId,
    courseId,
    workspaceId,
  }: {
    userId: string | number;
    pitchId: string | number;
    courseId?: string | number;
    workspaceId?: string | number;
  }) =>
    getUrlWithParams(
      workspaceId || this.isCoachInWorkspaceAdminRole ? 'boundedUserRecordDetails' : 'userRecordDetails',
      {
        pitchId,
        userId,
        courseId,
        workspaceId: workspaceId || this.currentWorkspaceId,
      },
    );

  public toMemberDetails = ({
    userId,
    id, // teamId
    workspaceId,
  }: {
    userId: string | number;
    id?: string | number;
    workspaceId?: string | number;
  }) => {
    const getRouteKey = () => {
      if (workspaceId || this.isCoachInWorkspaceAdminRole) {
        if (id) {
          return 'coachTeamMemberDetails';
        } else {
          return 'coachMemberDetails';
        }
      } else {
        if (id) {
          return 'teamMemberDetails';
        } else {
          return 'memberDetails';
        }
      }
    };
    const routeKey = getRouteKey();

    return getUrlWithParams(routeKey, { userId, id, workspaceId: workspaceId || this.currentWorkspaceId });
  };

  public toWorkspaceUserStatus = ({
    workspaceId,
    userStatus,
  }: {
    workspaceId?: string | number;
    userStatus: string;
  }) => {
    const urlKey = this.isCoachInWorkspaceAdminRole ? 'workspaceUserList' : 'workspace';
    const query = userStatus ? `?userStatus=${userStatus}` : '';
    return `${getUrlWithParams(urlKey, { workspaceId })}${query}`;
  };

  public toWorkspaceDetails = ({ workspaceId }: { workspaceId: string | number }) =>
    getUrlWithParams('workspaceDetails', { workspaceId });

  public toPitchRecords = ({
    courseId,
    pitchId,
    workspaceId,
  }: {
    courseId: string | number;
    pitchId: string | number;
    workspaceId?: string | number;
  }) =>
    getUrlWithParams(workspaceId || this.isCoachInWorkspaceAdminRole ? 'boundedPitchRecords' : 'pitchRecords', {
      courseId,
      pitchId,
      workspaceId: workspaceId || this.currentWorkspaceId,
    });
}

const NavigationUrls = new NavigationRules();

export default NavigationUrls;
