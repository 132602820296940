import React, { useState } from 'react';
// utils
import { useIsMobile } from 'hooks/useIsMobile';
// local
import DynamicHeader from '../common/dynamic-header';
import Sidebar from '../common/sidebar';
// styles
import { useLayoutsStyles } from '../common/styles';

const FullwidthLayout: React.FC = ({ children }) => {
  const isMobile = useIsMobile();

  const [isMenuOpen, setMenuOpen] = useState(false);

  const layoutsStyles = useLayoutsStyles({
    withHeader: true,
    withSidebar: false,
  });

  return (
    <div className={layoutsStyles.layout}>
      <div className={layoutsStyles.header}>
        <DynamicHeader isMenuOpen={isMenuOpen} onMenuClick={() => setMenuOpen(!isMenuOpen)}/>
      </div>

      {isMobile && (
        <div className={`${layoutsStyles.mobileSidebar} ${isMenuOpen ? 'open' : 'close'}`}>
          <Sidebar isMenu={isMobile} />
        </div>
      )}

      <div className={layoutsStyles.content}>{children}</div>
    </div>
  );
};

export default React.memo(FullwidthLayout);
