export enum PortalTypes {
  Workspace = 'Workspace',
  Backoffice = 'Backoffice',
  Scorm = 'SCORM',
  Coach = 'Coach',
  Company = 'Company',
}

export interface ScormWindow extends Window {
  RXD?: {
    GetStudentID: () => string;
    GetStudentName: () => string;
    SetPassed: () => void;
    SetReachedEnd: () => void;
    ConcedeControl: () => void;
  };
}

export const RXD = (window.parent as ScormWindow).RXD;

export const currentPortalType =
  process.env.REACT_APP_IS_ADMIN_PANEL === 'true'
    ? PortalTypes.Backoffice
    : !!RXD
    ? PortalTypes.Scorm
    : PortalTypes.Workspace;

export const isScormPortal = currentPortalType === PortalTypes.Scorm;
export const isBackofficePortal = currentPortalType === PortalTypes.Backoffice;
export const isWorkspacePortal = currentPortalType === PortalTypes.Workspace;
