import { AnalyzedWordType } from './entities';
import { EvaluationCriteriaType, ReviewScoreType } from 'types/slides';

enum WordTypes {
  Do = 'do',
  Dont = "don't",
}

export const serializeWordAnalyses = (words: AnalyzedWordType[]) => ({
  doWords: words.filter((item) => item.type === WordTypes.Do),
  dontWords: words.filter((item) => item.type === WordTypes.Dont),
});

export const serializeEvaluationCriteriaList = (
  evaluationCriteriaList: EvaluationCriteriaType[],
): ReviewScoreType[] => {
  return evaluationCriteriaList.map(({ id, name, icon }) => ({
    evaluationCriteriaId: id,
    score: undefined,
    name,
    icon,
  }));
};
