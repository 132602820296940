import React from 'react';
// components
import GenericButton, { OwnProps as GenericButtonProps } from './GenericButton';
// styles
import { makeStyles } from '@mui/styles';
import cx from 'clsx';

interface OwnProps extends GenericButtonProps {
  to?: string;
}
const RoundedButton: React.FC<OwnProps> = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <GenericButton
      //
      className={cx(classes.root, className)}
      {...rest}
    />
  );
};

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: 99,
  },
}));

export default React.memo(RoundedButton);
