import { AxiosPromise, Method as AxiosMethod } from 'axios';
import { makeUrl } from './url';

// All lowercase
export type Method = Exclude<
  AxiosMethod,
  'GET' | 'DELETE' | 'HEAD' | 'OPTIONS' | 'POST' | 'PUT' | 'PATCH' | 'LINK' | 'UNLINK'
>;

export type DefaultParams = {
  params?: {
    [T: string]: string | number | Params | (string | number | Params)[];
  };
};
export type Params<P = DefaultParams> = (P extends DefaultParams
  ? P
  : {
      params: P;
    }) & {
  // rest
  [T: string]: any;
};

export type GenericUrl = ReturnType<typeof makeUrl> | string;
export interface ApiCall<P = any> {
  (params: Record<string, unknown>): AxiosPromise<PromisePayload<P>>;
  method: Method;
  url: GenericUrl;
  selfName: string;
  paramNames: string[];
}

export type PromisePayload<P> = {
  data: P;
  meta?: Record<string, unknown>;
  headers: Record<string, unknown>;
};

export enum NetworkErrors {
  RefreshTokenExpired = 'RefreshTokenExpired',
}
