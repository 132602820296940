import { ComponentType, useMemo } from 'react';
import { ActionCreatorWithPayload, compose } from '@reduxjs/toolkit';
import { RouteComponentProps } from 'react-router';
// types
import { UserType } from 'types/users';
// utils
import { ChildRoute, GenericChildRoute, isAbstractRoute } from 'routes';
import { withRoutedLayout } from 'routes/withRoutedLayout';
import { withCurrentRouteTracking } from 'routes/withCurrentRouteTracking';

type UseGlobalRoutesConfig = {
  user: UserType | undefined;
  //
  routes: GenericChildRoute[];
  checkRouteAllowed: (route: GenericChildRoute) => boolean;
  notifyRouteMountedAction: ActionCreatorWithPayload<string>;
};
export const useGlobalRoutes = ({
  user,
  //
  routes,
  checkRouteAllowed,
  notifyRouteMountedAction,
}: UseGlobalRoutesConfig) => {
  return useMemo(() => {
    const nonAbstractRoutes = routes.filter((route) => !isAbstractRoute(route)) as ChildRoute[];

    const allowedRoutes = nonAbstractRoutes.filter(checkRouteAllowed).map((route) => {
      const { component, ...item } = route;

      return {
        ...item,
        //
        component: compose<ComponentType<RouteComponentProps>>(
          withRoutedLayout(route),
          withCurrentRouteTracking(route, notifyRouteMountedAction),
        )(component),
      };
    });

    const defaultRoute = allowedRoutes.find(({ isDefault }) => isDefault)!;

    return { allowedRoutes, defaultRoute };
    // recalculating routes based on permissions (ACL) which access user indirectly
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
};
