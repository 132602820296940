import { SortConfigType } from 'types/common';
import { ResourcesResponse } from 'lib/http';
import { UserType } from './users';
import { IntlKeys } from 'localization';
import { useIntl } from 'react-intl';
import { FilterFormValues } from 'components/filter-bar';

export type WorkspaceType = {
  id: number;
  name: string;
  usersCount: number;
  logo: string;
  coursesCount: number;
  numberOfEmployees: number;
  pitchesCount: number;
  recordsCount: number;
  newRecordsCount: number;
  oldestNotReviewedRecord: string | null;
  status: WorkspaceStatusEnum;
  type: string;
  users?: UserType[];
  sessionCount?: number;
  coursesCountPublished?: number;
  //
  createdAt: string;
  updatedAt: string;
};

export enum WorkspaceStatusEnum {
  Lead = 'lead',
  Active = 'active',
  Archived = 'archived',
}

export type WorkspacesResponse = ResourcesResponse<WorkspaceType>;

export const uSortConfigs = {
  nameAsc: {
    field: 'name',
    order: 'ASC',
  },
  nameDesc: {
    field: 'name',
    order: 'DESC',
  },
  usersCountAsc: {
    field: 'usersCount',
    order: 'ASC',
  },
  usersCountDesc: {
    field: 'usersCount',
    order: 'DESC',
  },
  activityAsc: {
    field: 'activity',
    order: 'ASC',
  },
  activityDesc: {
    field: 'activity',
    order: 'DESC',
  },
  updatedAtAsc: {
    field: 'updatedAt',
    order: 'ASC',
  },
  updatedAtDesc: {
    field: 'updatedAt',
    order: 'DESC',
  },
};

export const WorkspaceSortWithLabels = () => {
  const { formatMessage } = useIntl();
  return [
    {
      value: 'companyNameAsc',
      label: formatMessage({ id: IntlKeys.workspaceNameAToZ }),
    },
    {
      value: 'companyNameDesc',
      label: formatMessage({ id: IntlKeys.workspaceNameZToA }),
    },
    {
      value: 'activityAsc',
      label: formatMessage({ id: IntlKeys.workspaceOldUpdates }),
    },
    {
      value: 'activityDesc',
      label: formatMessage({ id: IntlKeys.workspaceLastUpdates }),
    },
    {
      value: 'usersCountAsc',
      label: formatMessage({ id: IntlKeys.workspaceUserCountAsc }),
    },
    {
      value: 'usersCountDesc',
      label: formatMessage({ id: IntlKeys.workspaceUserCountDesc }),
    },
  ];
};

export enum WorkspaceActionEnum {
  Update = 'Update',
  Delete = 'Delete',
  Archive = 'Archive',
  UnArchive = 'UnArchive',
}

export const WorkspacesSortConfigs = uSortConfigs as Record<keyof typeof uSortConfigs, SortConfigType>;
export type WorkspaceFiltersFormValues = FilterFormValues<keyof typeof WorkspacesSortConfigs>;

export type WorkspaceListRequestParams = {
  sort: keyof typeof WorkspacesSortConfigs;
  filters: Record<'search' | string, string>;
};
export type WorkspaceLisResponse = ResourcesResponse<WorkspaceType>;
