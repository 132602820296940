import React from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from 'react-query';
//
import { ThemeProvider } from '@mui/material';
import StylesProvider from '@mui/material/StyledEngineProvider';
import CssBaseline from '@mui/material/CssBaseline';
import 'react-toastify/dist/ReactToastify.css';
//
import { store } from 'portal/backoffice/domain/store';
//
import App from './App';

import { mainTheme } from 'theme';
import './index.css';
import 'assets/fonts/Gilmer-Font/GilmerMedium.otf';
import 'assets/fonts/Gilmer-Font/GilmerRegular.otf';
import 'assets/fonts/HandoTrial/HandoTrial-Bold.otf';
import 'assets/fonts/HandoTrial/HandoTrial-Regular.otf';

const queryClient = new QueryClient();

const BackofficeRoot = () => {
  return (
    <Provider store={store}>
      <StylesProvider injectFirst>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={mainTheme}>
            <App>
              <ToastContainer position="bottom-center" />
            </App>

            <CssBaseline />
          </ThemeProvider>
        </QueryClientProvider>
      </StylesProvider>
    </Provider>
  );
};

export default BackofficeRoot;
