import axios from 'axios';
//
import { ApiCall } from 'lib/http';
import { createApiCall } from 'lib/http/utils/createApiCall';
//
type ImageUploadConfig = {
  name?: string;
  image: Blob | null;
};
export const bindUploadImage =
  (api: ApiCall) =>
  async ({ name, image }: ImageUploadConfig) => {
    const callGetCoverUploadUrlApi = createApiCall<Record<'uploadUrl' | 'accessUrl', string>>({
      api,
    });

    let coverUrl = undefined;

    if (image) {
      const {
        data: { uploadUrl, accessUrl },
      } = await callGetCoverUploadUrlApi({ type: image.type, name });
      //
      const file = new File([image], 'image', {
        lastModified: new Date().getTime(),
        type: image.type,
      });

      //
      await axios.put(uploadUrl, file);

      coverUrl = accessUrl;
    }

    return coverUrl;
  };

export type FileInfo = {
  name: string;
  file: File | Blob;
  relatedEntity?: { entityIntlKey: string } & Record<string, unknown>;
};

export type OnProgressFileInfoPayload = FileInfo & {
  id: string;
};

export type FileUploader = (config: FileInfo) => Promise<string>;
export type FileUploadProgressListener = (progressEvent: ProgressEvent, fileInfo: OnProgressFileInfoPayload) => void;

export const bindUploadFile =
  (
    api: ApiCall,
    onUploadProgress?: ({ loaded, total }: ProgressEvent, { name, relatedEntity }: OnProgressFileInfoPayload) => void,
  ): FileUploader =>
  async (data: FileInfo) => {
    const callFileUploadUrl = createApiCall<Record<'uploadUrl' | 'accessUrl', string>>({
      api,
    });
    const { file, name, relatedEntity } = data;
    const id = `${name}-${new Date().getTime()}`;

    const {
      data: { uploadUrl, accessUrl },
    } = await callFileUploadUrl({
      type: file.type,
      name: name,
    });

    axios.put(
      uploadUrl,
      file,
      onUploadProgress && {
        onUploadProgress: (progressEvent) => onUploadProgress(progressEvent, { file, name, relatedEntity, id }),
      },
    );

    return accessUrl;
  };
