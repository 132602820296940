import React from 'react';
import { Button, CircularProgress, ButtonProps, CircularProgressProps } from '@mui/material';
// utils
import { mergeClasses } from 'utils/styles';
// styles
import { makeStyles } from '@mui/styles';
import cx from 'clsx';

export interface OwnProps extends ButtonProps {
  // see https://github.com/mui-org/material-ui/issues/7877
  component?: React.ElementType;
  loading?: boolean;
  circularProgressProps?: Partial<CircularProgressProps>;
}
const GenericButton: React.FC<OwnProps> = ({
  className,
  //
  children,
  loading,
  circularProgressProps,
  variant,
  classes,
  ...rest
}) => {
  const localClasses = useStyles();

  return (
    <Button
      //
      variant={variant}
      className={cx(localClasses.button, variant && localClasses[variant], className)}
      classes={mergeClasses({
        localClasses: {
          startIcon: localClasses.startIcon,
        },
        externalClasses: classes,
      })}
      {...rest}
    >
      {loading ? (
        <CircularProgress size={28} className={localClasses.circularProgress} {...circularProgressProps} />
      ) : (
        children && <span>{children}</span>
      )}
    </Button>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: 'unset',
  },
  circularProgress: {
    color: theme.colors.white,
  },

  // variants
  text: {},
  outlined: {},
  contained: {
    color: 'white',
  },
  //
  startIcon: {
    '&:only-child': {
      marginRight: 0,
    },
  },
}));

export default React.memo(GenericButton);
