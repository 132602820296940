import { createReducer, Reducer } from '@reduxjs/toolkit';
import { DomainType } from 'types/domains';
import {
  checkDomainAction,
  createDomainAction,
  deleteDomainAction,
  domainsSetIsStaleAction,
  getDomainAction,
  getPublicDomainAction,
  resetDomainAction,
  updateDomainAction,
} from './actions';

const initialState = {
  meta: {
    isLoading: false,
    isLoaded: false,
    //
    isStale: false,
    //
    global: {
      isLoading: false,
      isLoaded: false,
    },
  },
  domain: null as DomainType | null,
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getDomainAction.pending, (draft) => {
      draft.meta.isLoading = true;
      draft.meta.isLoaded = false;
    })
    .addCase(getDomainAction.fulfilled, (draft, { payload }) => {
      draft.meta.isLoading = false;
      draft.meta.isLoaded = true;
      draft.domain = payload;
    })
    .addCase(getDomainAction.rejected, (draft) => {
      draft.meta.isLoading = false;
      draft.meta.isLoaded = false;
    })
    .addCase(checkDomainAction.pending, (draft) => {
      draft.meta.isLoading = true;
      draft.meta.isLoaded = false;
    })
    .addCase(checkDomainAction.fulfilled, (draft) => {
      draft.meta.isLoading = false;
      draft.meta.isLoaded = true;
    })
    .addCase(checkDomainAction.rejected, (draft) => {
      draft.meta.isLoading = false;
      draft.meta.isLoaded = false;
    })
    .addCase(getPublicDomainAction.pending, (draft) => {
      draft.meta.isLoading = true;
      draft.meta.isLoaded = false;
    })
    .addCase(getPublicDomainAction.fulfilled, (draft, { payload }) => {
      draft.meta.isLoading = false;
      draft.meta.isLoaded = true;
      draft.domain = payload;
    })
    .addCase(getPublicDomainAction.rejected, (draft) => {
      draft.meta.isLoading = false;
      draft.meta.isLoaded = false;
    })
    .addCase(createDomainAction.fulfilled, (draft) => {
      draft.meta.isStale = true;
    })
    .addCase(updateDomainAction.fulfilled, (draft) => {
      draft.meta.isStale = true;
    })
    .addCase(deleteDomainAction.fulfilled, (draft) => {
      draft.meta.isStale = true;
    })
    .addCase(domainsSetIsStaleAction, (draft, { payload }) => {
      draft.meta.isStale = payload;
    })
    .addCase(resetDomainAction, (draft) => {
      draft.domain = null;
    });
});

export const domainsReducer: Reducer<typeof initialState> = (draft = initialState, action) => {
  return reducer(draft, action);
};
