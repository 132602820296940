import { bindUrlGetters } from 'routes';
//
import { UserRoles, UserType } from 'types/users';
//
import workspaceRoutes, { coachRoutes } from './routes';

const { getUrl, getUrlWithParams } = bindUrlGetters([...workspaceRoutes, ...coachRoutes]);

export const getRoutesForUser = (user?: UserType) => {
  switch (user?.role) {
    case UserRoles.Coach: {
      return coachRoutes;
    }

    default: {
      return workspaceRoutes;
    }
  }
};

export const getSimulatedUrl = ({
  realRole,
  virtualRole,
  mainUrl,
  simulatedUrl,
}: {
  realRole?: UserRoles;
  virtualRole?: UserRoles;
  mainUrl: string;
  simulatedUrl: string;
}) => {
  // Coach can request temporary token which will let him into workspace as workspace admin
  const isCoach = realRole === UserRoles.Coach;
  const isSimulatingWorkspaceAdmin = isCoach && virtualRole === UserRoles.WorkspaceAdmin;

  if (!isSimulatingWorkspaceAdmin) {
    return mainUrl;
  }

  return simulatedUrl;
};

export { getUrl, getUrlWithParams };
