import React, { useCallback, useLayoutEffect, useState } from 'react';
import { SwipeableDrawer } from '@mui/material';
// utils
import { useIsMobile } from 'hooks/useIsMobile';
// local
import DynamicHeader from '../common/dynamic-header';
import Sidebar from '../common/sidebar';
// styles
import { useLayoutsStyles } from '../common/styles';

interface OwnProps {
  children?: React.ReactNode;
}

const DefaultLayout: React.FC<OwnProps> = ({ children }) => {
  const isMobile = useIsMobile();
  //
  const layoutsStyles = useLayoutsStyles({
    withHeader: true,
    withSidebar: !isMobile,
  });
  //
  const [isVisible, setVisible] = useState(!isMobile);
  const [isMenuOpen, setMenuOpen] = useState(false);

  const handleOpen = useCallback(() => setVisible(true), []);
  const handleClose = useCallback(() => setVisible(false), []);

  useLayoutEffect(() => {
    setVisible((isVisible) => (isVisible !== !isMobile ? !isMobile : isVisible));
  }, [isMobile]);

  return (
    <div className={layoutsStyles.layout}>
      <SwipeableDrawer
        open={isVisible}
        onOpen={handleOpen}
        onClose={handleClose}
        //
        variant={isMobile ? undefined : 'persistent'}
        hideBackdrop={!isMobile}
        swipeAreaWidth={isMobile ? 0 : 60}
        //
        classes={{
          paper: layoutsStyles.drawer,
        }}
      >
        <div className={layoutsStyles.sidebar}>
          <Sidebar />
        </div>
      </SwipeableDrawer>

      <div className={layoutsStyles.header}>
        <DynamicHeader isMenuOpen={isMenuOpen} onMenuClick={() => setMenuOpen(!isMenuOpen)} />
      </div>

      {isMobile && (
        <div className={`${layoutsStyles.mobileSidebar} ${isMenuOpen ? 'open' : 'close'}`}>
          <Sidebar isMenu={isMobile} />
        </div>
      )}

      <div className={layoutsStyles.content}>{children}</div>
    </div>
  );
};

export default DefaultLayout;
