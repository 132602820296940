import axios from 'axios';
import qs from 'qs';
// utils
import { injectAuthHeaders } from 'lib/http/utils/injectAuthHeaders';
//
import revalidateWorkspaceToken from 'portal/workspace/lib/http/revalidateWorkspaceToken';

export const Axios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  paramsSerializer: (params) =>
    qs.stringify(params, {
      skipNulls: true,
      encode: false,
      arrayFormat: 'brackets',
    }),
});

Axios.interceptors.request.use(
  async (config) => {
    const data = await revalidateWorkspaceToken();
    const getTokens = () => data;

    return injectAuthHeaders(config, getTokens);
  },
  (err) => Promise.reject(err),
);
