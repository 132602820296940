import { makeUrl } from 'lib/http/utils/url';
import { configureApiCalls } from 'lib/http/utils/createApi';
import { api } from './api';

export const PREFIX = 'prefix';
export const workspaceRestClient = configureApiCalls({
  // updateExample: api('post', makeUrl`/examples/${'exampleId'}`), // exampleId -> from action.payload.params
  // auth
  signIn: api('post', '/auth/sign-in'),
  signInScorm: api('post', '/auth/sign-in/scorm'),
  signUp: api('post', 'auth/sign-up'),
  forgotPassword: api('post', '/auth/reset-password'),
  resetPassword: api('post', '/auth/set-new-password'),
  refreshToken: api('post', '/auth/refresh-token'),
  inviteMember: api('post', '/auth/invite'),
  generateInviteLink: api('post', '/auth/invite/link'),
  issueWorkspaceToken: api('post', makeUrl`/auth/company/workspaces/${'id'}/issue-workspace-token`),
  issueCompanyToken: api('post', makeUrl`/auth/company/issue-company-token`),
  //
  getUsers: api('get', makeUrl`${PREFIX}/users`),
  getInvitedUsers: api('get', makeUrl`${PREFIX}/invitation-tokens`),
  revokeUserInvitations: api('delete', makeUrl`${PREFIX}/invitation-tokens`),
  revokeAllUserInvitations: api('delete', makeUrl`${PREFIX}/invitation-tokens/all`),
  getUser: api('get', makeUrl`${PREFIX}/users/${'id'}`),
  deleteUser: api('delete', makeUrl`/users/${'id'}`),
  patchUser: api('patch', makeUrl`/users/${'id'}`),
  getCurrentUser: api('get', '/users/me'),
  updateUserAvatar: api('patch', '/users/me/avatar'),
  uploadUserAvatar: api('post', '/users/me/avatar/upload'),
  importUsers: api('post', '/users/import'),
  updateUserStatus: api('patch', makeUrl`/users/${'id'}/status`),
  updateUser: api('put', makeUrl`/users/${'id'}`),

  // domain
  getPublicDomain: api('get', makeUrl`/public/domains/${'domainName'}`),
  getDomainList: api('get', '/company/domains'),
  getDomain: api('get', makeUrl`/company/domains/${'id'}`),
  checkDomain: api('post', '/company/domains/check'),
  createDomain: api('post', '/company/domains'),
  updateDomain: api('put', makeUrl`/company/domains/${'id'}`),
  deleteDomain: api('delete', makeUrl`/company/domains/${'id'}`),
  // courses
  getCourseList: api('get', makeUrl`/${PREFIX}/courses`),
  getCourse: api('get', makeUrl`/${PREFIX}/courses/${'id'}`),
  createCourse: api('post', makeUrl`/${PREFIX}/courses`),
  updateCourse: api('put', makeUrl`/${PREFIX}/courses/${'id'}`),
  patchCourse: api('patch', makeUrl`/${PREFIX}/courses/${'id'}`),
  deleteCourse: api('delete', makeUrl`/${PREFIX}/courses/${'id'}`),
  duplicateCourse: api('post', makeUrl`/courses/${'id'}/clone`),
  shareCourse: api('post', makeUrl`${PREFIX}/courses/share`),
  unshareCourse: api('post', makeUrl`${PREFIX}/courses/unshare`),
  exportToScormCourse: api('post', makeUrl`/courses/${'id'}/export/scorm`),
  favoriteCourse: api('post', makeUrl`/favorites/courses/${'id'}`),
  defavoriteCourse: api('delete', makeUrl`/favorites/courses/${'id'}`),
  attachTeamsToCourse: api('patch', makeUrl`/teams/attach-courses`),
  detachTeamsFromCourse: api('patch', makeUrl`/teams/detach-courses`),
  attachMembersToCourse: api('patch', makeUrl`/courses/attach-members`),
  detachMembersFromCourse: api('patch', makeUrl`/courses/detach-members`),

  // tags
  getTagList: api('get', '/tags'),
  // settings
  updateCurrentUser: api('put', '/users/me'),
  updateUserPassword: api('post', '/auth/change-password'),
  // pitches
  favoritePitch: api('post', makeUrl`/favorites/pitches/${'id'}`),
  defavoritePitch: api('delete', makeUrl`favorites/pitches/${'id'}`),
  getPitch: api('get', makeUrl`/${PREFIX}/pitches/${'pitchId'}`),
  getPitchList: api('get', makeUrl`/${PREFIX}/pitches`),
  createPitch: api('post', makeUrl`/${PREFIX}/pitches`),
  updatePitch: api('put', makeUrl`/${PREFIX}/pitches/${'id'}`),
  patchPitch: api('put', makeUrl`/${PREFIX}/pitches/${'id'}`),
  deletePitch: api('delete', makeUrl`/${PREFIX}/pitches/${'id'}`),
  pitchCopyToCourse: api('post', makeUrl`/${PREFIX}/pitches/${'id'}/copy-to-course`),
  pitchesCopyToCourse: api('post', `/pitches/copy-to-courses`),
  getPitchSlides: api('get', makeUrl`/${PREFIX}/pitches/${'pitchId'}/slides`),
  getPitchSlide: api('get', makeUrl`/${PREFIX}/pitches/${'pitchId'}/slides/${'slideId'}`),
  createPitchSlide: api('post', makeUrl`/${PREFIX}/pitches/${'pitchId'}/slides`),
  updatePitchSlide: api('put', makeUrl`/${PREFIX}/pitches/${'pitchId'}/slides/${'slideId'}`),
  deletePitchSlide: api('delete', makeUrl`/${PREFIX}/pitches/${'pitchId'}/slides/${'slideId'}`),
  getEvaluationCriterias: api('get', makeUrl`/slides/${'slideId'}/evaluation-criterias`),
  completePitchSlide: api('post', makeUrl`/pitches/${'pitchId'}/slides/${'slideId'}/complete-slide`),
  exportToScormPitch: api('post', makeUrl`/pitches/${'id'}/export/scorm`),
  resetPitchProgress: api('post', makeUrl`/pitches/${'id'}/reset-progress`),
  uploadPPT: api(
    'post',
    makeUrl`/pitches/${'id'}/import/ppt?importAsMaterialsToOneSlide=${'importAsMaterialsToOneSlide'}`,
  ),
  // teams
  createTeam: api('post', '/teams'),
  getTeams: api('get', '/teams'),
  getTeam: api('get', makeUrl`/teams/${'id'}`),
  updateTeam: api('put', makeUrl`/teams/${'id'}`),
  deleteTeam: api('delete', makeUrl`/teams/${'id'}`),
  detachCourses: api('patch', makeUrl`/teams/detach-courses`),
  attachCourses: api('patch', makeUrl`/teams/attach-courses`),
  detachCoursesFromTeam: api('patch', makeUrl`/teams/${'id'}/detach-courses`),
  attachCoursesToTeam: api('patch', makeUrl`/teams/${'id'}/attach-courses`),
  detachMembersFromTeam: api('patch', makeUrl`/teams/${'id'}/detach-members`),

  // files
  uploadImage: api('post', '/file-uploads/upload-image'),
  uploadFile: api('post', '/file-uploads/upload-file'),
  // content
  getContentFiles: api('get', '/content/files'),
  createFile: api('post', '/content/files'),
  updateFile: api('put', makeUrl`/content/files/${'id'}`),
  deleteFile: api('delete', makeUrl`/content/files/${'id'}`),
  //
  getContentFolders: api('get', '/content/folders'),
  createFolder: api('post', '/content/folders'),
  updateFolder: api('put', makeUrl`/content/folders/${'id'}`),
  deleteFolder: api('delete', makeUrl`/content/folders/${'id'}`),
  getFolder: api('get', makeUrl`content/folders/${'id'}`),
  //
  getContentLists: api('get', '/content/lists'),
  createList: api('post', '/content/lists'),
  updateList: api('put', makeUrl`/content/lists/${'id'}`),
  deleteList: api('delete', makeUrl`/content/lists/${'id'}`),
  // records
  getRecords: api('get', '/records'),
  getRecord: api('get', makeUrl`/records/${'id'}`),
  getWatsonAiToken: api('post', '/speech-recognition/generate-session-payload'),
  getRecordAnalyses: api('post', '/record-analyses/analise'),
  createRecord: api('post', '/records'),
  createComment: api('post', makeUrl`/records/${'id'}/record-comments`),
  getRecordComments: api('get', makeUrl`/records/${'id'}/record-comments`),
  shareRecord: api('post', makeUrl`/records/${'id'}/share`),
  deleteComment: api('delete', makeUrl`/records/${'recordId'}/record-comments/${'recordCommentId'}`),
  updateComment: api('put', makeUrl`/records/${'recordId'}/record-comments/${'recordCommentId'}`),
  createQuizAnswers: api('post', '/quiz/answers'),
  getRecordUserList: api('get', '/records/users'),
  getUserRecordPitches: api('get', makeUrl`/users/${'id'}/pitches`),

  // Notification
  getNotifications: api('get', '/notifications'),
  updateNotification: api('put', makeUrl`/notifications/${'id'}`),
  deleteOutdatedNotification: api('delete', '/notifications'),
  // reviews
  getReviews: api('get', '/reviews'),
  createReview: api('post', '/reviews'),
  updateReview: api('put', makeUrl`/reviews/${'id'}`),

  // Leaderboard
  getTeamsLeaderboard: api('get', '/leaderboards/teams-leaderboard'),
  getPitchLeaderboard: api('get', makeUrl`/pitches/${'id'}/leaderboard`),
  getMembersLeaderboard: api('get', makeUrl`${PREFIX}/leaderboards/members-leaderboard`),
  getWorkspaceLeaderboard: api('get', makeUrl`/${PREFIX}/leaderboards/workspaces-leaderboard`),

  //Facial analysis
  postFacialAnalysis: api('post', '/facial-analyses/analise-image'),

  // Quiz
  getQuizAnswers: api('get', makeUrl`/pitches/${'id'}/answers`),
  markQuizCorrectOrIncorrect: api('put', makeUrl`/quiz/answers/${'id'}/correct/${'status'}`),

  //companies
  getWorkspaces: api('get', '/company/workspaces'),
  getWorkspace: api('get', makeUrl`/company/workspaces/${'id'}`),
  patchWorkspace: api('patch', makeUrl`/company/workspaces/${'id'}`),
  deleteWorkspace: api('delete', makeUrl`/company/workspaces/${'id'}`),
  createWorkspace: api('post', makeUrl`/company/workspaces`),
  updateWorkspace: api('put', makeUrl`/company/workspaces/${'id'}`),
});
