import { createReducer } from '@reduxjs/toolkit';
import { Reducer } from 'redux';
import { EvaluationCriteriaType, EvaluationCriteriaLeaderboardItem } from 'types/slides';
//
import { getEvaluationCriterias, getEvaluationCriteriaLeaderboard } from './actions';

const initialState = {
  meta: {
    isLoading: false,
    isLoaded: false,
  },
  pagination: {
    page: 1,
    perPage: 10,
    pageCount: 0,
    total: 0,
  },
  resource: null as EvaluationCriteriaType | null,
  resources: [] as EvaluationCriteriaType[],
  criteriaLeaderboard: [] as EvaluationCriteriaLeaderboardItem[],
};

const reducer = createReducer(initialState, (builder) => {
  builder
    ////////////////////////////////////////////////////////////////////////////////////////////////

    .addCase(getEvaluationCriterias.pending, (draft) => {
      draft.meta.isLoading = true;
      draft.meta.isLoaded = false;
    })
    .addCase(getEvaluationCriterias.fulfilled, (draft, { payload }) => {
      draft.meta.isLoading = false;
      draft.meta.isLoaded = true;
      // TODO: this should split based on the slideId
      draft.resources = payload;
    })
    .addCase(getEvaluationCriterias.rejected, (draft) => {
      draft.meta.isLoading = false;
      draft.meta.isLoaded = false;
    })
    //
    .addCase(getEvaluationCriteriaLeaderboard.pending, (draft) => {
      draft.meta.isLoading = true;
      draft.meta.isLoaded = false;
    })
    .addCase(getEvaluationCriteriaLeaderboard.fulfilled, (draft, { payload }) => {
      draft.meta.isLoading = false;
      draft.meta.isLoaded = true;
      draft.criteriaLeaderboard = payload;
    })
    .addCase(getEvaluationCriteriaLeaderboard.rejected, (draft) => {
      draft.meta.isLoading = false;
      draft.meta.isLoaded = false;
    });
});

export const evaluationCriteriasReducer: Reducer<typeof initialState> = (draft = initialState, action) => {
  return reducer(draft, action);
};
