import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
// utils
import { backofficeRestClient } from 'portal/backoffice/lib/http';
import { Params } from 'lib/http';
import { createApiCall } from 'lib/http/utils/createApiCall';
// local
import { NewTeamPayload, TeamsResponse, TeamType } from 'types/teams';

export const fetchTeamsWorker = async <T>(requestPayload: T) => {
  const callApi = createApiCall<TeamsResponse>({
    api: !(requestPayload as Params)?.filters ? backofficeRestClient.getTeams : backofficeRestClient.getWorkspaceTeams,
  });

  const { data } = await callApi(requestPayload);

  return data;
};

export const createTeamAction = createAsyncThunk<NewTeamPayload, Params>(
  'teams/createTeam',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<NewTeamPayload>({
      api: backofficeRestClient.createTeam,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getTeamsAction = createAsyncThunk<TeamsResponse, Params | undefined>(
  'teams/getTeams',
  async (requestPayload, { rejectWithValue }) => {
    try {
      return await fetchTeamsWorker(requestPayload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const changeTeamsRequestParamsAction = createAction<{
  sort: Record<string, unknown>;
  filters: Record<string, unknown>;
}>('teams/changeTeamsParams');

export const getTeamAction = createAsyncThunk<TeamType, Params>(
  'teams/getTeam',
  async (requestPayload: Params, { rejectWithValue }) => {
    const callApi = createApiCall({
      api: backofficeRestClient.getTeam,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateTeamAction = createAsyncThunk<NewTeamPayload, Params>(
  'teams/updateTeam',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<NewTeamPayload>({
      api: backofficeRestClient.updateTeam,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const deleteTeamAction = createAsyncThunk<{ success: boolean }, Params>(
  'teams/deleteTeam',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<{ success: boolean }>({
      api: backofficeRestClient.deleteTeam,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const detachCoursesAction = createAsyncThunk<{ success: boolean }, Params>(
  'teams/detachCourses',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<{ success: boolean }>({
      api: backofficeRestClient.detachCourses,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const detachCoursesFromTeamAction = createAsyncThunk<{ success: boolean }, Params>(
  'teams/detachCoursesFromTeam',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<{ success: boolean }>({
      api: backofficeRestClient.detachCoursesFromTeam,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const attachCoursesAndTeamsAction = createAsyncThunk<{ success: boolean }, Params>(
  'teams/attachCoursesAndTeams',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<{ success: boolean }>({
      api: backofficeRestClient.attachCourses,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const attachCoursesToTeamAction = createAsyncThunk<{ success: boolean }, Params>(
  'teams/attachCoursesFromTeam',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<{ success: boolean }>({
      api: backofficeRestClient.attachCoursesToTeam,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const resetAtachDetachCoursesAction = createAction('teams/resetDetachCourses');
export const teamsSetIsStaleAction = createAction<boolean>('teams/setIsStale');
