import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { AppBar, Avatar, AppBarProps, Toolbar } from '@mui/material';
// utils
import { getUrlWithParams } from 'portal/backoffice/routes';
import { useIsMobile } from 'hooks/useIsMobile';
// domain
import { authUserAvatarSelector } from 'portal/backoffice/domain/auth/selectors';
import { currentRouteSelector } from 'portal/backoffice/domain/router/reducer';
// components
import { DynamicContentContainer } from 'components/layouts/common/dynamic-header';
import NotificationPopOver from 'components/notification';
// Local
import LanguagePicker from '../LanguagePicker';
// styles
import { useStyles } from './styles';
import { ReactComponent as NotificationsOutlined } from 'assets/icons/notifications_outlined.svg';
import { ReactComponent as ChevronLeft } from 'assets/icons/chevronLeft.svg';
import logo from 'assets/logo.svg';
import { ReactComponent as MenuIcon } from 'assets/icons/menu-dash.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

interface OwnProps {
  position?: AppBarProps['position'];
  onMenuClick?: () => void;
  isMenuOpen?: boolean;
}
const DynamicHeader: React.FC<OwnProps> = ({ position = 'relative', onMenuClick, isMenuOpen }) => {
  const params = useParams();
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const { title, parent } = useSelector(currentRouteSelector);
  const avatar = useSelector(authUserAvatarSelector);
  //
  const isMobile = useIsMobile();

  const DefaultToolbar = (
    <Toolbar className={classes.container}>
      <DynamicContentContainer>
        {/* This children will be overwritten when custom content is set */}
        <div className={classes.breadcrumbs}>
          {parent && parent.key !== 'home' && (
            <Link to={getUrlWithParams(parent!.key, params)} className={classes.back}>
              <ChevronLeft />
            </Link>
          )}
          <h4>{formatMessage({ id: title })}</h4>
        </div>
      </DynamicContentContainer>

      <div className={classes.menuContainer}>
        <LanguagePicker />
        <NotificationPopOver className={classes.notificationIcon} />
        <Avatar className={classes.avatar} src={avatar ?? undefined} alt="avatar" />
      </div>
    </Toolbar>
  );

  const MobileToolbar = (
    <Toolbar className={classes.container}>
      <div className={classes.mobileContent}>
        <img src={logo} alt="logo" className={classes.logo} />
      </div>
      <div className={classes.mobileMenuContainer}>
        <NotificationsOutlined className={classes.mobileNotifictionIcon} />
        {!isMenuOpen && <MenuIcon className={classes.mobileNotifictionIcon} onClick={onMenuClick} />}
        {isMenuOpen && <CloseIcon className={classes.mobileNotifictionIcon} onClick={onMenuClick} />}
      </div>
    </Toolbar>
  );

  return (
    <AppBar className={classes.root} position={position}>
      {isMobile ? MobileToolbar : DefaultToolbar}
    </AppBar>
  );
};

export default React.memo(DynamicHeader);
