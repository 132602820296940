import { createAsyncThunk } from '@reduxjs/toolkit';
// types
import {
  MembersLeaderboardResponse,
  TeamsLeaderboardResponse,
  WorkspacesLeaderboardResponse,
} from 'types/leaderboards';
// utils
import { configureWorkspacePrefixes, workspaceRestClient } from 'portal/workspace/lib/http';
import { Params } from 'lib/http';
import { createApiCall } from 'lib/http/utils/createApiCall';
// local

export const fetchMembersLeaderboard = async <T>(requestPayload: T) => {
  const callApi = createApiCall<MembersLeaderboardResponse>({
    api: workspaceRestClient.getMembersLeaderboard,
    configureUrlParams: configureWorkspacePrefixes,
  });

  const { data } = await callApi(requestPayload);

  return data;
};

export const getMembersLeaderboardAction = createAsyncThunk<MembersLeaderboardResponse, Params | undefined>(
  'leaderboards/getMembersLeaderboard',
  async (requestPayload, { rejectWithValue }) => {
    try {
      return await fetchMembersLeaderboard(requestPayload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

// Team Leaderboard
export const fetchTeamsLeaderboard = async <T>(requestPayload: T) => {
  const callApi = createApiCall<TeamsLeaderboardResponse>({
    api: workspaceRestClient.getTeamsLeaderboard,
  });

  const { data } = await callApi(requestPayload);

  return data;
};

export const getTeamsLeaderboardAction = createAsyncThunk<TeamsLeaderboardResponse, Params | undefined>(
  'leaderboards/getTeamsLeaderboard',
  async (requestPayload, { rejectWithValue }) => {
    try {
      return await fetchTeamsLeaderboard(requestPayload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

// Workspace Leaderboard
export const fetchWorkspacesLeaderboard = async <T>(requestPayload: T) => {
  const callApi = createApiCall<WorkspacesLeaderboardResponse>({
    api: workspaceRestClient.getWorkspaceLeaderboard,
    configureUrlParams: configureWorkspacePrefixes,
  });

  const { data } = await callApi(requestPayload);

  return data;
};
