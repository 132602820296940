import { createAsyncThunk } from '@reduxjs/toolkit';
// types
import { NotificationResponse } from 'types/notifications';
import { Notification } from 'types/notification';
// utils
import { Params } from 'lib/http';
import { createApiCall } from 'lib/http/utils/createApiCall';
import { backofficeRestClient } from 'portal/backoffice/lib/http';

export const fetchNotificationListWorker = async <T>(requestPayload: T) => {
  const callApi = createApiCall<NotificationResponse>({
    api: backofficeRestClient.getNotifications,
  });

  const { data } = await callApi(requestPayload);

  return data;
};

export const getUserNotifications = createAsyncThunk<NotificationResponse, Params | undefined>(
  'notification/getUserNotifications',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<NotificationResponse>({
      api: backofficeRestClient.getNotifications,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateNotificationAction = createAsyncThunk<Notification, Params | undefined>(
  'notification/updateNotification',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<Notification>({
      api: backofficeRestClient.updateNotification,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
