import { UserRoles } from 'types/users';

export type JwtContent = {
  sub: number;
  email: string;
  role: UserRoles;
  workspaceId?: number;
  companyId: number;
  iat: number;
  exp: number;
};

export const decodeJwt = (token: string): JwtContent => {
  //
  try {
    return JSON.parse(atob(token.split('.')[1])) as JwtContent;
  } catch (e) {
    return {} as JwtContent;
  }
};
