import { createReducer, Reducer } from '@reduxjs/toolkit';
import merge from 'lodash/merge';
// local
import { CompanyType, CompaniesSortConfigs } from 'types/companies';
import {
  getCompaniesAction,
  getCompanyAction,
  companiesSetIsStaleAction,
  changeCompaniesRequestParamsAction,
} from './actions';

const initialState = {
  meta: {
    isLoading: false,
    isLoaded: false,
    isStale: false,
  },
  filters: {} as Record<string, string>,
  sort: CompaniesSortConfigs.nameAsc,
  pagination: {
    page: 0,
    perPage: 10,
    pageCount: 0,
    total: 0,
  },
  resources: [] as CompanyType[],
  resource: {} as CompanyType,
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getCompaniesAction.pending, (draft, {}) => {
      //
      draft.meta.isLoading = true;
      draft.meta.isLoaded = false;
    })
    .addCase(getCompaniesAction.fulfilled, (draft, { payload }) => {
      const { data, ...pagination } = payload;

      draft.meta.isLoading = false;
      draft.meta.isLoaded = true;
      draft.meta.isStale = false;

      draft.resources = pagination.page === initialState.pagination.page ? data : [...draft.resources, ...data];

      draft.pagination = {
        ...draft.pagination,
        ...pagination,
      };
    })
    .addCase(getCompaniesAction.rejected, (draft) => {
      draft.meta.isLoading = false;
      draft.meta.isLoaded = false;
    })

    ////////////////////////////////////////////////////////////////////////////////////////////////
    .addCase(getCompanyAction.pending, (draft, {}) => {
      draft.meta.isLoading = true;
    })
    .addCase(getCompanyAction.fulfilled, (draft, { payload }) => {
      draft.meta.isLoading = false;

      draft.resource = payload;
    })
    .addCase(getCompanyAction.rejected, (draft) => {
      draft.meta.isLoading = false;
    })
    ////////////////////////////////////////////////////////////////////////////////////////////////
    .addCase(changeCompaniesRequestParamsAction, (draft, { payload }) => {
      draft.meta.isStale = true;
      draft.meta.isLoaded = false;
      draft.filters = merge(draft.filters, payload.filters);
      draft.sort = merge(draft.sort, payload.sort);
      draft.resources = initialState.resources;
      draft.pagination = initialState.pagination;
    })
    ////////////////////////////////////////////////////////////////////////////////////////////////
    .addCase(companiesSetIsStaleAction, (draft, { payload }) => {
      draft.meta.isStale = payload;
    });
});

export const companiesReducer: Reducer<typeof initialState> = (draft = initialState, action) => {
  return reducer(draft, action);
};
