import { UserRoles, UserType } from 'types/users';
import { AccessFlags, PermissionEnum, rolePermissions, unauthorizedPermissions } from './entities';

abstract class BaseAccessSchema {
  abstract get me(): UserType | undefined;

  get isCoachInWorkspaceAdminRole() {
    return false;
  }

  get permissions() {
    const currentUser = this.me;

    return currentUser ? rolePermissions(this.isCoachInWorkspaceAdminRole)[currentUser.role] : unauthorizedPermissions;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  get isBackofficeAdmin() {
    return this.me?.role === UserRoles.BackofficeAdmin;
  }

  get isBackofficeSuperAdmin() {
    return this.me?.role === UserRoles.BackofficeSuperAdmin;
  }

  get isWorkspaceEmployee() {
    return this.me?.role === UserRoles.WorkspaceEmployee;
  }

  get isCoach() {
    return this.me?.role === UserRoles.Coach;
  }

  get isWorkspaceAdmin() {
    return this.me?.role === UserRoles.WorkspaceAdmin;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////

  private getPermissionLevel = (permission: AccessFlags): PermissionEnum[] => {
    // Hidden if not declared
    return this.permissions[permission] || [];
  };

  private checkAccess = (permission: AccessFlags, requestedAccess: PermissionEnum): boolean => {
    // hidden / view / edit
    const fieldPermissions = this.getPermissionLevel(permission);

    return fieldPermissions.includes(requestedAccess);
  };

  canView = (permission: AccessFlags): boolean => {
    return this.checkAccess(permission, PermissionEnum.VIEW);
  };

  canUpdate = (permission: AccessFlags): boolean => {
    return this.checkAccess(permission, PermissionEnum.UPDATE);
  };
  canDelete = (permission: AccessFlags): boolean => {
    return this.checkAccess(permission, PermissionEnum.DELETE);
  };

  canCreateScorm = (permission: AccessFlags): boolean => {
    return this.checkAccess(permission, PermissionEnum.CREATE_SCORM);
  };
}

export default BaseAccessSchema;
