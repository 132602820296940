import { createReducer } from '@reduxjs/toolkit';
import { Reducer } from 'redux';

import {
  createCourseAction,
  deleteCourseAction,
  getCourseAction,
  getTagListAction,
  updateCourseAction,
  updateSelectedCoursesAction,
  resetSelectedCoursesAction,
  coursesSetIsStaleAction,
  resetCourseAction,
  favoriteCourseAction,
  defavoriteCourseAction,
  getCoursesAction,
} from './actions';
import { CourseType, TagType } from 'types/courses';
import { CoursesSortConfigs } from 'types/courses/entities';

const initialState = {
  meta: {
    isLoading: false,
    isLoaded: false,
    //
    isStale: false,
    //
    isTagsLoading: false,
    isTagsLoaded: false,
    global: {
      isLoading: false,
      isLoaded: false,
    },
  },
  filters: {} as Record<string, string>,
  sort: CoursesSortConfigs.courseNameAsc,
  pagination: {
    page: 0,
    perPage: 10,
    pageCount: 0,
    total: 0,
  },
  resource: null as CourseType | null,
  resources: [] as CourseType[],
  tags: {
    // TODO get backend answer about pagination for tags
    data: [] as TagType[],
  },
  selectedCourses: {} as Record<string, number[]>,
};

const reducer = createReducer(initialState, (builder) => {
  builder //
    ////////////////////////////////////////////////////////////////////////////////////////////////
    .addCase(getCoursesAction.pending, (draft) => {
      draft.meta.isLoading = true;
      draft.meta.isLoaded = false;
    })
    .addCase(getCoursesAction.fulfilled, (draft, { payload }) => {
      draft.meta.isLoading = false;
      draft.meta.isLoaded = true;
      const { data, ...pagination } = payload;
      draft.resources = pagination.page === 1 ? data : [...draft.resources, ...data];

      draft.pagination = {
        ...draft.pagination,
        ...pagination,
      };
    })
    .addCase(getCoursesAction.rejected, (draft) => {
      draft.meta.isLoading = false;
      draft.meta.isLoaded = false;
    })
    ////////////////////////////////////////////////////////////////////////////////////////////////
    .addCase(getCourseAction.pending, (draft) => {
      draft.meta.isLoading = true;
      draft.meta.isLoaded = false;
    })
    .addCase(getCourseAction.fulfilled, (draft, { payload }) => {
      draft.meta.isLoading = false;
      draft.meta.isLoaded = true;

      draft.resource = payload;
    })
    .addCase(getCourseAction.rejected, (draft) => {
      draft.meta.isLoading = false;
      draft.meta.isLoaded = false;
      draft.resource = null;
    })
    ////////////////////////////////////////////////////////////////////////////////////////////////
    .addCase(createCourseAction.fulfilled, (draft) => {
      draft.meta.isStale = true;
    })
    ////////////////////////////////////////////////////////////////////////////////////////////////
    .addCase(updateCourseAction.fulfilled, (draft) => {
      draft.meta.isStale = true;
    })
    ////////////////////////////////////////////////////////////////////////////////////////////////
    .addCase(deleteCourseAction.fulfilled, (draft) => {
      draft.meta.isStale = true;
    })
    ////////////////////////////////////////////////////////////////////////////////////////////////
    .addCase(getTagListAction.pending, (draft) => {
      draft.meta.isTagsLoading = true;
      draft.meta.isTagsLoaded = false;
    })
    .addCase(getTagListAction.fulfilled, (draft, { payload }) => {
      draft.meta.isTagsLoading = false;
      draft.meta.isTagsLoaded = true;

      draft.tags = payload;
    })
    .addCase(getTagListAction.rejected, (draft) => {
      draft.meta.isTagsLoading = false;
      draft.meta.isTagsLoaded = false;
    })
    ////////////////////////////////////////////////////////////////////////////////////////////////
    .addCase(updateSelectedCoursesAction, (draft, { payload }) => {
      const { teamId, courseId, action } = payload;
      if (!draft.selectedCourses[teamId]) {
        draft.selectedCourses[teamId] = [];
      }
      switch (action) {
        case 'add':
          draft.selectedCourses[teamId].push(courseId);
          break;
        case 'delete':
          draft.selectedCourses[teamId] = draft.selectedCourses[teamId].filter((id) => id !== courseId);
          break;
        default:
          break;
      }
    })
    .addCase(favoriteCourseAction.pending, (draft) => {
      draft.meta.isLoading = true;
      draft.meta.isLoaded = false;
    })
    .addCase(favoriteCourseAction.fulfilled, (draft) => {
      draft.meta.isLoading = false;
      draft.meta.isLoaded = true;
    })
    .addCase(defavoriteCourseAction.pending, (draft) => {
      draft.meta.isLoading = true;
      draft.meta.isLoaded = false;
    })
    .addCase(defavoriteCourseAction.fulfilled, (draft) => {
      draft.meta.isLoading = false;
      draft.meta.isLoaded = true;
    })
    ////////////////////////////////////////////////////////////////////////////////////////////////
    .addCase(resetSelectedCoursesAction, (draft, { payload }) => {
      draft.selectedCourses[payload.teamId!] = [];
    })
    .addCase(coursesSetIsStaleAction, (draft, { payload }) => {
      draft.meta.isStale = payload;
    })
    .addCase(resetCourseAction, (draft) => {
      draft.resource = null;
    });
});

export const coursesReducer: Reducer<typeof initialState> = (draft = initialState, action) => {
  return reducer(draft, action);
};
