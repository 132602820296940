import React from 'react';
import { RouteComponentProps, RouteProps } from 'react-router';
// utils
import { AccessFlags } from 'lib/acl/entities';

// local
export interface Route extends RouteProps {
  key: string;
  path: string;
  exact?: boolean;
  title: string;
  component: React.ComponentType<RouteComponentProps>;
  Layout?: React.ComponentType;
  routes?: Route[];
  isDefault?: boolean;
  restrictions?: AccessFlags[];
}

export interface ChildRoute extends Route {
  parent?: Route;
  routes?: ChildRoute[];
}

// not meant for rendering page but for subpage routing purposes
export interface AbstractRoute extends Omit<Route, 'component' | 'routes'> {
  component?: Route['component'];
  routes?: AbstractRoute[];
}

export interface AbstractChildRoute extends AbstractRoute {
  parent?: AbstractRoute;
  routes?: AbstractChildRoute[];
}

export type GenericRoute = Route | AbstractRoute;
export type GenericChildRoute = ChildRoute | AbstractChildRoute;

export * from './utils';
