import { ResourcesResponse } from 'lib/http';
export type DomainType = {
  id: number;
  companyId: number;
  domainName: string;
  cover: string | null;
  logo: string | null;
  loginTitle?: string;
  loginDescription?: string;
  primaryColor: string;
  createdAt: string;
  updatedAt: string;
};

export const DomainWrongSubdomains = [
  'dev',
  'release',
  'preprod',
  'prod',
  'admin',
  'cdn',
  'app',
  'backoffice',
  'www',
  'api',
  'oauth',
  'mail',
  'ftp',
  'smtp',
  'coach',
];

export type DomainListResponse = ResourcesResponse<DomainType>;

export type DomainPayloadType = {
  domainName: string;
  cover: Blob | string | null;
  logo: Blob | string | null;
  primaryColor: string;
};
