import React from 'react';
//
import { AppBar, Dialog, DialogProps, Fade, IconButton, Toolbar, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
//
import { Loader } from 'components/loader';
//
import { makeStyles } from '@mui/styles';
import cx from 'clsx';
import { GrClose } from 'react-icons/gr';
import { useIsMobile } from 'hooks/useIsMobile';
import { useIntl } from 'react-intl';
import { IntlKeys } from 'localization';

interface OwnProps extends Omit<DialogProps, 'open' | 'classes' | 'title'> {
  isOpen?: boolean;
  isLoading?: boolean;
  title?: React.ReactNode;
  //
  handleClose?: (e: React.MouseEvent) => void;

  className?: string;
  classes?: Partial<Record<'dialog' | 'topBar' | 'topBarTitle' | 'bottomBar' | 'content', string>>;
  //
  children: React.ReactNode;
  bottomBarContent?: React.ReactNode;
  disableTopBarCloseButton?: boolean;
  disableTitle?: boolean;
}

const ModalDialog: React.FC<OwnProps> = ({
  //
  isOpen = false,
  isLoading = false,
  title = '',
  //
  handleClose = () => {},
  //
  className,
  classes = {},
  //
  children,
  bottomBarContent,
  disableTopBarCloseButton,
  disableTitle = false,
  ...dialogProps
}) => {
  const localClasses = useStyles();
  const isMobile = useIsMobile();
  const { formatMessage } = useIntl();

  return (
    <Dialog
      //
      open={isOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
      classes={{
        paper: cx(localClasses.dialog, classes.dialog, className),
      }}
      maxWidth="lg"
      {...dialogProps}
      disablePortal={true}
    >
      {!disableTitle && (
        <AppBar className={cx(localClasses.appBar, localClasses.topAppBar)} position="sticky">
          <Toolbar className={cx(localClasses.topBar, classes.topBar)}>
            <Typography
              sx={{ ml: 2, flex: 1 }}
              variant="h6"
              component="div"
              className={cx(classes.topBarTitle, localClasses.topBarTitle)}
            >
              {title}
            </Typography>

            {!disableTopBarCloseButton && !isMobile && (
              <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
                <GrClose />
              </IconButton>
            )}
            {!disableTopBarCloseButton && isMobile && (
              <Typography color="primary" onClick={handleClose} aria-label="close">
                {formatMessage({ id: IntlKeys.cancel })}
              </Typography>
            )}
          </Toolbar>
        </AppBar>
      )}

      <div className={cx(localClasses.content, classes.content)}>{children}</div>

      {bottomBarContent && (
        <AppBar className={cx(localClasses.appBar, localClasses.bottomAppBar)} position="sticky">
          <Toolbar className={cx(localClasses.bottomBar, classes.bottomBar)}>{bottomBarContent}</Toolbar>
        </AppBar>
      )}

      {isLoading && <Loader filled={false} className={localClasses.loader} />}
    </Dialog>
  );
};

const Transition: React.FC<
  TransitionProps & {
    children: React.ReactElement;
  }
> = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  dialog: {
    display: 'block',
    [theme.breakpoints.down('md')]: {
      margin: 0,
      width: '100%',
      height: '100%',
      maxHeight: '100%',
      maxWidth: '100%',
    },
  },
  appBar: {
    //
    background: 'white',
    boxShadow: 'none',
  },
  topAppBar: {
    top: 0,
    bottom: 'auto',
  },
  topBar: {
    borderBottom: `1px solid ${theme.colors.stroke}`,
  },
  topBarTitle: {
    fontSize: '24px',
    fontFamily: theme.fonts.gilmer,
    color: theme.colors.oxfordBlue,
    fontWeight: 600,
    lineHeight: '32px',
    alignItems: 'start',
    [theme.breakpoints.down('md')]: {
      textAlign: 'left !important',
      marginLeft: '0 !important',
      fontWeight: 'normal !important',
    },
  },
  bottomAppBar: {
    bottom: 0,
    top: 'auto',
  },
  content: {
    [theme.breakpoints.down('md')]: {
      '& h6': {
        marginTop: '.5rem',
        marginBottom: '2rem',
      },
      '& img': {
        width: '100%',
      },
      minHeight: 'calc(100% - 150px)',
    },
  },
  bottomBar: {
    display: 'flex',
    justifyContent: 'flex-end',
    borderTop: `1px solid ${theme.colors.stroke}`,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      padding: '1rem',
      '& button': {
        width: '100%',
        marginBottom: '.5rem',
        maxWidth: '100%',
      },
    },
  },
  loader: {
    zIndex: 1255,
  },
}));

export default React.memo(ModalDialog);
