import { DeepPartial } from '@reduxjs/toolkit';
import { merge, uniqueId } from 'lodash';
// types
import {
  SlideConditionTypeEnum,
  SlideType,
  SlideConditionType,
  SlideTaskQuizTypeEnum,
  QuizType,
  HiddenObjectiveType,
  PlaybookReactionType,
  PlaybookType,
  SlideMaterialType,
} from 'types/slides';
import {
  HiddenObjectivePayloadType,
  PlaybookPayloadType,
  PlaybookReactionPayloadType,
  SlideConditionPayloadType,
  SlidePayloadType,
  SlideMaterialPayloadType,
  BackgroundMaterialPayloadType,
} from 'types/slides/contract';
import { serializePitchSlide } from 'types/slides/serializers';
// utils
import { ApiCall } from 'lib/http/utils';
import { FileUploader } from 'lib/uploadFile';
import { ApiFetcherPayloadBuilder, createApiCall } from 'lib/http/utils/createApiCall';
import { moveElementInArray } from 'utils/arrays';
import { isStringArray } from 'utils/typeguards';
import { IntlKeys } from 'localization';
//

const tempSlideIdPrefix = 'tempSlide_';
export const createSlideTempId = () => `${tempSlideIdPrefix}${uniqueId()}` as unknown as number;
export const createTemporarySlide = (partialSlide: Partial<SlideType> = {}): SlideType => {
  //
  const slideId = createSlideTempId();
  return {
    id: slideId,
    pitchId: -1,
    type: null,
    taskType: null,
    infoType: null,
    title: 'Slide title',
    position: 1,
    status: 'draft',
    condition: {
      type: SlideConditionTypeEnum.Text,
      headline: 'Condition headline',
      description: '',
      link: null,
      fileId: null,
    },
    quiz: {
      tip: '',
      title: '',
      description: '',
      taskAnswer: {
        correctAnswers: [], // MultipleChoice, PictureChoice
        pairs: [],
      },
      type: SlideTaskQuizTypeEnum.PictureChoice,
      task: {
        answers: [],
        multiple: true,
        randomize: true,
        other: true,
        min: 0,
        max: 0,
        step: 0,
        answer: 'yes',
        questions: [],
      },
      id: 0,
      slideId: slideId,
    },
    hiddenObjectiveEnabled: false,
    hiddenObjectives: [],
    playbookEnabled: false,
    playbooks: [],
    aiDoWordsEnabled: false,
    aiDontWordsEnabled: false,
    doWords: [],
    dontWords: [],
    timerEnabled: partialSlide.timer !== null, // computed field
    timer: null,
    evaluationCriteriasEnabled: false,
    evaluationCriterias: [],
    materials: [],
    backgroundMaterial: null,
    createdAt: '2021-12-15T13:21:55.175Z',
    updatedAt: '2021-12-15T13:21:55.175Z',
    //
    ...partialSlide,
  };
};

export const createEmptyQuiz = (partialQuizData?: DeepPartial<QuizType>): QuizType => {
  const quizType = partialQuizData?.type || SlideTaskQuizTypeEnum.YesOrNo;
  //
  const baseQuiz = {
    title: 'Quiz',
    description: '',
    tip: null,
    type: SlideTaskQuizTypeEnum.YesOrNo,

    task: {
      multiple: null, // MultipleChoice and PictureChoice Type
      other: null, // MultipleChoice and PictureChoice Type
      randomize: null, // MultipleChoice and PictureChoice Type
      min: null, // Scale and ShortText Type
      max: null, // Scale and ShortText Type
      step: null, // only Scale Type
      answer: 'yes', // YesNo Type
      answers: [], // MatchingList, MultipleChoice, PictureChoice
      questions: [], // MatchingList
    },
    taskAnswer: {
      correctAnswers: [], // MultipleChoice, PictureChoice
      pairs: [], // MatchingList
    },
  };

  const emptyQuizOfType = {
    [SlideTaskQuizTypeEnum.MultipleChoice]: createEmptyMultiChoiceQuiz,
    [SlideTaskQuizTypeEnum.PictureChoice]: createEmptyPictureChoiceQuiz,
    [SlideTaskQuizTypeEnum.MatchingList]: createEmptyMatchingListQuiz,
    [SlideTaskQuizTypeEnum.ScaleChoice]: createEmptyScaleChoiceQuiz,
    [SlideTaskQuizTypeEnum.ShortText]: createEmptyShortTextQuiz,
    [SlideTaskQuizTypeEnum.YesOrNo]: createEmptyYesNoQuiz,
  }[quizType]();

  return merge(baseQuiz, emptyQuizOfType, partialQuizData);
};

export const createEmptyMultiChoiceQuiz = (): DeepPartial<QuizType> => ({
  title: 'Multiple Choice Quiz',
  type: SlideTaskQuizTypeEnum.MultipleChoice,
  task: {
    multiple: false,
    other: false,
    randomize: false,
    answers: [],
  },
  taskAnswer: {
    correctAnswers: [],
  },
});

export const createEmptyPictureChoiceQuiz = (): DeepPartial<QuizType> => ({
  title: 'Picture Choice Quiz',
  type: SlideTaskQuizTypeEnum.PictureChoice,
  task: {
    multiple: false,
    other: false,
    randomize: false,
    answers: [],
  },
  taskAnswer: {
    correctAnswers: [],
  },
});

export const createEmptyMatchingListQuiz = (): DeepPartial<QuizType> => ({
  title: 'Matching List Quiz',
  type: SlideTaskQuizTypeEnum.MatchingList,
  task: {
    answers: [],
    questions: [],
  },
  taskAnswer: {
    pairs: [],
  },
});

export const createEmptyScaleChoiceQuiz = (): DeepPartial<QuizType> => ({
  title: 'Scale Choice Quiz',
  type: SlideTaskQuizTypeEnum.ScaleChoice,
  task: {
    min: 0,
    max: 10,
    step: 1,
  },
  taskAnswer: {},
});

export const createEmptyShortTextQuiz = (): DeepPartial<QuizType> => ({
  title: 'Short Text Quiz',
  type: SlideTaskQuizTypeEnum.ShortText,
  task: {
    min: 1,
    max: 255,
  },
  taskAnswer: {},
});

export const createEmptyYesNoQuiz = (): DeepPartial<QuizType> => ({
  title: 'Yes or No Quiz',
  type: SlideTaskQuizTypeEnum.YesOrNo,
  task: {
    answer: 'yes',
  },
  taskAnswer: {},
});

export const isTemporarySlide = (slide: SlideType | SlidePayloadType) => {
  return new RegExp(tempSlideIdPrefix).test(slide.id as unknown as string);
};

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

type SlidePositionModel = Pick<SlideType, 'id' | 'position'>;
export const updateSlideWithOrder = <T extends SlidePositionModel>(slides: T[], slide: T): T[] => {
  // position starts from 1
  // first find previous position for reordering
  const prevPosition = slides.find(({ id }) => id === slide.id)?.position;
  // update
  const tempSlides = slides.map((_slide) => (_slide.id === slide.id ? slide : _slide));

  // and rearrange positions
  let newArr: T[];

  if (prevPosition) {
    newArr = moveElementInArray(tempSlides, prevPosition - 1, slide.position - 1);
  } else {
    tempSlides.splice(slide.position - 1, 0, slide);
    newArr = tempSlides;
  }

  return alignSlidesPositions(newArr);
};

export const alignSlidesPositions = <T extends SlidePositionModel>(slides: T[]) => {
  return slides.map((slide, ix) => ({
    ...slide,
    position: ix + 1,
  }));
};

export const deleteSlideAtPosition = <T extends SlidePositionModel>(slides: Array<T>, pos: number): T[] => {
  const filtered = slides.filter(({ position }) => pos !== position);

  return alignSlidesPositions(filtered);
};

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export type SlideWorkerPayloadType = (SlidePayloadType | SlideType) & { pitchId: number };

type SlideModifyWorkerParams = {
  slideApi: ApiCall;
  requestPayload: SlideWorkerPayloadType;
  configurePrefixes?: ApiFetcherPayloadBuilder<SlideType>;
};

export const bindPitchSlideUtils = ({ uploadFile }: { uploadFile: FileUploader }) => {
  const processHiddenObjectives = async (hiddenObjectives: HiddenObjectivePayloadType[]) => {
    const unprocessedFilesPresent = hiddenObjectives.filter(({ file }) => Boolean(file)).length > 0;

    return unprocessedFilesPresent
      ? await Promise.all<HiddenObjectiveType>(
          hiddenObjectives.map((_objective) => {
            const { file, ...objective } = _objective;

            return file
              ? new Promise(async (resolve) => {
                  const fileUrl = await uploadFile({
                    name: file.name,
                    file,
                    relatedEntity: {
                      entityIntlKey: IntlKeys.pitchesObjectives,
                      ...objective,
                    },
                  });

                  resolve({
                    ...objective,
                    content: fileUrl,
                  });
                })
              : Promise.resolve(objective);
          }),
        )
      : hiddenObjectives;
  };

  const processReactions = async (reactions: PlaybookReactionPayloadType[]) => {
    const unprocessedFilesPresent = reactions.some(({ linkFile }) => Boolean(linkFile));

    return unprocessedFilesPresent
      ? await Promise.all<PlaybookReactionType>(
          reactions.map(({ linkFile, linkFileObjectUrl, ...reaction }) => {
            //
            return linkFile
              ? new Promise(async (resolve) => {
                  const fileUrl = await uploadFile({
                    name: linkFile.name,
                    file: linkFile,
                  });

                  resolve({
                    ...reaction,
                    link: fileUrl,
                  });
                })
              : Promise.resolve(reaction);
          }),
        )
      : reactions;
  };

  const processPlaybooks = async (playbooks: PlaybookPayloadType[]) => {
    //
    return await Promise.all<PlaybookType>(
      playbooks.map(async ({ reactions, ...playbook }) => {
        const resolvedReactions = await processReactions(reactions);

        return {
          ...playbook,
          reactions: resolvedReactions,
        };
      }),
    );
  };

  const processCondition = async (maybeCondition: SlideConditionPayloadType | null) => {
    if (!maybeCondition) return null;

    const { file, ...condition } = maybeCondition;

    //
    return file
      ? await new Promise<SlideConditionType>(async (resolve) => {
          const fileUrl = await uploadFile({
            name: file.name,
            file: file,
          });

          const resolveValue: SlideConditionType = {
            ...condition,
            link: fileUrl || null,
          };

          resolve(resolveValue);
        })
      : condition;
  };

  const processMaterials = async (materials: SlideMaterialPayloadType[]) => {
    //
    return await Promise.all(
      materials.map(async ({ file, ...material }) => {
        return file
          ? new Promise<SlideMaterialType>(async (resolve) => {
              const fileUrl = await uploadFile({
                name: file.name,
                file: file,
              });

              resolve({
                ...material,
                content: fileUrl,
              });
            })
          : Promise.resolve(material);
      }),
    );
  };

  const processBackgroundMedia = async (backgroundMaterial: BackgroundMaterialPayloadType | null) => {
    if (!backgroundMaterial) return null;

    const { fileObj, ...media } = backgroundMaterial;
    //
    return fileObj
      ? await new Promise<BackgroundMaterialPayloadType>(async (resolve) => {
          const fileUrl = await uploadFile({
            name: fileObj.name,
            file: fileObj,
          });

          const resolveValue: BackgroundMaterialPayloadType = {
            ...media,
            file: fileUrl || null,
          };

          resolve(resolveValue);
        })
      : backgroundMaterial.file
      ? backgroundMaterial
      : null;
  };

  const processQuiz = async (quiz: SlideWorkerPayloadType['quiz']): Promise<QuizType | null> => {
    if (quiz == null) return Promise.resolve(null);

    type LocalQuizType = NonNullable<SlideWorkerPayloadType['quiz']>;

    const processQuizAnswers = async (answers: LocalQuizType['task']['answers']) => {
      if (answers.length === 0 || isStringArray(answers)) {
        // no files here
        return Promise.resolve(answers as string[]);
      }

      const unprocessedFilesPresent = answers.some(({ file }) => Boolean(file));

      return unprocessedFilesPresent
        ? await Promise.all<string>(
            answers.map(async ({ value, file }) => {
              return file
                ? await uploadFile({
                    name: file.name,
                    file: file,
                  })
                : Promise.resolve(value);
            }),
          )
        : answers.map(({ value }) => value);
    };

    return merge(
      {},
      quiz as QuizType,
      quiz.task && {
        task: {
          answers: await processQuizAnswers(quiz.task.answers),
        },
      },
    );
  };

  const modifySlide = async ({ slideApi, requestPayload, configurePrefixes }: SlideModifyWorkerParams) => {
    //
    const { pitchId, hiddenObjectives, playbooks, condition, materials, quiz, backgroundMaterial, ...slideData } =
      requestPayload;

    const callApi = createApiCall<SlideType>({
      api: slideApi,
      configureUrlParams: configurePrefixes,
    });

    const [
      //
      processedHiddenObjectives,
      processedPlaybooks,
      processedCondition,
      processedMaterials,
      processedBackgroundMaterial,
      processedQuiz,
    ] = await Promise.all([
      processHiddenObjectives(hiddenObjectives),
      processPlaybooks(playbooks),
      processCondition(condition),
      processMaterials(materials),
      processBackgroundMedia(backgroundMaterial),
      processQuiz(quiz),
    ]);

    const slide: SlideType = {
      ...slideData,
      pitchId,
      hiddenObjectives: processedHiddenObjectives,
      playbooks: processedPlaybooks,
      condition: processedCondition,
      materials: processedMaterials,
      backgroundMaterial: processedBackgroundMaterial,
      quiz: processedQuiz,
    };

    const { data } = await callApi({
      ...slide,
      //
      params: {
        slideId: slideData.id,
        pitchId,
      },
    });

    return serializePitchSlide(data);
  };

  return {
    processHiddenObjectives,
    processPlaybooks,
    processCondition,
    processMaterials,
    //
    modifySlide,
  };
};
