import { createAsyncThunk } from '@reduxjs/toolkit';
//
import { NotificationResponse } from 'types/notifications';
import { Notification } from 'types/notification';
//
import { Params } from 'lib/http';
import { createApiCall } from 'lib/http/utils/createApiCall';
import { workspaceRestClient } from 'portal/workspace/lib/http';
import { authUserSelector } from '../auth/selectors';
import { store } from '../store';

export const fetchNotificationListWorker = async <T>(requestPayload: T) => {
  const callApi = createApiCall<NotificationResponse>({
    api: workspaceRestClient.getNotifications,
  });

  const { data } = await callApi(requestPayload);

  return data;
};

export const getUserNotifications = createAsyncThunk<NotificationResponse, Params | undefined>(
  'notification/getUserNotifications',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<NotificationResponse>({
      api: workspaceRestClient.getNotifications,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateNotificationAction = createAsyncThunk<Notification, Params | undefined>(
  'notification/updateNotification',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<Notification>({
      api: workspaceRestClient.updateNotification,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const deleteOutdatedNotificationsAction = createAsyncThunk<NotificationResponse, Params>(
  'notificaion/deleteOutdated',
  async (requestPayload, { rejectWithValue }) => {
    const state = store.getState();
    const user = authUserSelector(state);
    const callApi = createApiCall<void>({
      api: workspaceRestClient.deleteOutdatedNotification,
    });
    const callFetchApi = createApiCall<NotificationResponse>({
      api: workspaceRestClient.getNotifications,
    });

    try {
      await callApi(requestPayload);
      const { data } = await callFetchApi({
        'filters[userId]': user?.id,
        'sort[order]': 'DESC',
      });
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
