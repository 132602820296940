import { createAsyncThunk } from '@reduxjs/toolkit';
// types
import { EvaluationCriteriaType, EvaluationCriteriaLeaderboardItem } from 'types/slides';
// utils
import { workspaceRestClient } from 'portal/workspace/lib/http';
import { createApiCall } from 'lib/http/utils/createApiCall';
// theme
import { colors } from 'theme';

export const getEvaluationCriterias = createAsyncThunk<EvaluationCriteriaType[], { slideId: number }>(
  'pitches/getEvaluationCriterias',
  async (requestPayload, { rejectWithValue }) => {
    const { slideId } = requestPayload;

    const callApi = createApiCall<EvaluationCriteriaType[]>({
      api: workspaceRestClient.getEvaluationCriterias,
    });

    try {
      const { data } = await callApi({
        params: {
          slideId,
        },
      });

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getEvaluationCriteriaLeaderboard = createAsyncThunk<
  EvaluationCriteriaLeaderboardItem[],
  { pitchId: string | number }
>('pitches/getPitchLeaderboard', async (requestPayload, { rejectWithValue }) => {
  const { pitchId } = requestPayload;

  const callApi = createApiCall<EvaluationCriteriaLeaderboardItem[]>({
    api: workspaceRestClient.getPitchLeaderboard,
  });

  try {
    const { data } = await callApi({ params: { id: pitchId } });

    const criteriaColors = [
      '#6A14F0',
      '#0035FD',
      '#00CC77',
      '#1D7984',
      '#129AFE',
      '#FFBB11',
      '#432FD3',
      '#1C5E17',
      colors.red,
      colors.primary,
      colors.warning,
      colors.success,
      colors.oxfordBlue,
    ];

    return data.map((item, idx) => ({
      ...item,
      color: criteriaColors[idx] || colors.randomColor,
    }));
  } catch (err) {
    return rejectWithValue(err);
  }
});
