import { Reducer } from 'redux';
import { createReducer } from '@reduxjs/toolkit';
//
import { SortConfigType } from 'types/common';
import { ContentFileType } from 'types/content/files';
//
import { getFilesAction, createFileAction, updateFileAction, deleteFileAction } from './actions';

const initialState = {
  meta: {
    isLoading: false,
  },
  pagination: {
    page: 1,
    perPage: 8,
    pageCount: 0,
    total: 0,
  },
  sort: {
    field: 'updatedAt',
    order: 'ASC',
  } as SortConfigType,
  resources: [] as ContentFileType[],
};

const reducer = createReducer(initialState, (builder) => {
  builder
    // getFilesAction
    .addCase(getFilesAction.pending, (draft) => {
      draft.meta.isLoading = true;
    })
    .addCase(getFilesAction.fulfilled, (draft, { payload }) => {
      const { data, ...pagination } = payload;
      draft.resources = data;
      draft.pagination = pagination;
      draft.meta.isLoading = false;
    })
    // createFileAction
    .addCase(createFileAction.pending, (draft) => {
      draft.meta.isLoading = true;
    })
    .addCase(createFileAction.fulfilled, (draft, { payload }) => {
      draft.meta.isLoading = false;
      draft.resources = [payload, ...draft.resources];
    })
    // updateFileAction
    .addCase(updateFileAction.pending, (draft) => {
      draft.meta.isLoading = true;
    })
    .addCase(updateFileAction.fulfilled, (draft, { payload }) => {
      draft.resources = draft.resources.map((item) => (item.id === payload.id ? payload : item));
      draft.meta.isLoading = false;
    })
    // deleteFileAction
    .addCase(deleteFileAction.pending, (draft) => {
      draft.meta.isLoading = true;
    })
    .addCase(deleteFileAction.fulfilled, (draft, { payload }) => {
      draft.meta.isLoading = false;
      draft.resources = draft.resources.filter((item) => item.id !== payload);
    });
});

export const filesReducer: Reducer<typeof initialState> = (draft = initialState, action) => {
  return reducer(draft, action);
};
