import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
//
import { uploadImage } from 'portal/workspace/lib/uploadFile';
import { createApiCall } from 'lib/http/utils/createApiCall';
import { configureWorkspacePrefixes, workspaceRestClient } from 'portal/workspace/lib/http';
import { Params } from 'lib/http';
//
import { PitchType } from 'types/pitches';
import { PitchListResponse, NewPitchPayload, FaceAnalysisResponse } from './entities';
//
import { sortBy } from 'lodash';
import { saveFile } from 'lib/saveFile';
import { FavoriteType } from 'types/courses';
import axios from 'axios';
import revalidateWorkspaceToken from 'portal/workspace/lib/http/revalidateWorkspaceToken';
import { injectAuthHeaders } from 'lib/http/utils/injectAuthHeaders';
import { UserType } from 'types/users';
import { SlideType } from 'types/slides';

export const fetchPitchListWorker = async <T>(requestPayload: T) => {
  const callApi = createApiCall<PitchListResponse>({
    api: workspaceRestClient.getPitchList,
    configureUrlParams: configureWorkspacePrefixes,
  });

  const { data } = await callApi(requestPayload);

  return data;
};

export const createPitchAction = createAsyncThunk<PitchType, Params>(
  'pitches/createPitch',
  async (requestPayload, { rejectWithValue }) => {
    const { cover, ...rest } = requestPayload as NewPitchPayload;

    const callApi = createApiCall<PitchType>({
      api: workspaceRestClient.createPitch,
      configureUrlParams: configureWorkspacePrefixes,
    });

    try {
      const coverUrl = await uploadImage({
        image: cover,
        name: 'PitchCover',
      });

      const { data } = await callApi({
        ...rest,
        cover: coverUrl,
      });

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updatePitchAction = createAsyncThunk<PitchType, Params>(
  'pitches/updatePitch',
  async (requestPayload, { rejectWithValue }) => {
    const { cover, ...rest } = requestPayload as NewPitchPayload;

    const callApi = createApiCall<PitchType>({
      api: workspaceRestClient.updatePitch,
      configureUrlParams: configureWorkspacePrefixes,
    });

    try {
      const coverUrl = await uploadImage({
        image: cover,
        name: 'PitchCover',
      });

      const { data } = await callApi({
        ...rest,
        cover: coverUrl,
      });

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const patchPitchAction = createAsyncThunk<PitchType, Params>(
  'pitches/patchPitch',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<PitchType>({
      api: workspaceRestClient.patchPitch,
      configureUrlParams: configureWorkspacePrefixes,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const deletePitchAction = createAsyncThunk<{ success: boolean }, Params>(
  'pitches/deletePitch',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<{ success: boolean }>({
      api: workspaceRestClient.deletePitch,
      configureUrlParams: configureWorkspacePrefixes,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const exportPitchToScormAction = createAsyncThunk<Blob, Params<{ id: number }>>(
  'pitches/exportToScorm',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<Blob>({
      api: workspaceRestClient.exportToScormPitch,
    });

    try {
      const { data } = await callApi(requestPayload);
      saveFile(data, `SCORM_Pitch_id-${requestPayload.params.id}.zip`);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const copyPitchToCourseAction = createAsyncThunk<{ success: boolean }, Params>(
  'pitches/copyPitchToCourse',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<{ success: boolean }>({
      api: workspaceRestClient.pitchCopyToCourse,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const copyPitchsToCourseAction = createAsyncThunk<{ success: boolean }, Params>(
  'pitches/copyPitchsToCourses',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<{ success: boolean }>({
      api: workspaceRestClient.pitchesCopyToCourse,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getPitchAction = createAsyncThunk<PitchType | null, { pitchId: number }>(
  'pitches/getPitch',
  async (requestPayload, { rejectWithValue }) => {
    const { pitchId } = requestPayload;

    const callApi = createApiCall<PitchType>({
      api: workspaceRestClient.getPitch,
      configureUrlParams: configureWorkspacePrefixes,
    });

    try {
      const { data } = await callApi({
        params: {
          pitchId,
        },
      });

      return {
        ...data,
        slides: sortBy(data.slides, 'position'),
      };
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
export const createTeamTrainingAction = createAsyncThunk<string | null, { pitchId: number }>(
  'pitches/createTeamTraining',
  async (requestPayload, { rejectWithValue }) => {
    const { pitchId } = requestPayload;
    const callApi = createApiCall<PitchType>({
      api: workspaceRestClient.getPitch,
    });
    const callSlideApi = createApiCall<SlideType[]>({
      api: workspaceRestClient.getPitchSlides,
    });
    const callUserApi = createApiCall<UserType>({
      api: workspaceRestClient.getCurrentUser,
    });
    const callPPv1Api = axios.create({
      baseURL: process.env.REACT_PPV1_API_URL || 'https://peterpitch.com',
    });
    callPPv1Api.interceptors.request.use(
      async (config) => {
        await revalidateWorkspaceToken();

        return injectAuthHeaders(config);
      },
      (err) => Promise.reject(err),
    );
    try {
      const { data: pitch } = await callApi({
        params: { pitchId },
      });
      const { data: slides } = await callSlideApi({
        params: { pitchId },
      });
      const { data: user } = await callUserApi();
      const { data } = await callPPv1Api({
        method: 'post',
        url: '/pitches/createFromJson',
        data: { pitch: pitch, slides: slides, user: user },
      });

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const favoritePitchAction = createAsyncThunk<{ success: boolean }, Params<{ id: number }>>(
  'pitches/favoritePitch',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<FavoriteType>({
      api: workspaceRestClient.favoritePitch,
    });
    try {
      await callApi(requestPayload);
      return { success: true };
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const defavoritePitchAction = createAsyncThunk<{ success: boolean }, Params<{ id: number }>>(
  'courses/defavoriteCourse',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<{ success: true }>({
      api: workspaceRestClient.defavoritePitch,
    });
    try {
      const { data } = await callApi(requestPayload);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const resetPitchProgressAction = createAsyncThunk<{ success: boolean }, Params>(
  'pitches/resetPitchProgress',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<{ success: boolean }>({
      api: workspaceRestClient.resetPitchProgress,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const resetPitchesAction = createAction('pitches/resetPitchStale');
export const pitchesSetIsStaleAction = createAction<boolean>('pitches/setIsStale');

export const uploadPPTAction = createAsyncThunk<{ success: boolean }, Params>(
  'pitches/uploadPPT',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<{ success: boolean }>({
      api: workspaceRestClient.uploadPPT,
      headers: {
        'content-type': `multipart/form-data;`,
      },
      preventDataTransform: true,
    });
    try {
      const { data } = await callApi({
        ...requestPayload,
      });
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const facialEmotionAnalysis = createAsyncThunk<FaceAnalysisResponse, Params>(
  'pitches/facialEmotionAnalysis',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<FaceAnalysisResponse>({
      api: workspaceRestClient.postFacialAnalysis,
      headers: {
        'content-type': `multipart/form-data;`,
      },
      preventDataTransform: true,
    });
    try {
      const { data } = await callApi({
        ...requestPayload,
      });
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
