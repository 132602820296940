import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
// types
import { CourseListResponse, CourseType, CoursePayloadType, TagType, FavoriteType } from 'types/courses';
//
import { uploadImage } from 'portal/workspace/lib/uploadFile';
import { configureWorkspacePrefixes, workspaceRestClient } from 'portal/workspace/lib/http';
import { Params } from 'lib/http';
import { createApiCall } from 'lib/http/utils/createApiCall';
import { saveFile } from 'lib/saveFile';
import { CourseShareWorkspacesPayload } from 'types/courses/entities';
//

export const fetchCoursesWorker = async <T>(requestPayload: T) => {
  const callApi = createApiCall<CourseListResponse>({
    api: workspaceRestClient.getCourseList,
    configureUrlParams: configureWorkspacePrefixes,
  });

  const { data } = await callApi(requestPayload);

  return data;
};
export const getCoursesAction = createAsyncThunk<CourseListResponse, Params | undefined>(
  'courses/getCoursesList',
  async (requestPayload, { rejectWithValue }) => {
    try {
      return await fetchCoursesWorker(requestPayload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
export const getCourseAction = createAsyncThunk<CourseType, Params>(
  'courses/getCourse',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<CourseType>({
      api: workspaceRestClient.getCourse,
      configureUrlParams: configureWorkspacePrefixes,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const exportToScormAction = createAsyncThunk<Blob, Params<{ id: string | number }>>(
  'courses/exportToScorm',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<Blob>({
      api: workspaceRestClient.exportToScormCourse,
    });

    try {
      const { data } = await callApi(requestPayload);
      saveFile(data, `SCORM_Course_id-${requestPayload.params.id}.zip`);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const deleteCourseAction = createAsyncThunk<{ success: boolean }, Params>(
  'courses/deleteCourse',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<{ success: boolean }>({
      api: workspaceRestClient.deleteCourse,
      configureUrlParams: configureWorkspacePrefixes,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const duplicateCourseAction = createAsyncThunk<{ success: boolean }, Params>(
  'courses/duplicateCourse',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<{ success: boolean }>({
      api: workspaceRestClient.duplicateCourse,
      configureUrlParams: configureWorkspacePrefixes,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const ensureCoverUploaded = async (cover: CoursePayloadType['cover']) => {
  const coverUrl =
    cover instanceof Blob
      ? await uploadImage({
          image: cover,
          name: 'CourseCover',
        })
      : cover;

  return coverUrl || null;
};

export const createCourseAction = createAsyncThunk<CourseType, CoursePayloadType>(
  'courses/createCourse',
  async (requestPayload, { rejectWithValue }) => {
    const { cover, ...rest } = requestPayload as CoursePayloadType;

    const callApi = createApiCall<CourseType>({
      api: workspaceRestClient.createCourse,
      configureUrlParams: configureWorkspacePrefixes,
    });

    try {
      const { data } = await callApi({
        ...rest,
        cover: await ensureCoverUploaded(cover),
      });

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const publishCourseAction = createAsyncThunk<CourseType, Params>(
  'courses/publishCourse',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<CourseType>({
      api: workspaceRestClient.patchCourse,
      configureUrlParams: configureWorkspacePrefixes,
    });
    try {
      const { data } = await callApi(requestPayload);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateCourseStatusAction = createAsyncThunk<CourseType, Params>(
  'courses/updateCourseStatusAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<CourseType>({
      api: workspaceRestClient.patchCourse,
      configureUrlParams: configureWorkspacePrefixes,
    });
    try {
      const { data } = await callApi(requestPayload);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const favoriteCourseAction = createAsyncThunk<{ success: boolean }, Params<{ id: number }>>(
  'courses/favoriteCourse',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<FavoriteType>({
      api: workspaceRestClient.favoriteCourse,
    });
    try {
      await callApi(requestPayload);
      return { success: true };
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const defavoriteCourseAction = createAsyncThunk<{ success: boolean }, Params<{ id: number }>>(
  'courses/defavoriteCourse',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<{ success: true }>({
      api: workspaceRestClient.defavoriteCourse,
    });
    try {
      const { data } = await callApi(requestPayload);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateCourseAction = createAsyncThunk<CourseType, Params>(
  'courses/updateCourse',
  async (requestPayload, { rejectWithValue }) => {
    const { cover, ...rest } = requestPayload as CoursePayloadType & { cover: Blob | string };

    const callApi = createApiCall<CourseType>({
      api: workspaceRestClient.updateCourse,
      configureUrlParams: configureWorkspacePrefixes,
    });

    try {
      const { data } = await callApi({
        ...rest,
        cover: await ensureCoverUploaded(cover),
      });

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getTagListAction = createAsyncThunk<{ data: TagType[] }, Params | undefined>(
  'courses/getTagList',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<{ data: TagType[] }>({
      api: workspaceRestClient.getTagList,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const shareCourseAction = createAsyncThunk<CourseType, CourseShareWorkspacesPayload>(
  'courses/shareCourse',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<CourseType>({
      api: workspaceRestClient.shareCourse,
      configureUrlParams: configureWorkspacePrefixes,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const unshareCourseAction = createAsyncThunk<{ success: boolean }, CourseShareWorkspacesPayload | Params>(
  'courses/shareCourse',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<CourseType>({
      api: workspaceRestClient.unshareCourse,
      configureUrlParams: configureWorkspacePrefixes,
    });

    try {
      await callApi(requestPayload);
      return { success: true };
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const coursesSetIsStaleAction = createAction<boolean>('courses/setIsStale');

export const resetCourseAction = createAction('courses/reset');

export const updateSelectedCoursesAction = createAction<{
  teamId: number;
  courseId: number;
  action: 'add' | 'delete' | 'reset';
}>('courses/updateSelectedCourses');

export const resetSelectedCoursesAction = createAction<{
  teamId?: number | string;
}>('courses/resetSelectedCourses');

export const attachTeamsToCourseAction = createAsyncThunk<{ success: boolean }, Params>(
  'courses/attachTeamsToCourse',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<{ success: boolean }>({
      api: workspaceRestClient.attachTeamsToCourse,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const detachTeamsFromCourseAction = createAsyncThunk<{ success: boolean }, Params>(
  'courses/detachTeamsFromCourse',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<{ success: boolean }>({
      api: workspaceRestClient.detachTeamsFromCourse,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const attachMembersToCourseAction = createAsyncThunk<{ success: boolean }, Params>(
  'courses/attachMembersToCourse',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<{ success: boolean }>({
      api: workspaceRestClient.attachMembersToCourse,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const detachMembersFromCourseAction = createAsyncThunk<{ success: boolean }, Params>(
  'courses/detachMembersFromCourse',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<{ success: boolean }>({
      api: workspaceRestClient.detachMembersFromCourse,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
