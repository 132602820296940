import cx from 'clsx';
//

type MergeClassesConfig = {
  localClasses?: Record<string, string>;
  externalClasses?: Record<string, string>;
  localClassName?: string;
  externalClassName?: string;
  //
  rootKey?: string;
};
export const mergeClasses = ({
  localClasses = {},
  externalClasses = {},
  localClassName,
  externalClassName,
  //
  rootKey = 'root',
}: MergeClassesConfig) => {
  //

  const uniqClasses = [
    ...new Set([
      //
      ...Object.keys(externalClasses),
      ...Object.keys(localClasses),
    ]),
  ];

  return uniqClasses.reduce(
    (acc, key) => {
      acc[key as keyof typeof acc] = cx(
        //
        localClasses[key as keyof typeof localClasses],
        externalClasses[key as keyof typeof externalClasses],
      );

      return acc;
    },
    { [rootKey]: cx(externalClassName, localClassName) },
  );
};
