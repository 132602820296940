import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  modalBarWithoutBorder: {
    border: 'none',
  },
  modalTopBarTitle: {
    textAlign: 'center',
    fontFamily: '"Gilmer-Font", serif',
    color: theme.colors.oxfordBlue,
    fontSize: '24px',
    lineHeight: '32px',
    fontWeight: 600,
    margin: '3rem 0rem 1rem',
  },
  modalContent: {
    height: '100%',
  },
  modalContentWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    padding: '0rem 2rem',
  },
  subText: {
    fontFamily: 'Hando-Trial',
    color: theme.colors.oxfordBlue,
    textAlign: 'center',
    fontSize: '1rem',
  },
  description: {
    fontFamily: '"Gilmer-Font", serif',
    fontEeight: 'normal',
    fontSize: '1rem',
    lineHeight: '28px',
    color: theme.colors.red,
    textAlign: 'center',
  },
  modalContentImage: {
    aspectRatio: '1',
    width: '65%',
  },
  modalBottomBar: {
    padding: '24px 32px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  modalButton: {
    flex: 1,
    maxWidth: '272px',
    padding: '12px',
    opacity: '0.95',
    fontFamily: '"Gilmer-Font", serif',
    fontSize: '18px',
    textTransform: 'none',
    margin: '0 8px 0',
    lineHeight: '24px',
    '&:hover': {
      opacity: 1,
    },
  },
  modalLogoutButton: {
    backgroundColor: theme.colors.red,
    color: theme.colors.white,
    '&:hover': {
      backgroundColor: theme.colors.red,
    },
  },
  modalCancelButton: {
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors.stroke}`,
    color: theme.colors.oxfordBlue,
    '&:hover': {
      backgroundColor: theme.colors.white,
      border: `1px solid ${theme.colors.primary}`,
    },
  },
}));
