import { Reducer } from 'redux';
import { createReducer } from '@reduxjs/toolkit';
//
import { ListsStateType } from 'types/content/lists';
import { getListsAction, createListAction, deleteListAction, getListsByTypeAction, updateListAction } from './actions';

const initialState: ListsStateType = {
  meta: {
    isLoading: false,
    isLoaded: false,
    isStale: true,
  },
  pagination: {
    page: 1,
    perPage: 16,
    pageCount: 0,
    total: 0,
  },
  filters: {},
  sort: {},
  resources: [],
  //
  listsByType: {},
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getListsAction.pending, (draft) => {
      draft.meta.isLoading = true;
      draft.meta.isLoaded = false;
    })
    .addCase(getListsAction.fulfilled, (draft, { payload }) => {
      const { data, ...pagination } = payload;
      draft.resources = data;

      draft.pagination = {
        ...draft.pagination,
        ...pagination,
      };

      draft.meta.isLoading = false;
      draft.meta.isLoaded = true;
      draft.meta.isStale = false;
    })
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    .addCase(getListsByTypeAction.fulfilled, (draft, { payload, meta }) => {
      const { data } = payload;

      draft.listsByType[meta.arg] = data;
    })
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    .addCase(createListAction.pending, (draft) => {
      draft.meta.isLoading = true;
    })
    .addCase(createListAction.fulfilled, (draft, { payload }) => {
      draft.meta.isLoading = false;
      draft.resources = [payload, ...draft.resources];
    })
    .addCase(createListAction.rejected, (draft) => {
      draft.meta.isLoading = false;
    })
    // updateFolderAction
    .addCase(updateListAction.pending, (draft) => {
      draft.meta.isLoading = true;
    })
    .addCase(updateListAction.fulfilled, (draft, { payload }) => {
      draft.resources = draft.resources.map((item) => (item.id === payload.id ? payload : item));
      draft.meta.isLoading = false;
    })
    .addCase(updateListAction.rejected, (draft) => {
      draft.meta.isLoading = false;
    })
    // deleteListAction
    .addCase(deleteListAction.pending, (draft) => {
      draft.meta.isLoading = true;
    })
    .addCase(deleteListAction.fulfilled, (draft, { payload }) => {
      draft.meta.isLoading = false;
      draft.resources = draft.resources.filter((item) => item.id !== payload);
    });
});

export const listsReducer: Reducer<typeof initialState> = (draft = initialState, action) => {
  return reducer(draft, action);
};
