import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import usePopoverMenu from 'hooks/usePopoverMenu';
import PopoverMenu, { ActionType } from 'components/popover-menu';

import { updateUserAction } from 'portal/backoffice/domain/auth/actions';
import { authUserSelector } from 'portal/backoffice/domain/auth/selectors';

import { makeStyles } from '@mui/styles';

import { fallbackLocale } from 'localization/utils';
import { LangCode, supportedLanguages } from 'localization';

const LanguagePicker = () => {
  const { anchorEl, isMenuOpen, handleOpenMenu, handleCloseMenu } = usePopoverMenu();
  const dispatch = useDispatch();
  const classes = useStyles();
  const user = useSelector(authUserSelector);
  const [selectedLocale, setSelectedLocale] = useState<LangCode>(user?.language ?? fallbackLocale);

  const actions = useMemo(() => {
    const selectLocale = (code: LangCode) => {
      dispatch(updateUserAction({ ...user, language: code }))
        .unwrap()
        .then(() => {
          setSelectedLocale(code);
          localStorage.setItem('selectedLocale', code ?? fallbackLocale);
        });
    };

    const actions: Array<ActionType | boolean> =
      Object.keys(supportedLanguages).map((language) => ({
        label: supportedLanguages[language as LangCode]?.label,
        Icon: () => (
          <div className={classes.icon}>
            <img src={supportedLanguages[language as LangCode]?.icon} />
          </div>
        ),
        onClick: () => {
          selectLocale(language as LangCode);
          handleCloseMenu();
        },
      })) || [];

    return actions.filter(Boolean) as Array<ActionType>;
  }, [handleCloseMenu, classes, dispatch, user]);

  return (
    <>
      <button onClick={handleOpenMenu} className={classes.menuButton}>
        <img src={supportedLanguages[selectedLocale as LangCode]?.icon} />
      </button>
      <PopoverMenu
        //
        isOpen={isMenuOpen}
        anchor={anchorEl}
        actions={actions}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  menuButton: {
    width: '48px',
    border: '1px solid',
    borderColor: theme.colors.stroke,
    height: '48px',
    marginRight: '1rem',
    borderRadius: '50%',
    background: 'transparent',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  icon: {
    fontSize: '1.5rem',
    marginRight: '-.5rem',
  },
}));

export default LanguagePicker;
