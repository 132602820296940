import React from 'react';
import { RouteComponentProps } from 'react-router';
// local
import { ChildRoute } from './index';

const SimpleContainer: React.FC = ({ children }) => <div>{children}</div>;
export const withRoutedLayout = (route: ChildRoute) => (Component: React.ComponentType<RouteComponentProps>) => {
  //
  const Cmp: React.FC<RouteComponentProps> = (props) => {
    const Layout = route.Layout || SimpleContainer;

    return (
      <Layout>
        <Component {...props} />
      </Layout>
    );
  };

  return Cmp;
};
