import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
// types
import { WorkspaceType } from 'types/workspaces';
import { UserRoles } from 'types/users';
// utils
import { selectWorkspace as selectWorkspaceLS, removeWorkspace } from 'lib/localStorage';
import { getUrlWithParams } from 'portal/workspace/routes';
// domain
import { RootState } from 'portal/workspace/domain/store';
import { issueCompanyToken, issueWorkspaceToken } from 'portal/workspace/domain/auth/actions';
import { authCurrentWorkspaceIdSelector, authRolesSelector } from 'portal/workspace/domain/auth/selectors';
import { selectedWorkspaceSelector } from 'portal/workspace/domain/coaches/workspaces/selectors';
import {
  getWorkspaceAction,
  selectWorkspaceAction,
  unSelectWorkspaceAction,
} from 'portal/workspace/domain/coaches/workspaces/actions';

type OwnProps = {
  workspace?: WorkspaceType;
  goOutOfWorkspace: () => void;
};

const useCoachWorkspaceAuth = (): OwnProps => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { workspaceId } = useParams<{ workspaceId?: string }>();
  //
  const { workspace, currentWorkspaceId } = useSelector(mapStateToProps);
  const { realRole } = useSelector(authRolesSelector);

  const unselectWorkspaceProcess = useCallback(() => {
    dispatch(unSelectWorkspaceAction());
    removeWorkspace();
  }, [dispatch]);

  const goOutOfWorkspace = useCallback(() => {
    history.push(getUrlWithParams('workspaces', {}));
  }, [history]);

  const selectNewWorkspace = useCallback(() => {
    if (!workspaceId) return;
    // run these requests in parallel with coach token
    //
    // cant request workspace when we're already logged into it as coach so request beforehand
    dispatch(getWorkspaceAction({ params: { id: workspaceId as string } }))
      .unwrap()
      .then((workspace: WorkspaceType) => {
        dispatch(selectWorkspaceAction({ workspace: workspace }));
        selectWorkspaceLS(workspace);
      })
      .catch(() => {
        // Most common case: workspace doesn't belong to the user or wrong token to access the worksopace
        // eg: Workspace is deleted but old notification leads to that workspace
        unselectWorkspaceProcess();
        history.push('/');
      });
    dispatch(issueWorkspaceToken({ params: { id: workspaceId as string } }))
      .unwrap()
      .catch(() => {
        // Most common case: workspace doesn't belong to the user or wrong token to access the worksopace
        // eg: Workspace is deleted but old notification leads to that workspace
        unselectWorkspaceProcess();
        history.push('/');
      });
  }, [workspaceId, dispatch, history, unselectWorkspaceProcess]);

  useEffect(() => {
    if (realRole !== UserRoles.Coach) return;
    // only for coach
    // should be logged into workspace but we aren't

    if (workspaceId) {
      if (!Boolean(currentWorkspaceId)) {
        selectNewWorkspace();
      } else if (Number(workspaceId) !== currentWorkspaceId) {
        dispatch(issueCompanyToken())
          .unwrap()
          .then(() => {
            selectNewWorkspace();
          });
      }
    }
    // should not be logged into workspace but we are
    else if (!workspaceId && Boolean(currentWorkspaceId)) {
      dispatch(issueCompanyToken());
      unselectWorkspaceProcess();
    }
  }, [currentWorkspaceId, workspaceId, dispatch, history, realRole, selectNewWorkspace, unselectWorkspaceProcess]);

  return {
    workspace,
    goOutOfWorkspace,
  };
};

const mapStateToProps = (state: RootState) => ({
  workspace: selectedWorkspaceSelector(state),
  currentWorkspaceId: authCurrentWorkspaceIdSelector(state),
});

export default useCoachWorkspaceAuth;
