import { IntlKeys } from 'localization/keys';

const translations = {
  //COMMON
  [IntlKeys.optional]: '(Optional)',
  [IntlKeys.back]: 'Back',
  [IntlKeys.dashboard]: 'Dashboard',
  [IntlKeys.courses]: 'Courses',
  [IntlKeys.boundCourses]: 'Bound courses',
  [IntlKeys.users]: 'Users',
  [IntlKeys.coach]: 'Coach',
  [IntlKeys.coaches]: 'Coaches',
  [IntlKeys.generalInfo]: 'General Info',
  [IntlKeys.favourites]: 'Favourites',
  [IntlKeys.teams]: 'Teams',
  [IntlKeys.members]: 'Members',
  [IntlKeys.content]: 'Content',
  [IntlKeys.files]: 'Files',
  [IntlKeys.lists]: 'Lists',
  [IntlKeys.folders]: 'Folders',
  [IntlKeys.folder]: 'Folder',
  [IntlKeys.community]: 'Community',
  [IntlKeys.workspace]: 'Workspace',
  [IntlKeys.workspaces]: 'Workspaces',
  [IntlKeys.company]: 'Company',
  [IntlKeys.companies]: 'Companies',
  [IntlKeys.coachCompanies]: 'Coach companies',
  [IntlKeys.settings]: 'Settings',
  [IntlKeys.help]: 'Help',
  [IntlKeys.training]: 'Training',
  [IntlKeys.email]: 'Email',
  [IntlKeys.password]: 'Password',
  [IntlKeys.passwordPlaceholder]: 'Type your password',
  [IntlKeys.retypePassword]: 'Re-type password',
  [IntlKeys.newPassword]: 'New password',
  [IntlKeys.enterPassword]: 'Enter password',
  [IntlKeys.retypeNewPassword]: 'Re-type new password',
  [IntlKeys.name]: 'Name',
  [IntlKeys.namePlaceholder]: 'Enter your name',
  [IntlKeys.surname]: 'Surname',
  [IntlKeys.surnamePlaceholder]: 'Enter your surname',
  [IntlKeys.jobTitle]: 'Job title',
  [IntlKeys.jobTitlePlaceholder]: 'Enter your job title',
  [IntlKeys.serverNotAvailableError]: 'Server is not available',
  [IntlKeys.reviewsRequested]: 'Reviews Requested',
  [IntlKeys.reviewsSearch]: 'Search for a Review',
  [IntlKeys.update]: 'Update',
  [IntlKeys.updates]: 'Updates',
  [IntlKeys.records]: 'Records',
  [IntlKeys.lastRecords]: 'Last Records',
  [IntlKeys.seeAll]: 'See all',
  [IntlKeys.pitchesAndTrainings]: 'Trainings',
  [IntlKeys.cancel]: 'Cancel',
  [IntlKeys.add]: 'Add',
  [IntlKeys.confirm]: 'Confirm',
  [IntlKeys.confirmQuestion]: 'Are you sure?',
  [IntlKeys.ok]: 'Ok',
  [IntlKeys.upload]: 'Upload',
  [IntlKeys.library]: 'Library',
  [IntlKeys.dragAndDrop]: 'Drag & Drop or click to upload',
  [IntlKeys.create]: 'Create',
  [IntlKeys.sortBy]: 'Sort by',
  [IntlKeys.change]: 'Change',
  [IntlKeys.remove]: 'Remove',
  [IntlKeys.removeConfirm]: 'Yes, remove',
  [IntlKeys.requiredItem]: 'This is required',
  [IntlKeys.save]: 'Save',
  [IntlKeys.errorSomethingWentWrong]: 'Something went wrong...',
  [IntlKeys.title]: 'Title',
  [IntlKeys.enter]: 'Enter',
  [IntlKeys.edit]: 'Edit',
  [IntlKeys.apply]: 'Apply',
  [IntlKeys.filter]: 'Filter',
  [IntlKeys.filters]: 'Filters',
  [IntlKeys.status]: 'Status',
  [IntlKeys.share]: 'Share',
  [IntlKeys.delete]: 'Delete',
  [IntlKeys.timer]: 'Timer',
  [IntlKeys.text]: 'Text',
  [IntlKeys.choices]: 'Choices',
  [IntlKeys.quiz]: 'Quiz',
  [IntlKeys.enterText]: 'Enter a text',
  [IntlKeys.addText]: 'Add text',
  [IntlKeys.or]: 'OR',
  [IntlKeys.other]: 'Other',
  [IntlKeys.selectFromList]: 'Select from list',
  [IntlKeys.duplicate]: 'Duplicate',
  [IntlKeys.of]: 'of',
  [IntlKeys.part]: 'Part',
  [IntlKeys.dontWorry]: 'Don’t worry!',
  [IntlKeys.submit]: 'Submit',
  [IntlKeys.retake]: 'Retake',
  [IntlKeys.loading]: 'Loading...',
  [IntlKeys.from]: 'From',
  [IntlKeys.all]: 'All',
  [IntlKeys.changesSaved]: 'Changes saved',
  [IntlKeys.saveChanges]: 'Save Changes',
  [IntlKeys.enterValidString]: 'Please enter valid string',
  [IntlKeys.teamName]: 'Team name',
  [IntlKeys.emoji]: 'Emoji',
  [IntlKeys.addTeam]: 'Add team',
  [IntlKeys.copyLink]: 'Copy link',
  [IntlKeys.invalidEmail]: 'is not a valid email',
  [IntlKeys.linkCopied]: 'Link copied',
  [IntlKeys.userName]: 'User name',
  [IntlKeys.role]: 'Role',
  [IntlKeys.chooseRole]: 'Choose role',
  [IntlKeys.language]: 'Language',
  [IntlKeys.chooseLanguage]: 'Choose language',
  [IntlKeys.passwordsMustMatch]: 'Passwords must match',
  [IntlKeys.eightCharacterAtLeast]: 'Must be 8 characters at least',
  [IntlKeys.incorrectEmail]: 'Incorrect email',
  [IntlKeys.seeMore]: 'See more',
  [IntlKeys.employee]: 'Employee',
  [IntlKeys.overview]: 'Overview',
  [IntlKeys.details]: 'Details',
  [IntlKeys.record]: 'Record',
  [IntlKeys.startRecording]: 'Start Recording',
  [IntlKeys.exportAsSCORMPackage]: 'Export as SCORM package',
  [IntlKeys.draft]: 'Draft',
  [IntlKeys.published]: 'Published',
  [IntlKeys.disabled]: 'Disabled',
  [IntlKeys.completed]: 'Completed',
  [IntlKeys.admin]: 'Admin',
  [IntlKeys.manager]: 'Manager',
  [IntlKeys.scormEmployee]: 'Employee (SCORM)',
  [IntlKeys.leavePageProomptMessage]: 'You have unsaved changes! Are you sure want to leave the page?',
  [IntlKeys.leavePageQuestion]: 'Leave page?',
  [IntlKeys.archive]: 'Archive',
  [IntlKeys.uploadInProgress]: 'Uploading {count, plural, =0 {items} one {# item} other {# items}}',
  [IntlKeys.uploadProgressPromptMessage]:
    'Your files are uploading. Leaving the page may fail the upload and can affect the content you saved',
  [IntlKeys.stopRecording]: 'Stop Recording',
  [IntlKeys.unArchive]: 'Un-Archive',
  [IntlKeys.yes]: 'Yes',
  [IntlKeys.no]: 'No',
  [IntlKeys.viewDetails]: 'View details',
  [IntlKeys.task]: 'Task',
  [IntlKeys.taskAudio]: 'Audio Task',
  [IntlKeys.taskInfo]: 'Task info',
  [IntlKeys.taskText]: 'Text Task',
  [IntlKeys.taskVideo]: 'Video Task',
  [IntlKeys.accept]: 'Accept',
  [IntlKeys.reject]: 'Reject',
  [IntlKeys.phone]: 'Phone',
  [IntlKeys.enterPhone]: 'Enter phone number',
  [IntlKeys.enterSurname]: 'Enter surname',
  [IntlKeys.summary]: 'Summary',
  [IntlKeys.timeline]: 'Timeline',
  [IntlKeys.showDesktop]: 'show desktop view',
  [IntlKeys.showMobile]: 'show mobile view',
  [IntlKeys.logo]: 'Logo',
  [IntlKeys.type]: 'Type',
  [IntlKeys.search]: 'Search',

  //LOGIN PAGE
  [IntlKeys.loginTitle]: 'Welcome back to HeyPractice!',
  [IntlKeys.loginSubtitle]: 'Please login to your account to continue',
  [IntlKeys.signIn]: 'Login',
  [IntlKeys.loginNoAccount]: 'Don’t have an account?',
  [IntlKeys.signInError401]: 'Wrong email or password',

  //FORGOT PASSWORD PAGE
  [IntlKeys.forgotPassword]: 'Forgot password',
  [IntlKeys.forgotPasswordQuestion]: 'Forgot password?',
  [IntlKeys.forgotPasswordEnterEmail]: 'Enter the email address associated with your account',
  [IntlKeys.forgotPasswordSendLink]: 'Send reset link',
  [IntlKeys.forgotPasswordBackToLogin]: 'Back to Login',
  [IntlKeys.forgotPasswordFulfilled]: 'Link sent to your email',
  [IntlKeys.forgotPasswordError404]: "This email address don't exist",

  //RESET PASSWORD PAGE
  [IntlKeys.resetPassword]: 'Reset password',
  [IntlKeys.resetPasswordSubtitle]: 'Please enter your new password',
  [IntlKeys.resetPasswordChangePassword]: 'Change password',
  [IntlKeys.resetPasswordHaveAccount]: 'Already have an account?',
  [IntlKeys.resetPasswordFulfilled]: 'Password successfully changed',
  [IntlKeys.resetPasswordError404]: 'This link is invalid',
  [IntlKeys.resetPasswordError422]: 'This link is invalid',

  //SIGNUP PASSWORD PAGE
  [IntlKeys.signUp]: 'Sign up',
  [IntlKeys.signUpTitle]: 'Join HeyPractice',
  [IntlKeys.signUpSubTitle]: 'Create an account and start your training!',
  [IntlKeys.signUpHaveAccount]: 'Have an account?',
  [IntlKeys.signUpFulfilled]: 'User successfully created',
  [IntlKeys.signUpError404]: 'This link is invalid',
  [IntlKeys.signUpError422]: 'This link is invalid',
  [IntlKeys.signUpError500]: 'This email already exists',

  // Form Validation
  [IntlKeys.formFieldFequired]: 'This field is required',
  [IntlKeys.formTimeExceedsTimer]: 'Summary tasks time exceeds total timer',

  // Courses
  [IntlKeys.noCoursesAvailable]: 'Looks like you don’t have any courses',
  [IntlKeys.addNewCourse]: 'Add new course',
  [IntlKeys.createCourse]: 'Create course',
  [IntlKeys.createCourseSubmit]: 'Create new course',
  [IntlKeys.createCourseFormLabelName]: 'Course name',
  [IntlKeys.createCourseFormLabelDescription]: 'Description',
  [IntlKeys.createCourseFormLabelTags]: 'Tags',
  [IntlKeys.createCourseFormLabelTeams]: 'Teams',
  [IntlKeys.createCourseFormLabelCover]: 'Cover Image',
  [IntlKeys.courseDetails]: 'Details',
  [IntlKeys.coursesSearch]: 'Search a course',
  [IntlKeys.coursesDetachTeamTooltip]: 'Detach team',
  [IntlKeys.coursesAttchTeamTooltip]: 'Attach team',
  [IntlKeys.coursesDetachMemberTooltip]: 'Detach member',
  [IntlKeys.coursesAttachMemberTooltip]: 'Attach member',
  [IntlKeys.coursesDetachUnableMemberTooltip]: 'Members belonging to attached teams are unable to detach',
  [IntlKeys.coursesRecordsView]: 'View Records',
  [IntlKeys.coursesAddCoursesToTeam]: 'Add courses to the team',
  [IntlKeys.coursesClearSelection]: 'Clear selection',
  [IntlKeys.coursesCourseNameAToZ]: 'Course name A-Z',
  [IntlKeys.coursesCourseNameZToA]: 'Course name Z-A',
  [IntlKeys.coursesAuthorNameAToZ]: 'Author name A-Z',
  [IntlKeys.coursesAuthorNameZToA]: 'Author name Z-A',
  [IntlKeys.coursesLastUpdates]: 'Last updates',
  [IntlKeys.coursesOldUpdates]: 'Old updates',
  [IntlKeys.courseClearFilter]: 'clear filter',
  [IntlKeys.coursesAddCourse]: 'Add course',
  [IntlKeys.connectedCourses]:
    '{count, plural, =0 {no connected courses} one {# connected course} other {# connected courses}}',
  [IntlKeys.coursesAttachToWorkspaces]: 'Attach to workspaces',

  // Course
  [IntlKeys.courseAttachDetachMember]: 'Attach / Detach member',
  [IntlKeys.courseAddTeam]: 'Add team',
  [IntlKeys.courseAddWorkspace]: 'Manage Workspace Access',
  [IntlKeys.courseAttachWorkspace]: 'Attach Workspace',
  [IntlKeys.courseDetachWorkspace]: 'Detach Workspace',
  [IntlKeys.courseOverview]: 'Overview',
  [IntlKeys.courseHistory]: 'History',
  [IntlKeys.courseViewRecords]: 'View Records',
  [IntlKeys.courseUpdateCourse]: 'Update course',
  [IntlKeys.coursePublish]: 'Publish',
  [IntlKeys.coursePublishFailed]: 'publishing course failed',
  [IntlKeys.courseExportToSCORM]: 'Export to SCORM',
  [IntlKeys.courseNoTeamAvailable]: 'Looks like you don’t have any team',
  [IntlKeys.courseCreatingTeamSuggestion]: 'You can easily create new team and start training',
  [IntlKeys.coursePublishCourseQuestionTitle]: 'Publish the course?',
  [IntlKeys.coursePublishCourseQuestionSubText]: 'Are you sure you want to publish the course for training?',
  [IntlKeys.coursePublishCourseQuestionConfirmBtnText]: 'Yes, Publish',
  [IntlKeys.courseAddTags]: 'Add tags',
  [IntlKeys.courseAddTeams]: 'Add teams',
  [IntlKeys.courseEnterTag]: 'Enter tag',
  [IntlKeys.courseAuthor]: 'Author',
  [IntlKeys.courseSendInvite]: 'Send Invite',
  [IntlKeys.courseEnterEmail]: 'Type email, press enter to add',
  [IntlKeys.courseRemoveCourse]: 'Remove course',
  [IntlKeys.courseRemovingCourseConfirmQuestion]: 'Are you sure you want to remove the course?',
  [IntlKeys.courseRemovingCourseDescription]:
    'All the information associated with the course will be deleted including the user recordings and scores associated with the course',
  [IntlKeys.courseShareCourse]: 'Share course',
  [IntlKeys.courseCompleted]: 'Completed',
  [IntlKeys.courseDateCreated]: 'Date created',
  [IntlKeys.courseDetachAttachTriggerAttachFulfilled]: 'Successfully attached courses to teams',
  [IntlKeys.courseDetachAttachTriggerDetachFromTeam]: 'Detach from team',
  [IntlKeys.courseDetachAttachTriggerAddToAnotherTeam]: 'Add to another team',
  [IntlKeys.courseDetachAttachTriggerDetachFromTeamConfirmQuestion]:
    'Are you sure you want to detach the selected courses from the team?',
  [IntlKeys.courseDetachAttachTriggerDetachFromTeamConfirmBtnText]: 'Yes, Detach',
  [IntlKeys.courseProgressTitle]: 'Progress',
  [IntlKeys.courseLeadershipBoardTitle]: 'Course Leadership board',
  [IntlKeys.copyToCourse]: 'Copy to course',
  [IntlKeys.courseCompletedStatus]: 'Courses completed',
  [IntlKeys.courseDeletionSuccess]: 'Course deleted succcessfully',
  [IntlKeys.courseDeletionFailure]: 'Course deletion failed',
  [IntlKeys.courseDuplicateTitle]: 'Duplicate course',
  [IntlKeys.courseDuplicateDesc]: 'Are you sure you want to duplicate the course?',
  [IntlKeys.courseDuplicateConfirm]: 'Yes, Duplicate',
  [IntlKeys.courseArchiveCourse]: 'Archive course',
  [IntlKeys.courseUnArchiveCourse]: 'Un Archive course',
  [IntlKeys.courseArchivingCourseConfirmQuestion]: 'Are you sure you want to archive the course?',
  [IntlKeys.courseUnArchivingCourseConfirmQuestion]: 'Are you sure you want to un-archive the course?',
  [IntlKeys.archiveConfirm]: 'Yes, archive',
  [IntlKeys.unArchiveConfirm]: 'Yes, un-archive',
  [IntlKeys.courseArchivingSuccess]: 'Course archived succcessfully',
  [IntlKeys.courseArchivingFailure]: 'Course archiving failed',
  [IntlKeys.courseUnArchivingSuccess]: 'Course un-archived succcessfully',
  [IntlKeys.courseUnArchivingFailure]: 'Course un-archiving failed',
  [IntlKeys.courseSearchForCompany]: 'Search for a company',
  [IntlKeys.courseScormLeavingQuestion]: "You've successfully finished the {type}, do you want to leave?",
  [IntlKeys.courseScormLeavingSubmitText]: 'Leave',
  [IntlKeys.courseScormLeavingSubtitle]:
    "You've finished all the steps on this {type}, do you want stay and redo some trainings, or do you want to go back to your Portal?",
  [IntlKeys.courseEmptyCourseSubText]: 'The course you are trying to access is not available.',

  // Teams
  [IntlKeys.teamSearch]: 'Search a team',
  [IntlKeys.teamsAndMembers]: 'Teams & Members',
  [IntlKeys.teamEmptyTeam]: 'Looks like no team is attached to this course',
  [IntlKeys.teamAccessLimited]: 'Oops! No member can access this course',
  [IntlKeys.teamsProgress]: 'Teams Progress',
  [IntlKeys.teamNoLeaderBoardMembers]: 'No stats available',
  [IntlKeys.teamNoLeaderBoardMembersSubTitle]:
    'No team progress information available. You will start seeing the stats once users start training',
  [IntlKeys.teamMembersProgressTitle]: 'Progress',
  [IntlKeys.teamMembersLeadershipBoardTitle]: 'Leadership board',
  [IntlKeys.teamNoLeaderBoardMembersSubTitle]:
    'No team progress information available. You will start seeing the stats once users start training',
  [IntlKeys.teamManageMembers]: 'Manage Members',

  // Admin dashboard
  [IntlKeys.adminDashboardMyDrafts]: 'My Drafts',

  // Dashboard
  [IntlKeys.dashboardActiveUsers]: 'Most active users',
  [IntlKeys.dashboardActiveWorkspaces]: 'Most active workspaces',
  [IntlKeys.dashboardCourseCompletion]: 'Course progress',
  [IntlKeys.dashboardCourseCompletionSubline]: 'Select workspace and available course',
  [IntlKeys.dashboardCoursesPopular]: 'Most popular courses',
  [IntlKeys.dashboardLast30Days]: 'last 30 days',
  [IntlKeys.dashboardMostActiveUser]: 'Most active user',
  [IntlKeys.dashboardMostActiveWorkspace]: 'Most active workspace',
  [IntlKeys.dashboardNewRecords]: 'New records',
  [IntlKeys.dashboardNoUsers]: 'No users available',
  [IntlKeys.dashboardNoWorkspaces]: 'No workspaces available',
  [IntlKeys.dashboardSessionsCount]: '{count, plural, =0 {no sessions} one {# session} other {# sessions}}',
  [IntlKeys.dashboardUsersCount]: '{count, plural, =0 {no users} one {# user} other {# users}}',
  [IntlKeys.dashboardUserLeaderboard]: 'User leaderboard',
  [IntlKeys.dashboardWorkspaceLeaderboard]: 'Workspace leaderboard',

  // Manager Dashboard
  [IntlKeys.managerDashboardTeamsActivity]: 'Teams Activity',
  [IntlKeys.managerDashboardMembersActivity]: 'Members Activity',
  [IntlKeys.managerDashboardNoLeaderboardAvailable]:
    'No leaderboard information available. You will start seeing the stats once users start training',

  // Employee Dashboard
  [IntlKeys.employeeDashboardActiveCourses]: 'My Active Courses',
  [IntlKeys.employeeDashboardScoreDesc]: 'Score ↓',
  [IntlKeys.employeeDashboardScoreAsc]: 'Score ↑',
  [IntlKeys.employeeDashboardCompletedPercentDesc]: 'Completion rate ↓',
  [IntlKeys.employeeDashboardCompletedPercentAsc]: 'Completion rate ↑',
  [IntlKeys.employeeDashboardLeadersActivities]: 'Leaders activities',

  // Company
  [IntlKeys.activeStatus]: 'Active',
  [IntlKeys.leadStatus]: 'Lead',
  [IntlKeys.archivedStatus]: 'Archived',
  [IntlKeys.addCompany]: 'Add company',
  [IntlKeys.companyName]: 'Company name',
  [IntlKeys.companyType]: 'Type',
  [IntlKeys.noOfEmployees]: 'No. of Employees',
  [IntlKeys.tabGeneralInfo]: 'General Info',
  [IntlKeys.tabUsers]: 'Users',
  [IntlKeys.companyAcceptQuestionTitle]: 'Accept {companyName}',
  [IntlKeys.companyAcceptQuestionSupText]: 'Are you sure you want to accept the company?',
  [IntlKeys.companyAcceptConfirmBtnText]: 'Yes, Accept',
  [IntlKeys.companyRejectQuestionTitle]: 'Reject {companyName}',
  [IntlKeys.companyRejectQuestionSupText]: 'Are you sure you want to reject the company? Company will be deleted',
  [IntlKeys.companyRejectConfirmationFieldLabel]: 'Write down company name as confirmation',
  [IntlKeys.companyRejectConfirmBtnText]: 'Yes, Reject',
  [IntlKeys.companyAddContactPersonTitle]: 'Add Contact Person',
  [IntlKeys.companySuperAdmin]: 'Super admin',
  [IntlKeys.companyEnterEmail]: 'Enter email',
  [IntlKeys.companyRejectCompanyNameMustMatch]: 'Name of company is not correct!',
  [IntlKeys.companyRejectCompanyFullfilled]: 'Company rejected successfully',
  [IntlKeys.companyAcceptCompanyFullfilled]: 'Company accepted successfully',
  [IntlKeys.companyRemove]: 'Remove Company',
  [IntlKeys.companyRemoveSubText]: 'Are you sure you want to remove the company?',
  [IntlKeys.companyRemoveDescription]: 'All the information associated with the company will be deleted',
  [IntlKeys.companyArchive]: 'Archive company',
  [IntlKeys.companyArchiveSubText]: 'Are you sure you want to archive the company?',
  [IntlKeys.companyArchiveDescription]:
    'Archived user will no longer be able to access HeyPractice. All the information associated with the company will be available',
  [IntlKeys.companyUnArchive]: 'Un-archive company',
  [IntlKeys.companyUnArchiveSubText]: 'Are you sure you want to un-archive the company?',

  // Feedback
  [IntlKeys.feedbacks]: 'Feedbacks',
  [IntlKeys.feedbackForTraining]: 'For training: ',
  [IntlKeys.feedbackInTheCourse]: 'In the course: ',
  [IntlKeys.feedbackNoFeedbackProvided]: '-- No feedback provided --',
  [IntlKeys.feedbackEnterYourFeedback]: 'Enter your feedback',

  // Content
  [IntlKeys.contentPresentation]: 'Presentation',
  [IntlKeys.contentCreatedBy]: 'Created by',
  [IntlKeys.contentTypeOfContentFolders]: 'Folders',
  [IntlKeys.contentTypeOfContentFiles]: 'Files',
  [IntlKeys.contentTypeOfContentLists]: 'Lists',
  [IntlKeys.contentUploadFile]: 'Upload file',
  [IntlKeys.contentAddFolder]: 'Add folder',
  [IntlKeys.contentCreateList]: 'Create list',
  [IntlKeys.contentRecentFiles]: 'Recent files',
  [IntlKeys.contentAddContent]: 'Add content',
  [IntlKeys.contentNothingToShow]: 'Nothing to show',
  [IntlKeys.contentNoFileAvailable]: 'Looks like you don’t have any files here',
  [IntlKeys.contentFileEmpty]: 'folder is empty',
  [IntlKeys.contentListSubtitle]: 'You can easily create new folder, upload file or add fade-in list',
  [IntlKeys.contentCreateFile]: 'Create file',
  [IntlKeys.contentNewFolder]: 'New folder',
  [IntlKeys.contentSearchContent]: 'Search content',
  [IntlKeys.contentSymbolQuantityLimited]: 'Should be 3 symbols at least',
  [IntlKeys.contentDownload]: 'Download',
  [IntlKeys.contentDetails]: 'Details',
  [IntlKeys.contentPreview]: 'Preview',
  [IntlKeys.contentAddAudio]: 'Add Audio',
  [IntlKeys.contentAddDocument]: 'Add Document',
  [IntlKeys.contentReactionName]: 'Fade-in name',
  [IntlKeys.contentSelectEmoji]: 'Select emoji',
  [IntlKeys.contentSaveReaction]: 'Save fade-in',
  [IntlKeys.contentEnterName]: 'Enter name',
  [IntlKeys.contentAddFiles]: 'Add files',
  [IntlKeys.contentSearchFiles]: 'Search files',
  [IntlKeys.contentSearchFolder]: 'Search folder',
  [IntlKeys.contentCreateFolder]: 'Create folder',
  [IntlKeys.contentSearch]: 'Suchen Sie einen Kurs',
  [IntlKeys.noContentAvailable]: 'Anscheinend hast du keine Kurse',

  // Content List
  [IntlKeys.contentListListName]: 'List name',
  [IntlKeys.contentListEnterListName]: 'Enter list name"',
  [IntlKeys.contentListEvaluationCriterias]: 'Evaluation Criterias',
  [IntlKeys.contentListEvaluationCriteria]: 'Evaluation Criteria',
  [IntlKeys.contentListAddCriteria]: 'Add criteria',
  [IntlKeys.contentListSaveCriteria]: 'Save criteria',
  [IntlKeys.contentListEnterDescription]: 'Enter description',
  [IntlKeys.contentListReactions]: 'Fade-ins',
  [IntlKeys.contentListDoWords]: 'Do-words',
  [IntlKeys.contentListDoWordsExplained]: '"Do-words" are words that user have to use for the best taking task',
  [IntlKeys.contentListDontWords]: 'Dont-words',
  [IntlKeys.contentListDontWordsExplained]: '"Dont-words" are words that user not have to use for the best taking task',
  [IntlKeys.contentListEnterDoWords]: 'Enter do-word',
  [IntlKeys.contentListEnterDontWords]: 'Enter dont-word',
  [IntlKeys.contentListEnterFileName]: 'Enter file name',
  [IntlKeys.contentListEnterFolderName]: 'Enter folder name',
  [IntlKeys.contentListAddContentList]: 'Add content list',
  [IntlKeys.contentListSearchContentList]: 'Search ContentList',

  // DropzoneCard
  [IntlKeys.dropzoneCardLibrary]: 'Library',
  [IntlKeys.dropzoneCardLink]: 'Link',
  [IntlKeys.dropzoneCardEnterLink]: 'Enter link',
  [IntlKeys.dropzoneCardAudio]: 'Audio',
  [IntlKeys.dropzoneCardImage]: 'Image',
  [IntlKeys.dropzoneCardVideo]: 'Video',
  [IntlKeys.dropzoneCardDocument]: 'Document',

  // LogOut
  [IntlKeys.logOut]: 'Log Out',
  [IntlKeys.logOutConfirmQuestion]: 'Are you sure you want to log out?',
  [IntlKeys.logOutConfirmBtnText]: 'Yes, logout',

  // Alert messages
  [IntlKeys.alertFileCreatingFulfilled]: 'File successfully created',
  [IntlKeys.alertFileCreatingFailed]: 'This file name is used. Please try another name',
  [IntlKeys.alertFileDeletingFulfilled]: 'File successfully deleted',
  [IntlKeys.alertFileUpdatingFulfilled]: 'File successfully updated',
  [IntlKeys.alertFileUpdatingFailed]: 'This file name is used. Please try another name',
  [IntlKeys.alertFolderCreatingFulfilled]: 'Folder successfully created',
  [IntlKeys.alertFolderDeletingFulfilled]: 'Folder successfully deleted',
  [IntlKeys.alertFolderUpdatingFulfilled]: 'Folder successfully updated',
  [IntlKeys.alertListCreatingFulfilled]: 'List successfully created',
  [IntlKeys.alertListDeletingFulfilled]: 'List successfully deleted',
  [IntlKeys.alertListUpdatingFulfilled]: 'List successfully updated',
  [IntlKeys.alertNotSupportFileFormat]: 'File format not supported',
  [IntlKeys.alertCoursesAttachingFulfilled]: 'Successfully attached courses to the team',
  [IntlKeys.alertSlideUpdatingFulfilled]: 'Slide updated successfully',
  [IntlKeys.alertSlidePublishingFulfilled]: 'Training published successfully',
  [IntlKeys.alertSlidePublishingFailed]: 'Failed to publish',
  [IntlKeys.alertReviewSubmittingFulfilled]: 'Successfully submitted the review',
  [IntlKeys.alertSomethingWentWrongTryAgain]: 'Something went wrong. Please try again later',
  [IntlKeys.alertMembersEditingFulfilled]: 'Sucessfully edited the members list',
  [IntlKeys.alertMembersUpdatingFailed]: 'Failed to update the members list',
  [IntlKeys.alertSavingChangesFulfilled]: 'Changes saved successfully',
  [IntlKeys.alertCloningCourseFulfilled]: 'Course cloned successfully',
  [IntlKeys.alertUpdatingPitchFulfilled]: 'Successfully updated training',
  [IntlKeys.alertCreatingPitchFulfilled]: 'Successfully created training',
  [IntlKeys.alertCopyingPitchToCourseFulfilled]: 'Training copied to course successfully',
  [IntlKeys.alertDeletingPitchFulfilled]: 'Successfully deleted training',
  [IntlKeys.alertDeletingCourseFulfilled]: 'Successfully deleted course',
  [IntlKeys.alertDeletingCourseFailed]: 'Failed to delete course',
  [IntlKeys.alertCloningCourseFailed]: 'Course clone failed',
  [IntlKeys.alertUpdatingPitchFailed]: 'Training update failed',
  [IntlKeys.alertCreatingPitchFailed]: 'Training creation failed',
  [IntlKeys.alertCopyingPitchToCourseFailed]: 'Training copied to course failed',
  [IntlKeys.alertDeletingPitchFailed]: 'Training delete failed',
  [IntlKeys.alertDeletingCommentFailed]: 'Sorry! failed to delete the comment, please try again',
  [IntlKeys.alertEditingCommentFailed]: 'Sorry! failed to update the comment, please try again',
  [IntlKeys.alertUsersCreatingFailed]: 'Users creation failed',
  [IntlKeys.alertDetachingMemberFulfilled]: 'Member successfully detached',
  [IntlKeys.alertDetachingMemberFailed]: 'Failed to detach',
  [IntlKeys.alertFavoritingCourseFailed]: 'Sorry! failed to add course to favorites.',
  [IntlKeys.alertFavoritingPitchFailed]: 'Sorry! failed to add training to favorites.',
  [IntlKeys.alertCreatingTeamTrainingFailed]: 'Team training creation failed',
  [IntlKeys.alertCreatingTeamTrainingFulfilled]: 'Successfully created team training',
  [IntlKeys.alertCoursesAttachingToWorkspaceFulfilled]: 'Successfully attached courses to workspaces',
  [IntlKeys.alertSlideUploadingFileFailed]: 'Uploading file failed',
  [IntlKeys.alertSlideUploadingFileFulfilled]: 'Successfully uploaded file',
  [IntlKeys.alertDeletingUserFulfilled]: 'User successfully deleted',
  [IntlKeys.alertDeletingUserFailed]: 'Failed to delete user',
  [IntlKeys.alertArchivingUserFulfilled]: 'User successfully archived',
  [IntlKeys.alertArchivingUserFailed]: 'Failed to archive user',
  [IntlKeys.alertArchivingUserFailedUserWorkspaceAdmin]:
    'Oops! Can not archive an admin user. Change the user role to Employee and try again.',
  [IntlKeys.alertUnArchivingUserFulfilled]: 'User successfully unArchived',
  [IntlKeys.alertUnArchivingUserFailed]: 'Failed to unArchive user',
  [IntlKeys.alertSlideUploadingPresentationFailed]: 'Failed to import presentation',
  [IntlKeys.alertSlideUploadingPresentationFulfilled]: 'Successfully imported presentation into content slide',
  [IntlKeys.alertEditingUserFulfilled]: 'User successfully edited',
  [IntlKeys.alertEditingUserFailed]: 'Failed to edit user',
  [IntlKeys.alertSlideUpdatingFailed]: 'Saving slides has failed. Please check the data and try again',
  [IntlKeys.alertMicrophoneIsDisabled]: 'Please enable your microphone to start the training',
  [IntlKeys.alertRevokeInvitationsFulfilled]: 'Successfully revoked invitations',
  [IntlKeys.alertRevokeInvitationsFailed]: 'Failed to revoke invitations',

  // Pitches
  [IntlKeys.pitches]: 'Trainings',
  [IntlKeys.pitchesNotAvailable]: 'This Training is not available',
  [IntlKeys.pitchesAddWord]: 'Add word',
  [IntlKeys.pitchesSelectFromLists]: 'Select from lists',
  [IntlKeys.pitchesNoListAvailable]: 'No lists available',
  [IntlKeys.pitchesAiFeedbackTab]:
    'AI feedback allows to set up automatically feedback directly after the training task. It includes: spoken words per minute, volume & clearness, number of used dont-words.',
  [IntlKeys.pitchesEvaluationCriteriaLabel]: 'Evaluation criterias',
  [IntlKeys.pitchesEvaluationCriteriaOverview]: 'Criteria overview',
  [IntlKeys.pitchesEvaluationCriteriaAddNew]: 'Add new evaluation criteria',
  [IntlKeys.pitchesEvaluationCriteriaUpdate]: 'Update evaluation criteria',
  [IntlKeys.PitchesAddImage]: 'Add Image',
  [IntlKeys.PitchesAddVideo]: 'Add Video',
  [IntlKeys.pitchesMaterialsTitle]: 'Materials',
  [IntlKeys.pitchesMaterialsAddMediaMessage]: 'add media to your task description',
  [IntlKeys.pitchesMaterialsAddDocument]: 'Add Document',
  [IntlKeys.pitchesObjectives]: 'Objectives',
  [IntlKeys.pitchesObjectivesWillAppear]: 'will appear as soon as the user starts task',
  [IntlKeys.pitchesPlaybook]: 'Playbook',
  [IntlKeys.pitchesPlaybookCreatePlaybookFollowing]: 'create a Playbook the user should follow',
  [IntlKeys.pitchesPlaybookAddPlaybookPart]: 'Add playbook part',
  [IntlKeys.pitchesPlaybookEpisodeDuration]: 'Episode duration',
  [IntlKeys.pitchesPlaybookAddReactions]: 'Add fade-ins',
  [IntlKeys.pitchesPlaybookTime]: 'Time of the episode',
  [IntlKeys.pitchesPlaybookTimetoAppear]: 'Time of the fade-in in the episode',
  [IntlKeys.pitchesPlaybookRandomReactionSelect]: 'Random fade-in select',
  [IntlKeys.pitchesPlaybookRandomReactionSecText]:
    'When random selected, it will ignore the time selected for each fade-in',
  [IntlKeys.pitchesReactionsAndObjections]: 'Fade-ins',
  [IntlKeys.pitchesTypeTabRecordVideoToTask]: 'Record a video response to the task',
  [IntlKeys.pitchesTypeTabRecordAudioToTask]: 'Record an audio reply to the task',
  [IntlKeys.pitchesTypeTabRecordTextToTask]: 'Prepare a short note to the task',
  [IntlKeys.pitchesTypeTabTimeBasedQuiz]: 'Create a time based quiz',
  [IntlKeys.pitchesTypeTabDisplayInformationImage]: 'Display an information image',
  [IntlKeys.pitchesTypeTabPlayLearningAudio]: 'Play a learning audio',
  [IntlKeys.pitchesTypeTabTask]: 'Task',
  [IntlKeys.pitchesTypeTabTaskAskingUserToDoTask]:
    'The user will be asked to do some task in the form of the selected option from the following categories',
  [IntlKeys.pitchesTypeTabKnowledgeSlide]: 'Knowledge Slide',
  [IntlKeys.pitchesTypeTabContentSlide]: 'Content Slide',
  [IntlKeys.pitchesTypeTabContentSlideSubtitle]: 'Provide some knowledge or information to prepare for the next task',
  [IntlKeys.pitchesTypeTabProvideKnowledgeSlide]: 'Provide a knowledge slide',
  [IntlKeys.pitchesTypeTabShowEducationalVideoRecordOrExample]: 'Show educational video record or task example',
  [IntlKeys.pitchesFormTabsType]: 'Type',
  [IntlKeys.pitchesFormTabsContent]: 'Content',
  [IntlKeys.pitchesFormTabsDynamicContent]: 'Dynamic Content',
  [IntlKeys.pitchesFormTabsQuizAnswers]: 'Correct answers',
  [IntlKeys.pitchesFormTabsAiFeedback]: 'AI-Feedback',
  [IntlKeys.pitchesWordsTabAddNewWord]: 'Add new word',
  [IntlKeys.pitchesConditionsTabHeadlineLabel]: 'Headline',
  [IntlKeys.pitchesConditionsTabEnterHeadline]: 'Enter headline',
  [IntlKeys.pitchesPublish]: 'Publish',
  [IntlKeys.pitchesPublishConfirmQuestion]: 'Publish the training?',
  [IntlKeys.pitchesPublishSubText]: 'Once you published the training, you won’t be able to update it',
  [IntlKeys.pitchesPublishConfirmBtnText]: 'Yes, Publish',
  [IntlKeys.pitchesAddNewSlides]: 'Add new slides',
  [IntlKeys.pitchesAiFeedbackTabSpokenWords]: 'Spoken words',
  [IntlKeys.pitchesAiFeedbackTabLongPause]: 'Long pause',
  [IntlKeys.pitchesAiFeedbackTabDoWords]: 'Do words',
  [IntlKeys.pitchesAiFeedbackTabDontWords]: 'Don’t words',
  [IntlKeys.pitchedFeedbackLowVoice]: 'Volume',
  [IntlKeys.pitchedFeedbackLowVoiceCount]: 'times with low voice',
  [IntlKeys.pitchedFeedbackFacialExpression]: 'Facial Expressions',
  [IntlKeys.pitchedFeedbackEmotionalAnalysis]: 'Emotional Analysis',
  [IntlKeys.pitcheDeviceSettingsTitle]: 'Media devices settings',
  [IntlKeys.pitchesDeviceSettingsCamera]: 'Camera',
  [IntlKeys.pitcheDeviceSettingsMicrophone]: 'Microphone',
  [IntlKeys.pitchesTrainingWelcome]: 'Welcome to the HeyPractice training',
  [IntlKeys.pitchesTrainingStart]: 'Start training',
  [IntlKeys.pitchesTrainingAbort]: 'Abort',
  [IntlKeys.pitchesTrainingStartTask]: 'Start task',
  [IntlKeys.pitchesTrainingEmptyTextTaskAlert]: 'Text field should be filled',
  [IntlKeys.pitchesTrainingTextTaskLabel]: 'Your answer',
  [IntlKeys.pitchesTeamTrainingStart]: 'Team training',
  [IntlKeys.pitchesTeamTrainingSubline]: 'Team Training starts in new tab on peterpitch.com',
  [IntlKeys.pitchesSlideSelectType]: 'Select slide type',
  [IntlKeys.pitchesSlideInfoSlide]: 'Slide',
  [IntlKeys.pitchesSlideInfoInfo]: 'Info',
  [IntlKeys.pitchesEvaluationCriteriaDescription]:
    'The task content would appear after you start the training. So don’t hesitate and let’s rock 🔥',
  [IntlKeys.pitchesSlideValidationError]:
    'The slide contains incorrect data. Please check the marked fields and try saving again after fixing them',
  [IntlKeys.pitchesSlideTrainingReviewLabel]: 'Review',
  [IntlKeys.pitchesSlideTrainingReviewCompletedTask]: 'Great job! You completed the task 🔥',
  [IntlKeys.pitchesSlideTrainingReviewEvaluateCompletedTask]: 'Please, evaluate how you completed the task!',
  [IntlKeys.pitchesSlideTrainigReviewCommentsInProgress]: 'Comments in progress',
  [IntlKeys.pitchesSlideTrainingReviewCommentsLabel]: 'Comments',
  [IntlKeys.pitchesSlideTrainingReviewAiFeedbackLabel]: 'AI feedback',
  [IntlKeys.pitchesSlideTrainingReviewAllAIAnalytics]: 'All AI-Analytics',
  [IntlKeys.pitchesSlideTrainingReviewShowAIAnalyticsDetails]: 'Show full analytics',
  [IntlKeys.pitchesSlideTrainingLowSound]: 'Low sound',
  [IntlKeys.pitchesSlideTrainingLowSoundChart]: 'Low sound chart',
  [IntlKeys.pitchesSlideTrainingFacialExpressionsChart]: 'Facial expressions chart',
  [IntlKeys.pitchesSlideTrainingWordsSpokenChart]: 'Word spoken chart',
  [IntlKeys.pitchesSlideTrainingWordsSpoken]: 'Spoken words',
  [IntlKeys.pitchesSlideTrainingLongPauseChart]: 'Long pause chart',
  [IntlKeys.pitchesSlideTrainingTotalPauses]: 'Overall pauses duration',
  [IntlKeys.pitchesSlideTrainingEmotionalAnalyticsChart]: 'Emotional analytics chart',
  [IntlKeys.pitchesTimerPanelTimeLimit]: 'Time limit:',
  [IntlKeys.pitchesSlideQuizType]: 'Quiz type',
  [IntlKeys.pitchesSlideSelectQuizType]: 'Please select Quiz type',
  [IntlKeys.pitchesSlideQuizPreferences]: 'Quiz Preferences',
  [IntlKeys.pitchesSlideQuizChoiceSettings]: 'Choice settings',
  [IntlKeys.pitchesSlideQuizChoiceSettingsOther]: '“Other” option',
  [IntlKeys.pitchesSlideQuizChoiceSettingsMultiple]: 'Multiple selection',
  [IntlKeys.pitchesSlideQuizChoiceSettingsRandomize]: 'Randomize',
  [IntlKeys.pitchesSlideQuizChoiceSettingsMaxCharactersLabel]: 'Maximum Characters',
  [IntlKeys.pitchesSlideQuizChoiceSettingsMaxCharactersTooltip]: 'Set a maximum characters allowed for the quiz answer',
  [IntlKeys.pitchesSlideQuizTypePlaceholder]: 'Select the type of the Quiz',
  [IntlKeys.pitchesSlideQuizOptionalCorrectAnswers]:
    'Do you want to set a correct answer for your quiz? This is optional',
  [IntlKeys.pitchesSlideQuizEnterAnswerPlaceholder]: 'Enter your answer',
  [IntlKeys.pitchesSlideQuizSelectPair]: 'Select the correct pair',
  [IntlKeys.pitchesSlideQuizPairInstruction]:
    'Match correct pairs out of the choices for evaluation. Users will not be able to able to see the correct opion when doing a training',
  [IntlKeys.pitchesSlidesQuizPairLabel]: 'Pair',
  [IntlKeys.pitchesSlidesQuizChoicesFirst]: 'First set of choices',
  [IntlKeys.pitchesSlidesQuizChoicesSecond]: 'Second set of choices',
  [IntlKeys.pitchesSlidesBackgroundMedia]: 'Background Media',
  [IntlKeys.pitchesSlidesBackgroundMediaDescription]:
    'Background media will appear to the user in the background while they are doing the training.',
  [IntlKeys.pitchesPitchNameAToZ]: 'Training name A-Z',
  [IntlKeys.pitchesPitchNameZToA]: 'Training name Z-A',
  [IntlKeys.pitchesSearchAPitch]: 'Search a training',
  [IntlKeys.pitchesRemovePitch]: 'Remove training',
  [IntlKeys.pitchesRemovePitchSubText]: 'Are you sure you want to remove the training?',
  [IntlKeys.pitchesCreatePitch]: 'Create training',
  [IntlKeys.pitchesAddPitch]: 'Add training',
  [IntlKeys.pitchesPitchName]: 'Training name',
  [IntlKeys.pitchesCover]: 'Cover',
  [IntlKeys.pitchesUpdatePitch]: 'Update training',
  [IntlKeys.pitchesNoPitchAvailable]: 'Looks like you don’t have any trainings',
  [IntlKeys.pitchesAddNewPitch]: 'Add new training',
  [IntlKeys.pitchesFinishTraining]: 'Finish training',
  [IntlKeys.pitchesProceedTraining]: 'Proceed',
  [IntlKeys.pitchesCompletedHeading]: 'Great job! You’ve completed all the tasks',
  [IntlKeys.pitchesCompletedDescription]:
    'You have done a very good job. We will notify your manager about the course completion and the records will be available for review, You can share with your teammates below here to get feedbacks from them as well',
  [IntlKeys.pitchesTrainingShare]: 'Share',
  [IntlKeys.pitchesSharePlaceholderRecords]: 'Select the records you want to share',
  [IntlKeys.pitchesSharePlaceholderUsers]: 'Enter the name of your colleague',
  [IntlKeys.pitchesShareConfirmButton]: 'Confirm & Share',
  [IntlKeys.pitchesShareMessagesSuccess]: 'Successfully shared the records with selected colleagues',
  [IntlKeys.pitchesShareMessagesErrorApi]: 'Oops! Something failed. Please try again',
  [IntlKeys.pitchesShareMessagesErrorUnknown]: 'Failed! Please try again later to share records from your dashboad',
  [IntlKeys.pitchesShareTitle]: 'Share the recordings with your colleagues',
  [IntlKeys.pitchTrainingEmptyComments]:
    'Comments you add will appear here. The comments also will get submitted with your training',
  [IntlKeys.pitchesCopyToCourse]: 'Duplicate',
  [IntlKeys.pitchesTrainingSubmitTask]: 'Submit the task',
  [IntlKeys.pitchesTestTraining]: 'Test Training',
  [IntlKeys.pitchesTrainingLeavePagePromptMessage]:
    'You haven’t submitted your entry. Are you sure want to leave the page',
  [IntlKeys.pitchesTrainingCanNotBeStartedSlidesDoesNotExist]: 'Unable to start empty training',
  [IntlKeys.pitchesPartialTrainingPreviewTitle]: 'Welcome back! You have finished part of the training',
  [IntlKeys.pitchesPartialTrainingPreviewCompletedTasks]: 'Completed tasks:',
  [IntlKeys.pitchesPartialTrainingPreviewContinueTraining]: 'Continue Training',
  [IntlKeys.pitchesPartialTrainingStartOver]: 'Start Over',
  [IntlKeys.pitchesPartialTrainingCompletedTitle]: 'You have successfully completed the training',
  [IntlKeys.pitchesPartialTrainingStartOverConfimTitle]: 'Are you sure you want to start over the training?',
  [IntlKeys.pitchesPartialTrainingStartOverConfimText]:
    'Starting over your training will not delete your previous submissions. You can start over the training for improving your skills and score',
  [IntlKeys.pitchesPartialTrainingStartOverFailed]: 'Failed to start over the training',
  [IntlKeys.pitchesImportPresentation]: 'Import',
  [IntlKeys.pitchesImportPresentationDescription]: 'Upload your own files from the computer',
  [IntlKeys.pitchesImportPresentationDocs]: 'Import PDF, PowerPoint or Keynote file',
  [IntlKeys.pitchAddPitchToCourses]: 'Add pitch to the courses',
  [IntlKeys.pitchesTrainingCustomerReactionHeading]: 'Customer says:',
  [IntlKeys.pitchesImportIntoSingleSlideLabel]: 'Single slide',
  [IntlKeys.pitchesImportIntoMultipleSlidesLabel]: 'Multiple slides',
  [IntlKeys.pitchesImportIntoSingleSlideSubText]: 'Import into single slide',
  [IntlKeys.pitchesImportIntoMultipleSlidesSubText]: 'Import each page into individual slides',
  [IntlKeys.pitchProgressTiltle]: 'Progress',
  [IntlKeys.pitchProgressCompletedTasks]: ' tasks completed',
  [IntlKeys.pitchRecordsEmptyRecords]: 'No records available',
  [IntlKeys.pitchesQuizCorrectAnswer]: 'Correct answer',
  [IntlKeys.pitchesQuizIncorrectAnswer]: 'Incorrect answer',
  [IntlKeys.pitchesQuizWaitingForReview]: 'Waiting for review',
  [IntlKeys.pitchesQuizCorrectAnswersQuantity]: 'quizzes are correct',
  [IntlKeys.pitchesQuizEmptyQuizCard]: 'No quiz answered yet',
  [IntlKeys.pitchesQuizYetToAnswer]: 'Yet to answer',
  [IntlKeys.pitchesPitchLeadershipBoard]: 'Pitch Leadership board',
  [IntlKeys.pitchesQuizScaleChoiceStartValue]: 'Start value',
  [IntlKeys.pitchesQuizScaleChoiceMaxLimit]: 'Max limit',
  [IntlKeys.pitchesQuizScaleChoiceStep]: 'Step',
  [IntlKeys.pitchesQuizAddChoice]: 'Add choice',
  [IntlKeys.pitchesQuizTip]: 'Tip',
  [IntlKeys.pitchesQuizPictureChoicePicture]: 'Picture',
  [IntlKeys.pitchesQuizReviewAllQuizzes]: 'Review all quizes',
  [IntlKeys.pitchesQuizIncorrectAnswers]: 'answers are wrong',
  [IntlKeys.pitchesQuizQuizzesProgress]: 'Quizzes progress:',
  [IntlKeys.pitchesQuizNumberOfTry]: 'Number of try:',
  [IntlKeys.pitchesVideoAudioTaskRetakeConfimTitle]: 'Are you sure you want to retake the record?',
  [IntlKeys.pitchesVideoAudioTaskRetakeConfimText]: 'Retaking your record will delete your previous record.',
  [IntlKeys.pitchesRecordYourselfOnVideo]: 'Video recording',
  [IntlKeys.pitchesRecordYourselfOnAudio]: 'Audio recording',
  [IntlKeys.pitchesRightAnswerInText]: 'Writing answer',

  // Quiz
  [IntlKeys.pitchesTrainingTimeUp]: 'Time up',
  [IntlKeys.pitchesTrainingEnterYourAnswer]: 'Enter to proceed',
  [IntlKeys.pitchesTrainingSelectYesOrNo]: 'Select yes or no',
  [IntlKeys.pitchesTrainingMatchingListTip]:
    'Match options. Click on circle near line for deleting an incorrect connection',
  [IntlKeys.pitchesTrainingSelectOneOption]: 'Select one option',
  [IntlKeys.pitchesTrainingSelectSeveralOptions]: 'Select several options',
  [IntlKeys.pitchesTrainingNextButton]: 'Next',
  [IntlKeys.pitchesTrainingOtherAnswer]: 'Other answer',
  [IntlKeys.pitchesTrainigResultWithWronAnswer]: '{wrongAnswers} of {totalAnswers} quiz answers are incorrect',
  [IntlKeys.pitchesTrainingAllAnswersAreCorrect]: 'You’ve managed to submit all the quiz answers correctly',
  [IntlKeys.pitchesTrainingRetryFailedQuizzes]: 'Retry the failed ones',
  [IntlKeys.pitchesTrainigResultWithCorrectAnswer]: '{correctAnswers}/{totalAnswers} answers are correct',
  [IntlKeys.pitchesTrainigResultWithPendingAnswer]:
    '{pendingAnswers}/{totalAnswers} answers will be reviewed by the trainer',
  [IntlKeys.pitchesQuizOptionsMultipleChoice]: 'Multiple choice',
  [IntlKeys.pitchesQuizOptionsPictureChoice]: 'Picture choice',
  [IntlKeys.pitchesQuizOptionsMatchingList]: 'Matching List',
  [IntlKeys.pitchesQuizOptionsYesOrNo]: 'Yes/No',
  [IntlKeys.pitchesQuizOptionsScaleChoice]: 'Scale choice',
  [IntlKeys.pitchesQuizOptionsShortText]: 'Short text',
  [IntlKeys.pitchesQuizShortTextMaxValueMoreThanZero]: 'Maximum Characters must be more than 0',

  // Records
  [IntlKeys.recordsNotAvailable]: 'This record is not available',
  [IntlKeys.recordsAiFeedbackAuthorPutExtraCondition]:
    'The author of this training has set some extra conditions, which can affect the score on top of the evaluation criteria',
  [IntlKeys.recordsAiFeedbackGeneratedWithRealTimeSpeech]:
    'The folowing result has been generated with real time speech analysis during the training process',
  [IntlKeys.recordsPreviewPreviewComesHere]: 'Preview comes here:',
  [IntlKeys.recordsReviewTabSuggestionToRate]:
    'Please use the following evaluation criteria to rate the perfomance of {authorFirstName}. This will help {authorFirstName} to strengthen the skilss in specific areas',
  [IntlKeys.recordsTaskInfoUsefulTrainingMaterials]: 'Useful training materials',
  [IntlKeys.recordsUserFeedbacksNoReviewAvailable]: 'Sorry! No reviews for this training yet!',
  [IntlKeys.recordsSearchARecord]: 'Search a record',
  [IntlKeys.recordsRecordDetailsSubmitted]: 'Submitted:',
  [IntlKeys.recordsRecordDetailsTraining]: 'Training:',
  [IntlKeys.recordsEmptyRecordsSubTitle]: 'Looks like there are no records available to review',
  [IntlKeys.recordEmptyComments]: 'No comments found for this recording',
  [IntlKeys.recordSubmitted]: 'Records submitted',
  [IntlKeys.recordsNew]: 'New Record',
  [IntlKeys.noRecordsAvailable]: 'No users submissions available',
  [IntlKeys.noRecordsAvailableForTask]: 'No records has been submitted for this task',
  [IntlKeys.newRecords]: '{count, plural, =0 {no new records} one {# new record} other {# new records}}',
  [IntlKeys.newTotalRecords]: 'New / All records',

  // Quiz Submissions
  [IntlKeys.quizSubmissionReviewed]: 'Quiz submission got reviewed',
  [IntlKeys.quizSubmissionReviewRequired]: 'Review required',
  [IntlKeys.quizSubmissionCorrectAnswers]: 'Correct Answers',
  [IntlKeys.quizSubmissionNoAnswerYet]: 'No answer has been submitted yet',
  [IntlKeys.quizSubmissionIncorrectAnswer]: 'Incorrect Answer',
  [IntlKeys.quizSubmissionPendingEvaluation]: 'Review required as no correct answer was provided',
  [IntlKeys.quizSubmissionCorrectAnswer]: 'Answer is correct',
  [IntlKeys.quizSubmissionMarkAs]: 'Mark answer as:',
  [IntlKeys.quizSubmissionCorrect]: 'Correct',
  [IntlKeys.quizSubmissionIncorrect]: 'Incorrect',
  [IntlKeys.quizSubmissionNotYet]: 'No Quiz Answerd',
  [IntlKeys.quizSubmissionExpectedAnswer]: 'Expected answer',

  // Review
  [IntlKeys.commentsPlaceholder]: 'Write your comment here',
  [IntlKeys.commentsCommentText]: 'Comment',

  // Settings
  [IntlKeys.settingsAccountSettingsAccountEmailTitle]: 'Account email',
  [IntlKeys.settingsAccountSettingsSetupNewPassword]: 'Set up new password',
  [IntlKeys.settingsAccountSettingsOldPassword]: 'Old password',
  [IntlKeys.settingsAccountSettingsEnterYourPassword]: 'Enter your password',
  [IntlKeys.settingsAccountSettingsPasswordNotMatch]: 'Password confirmation not match',
  [IntlKeys.settingsAccountSettingsEnterNewPassword]: 'Enter new password',
  [IntlKeys.settingsNotificationSettingsNotification]: 'Notification',
  [IntlKeys.settingsNotificationSettingsPitchNotifications]: 'Training notifications',
  [IntlKeys.settingsNotificationSettingsNewCommentsFromCoach]: 'New comments from coach',
  [IntlKeys.settingsNotificationSettingsFeedbackFromCoach]: 'Feedback from coach',
  [IntlKeys.settingsNotificationSettingsNewCommentsFromUsers]: 'New comments from users',
  [IntlKeys.settingsNotificationSettingsFeedbackFromUsers]: 'Feedback from users',
  [IntlKeys.settingsNotificationSettingsPersonalNotifications]: 'Personal notifications',
  [IntlKeys.settingsNotificationSettingsNewBadges]: 'New badges',
  [IntlKeys.settingsNotificationSettingsLeaderboardUpdates]: 'Leaderboard updates',
  [IntlKeys.settingsNotificationSettingsCommunityNotifications]: 'Community notifications',
  [IntlKeys.settingsProfileSettingsChangeProfilePhoto]: 'Change profile photo',
  [IntlKeys.settingsProfileSettingsUploadNewPhoto]: 'Upload new photo',
  [IntlKeys.settingsProfileSettingsPersonalInfo]: 'Personal info',
  [IntlKeys.settingsProfileSettingsTimeAndLanguage]: 'Time and language',
  [IntlKeys.settingsProfileSettingsTimeZone]: 'Time zone',
  [IntlKeys.settingsProfileSettingsRemoveProfilePhoto]: 'Remove profile photo',
  [IntlKeys.settingsProfileSettingsRemoveProfilePhotoSubText]: 'Are you sure you want to remove your profile photo?',
  [IntlKeys.settingsProfile]: 'Profile',
  [IntlKeys.settingsProfileDescription]: 'Add information about yourself',
  [IntlKeys.settingsAccount]: 'Account',
  [IntlKeys.settingsAccountDescription]: 'Edit your account settings and change your password here.',
  [IntlKeys.settingsNotifications]: 'Notifications',
  [IntlKeys.settingsNotificationsDescription]: 'Set up notifications from HeyPractice',
  [IntlKeys.settingsCustomization]: 'Customization',
  [IntlKeys.settingsCustomizationDescription]: 'Change the appearance of HeyPractice across all your workspaces',
  [IntlKeys.settingsDomain]: 'Domain',
  [IntlKeys.settingsDomainTitle]: 'Personal domain',
  [IntlKeys.settingsDomainAppearance]: 'The appearance',
  [IntlKeys.settingsDomainLoginTitle]: 'Login title',
  [IntlKeys.settingsDomainLoginDescription]: 'Login description',
  [IntlKeys.settingsDomainLogo]: 'Logo',
  [IntlKeys.settingsDomainLogoDefault]: 'Default logo',
  [IntlKeys.settingsDomainImage]: 'Login image',
  [IntlKeys.settingsDomainImageDefault]: 'Default image',
  [IntlKeys.settingsDomainThemeCustom]: 'Custom theme',
  [IntlKeys.settingsDomainThemeActiveColor]: 'Active color',
  [IntlKeys.settingsDomainThemeBGColor]: 'BG color',
  [IntlKeys.settingsPasswordMaxCharacter]: 'Password must be no longer than 20 characters',
  [IntlKeys.domainNameValidationError]: 'Domain name is invalid',
  [IntlKeys.domainNameUpdateSuccess]: 'Domain name updated successfully',
  [IntlKeys.domainNameUpdateFailure]: 'Domain name update failed',

  // Teams
  [IntlKeys.teamsCreateNewTeam]: 'Create new team',
  [IntlKeys.teamsSearchAndAddMembers]: 'Search and add members',
  [IntlKeys.teamsSelectMembers]: 'Select members',
  [IntlKeys.teamsNoMemberAvailable]: 'Looks like you don’t have any member',
  [IntlKeys.teamsYouCanAddNewMember]: 'You can easily add new member',
  [IntlKeys.teamsConnectedCourses]: 'Connected courses',
  [IntlKeys.teamsFilterTeamNameAtoZ]: 'Team name A-Z',
  [IntlKeys.teamsFilterTeamNameZtoA]: 'Team name Z-A',
  [IntlKeys.teamsFilterNumberOfMembersAsc]: 'No. of Members ↑',
  [IntlKeys.teamsFilterNumberOfMembersDesc]: 'No. of Members ↓',
  [IntlKeys.teamFilterCreatedAtDesc]: 'Created date ↓',
  [IntlKeys.teamFilterCreatedAtAsc]: 'Created date ↑',
  [IntlKeys.teamsEditTeam]: 'Edit team',
  [IntlKeys.teamsRemoveTeam]: 'Remove team',
  [IntlKeys.teamsRemoveTeamSubText]: 'Are you sure you want to remove team?',
  [IntlKeys.teamsMemeberMemberNameAtoZ]: 'Member name A-Z',
  [IntlKeys.teamsMemeberMemberNameZtoA]: 'Member name Z-A',
  [IntlKeys.teamsMemeberMemberProgressAsc]: 'Member Progress ↑',
  [IntlKeys.teamsMemeberMemberProgressDesc]: 'Member Progress ↓',
  [IntlKeys.teamsMemeberMemberNewRecords]: 'Member latest activity',
  [IntlKeys.teamsMemeberMemberOldRecords]: 'Member oldest activity',
  [IntlKeys.teamsSearchAMember]: 'Search a member',
  [IntlKeys.teamsDetachFromTeam]: 'Detach from the team',
  [IntlKeys.teamsRemoveMemberTitle]: 'Remove member',
  [IntlKeys.teamsRemoveMemberSubText]: 'Are you sure you want to remove the member?',
  [IntlKeys.teamFilterCreatedAtDesc]: 'Created date ↓',
  [IntlKeys.teamFilterCreatedAtAsc]: 'Created date ↑',

  // User Management
  [IntlKeys.usersCreateFulfilled]: 'Users successfully created',
  [IntlKeys.usersInvitationFulfilled]: 'User invitations were successfully sent to the emails',
  [IntlKeys.usersInviteUser]: 'Invite user',
  [IntlKeys.usersCreateUser]: 'Create user',
  [IntlKeys.usersCreateUserModal]: 'Create user modal',
  [IntlKeys.usersUploadCsv]: 'Import users by CSV / JSON',
  [IntlKeys.usersNameAtoZ]: 'User name A-Z',
  [IntlKeys.usersNameZtoA]: 'User name Z-A',
  [IntlKeys.usersProgressAsc]: 'User progress ↓',
  [IntlKeys.usersProgressDesc]: 'User progress ↑',
  [IntlKeys.usersActivityAsc]: 'User activity ↓',
  [IntlKeys.usersActivityDesc]: 'User activity ↑',
  [IntlKeys.usersOldestUsers]: 'Oldest users',
  [IntlKeys.usersRecentlyAdded]: 'Recently Added',
  [IntlKeys.usersInviteNewMembers]: 'Invite new members',
  [IntlKeys.usersWayOfMembersCreatingAccout]: 'Members will create an account via the sent email or invitation link',
  [IntlKeys.usersDelete]: 'Delete user',
  [IntlKeys.usersDeleteSubline]: 'Are you sure you want to delete the user?',
  [IntlKeys.usersDeactivate]: 'Deactivate',
  [IntlKeys.usersResendPassword]: 'Resend password',
  [IntlKeys.usersArchive]: 'Archive user',
  [IntlKeys.usersUnArchive]: 'Unarchive user',
  [IntlKeys.usersArchiveSubline]: 'Are you sure you want to archive the user?',
  [IntlKeys.usersArchiveDescription]:
    'Archived user will no longer be able to access HeyPractice. But, the records submitted will still be available to verify and review. The user can be unarchived later to give back the access again',
  [IntlKeys.usersUnArchiveSubline]: 'Are you sure you want to Un-Archive the user?',
  [IntlKeys.usersDeleteDescription]:
    'Deleting a user will delete all the information including personal information, training records, reviews received, score and statistics of that user',
  [IntlKeys.usersEditUser]: 'Edit user',
  [IntlKeys.usersActive]: 'Active users',
  [IntlKeys.invitedBy]: 'Invited By',
  [IntlKeys.usersRevokeInvitations]: 'Revoke Invitations',
  [IntlKeys.usersRevokeInvitationsConfimTitle]: 'Are you sure you want to revoke the invitations?',

  // Workspace
  [IntlKeys.workspaceAdd]: 'Add workspace',
  [IntlKeys.workspaceEdit]: 'Edit workspace',
  [IntlKeys.workspaceName]: 'Workspace name',
  [IntlKeys.workspaceTabsUsersManagement]: 'Users Management',
  [IntlKeys.workspaceTabsUsersManagementDescription]: 'Manage members in your workspace',
  [IntlKeys.workspaceTabsBilling]: 'Billing',
  [IntlKeys.workspaceTabsBillingDescription]: 'Set up payments details',
  [IntlKeys.workspaceSearch]: 'Search workspace',
  [IntlKeys.workspaceAttachCoursesToWorkspace]: 'Attach courses to workspaces',
  [IntlKeys.workspaceClearSelection]: 'Clear selection',
  [IntlKeys.workspaceNameAToZ]: 'Workspace name A-Z',
  [IntlKeys.workspaceNameZToA]: 'Workspace name Z-A',
  [IntlKeys.workspaceUserCountAsc]: 'User count ↑',
  [IntlKeys.workspaceUserCountDesc]: 'User count ↓',
  [IntlKeys.workspaceLastUpdates]: 'Last updates',
  [IntlKeys.workspaceOldUpdates]: 'Old updates',
  //
  [IntlKeys.workspaceRemoveWorkspace]: 'Remove workspace',
  [IntlKeys.workspaceArchiveWorkspace]: 'Archive workspace',
  [IntlKeys.workspaceUnArchiveWorkspace]: 'UnArchive workspace',
  [IntlKeys.workspaceRemovingWorkspaceConfirmQuestion]: 'Are you sure you want to remove the workspace?',
  [IntlKeys.workspaceRemovingWorkspaceDescription]:
    'All the information associated with the workspace will be deleted including the user recordings and scores associated with the courses',
  [IntlKeys.workspaceArchivingWorkspaceConfirmQuestion]: 'Are you sure you want to archive the workspace?',
  [IntlKeys.workspaceUnArchivingWorkspaceConfirmQuestion]: 'Are you sure you want to un archive the workspace?',
  [IntlKeys.workspaceConfirmationPhrase]: 'Please enter the workspace name to confirm the deletion',
  [IntlKeys.workspaceCreateSuccess]: 'Workspace created successfully',
  [IntlKeys.workspaceCreateFailure]: 'Workspace creation failed',
  [IntlKeys.workspaceUpdateSuccess]: 'Workspace updated successfully',
  [IntlKeys.workspaceUpdateFailure]: 'Workspace updating failed',
  [IntlKeys.workspaceDeletingSuccess]: 'Workspace deleted successfully',
  [IntlKeys.workspaceDeletingFailure]: 'Workspace deleting failed',
  [IntlKeys.workspaceAll]: 'All Workspaces',
  [IntlKeys.workspaceActive]: 'Active Workspaces',
  [IntlKeys.workspaceArchived]: 'Archived Workspaces',
  [IntlKeys.workspaceLead]: 'Lead Workspaces',

  //Mapping
  [IntlKeys.commentFromCoach]: 'left comment at your record',
  [IntlKeys.commentFromUser]: 'left comment at your record',
  [IntlKeys.feedbackFromCoach]: 'gave feedback for your submission',
  [IntlKeys.feedbackFromUser]: 'gave feedback for your submission',
  [IntlKeys.newBadges]: 'got new badges',
  [IntlKeys.leaderboardUpdated]: 'leadboard got updated',
  [IntlKeys.shareContentFile]: 'shared a file with you',
  [IntlKeys.shareContentFolder]: 'shared a folder with you',
  [IntlKeys.shareContentList]: 'content list shared',
  [IntlKeys.shareRecord]: 'shared a record with you',
  [IntlKeys.newCoursePublished]: 'published a new course',
  [IntlKeys.userAttachedToCourse]: 'shared a new course with you',

  //Face expression mapping
  [IntlKeys.happyFace]: 'Happiness detected',
  [IntlKeys.sadFace]: 'You seem sad',
  [IntlKeys.neutralFace]: 'You look neutral',
  [IntlKeys.disgustFace]: 'Are you disgusted?',
  [IntlKeys.fearFace]: "Don't be afraid!",
  [IntlKeys.angryFace]: 'Oh, oh, anger detected.',
  [IntlKeys.supriseFace]: 'Surprised?',

  [IntlKeys.happy]: 'Happy',
  [IntlKeys.sad]: 'Sad',
  [IntlKeys.neutral]: 'Neutral',
  [IntlKeys.disgust]: 'Disgusted',
  [IntlKeys.fear]: 'Afraid',
  [IntlKeys.angry]: 'Angry.',
  [IntlKeys.suprise]: 'Surprised',

  [IntlKeys.competitive]: 'Competitive',
  [IntlKeys.composed]: 'Composed',
  [IntlKeys.cooperative]: 'Cooperative',
  [IntlKeys.directing]: 'Directing',
  [IntlKeys.empathic]: 'Empathic',
  [IntlKeys.formal]: 'Formal',
  [IntlKeys.friendly]: 'Friendly',
  [IntlKeys.goal_oriented]: 'Goal Oriented',
  [IntlKeys.positive]: 'Positive',
  [IntlKeys.self_confident]: 'Self Confident',
  [IntlKeys.professional]: 'Professional',

  // Notifications
  [IntlKeys.notificationsMarkAllAsRead]: 'Mark all as read',
  [IntlKeys.notificationsNoNotificationAvailable]: 'No notifications available',
};

export default translations;
