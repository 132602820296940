// utils
import { saveAuthTokensToLS } from 'lib/localStorage';
import { NetworkErrors } from 'lib/http/index';
import { createTokenRevalidationWorker } from 'lib/http/utils/createTokenRevalidationWorker';
// domain
import { store } from 'portal/workspace/domain/store';
import { signOutAction, updateTokensAction } from 'portal/workspace/domain/auth/actions';
import { authHeadersSelector } from 'portal/workspace/domain/auth/selectors';

const revalidateWorkspaceToken = createTokenRevalidationWorker({
  getAuthTokens: () => {
    return authHeadersSelector(store.getState());
  },
  //
  getRevalidationUrl: () => require('./workspaceRestClient').workspaceRestClient.refreshToken.url as string,
  onRevalidationSuccess: ({ data }) => {
    store.dispatch(updateTokensAction(data));
    saveAuthTokensToLS(data);
  },
  onRevalidationFailure: ({ response }) => {
    if (response?.data) {
      const { statusCode } = response.data;

      // refreshToken expired
      if (statusCode === 401) {
        store.dispatch(signOutAction());
        throw NetworkErrors.RefreshTokenExpired;
      }
    }
  },
  //
  tokenExpiryTime: 300, // seconds
});

export default revalidateWorkspaceToken;
