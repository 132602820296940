import { LangCode, supportedLanguages } from 'localization';

const localStorageLanguage: LangCode = localStorage.getItem('selectedLocale') as LangCode;

const verifiedLanguageCode = (code: string): LangCode => {
  if (supportedLanguages[code as LangCode]) {
    return code as LangCode;
  }
  return 'en-US';
};

export const fallbackLocale: LangCode = verifiedLanguageCode(
  // @ts-expect-error window.navigator.userLanguage is for IE support, but is outdated
  localStorageLanguage || window.navigator.userLanguage || window.navigator.language,
);
