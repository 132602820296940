import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Button } from '@mui/material';
// types
import { UserRoles } from 'types/users';

// utils
import { isCurrentRoute } from 'routes';
import { getRoutesForUser, getUrlWithParams } from 'portal/workspace/routes';
import { useSidebarItems } from 'utils/useSidebarItems';
import { IntlKeys } from 'localization/keys';
import { checkRouteAllowed } from 'portal/workspace/lib/acl/checkRouteAllowed';
import useCoachWorkspaceAuth from 'portal/workspace/hooks/useCoachWorkspaceAuth';
// domain
import { authMetaSelector, authUserSelector } from 'portal/workspace/domain/auth/selectors';
import { signOutAction } from 'portal/workspace/domain/auth/actions';
import { domainsDomainSelector } from 'portal/workspace/domain/domain/selectors';
// components
import ConfirmDialog from 'components/confirm-dialog';
// styles
import { makeStyles } from '@mui/styles';
import cx from 'clsx';
import logo from 'assets/logo.svg';
import { ReactComponent as LogOut } from 'assets/icons/logOut.svg';
import { ReactComponent as ChevronLeft } from 'assets/icons/chevronLeft.svg';
import logoutImage from 'assets/generic-images/logout-modal-image.png';

interface OwnProps {
  isMenu?: boolean;
}
const Sidebar: React.FC<OwnProps> = ({ isMenu }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  //
  const domain = useSelector(domainsDomainSelector);
  const meta = useSelector(authMetaSelector);
  const user = useSelector(authUserSelector);
  //

  const isCoach = user?.role === UserRoles.Coach;
  const userRoutes = getRoutesForUser(user);
  const { workspace, goOutOfWorkspace } = useCoachWorkspaceAuth();

  const [isLogoutModalOpen, setLogoutModalOpen] = useState(false);
  const { mainNavigation, additionalNavigation } = useSidebarItems({
    //
    routes: userRoutes,
    user,
    checkRouteAllowed,
    selectedWorkspace: workspace,
    getUrlWithParams,
  });

  const openLogoutModal = useCallback(() => {
    setLogoutModalOpen(true);
  }, []);

  const closeLogoutModal = useCallback(() => {
    setLogoutModalOpen(false);
  }, []);

  const handleSignOut = useCallback(() => {
    dispatch(signOutAction());
    closeLogoutModal();
  }, [dispatch, closeLogoutModal]);

  return (
    <div className={classes.root}>
      {!isMenu && <img src={domain?.logo ? domain.logo : logo} alt="logo" className={classes.logo} />}

      <nav className={cx(classes.navigation, classes.navMain)}>
        {isCoach && workspace && (
          <>
            <div className={classes.menuTitle}>{workspace?.name}</div>
            <Button
              startIcon={<ChevronLeft />}
              className={classes.menuTitle}
              disabled={meta.isLoading}
              onClick={goOutOfWorkspace}
            >
              {formatMessage({ id: IntlKeys.back })}
            </Button>
          </>
        )}
        {mainNavigation.map(({ routeKey, path, Icon, IconActive, text }) => {
          return (
            <NavLink
              key={path}
              to={path}
              exact={routeKey == 'home' || routeKey === 'workspaceDetails' ? true : false}
              className={classes.menuItem}
              activeClassName={classes.menuItemActive}
            >
              {isCurrentRoute(pathname, path, routeKey == 'home' || routeKey === 'workspaceDetails' ? true : false)
                ? IconActive
                : Icon}{' '}
              {text}
            </NavLink>
          );
        })}
      </nav>

      <nav className={cx(classes.navigation, classes.navSecondary)}>
        {additionalNavigation.map(({ path, Icon, IconActive, text }) => (
          <NavLink
            key={path}
            to={path}
            exact={false}
            className={classes.menuItem}
            activeClassName={classes.menuItemActive}
          >
            {isCurrentRoute(pathname, path, false) ? IconActive : Icon} {text}
          </NavLink>
        ))}

        <a onClick={openLogoutModal} className={classes.menuItem}>
          <LogOut />
          {formatMessage({ id: IntlKeys.logOut })}
        </a>
      </nav>

      <ConfirmDialog
        title={formatMessage({ id: IntlKeys.logOut })}
        subText={formatMessage({ id: IntlKeys.logOutConfirmQuestion })}
        confirmBtnText={formatMessage({ id: IntlKeys.logOutConfirmBtnText })}
        imageSrc={logoutImage}
        isOpen={isLogoutModalOpen}
        onConfirm={handleSignOut}
        onCancel={closeLogoutModal}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => {
  const activeMenuItem = {
    backgroundColor: theme.colors.lightBlue,
    color: theme.colors.primary,

    '& > svg > path': {
      fill: 'currentColor',
    },
  };

  return {
    root: {
      height: '100vh',
      borderRight: `1px solid ${theme.colors.stroke}`,
      background: 'white',
    },

    logo: {
      width: 126,
      maxHeight: 126,
      margin: '24px 20px',
      objectFit: 'scale-down',
    },

    navigation: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      padding: 8,
    },

    navMain: {},
    navSecondary: {
      position: 'absolute',
      paddingTop: 16,
      bottom: 0,
      borderTop: `1px solid ${theme.colors.stroke}`,
      borderRight: `1px solid ${theme.colors.stroke}`,
      background: theme.colors.white,
    },

    menuTitle: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: 13,
      border: 'none',
      background: theme.colors.white,

      fontFamily: theme.fonts.gilmer,
      fontSize: 14,

      borderRadius: 5,
      transition: 'all 0.3s ease-out',

      cursor: 'pointer',
      color: theme.colors.secondary,
      textDecoration: 'none',
      fontWeight: 600,
    },

    menuItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: 13,

      fontFamily: theme.fonts.gilmer,
      fontSize: 14,

      borderRadius: 5,
      transition: 'all 0.3s ease-out',

      cursor: 'pointer',
      color: theme.colors.secondary,
      textDecoration: 'none',

      '& > svg': {
        marginRight: 10,
        marginTop: -1,
        width: 17,
        '& > path': {
          transition: 'all 0.3s ease-out',
        },
      },

      '&:hover': activeMenuItem,
    },

    menuItemActive: activeMenuItem,
  };
});

export default React.memo(Sidebar);
