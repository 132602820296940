import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Params } from 'lib/http';
import { createApiCall } from 'lib/http/utils/createApiCall';
import { workspaceRestClient } from 'portal/workspace/lib/http';
import { uploadImage } from 'portal/workspace/lib/uploadFile';
import { DomainPayloadType, DomainType } from 'types/domains';

export const fetchDomainsWorker = async <T>(requestPayload: T) => {
  const callApi = createApiCall<DomainType[]>({
    api: workspaceRestClient.getDomainList,
  });
  const { data } = await callApi(requestPayload);
  return data;
};

export const getPublicDomainAction = createAsyncThunk<DomainType, Params>(
  'domains/getPublicDomain',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<DomainType>({
      api: workspaceRestClient.getPublicDomain,
    });
    try {
      const { data } = await callApi(requestPayload);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const checkDomainAction = createAsyncThunk<void, Params>(
  'domains/checkDomain',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<void>({
      api: workspaceRestClient.checkDomain,
    });
    try {
      const { data } = await callApi(requestPayload);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getDomainAction = createAsyncThunk<DomainType, Params>(
  'domains/getDomain',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<DomainType>({
      api: workspaceRestClient.getDomain,
    });
    try {
      const { data } = await callApi(requestPayload);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const ensureCoverUploaded = async (cover: DomainPayloadType['cover']) => {
  const coverUrl =
    cover instanceof Blob
      ? await uploadImage({
          image: cover,
          name: 'DomainCover',
        })
      : cover;

  return coverUrl || null;
};
export const ensureLogoUploaded = async (logo: DomainPayloadType['logo']) => {
  const logoUrl =
    logo instanceof Blob
      ? await uploadImage({
          image: logo,
          name: 'DomainLogo',
        })
      : logo;

  return logoUrl || null;
};

export const createDomainAction = createAsyncThunk<DomainType, DomainPayloadType>(
  'domains/createDomain',
  async (requestPayload, { rejectWithValue }) => {
    const { cover, logo, ...rest } = requestPayload as DomainPayloadType;

    const callApi = createApiCall<DomainType>({
      api: workspaceRestClient.createDomain,
    });

    try {
      const { data } = await callApi({
        ...rest,
        logo: await ensureLogoUploaded(logo),
        cover: await ensureCoverUploaded(cover),
      });

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateDomainAction = createAsyncThunk<DomainType, Params | undefined>(
  'domains/updateDomain',
  async (requestPayload, { rejectWithValue }) => {
    const { cover, logo, ...rest } = requestPayload as DomainPayloadType;

    const callApi = createApiCall<DomainType>({
      api: workspaceRestClient.updateDomain,
    });

    try {
      const { data } = await callApi({
        ...rest,
        logo: await ensureLogoUploaded(logo),
        cover: await ensureCoverUploaded(cover),
      });

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const deleteDomainAction = createAsyncThunk<{ success: boolean }, Params>(
  'domains/deleteDomain',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<{ success: boolean }>({
      api: workspaceRestClient.deleteDomain,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const domainsSetIsStaleAction = createAction<boolean>('courses/setIsStale');
export const resetDomainAction = createAction('courses/reset');
