import enUS from './dictionary/en-US';
import deDE from './dictionary/de-DE';
import { createIntl, createIntlCache, IntlShape } from 'react-intl';
export * from './keys';

import DeIcon from './assets/de_flag.png';
import EnIcon from './assets/en_flag.png';

export interface Locale {
  label: string;
  icon: string;
}

export type LangCode = 'en-US' | 'de-DE';

const translations = { 'en-US': enUS, 'de-DE': deDE };

export const supportedLanguages: Record<LangCode, Locale> = {
  'en-US': {
    label: 'English',
    icon: EnIcon,
  },
  'de-DE': {
    label: 'Deutsch',
    icon: DeIcon,
  },
};

let intl: IntlShape;

export const initializeIntl = (locale: LangCode) => {
  const cache = createIntlCache();
  const messages = translations[locale];
  intl = createIntl({ locale, messages }, cache);
};

export { intl };

export default translations;
