import { bindUploadFile, bindUploadImage, OnProgressFileInfoPayload } from 'lib/uploadFile';
// utils
import { backofficeRestClient } from 'portal/backoffice/lib/http';
import { intl } from 'localization';
// domain
import { store } from 'portal/backoffice/domain/store';
import { updateFileUploadProgressAction } from 'portal/backoffice/domain/general/actions';

const onUploadProgress = ({ loaded, total }: ProgressEvent, { name, relatedEntity, id }: OnProgressFileInfoPayload) => {
  // entity present to add some additional info?
  const entityIntlKey = relatedEntity?.entityIntlKey;
  //
  const fileInfoKey = entityIntlKey ? `${intl.formatMessage({ id: entityIntlKey })}: ${name}` : name;
  const progress = Math.round((loaded / total) * 100);
  if (progress === 100) {
    // Show 100% for a couple of seconds before removing
    setTimeout(() => {
      store.dispatch(
        updateFileUploadProgressAction({
          id: id,
          fileName: name,
          progress: progress,
          hasFinished: true,
        }),
      );
    }, 2000);
  }

  store.dispatch(
    updateFileUploadProgressAction({
      id: id,
      entity: fileInfoKey,
      fileName: name,
      progress: progress,
    }),
  );
};

export const uploadImage = bindUploadImage(backofficeRestClient.uploadImage);
export const uploadFile = bindUploadFile(backofficeRestClient.uploadFile, onUploadProgress);
