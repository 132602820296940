import { combineReducers } from '@reduxjs/toolkit';
// actions
import { signOutAction } from './auth/actions';
// reducers
import routerReducer from './router/reducer';
import { authReducer } from './auth/reducer';
import { coursesReducer } from './courses/reducer';
import { pitchesReducer } from './pitches/reducer';
import contentReducer from './content/reducer';
import { companiesReducer } from './companies/reducer';
import { workspacesReducer } from './workspaces/reducer';
import { userReducer } from './users/reducer';
import { leaderboardReducer } from './leaderboard/reducer';
import { teamReducer } from './teams/reducer';
import { generalReducer } from './general/reducer';
import { notificationReducer } from './notifications/reducer';

export const createRootReducer = () => {
  const rootReducer = combineReducers({
    router: routerReducer,
    //
    auth: authReducer,
    courses: coursesReducer,
    pitches: pitchesReducer,
    content: contentReducer,
    companies: companiesReducer,
    workspaces: workspacesReducer,
    teams: teamReducer,
    users: userReducer,
    leaderboard: leaderboardReducer,
    general: generalReducer,
    notifications: notificationReducer,
  });

  const resettableReducer: typeof rootReducer = (state, action) => {
    if (action.type === signOutAction.fulfilled.type) {
      state = undefined;
    }

    return rootReducer(state, action);
  };

  return resettableReducer;
};
