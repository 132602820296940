import { api } from './api';
import { makeUrl } from 'lib/http/utils/url';

export const backofficeRestClient = {
  // updateExample: api('post', makeUrl`/examples/${'exampleId'}`), // exampleId -> from action.payload.params
  // auth
  signIn: api('post', '/admin/auth/sign-in'),
  signInScorm: api('post', '/admin/auth/sign-in/scorm'),
  signUp: api('post', '/admin/auth/sign-up'),
  forgotPassword: api('post', '/admin/auth/reset-password'),
  resetPassword: api('post', '/admin/auth/set-new-password'),
  refreshToken: api('post', '/admin/auth/refresh-token'),
  inviteMember: api('post', '/admin/auth/invite'),
  generateInviteLink: api('post', '/admin/auth/invite/link'),
  checkInviteToken: api('get', '/admin/auth/invite/token/check'),
  //
  getUsers: api('get', '/admin/users'),
  getUser: api('get', makeUrl`/admin/users/${'id'}`),
  createUser: api('post', '/admin/users'),
  patchUser: api('patch', makeUrl`/admin/users/${'id'}`),
  deleteUser: api('delete', makeUrl`/admin/users/${'id'}`),
  importUsers: api('post', makeUrl`/admin/users/import`),
  getCurrentUser: api('get', '/users/me'),
  updateUserAvatar: api('patch', '/users/me/avatar'),
  uploadUserAvatar: api('post', '/users/me/avatar/upload'),
  updateUserStatus: api('patch', makeUrl`/admin/users/${'id'}/status`),
  updateUser: api('put', makeUrl`/admin/users/${'id'}`),

  // courses
  getCourseList: api('get', '/admin/courses'),
  getCourse: api('get', makeUrl`/admin/courses/${'id'}`),
  createCourse: api('post', '/admin/courses'),
  updateCourse: api('put', makeUrl`/admin/courses/${'id'}`),
  patchCourse: api('patch', makeUrl`/admin/courses/${'id'}`),
  deleteCourse: api('delete', makeUrl`/admin/courses/${'id'}`),
  shareCourse: api('post', '/admin/courses/share'),
  unshareCourse: api('post', '/admin/courses/unshare'),
  exportToScormCourse: api('post', makeUrl`/admin/courses/${'id'}/export/scorm`),
  favoriteCourse: api('post', makeUrl`/favorites/courses/${'id'}`),
  defavoriteCourse: api('delete', makeUrl`/favorites/courses/${'id'}`),
  attachTeamsToCourse: api('patch', makeUrl`/${'id'}/teams/attach-courses`),
  detachTeamsFromCourse: api('patch', makeUrl`/${'id'}/teams/detach-courses`),
  attachMembersToCourse: api('patch', makeUrl`/courses/attach-members`),
  detachMembersFromCourse: api('patch', makeUrl`/courses/detach-members`),
  // pitches
  getPitch: api('get', makeUrl`/admin/pitches/${'pitchId'}`),
  getPitchList: api('get', '/admin/pitches'),
  createPitch: api('post', '/admin/pitches'),
  updatePitch: api('put', makeUrl`/admin/pitches/${'id'}`),
  deletePitch: api('delete', makeUrl`/admin/pitches/${'id'}`),
  patchPitch: api('patch', makeUrl`/admin/pitches/${'id'}`),
  pitchCopyToCourse: api('post', makeUrl`/admin/pitches/${'id'}/copy-to-course`),
  exportToScormPitch: api('post', makeUrl`/admin/pitches/${'id'}/export/scorm`),
  pitchesCopyToCourses: api('post', `/admin/pitches/copy-to-courses`),
  favoritePitch: api('post', makeUrl`/favorites/pitches/${'id'}`),
  defavoritePitch: api('delete', makeUrl`favorites/pitches/${'id'}`),
  uploadPPT: api(
    'post',
    makeUrl`/admin/pitches/${'id'}/import/ppt?importAsMaterialsToOneSlide=${'importAsMaterialsToOneSlide'}`,
  ),

  // slides
  getPitchSlides: api('get', makeUrl`/admin/pitches/${'pitchId'}/slides`),
  getPitchSlide: api('get', makeUrl`/admin/pitches/${'pitchId'}/slides/${'slideId'}`),
  createPitchSlide: api('post', makeUrl`/admin/pitches/${'pitchId'}/slides`),
  updatePitchSlide: api('put', makeUrl`/admin/pitches/${'pitchId'}/slides/${'slideId'}`),
  deletePitchSlide: api('delete', makeUrl`/admin/pitches/${'pitchId'}/slides/${'slideId'}`),
  // content
  // lists
  getContentLists: api('get', '/admin/content/lists'),
  createList: api('post', '/admin/content/lists'),
  updateList: api('put', makeUrl`/admin/content/lists/${'id'}`),
  deleteList: api('delete', makeUrl`/admin/content/lists/${'id'}`),
  // files
  getContentFiles: api('get', '/admin/content/files'),
  createFile: api('post', '/admin/content/files'),
  updateFile: api('put', makeUrl`/admin/content/files/${'id'}`),
  deleteFile: api('delete', makeUrl`/admin/content/files/${'id'}`),
  //
  getContentFolders: api('get', '/admin/content/folders'),
  createFolder: api('post', '/admin/content/folders'),
  updateFolder: api('put', makeUrl`/admin/content/folders/${'id'}`),
  deleteFolder: api('delete', makeUrl`/admin/content/folders/${'id'}`),
  getFolder: api('get', makeUrl`admin/content/folders/${'id'}`),
  // tags
  getTagList: api('get', '/tags'),
  // teams
  createTeam: api('post', makeUrl`/admin/workspaces/${'workspaceId'}/teams`),
  getTeams: api('get', makeUrl`/admin/workspaces/${'workspaceId'}/teams`),
  getTeam: api('get', makeUrl`/admin/workspaces/${'workspaceId'}/teams/${'teamId'}`),
  updateTeam: api('put', makeUrl`/admin/workspaces/${'workspaceId'}/teams/${'id'}`),
  deleteTeam: api('delete', makeUrl`/admin/workspaces/${'workspaceId'}/teams/${'id'}`),
  detachCourses: api('patch', makeUrl`/teams/detach-courses`),
  attachCourses: api('patch', makeUrl`/teams/attach-courses`),
  detachCoursesFromTeam: api('patch', makeUrl`/teams/${'id'}/detach-courses`),
  attachCoursesToTeam: api('patch', makeUrl`/teams/${'id'}/attach-courses`),
  getWorkspaceTeams: api('get', makeUrl`/admin/workspaces/${'workspaceId'}/teams`),

  // workspaces
  getWorkspaces: api('get', '/admin/workspaces'),
  createWorkspace: api('post', '/admin/workspaces'),
  getWorkspace: api('get', makeUrl`/admin/workspaces/${'id'}`),
  updateWorkspace: api('put', makeUrl`/admin/workspaces/${'id'}`),
  updateWorkspaceStatus: api('patch', makeUrl`/admin/workspaces/${'id'}`),
  deleteWorkspace: api('delete', makeUrl`/admin/workspaces/${'id'}`),
  // settings
  updateCurrentUser: api('put', '/users/me'),
  updateUserPassword: api('post', '/auth/change-password'),

  // companies
  getCompanies: api('get', '/admin/companies'),
  createCompany: api('post', '/admin/companies'),
  getCompany: api('get', makeUrl`/admin/companies/${'id'}`),
  updateCompany: api('put', makeUrl`/admin/companies/${'id'}`),
  patchCompany: api('patch', makeUrl`/admin/companies/${'id'}`),
  deleteCompany: api('delete', makeUrl`/admin/companies/${'id'}`),
  // company domains
  getCompanyDomainList: api('get', makeUrl`/admin/company/${'companyId'}/domains`),
  createCompanyDomain: api('post', makeUrl`/admin/company/${'companyId'}/domains`),
  getCompanyDomain: api('get', makeUrl`/admin/company/${'companyId'}/domains/${'domainId'}`),
  updateCompanyDomain: api('patch', makeUrl`/admin/company/${'companyId'}/domains/${'domainId'}`),
  deleteCompanyDomain: api('delete', makeUrl`/admin/company/${'companyId'}/domains/${'domainId'}`),

  //
  uploadImage: api('post', '/file-uploads/upload-image'),
  uploadFile: api('post', '/file-uploads/upload-file'),
  duplicateCourse: api('post', makeUrl`/admin/courses/${'id'}/clone`),

  // Leaderboard
  getMembersLeaderboard: api('get', '/admin/leaderboards/members-leaderboard'),
  getTeamsLeaderboard: api('get', '/admin/leaderboards/teams-leaderboard'),
  getWorkspacesLeaderboard: api('get', '/admin/leaderboards/workspaces-leaderboard'),
  duplicateCourses: api('post', makeUrl`/admin/courses/clone`),

  // Notification
  getNotifications: api('get', '/notifications'),
  updateNotification: api('put', makeUrl`/notifications/${'id'}`),
};
