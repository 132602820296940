import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
// types
import { CreateUserType, UserType, UsersResponse, InvitedUsersResponse } from 'types/users';
// utils
import { configureWorkspacePrefixes, workspaceRestClient } from 'portal/workspace/lib/http';
import { Params } from 'lib/http';
import { createApiCall } from 'lib/http/utils/createApiCall';

export const fetchInvitedUsersWorker = async <T>(requestPayload: T) => {
  const callApi = createApiCall<InvitedUsersResponse>({
    api: workspaceRestClient.getInvitedUsers,
    configureUrlParams: configureWorkspacePrefixes,
  });

  const { data } = await callApi(requestPayload);

  return data;
};

export const revokeUserInvitations = createAsyncThunk<{ success: boolean }, Params>(
  'users/revokeInvitations',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<{ success: boolean }>({
      api: workspaceRestClient.revokeUserInvitations,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const revokeAllUserInvitations = createAsyncThunk<{ success: boolean }, Params>(
  'users/revokeAllInvitations',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<{ success: boolean }>({
      api: workspaceRestClient.revokeAllUserInvitations,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const fetchUsersWorker = async <T>(requestPayload: T) => {
  const callApi = createApiCall<UsersResponse>({
    api: workspaceRestClient.getUsers,
    configureUrlParams: configureWorkspacePrefixes,
  });

  const { data } = await callApi(requestPayload);

  return data;
};

export const getUsersAction = createAsyncThunk<UsersResponse, Params | undefined>(
  'users/getUsers',
  async (requestPayload, { rejectWithValue }) => {
    try {
      return await fetchUsersWorker(requestPayload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const deleteUserAction = createAsyncThunk<{ success: boolean }, Params>(
  'users/deleteUsers',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<{ success: boolean }>({
      api: workspaceRestClient.deleteUser,
    });
    try {
      const { data } = await callApi(requestPayload);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
export const changeUsersRequestParamsAction = createAction<{
  sort: Record<string, unknown>;
  filters: Record<string, unknown>;
}>('users/changeUsersParams');

export const patchUserAction = createAsyncThunk<UserType, Params>(
  'users/patchUser',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<UserType>({
      api: workspaceRestClient.patchUser,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getUserAction = createAsyncThunk<UserType, Params>(
  'users/getUser',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<UserType>({
      api: workspaceRestClient.getUser,
      configureUrlParams: configureWorkspacePrefixes,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const bulkUploadUsersAction = createAsyncThunk<UserType[], CreateUserType[]>(
  'users/import',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<UserType[]>({
      api: workspaceRestClient.importUsers,
    });

    try {
      const { data } = await callApi({ users: requestPayload });

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const usersSetIsStaleAction = createAction<boolean>('users/setIsStale');

export const updateUserStatusAction = createAsyncThunk<{ success: boolean }, Params>(
  'users/updateUserStatus',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<{ success: boolean }>({
      api: workspaceRestClient.updateUserStatus,
    });
    try {
      const { data } = await callApi(requestPayload);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateUserInfoAction = createAsyncThunk<Partial<UserType>, Params>(
  'users/updateUserInfo',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<Partial<UserType>>({
      api: workspaceRestClient.updateUser,
    });
    try {
      const { data } = await callApi(requestPayload);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
