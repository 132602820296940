import React from 'react';
import { Box, Skeleton } from '@mui/material';

const DefaultCardSkeleton = () => {
  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between">
      <Skeleton variant="rectangular" height={200} />
      <Skeleton variant="text" />
      <Skeleton variant="text" />
      <Skeleton variant="rectangular" height={50} />
    </Box>
  );
};

export default React.memo(DefaultCardSkeleton);
