import { createAsyncThunk } from '@reduxjs/toolkit';
import { intl, IntlKeys } from 'localization';
// http
import { createApiCall } from 'lib/http/utils/createApiCall';
import { uploadFile } from 'portal/workspace/lib/uploadFile';
import { workspaceRestClient } from 'portal/workspace/lib/http';
import { Params, ResourceResponse } from 'lib/http';
// utils
import { replaceSpaces } from 'utils/string';
// types
import { ContentParams, ContentDeleteParams } from 'types/content/general';
import { ContentFileType, ContentListResponse, CreateFileParams } from 'types/content/files';
import { toast } from 'react-toastify';

export const fetchContentFilesWorker = async <T>(requestPayload: T) => {
  const callApi = createApiCall<ContentListResponse>({
    api: workspaceRestClient.getContentFiles,
  });

  const { data } = await callApi(requestPayload);

  return data;
};

export const getFilesAction = createAsyncThunk<ResourceResponse<ContentFileType[]>, ContentParams>(
  'content/getFiles',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<ResourceResponse<ContentFileType[]>>({
      api: workspaceRestClient.getContentFiles,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createFileAction = createAsyncThunk<ContentFileType, CreateFileParams>(
  'content/createFile',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<ContentFileType>({
      api: workspaceRestClient.createFile,
    });
    const { file, name } = requestPayload;

    try {
      const fileUrl = await uploadFile({
        file,
        name: replaceSpaces(name),
      });

      const { data } = await callApi({ ...requestPayload, file: fileUrl });
      // TODO change to snackbar and replace network handling to hook
      toast(intl.formatMessage({ id: IntlKeys.alertFileCreatingFulfilled }));

      return data;
    } catch (err) {
      toast(intl.formatMessage({ id: IntlKeys.alertFileCreatingFailed }));
      return rejectWithValue(err);
    }
  },
);

export const deleteFileAction = createAsyncThunk<number, ContentDeleteParams>(
  'content/deleteFile',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<{ success: boolean }>({
      api: workspaceRestClient.deleteFile,
    });

    try {
      await callApi(requestPayload);
      toast(intl.formatMessage({ id: IntlKeys.alertFileDeletingFulfilled }));

      return requestPayload.params.id;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateFileAction = createAsyncThunk<ContentFileType, Params>(
  'content/updateFile',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<ContentFileType>({
      api: workspaceRestClient.updateFile,
    });

    try {
      const { data } = await callApi(requestPayload);
      toast(intl.formatMessage({ id: IntlKeys.alertFileUpdatingFulfilled }));

      return data;
    } catch (err) {
      toast(intl.formatMessage({ id: IntlKeys.alertFileUpdatingFailed }));
      return rejectWithValue(err);
    }
  },
);
