// utils
import { ResourcesResponse } from 'lib/http';

// Members Leaderboard
export interface MembersLeaderboardType {
  id: string;
  firstName?: string;
  lastName?: string;
  avatar?: string;
  jobTitle: string;
  //
  completedCourseCount?: number;
  totalCourseCount?: number;
  completedPercent: number;
  score: number;
}

export type MembersLeaderboardResponse = ResourcesResponse<MembersLeaderboardType>;

export type SortConfigType = {
  field: string;
  order: 'ASC' | 'DESC';
};

const leaderboardSortConfigs = {
  scoreAsc: {
    field: 'score',
    order: 'ASC',
  },
  scoreDesc: {
    field: 'score',
    order: 'DESC',
  },
  completedPercentAsc: {
    field: 'completedPercent',
    order: 'ASC',
  },
  completedPercentDesc: {
    field: 'completedPercent',
    order: 'DESC',
  },
};

export const SortConfigs = leaderboardSortConfigs as Record<keyof typeof leaderboardSortConfigs, SortConfigType>;
