import React, { SyntheticEvent, useCallback, useMemo } from 'react';
import { useHistory, useParams } from 'react-router';
import { matchPath } from 'react-router-dom';
import { useIntl } from 'react-intl';
// utils
import { IntlKeys } from 'localization';
import { getUrlWithParams } from 'portal/backoffice/routes';
// components
import HorizontalTabs, { TabType } from 'components/tabs/horizotal-tabs';
import { InnerHeaderContent } from 'components/layouts/common/dynamic-header';
import DefaultLayout from 'portal/backoffice/components/layouts/default-layout';
//
import { makeStyles } from '@mui/styles';

interface OwnProps {}
const CoachCompanyLayout: React.FC<OwnProps> = ({ children }) => {
  const tabClasses = useTabStyles();
  //
  const { formatMessage } = useIntl();
  const { push, location } = useHistory();
  const { companyId } = useParams<{ companyId: string }>();
  //

  const companyTabs = useMemo(
    () =>
      [
        {
          label: formatMessage({ id: IntlKeys.courses }),
          path: getUrlWithParams('companyCourses', { companyId }),
        },
        {
          label: formatMessage({ id: IntlKeys.workspaces }),
          path: getUrlWithParams('coachCompanyWorkspaces', { companyId }),
        },
        {
          label: formatMessage({ id: IntlKeys.coaches }),
          path: getUrlWithParams('coachCompanyCoaches', { companyId }),
        },
        {
          label: formatMessage({ id: IntlKeys.settingsDomain }),
          path: getUrlWithParams('coachCompanySubdomains', { companyId }),
        },
      ] as TabType[],
    [companyId, formatMessage],
  );

  const activeTab = useMemo(() => {
    const resolvedTab = companyTabs.find((i) =>
      matchPath(location.pathname, { path: i.path as string, exact: true, strict: false }),
    );

    return (resolvedTab ?? companyTabs[0]).path;
  }, [location.pathname, companyTabs]);

  const handleTabChange = useCallback(
    (event: SyntheticEvent, newValue: string) => {
      push(newValue);
    },
    [push],
  );

  return (
    <DefaultLayout>
      <InnerHeaderContent>
        {/* TODO weird "missing tab" error */}
        <HorizontalTabs
          tabs={companyTabs}
          activeTab={activeTab}
          defaultValue={activeTab}
          onChange={handleTabChange}
          classes={tabClasses}
        />
      </InnerHeaderContent>

      {children}
    </DefaultLayout>
  );
};

const useTabStyles = makeStyles((theme) => ({
  root: {
    border: 'none',
  },
  indicator: {
    height: '4px !important',
    background: 'none',

    '&::after': {
      content: '""',
      display: 'block',
      position: 'relative',
      left: 'calc(50% - 2px)',
      width: 4,
      height: 4,
      borderRadius: 4,
      background: theme.colors.primary,
    },
  },
}));

export default CoachCompanyLayout;
