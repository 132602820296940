import { AxiosInstance } from 'axios';
import { merge } from 'lodash';

import { ApiCall, GenericUrl, Method, Params } from './index';

export const createApi = (AxiosInstance: AxiosInstance) => {
  // dynamic url helper
  return (method: Method, url: GenericUrl, predefinedParams?: Record<string, unknown>): ApiCall => {
    // dispatch params in action payload - ensure renames it to urlParams
    const apiCall = ({ urlParams, baseURL, headers, ...args }: Params) => {
      return AxiosInstance({
        baseURL,
        headers,
        method,
        url: typeof url === 'function' ? url(urlParams) : url,
        ...merge({}, predefinedParams, args),
      });
    };

    apiCall.method = method;
    apiCall.url = url;
    apiCall.selfName = '';
    apiCall.paramNames = typeof url === 'function' ? url.paramNames : [];

    return apiCall;
  };
};

export const configureApiCalls = <T extends Record<string, ApiCall>>(apiCalls: T): T => {
  for (const name in apiCalls) {
    apiCalls[name].selfName = name;
  }

  return apiCalls;
};
