import { lazy } from 'react';
//
import { AccessFlags } from 'lib/acl/entities';
import { checkDuplicateRoutes, GenericChildRoute, GenericRoute } from 'routes/index';
import { generateAppRoutes } from 'routes/utils';
import { IntlKeys } from 'localization';
//
import DefaultLayout from 'portal/backoffice/components/layouts/default-layout';
import FullscreenLayout from 'portal/backoffice/components/layouts/fullscreen-layout';
import FullwidthLayout from 'portal/backoffice/components/layouts/fullwidth-layout';
//
const LoginPage = lazy(() => import('portal/backoffice/pages/auth/login/LoginPage'));
const ForgotPasswordPage = lazy(() => import('portal/backoffice/pages/auth/forgot-password/ForgotPasswordPage'));
const ResetPasswordPage = lazy(() => import('portal/backoffice/pages/auth/reset-password/ResetPasswordPage'));
const SignUpPage = lazy(() => import('portal/backoffice/pages/auth/signup/SignUpPage'));
const SettingsPage = lazy(() => import('portal/backoffice/pages/settings/SettingsPage'));
const BackofficeWorkspacesPage = lazy(() => import('portal/backoffice/pages/workspaces/list'));
const BackofficeWorkspaceDetailsPage = lazy(() => import('portal/backoffice/pages/workspaces/details'));

const TeamsList = lazy(() => import('portal/backoffice/pages/teams/list'));
const TeamDetailsPage = lazy(() => import('portal/backoffice/pages/teams/details'));
const BackofficeContentPage = lazy(() => import('portal/backoffice/pages/content'));
const BackofficeWSCourseListPage = lazy(
  () => import('portal/backoffice/pages/workspaces/details/components/course-list'),
);
const BackofficeWSCourseDetailsPage = lazy(
  () => import('portal/backoffice/pages/workspaces/details/components/course-list/details'),
);
const ContentFilesPage = lazy(() => import('portal/backoffice/pages/content/files-page'));
const ContentFoldersPage = lazy(() => import('portal/backoffice/pages/content/folders-page'));
const ContentListsPage = lazy(() => import('portal/backoffice/pages/content/lists-page'));
const CoursesPage = lazy(() => import('portal/backoffice/pages/courses/list'));
const CourseDetailsPage = lazy(() => import('portal/backoffice/pages/courses/details'));
const PitchDetailsPage = lazy(() => import('portal/backoffice/pages/pitches/details'));
const CompaniesPage = lazy(() => import('portal/backoffice/pages/companies'));
const UserDetailsPage = lazy(() => import('portal/backoffice/pages/workspaces/details/components/users-list/details'));
//
import CoachCompanyLayout from 'portal/backoffice/pages/coach-companies/CoachCompanyLayout';
const CoachCompanyCourses = lazy(() => import('portal/backoffice/pages/coach-companies/courses'));
const CoachCompanyCourseDetailsPage = lazy(() => import('portal/backoffice/pages/coach-companies/courses/details'));
const CoachCompanyWorkspaces = lazy(() => import('portal/backoffice/pages/coach-companies/workspaces'));
const CoachCompanyCoaches = lazy(() => import('portal/backoffice/pages/coach-companies/coaches'));
const CoachCompanySubdomains = lazy(() => import('portal/backoffice/pages/coach-companies/subdomains'));

/* todo
    using plain string as key may cause errors when changing key since they dont reference each other
    also possible typos when using getUrlWithParams('longKeyNames'); Consider declaring keys as enum
    enum RKeys { Page1, Page2 } so it would be just static numbers and wouldn't change after enum key change
*/
/**
 * path - partial url (relative to parent route path)
 * key - (should be unique) used to getUrl helper to find route object
 */
const backofficeNestedRoutes: GenericRoute[] = [
  {
    path: '/',
    exact: true,
    key: 'home',
    title: IntlKeys.dashboard,
    isDefault: true,
    component: BackofficeWorkspacesPage,
    routes: [
      {
        path: '/sign-in',
        key: 'signIn',
        isDefault: true,
        restrictions: [AccessFlags.UNAUTHORIZED],
        title: IntlKeys.signIn,
        component: LoginPage,
        Layout: FullscreenLayout,
        routes: [],
      },
      {
        path: '/sign-up',
        key: 'signUp',
        restrictions: [AccessFlags.UNAUTHORIZED],
        title: IntlKeys.forgotPassword,
        component: SignUpPage,
        Layout: FullscreenLayout,
        routes: [],
      },
      {
        path: '/forgot-password',
        key: 'forgotPassword',
        restrictions: [AccessFlags.UNAUTHORIZED],
        title: IntlKeys.forgotPassword,
        component: ForgotPasswordPage,
        Layout: FullscreenLayout,
        routes: [],
      },
      {
        path: '/reset-password',
        key: 'resetPassword',
        restrictions: [AccessFlags.UNAUTHORIZED],
        title: IntlKeys.forgotPassword,
        component: ResetPasswordPage,
        Layout: FullscreenLayout,
        routes: [],
      },
      {
        path: '/settings',
        key: 'settings',
        title: IntlKeys.settings,
        component: SettingsPage,
      },
      {
        path: '/courses',
        key: 'courseList',
        restrictions: [AccessFlags.COURSES],
        title: IntlKeys.courses,
        component: CoursesPage,
        routes: [
          {
            path: '/:courseId',
            key: 'courseDetails',
            restrictions: [AccessFlags.COURSES],
            title: IntlKeys.courses,
            component: CourseDetailsPage,
            routes: [
              {
                path: '/pitches/:pitchId',
                key: 'editPitch',
                title: IntlKeys.courses,
                component: PitchDetailsPage,
                Layout: FullwidthLayout,
              },
            ],
          },
        ],
      },
      {
        path: '/workspaces',
        key: 'workspaceList',
        title: IntlKeys.workspaces,
        component: BackofficeWorkspacesPage,
        routes: [
          {
            path: '/:workspaceId',
            key: 'workspaceDetails',
            title: IntlKeys.companies,
            component: BackofficeWorkspaceDetailsPage,
            routes: [
              {
                path: '/courses',
                key: 'workspaceCoursesList',
                restrictions: [AccessFlags.COURSES],
                title: IntlKeys.courses,
                component: BackofficeWSCourseListPage,
                routes: [
                  {
                    path: '/:courseId',
                    key: 'workspaceCourseDetails',
                    restrictions: [AccessFlags.COURSES],
                    title: IntlKeys.courses,
                    component: BackofficeWSCourseDetailsPage,
                    routes: [
                      {
                        path: '/pitches/:pitchId',
                        key: 'workspaceCourseDetailsEditPitch',
                        title: IntlKeys.courses,
                        component: PitchDetailsPage,
                        Layout: FullwidthLayout,
                      },
                    ],
                  },
                ],
              },
              {
                path: '/users/:userId',
                key: 'userDetails',
                title: IntlKeys.members,
                component: UserDetailsPage,
              },
              {
                path: '/content',
                key: 'workspaceContent',
                title: IntlKeys.content,
                component: BackofficeContentPage,
                routes: [
                  {
                    path: '/files',
                    key: 'workspaceContentFiles',
                    title: IntlKeys.files,
                    component: ContentFilesPage,
                  },
                  {
                    path: '/lists',
                    key: 'workspaceContentLists',
                    title: IntlKeys.lists,
                    component: ContentListsPage,
                  },
                  {
                    path: '/folders',
                    key: 'workspaceContentFolders',
                    title: IntlKeys.folders,
                    component: ContentFoldersPage,
                    routes: [
                      {
                        path: '/:folderId',
                        key: 'workspaceContentFolder',
                        title: IntlKeys.folder,
                        component: BackofficeContentPage,
                        routes: [
                          {
                            path: '/files',
                            key: 'workspaceContentFolderFiles',
                            title: IntlKeys.files,
                            component: ContentFilesPage,
                          },
                          {
                            path: '/lists',
                            key: 'workspaceContentFolderLists',
                            title: IntlKeys.lists,
                            component: ContentListsPage,
                          },
                          {
                            path: '/folders',
                            key: 'workspaceContentFolderFolders',
                            title: IntlKeys.folders,
                            component: ContentFoldersPage,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                path: '/teams',
                key: 'teamsList',
                title: IntlKeys.teams,
                component: TeamsList,
                routes: [
                  {
                    path: '/:teamId',
                    key: 'teamDetails',
                    title: IntlKeys.overview,
                    component: TeamDetailsPage,
                    routes: [
                      {
                        path: '/courses/:courseId',
                        key: 'companyTeamsCoursesDetails',
                        title: IntlKeys.members,
                        component: CourseDetailsPage,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: '/content',
        key: 'content',
        title: IntlKeys.content,
        component: BackofficeContentPage,
        routes: [
          {
            path: '/files',
            key: 'contentFiles',
            title: IntlKeys.files,
            component: ContentFilesPage,
          },
          {
            path: '/lists',
            key: 'contentLists',
            title: IntlKeys.lists,
            component: ContentListsPage,
          },
          {
            path: '/folders',
            key: 'contentFolders',
            title: IntlKeys.folders,
            component: ContentFoldersPage,
            routes: [
              {
                path: '/:folderId',
                key: 'contentFolder',
                title: IntlKeys.folder,
                component: BackofficeContentPage,
                routes: [
                  {
                    path: '/files',
                    key: 'contentFolderFiles',
                    title: IntlKeys.files,
                    component: ContentFilesPage,
                  },
                  {
                    path: '/lists',
                    key: 'contentFolderLists',
                    title: IntlKeys.lists,
                    component: ContentListsPage,
                  },
                  {
                    path: '/folders',
                    key: 'contentFolderFolders',
                    title: IntlKeys.folders,
                    component: ContentFoldersPage,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: '/companies',
        key: 'companies',
        title: IntlKeys.companies,
        component: CompaniesPage,
        routes: [
          {
            path: '/:companyId',
            key: 'companyDetails',
            title: IntlKeys.content,
            routes: [
              {
                path: '/',
                key: 'companyCourses',
                exact: true,
                title: IntlKeys.content,
                component: CoachCompanyCourses,
                Layout: CoachCompanyLayout,
                routes: [
                  {
                    path: '/courses/:courseId',
                    key: 'coachCompanyCourseDetails',
                    restrictions: [AccessFlags.COURSES],
                    title: IntlKeys.courses,
                    component: CoachCompanyCourseDetailsPage,
                    routes: [
                      {
                        path: '/pitches/:pitchId',
                        key: 'coachCompanyPitchDetails',
                        title: IntlKeys.courses,
                        component: PitchDetailsPage,
                        Layout: FullwidthLayout,
                      },
                    ],
                  },
                ],
              },
              {
                path: '/workspaces',
                key: 'coachCompanyWorkspaces',
                title: IntlKeys.content,
                component: CoachCompanyWorkspaces,
                Layout: CoachCompanyLayout,
              },
              {
                path: '/coaches',
                key: 'coachCompanyCoaches',
                title: IntlKeys.content,
                component: CoachCompanyCoaches,
                Layout: CoachCompanyLayout,
              },
              {
                path: '/subdomains',
                key: 'coachCompanySubdomains',
                title: IntlKeys.content,
                component: CoachCompanySubdomains,
                Layout: CoachCompanyLayout,
              },
            ],
          },
        ],
      },
    ],
  },
];

const backofficeRoutes: GenericChildRoute[] = generateAppRoutes(backofficeNestedRoutes).map(
  ({ restrictions = [], ...route }) => {
    const hasAuthRestriction = restrictions.find(
      (restriction) => restriction === AccessFlags.AUTHORIZED || restriction === AccessFlags.UNAUTHORIZED,
    );

    // make all routes is authorized only if otherwise isn't mentioned
    if (!hasAuthRestriction) {
      restrictions.push(AccessFlags.AUTHORIZED);
    }

    return {
      Layout: DefaultLayout,
      //
      ...route,
      //
      restrictions,
    };
  },
);

if (process.env.NODE_ENV === 'development') {
  // Development only checks for route uniqueness (key/path)
  checkDuplicateRoutes(backofficeRoutes);
}

export default backofficeRoutes;
