import { createReducer, Reducer } from '@reduxjs/toolkit';
// actions
import { getRecentFilesAction } from './actions';
import { changeContentRequestParamsAction, resetContentRequestParamsAction } from './actions';
// utils
import { merge } from 'lodash';
// types
import { SortConfigType } from 'types/common';
import { ContentFileType } from 'types/content/files';

const initialState = {
  meta: {
    isLoading: false,
  },
  pagination: {
    page: 1,
    perPage: 8,
    recentFilesPerPage: 4,
  },
  sort: {
    field: 'updatedAt',
    order: 'DESC',
  } as SortConfigType,
  filters: {
    search: '',
  } as Record<string, string>,
  resources: [] as ContentFileType[],
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getRecentFilesAction.pending, (draft) => {
      draft.meta.isLoading = true;
    })
    .addCase(getRecentFilesAction.fulfilled, (draft, { payload }) => {
      draft.resources = payload.data;
      draft.meta.isLoading = false;
    })
    .addCase(changeContentRequestParamsAction, (draft, { payload }) => {
      draft.filters = merge(draft.filters, payload.filters);
    })
    .addCase(resetContentRequestParamsAction, (draft) => {
      draft.filters = { search: '' };
    });
});

export const generalContentReducer: Reducer<typeof initialState> = (draft = initialState, action) => {
  return reducer(draft, action);
};
