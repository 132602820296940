import { useIntl } from 'react-intl';
//
import { IntlKeys } from 'localization';

export enum NotificationActionEnum {
  CommentFromCoach = 'comment_from_coach',
  CommentFromUser = 'comment_from_user',
  FeedbackFromCoach = 'feedback_from_coach',
  FeedbackFromUser = 'feedback_from_user',
  QuizAnswerCorrected = 'quiz_answer_corrected',
  NewBadges = 'new_badges',
  LeaderboardUpdated = 'leaderboard_updates',
  ShareContentFile = 'share_content_file',
  ShareContentFolder = 'share_content_folder',
  ShareContentList = 'share_content_list',
  SharedRecord = 'shared_record',
  NewCoursePublished = 'new_course_published',
  UserAttachedToCourse = 'user_attached_to_course',
}

export enum NotificationStatusEnum {
  Active = 'active',
  Reviewed = 'reviewed',
}

export const useI18NotificationMapping = () => {
  const { formatMessage } = useIntl();

  const NotificationActionEnumMappings = {
    [NotificationActionEnum.CommentFromCoach]: formatMessage({ id: IntlKeys.commentFromCoach }),
    [NotificationActionEnum.CommentFromUser]: formatMessage({ id: IntlKeys.commentFromUser }),
    [NotificationActionEnum.FeedbackFromCoach]: formatMessage({ id: IntlKeys.feedbackFromCoach }),
    [NotificationActionEnum.FeedbackFromUser]: formatMessage({ id: IntlKeys.feedbackFromUser }),
    [NotificationActionEnum.QuizAnswerCorrected]: formatMessage({ id: IntlKeys.quizSubmissionReviewed }),
    [NotificationActionEnum.NewBadges]: formatMessage({ id: IntlKeys.newBadges }),
    [NotificationActionEnum.LeaderboardUpdated]: formatMessage({ id: IntlKeys.leaderboardUpdated }),
    [NotificationActionEnum.ShareContentFile]: formatMessage({ id: IntlKeys.shareContentFile }),
    [NotificationActionEnum.ShareContentFolder]: formatMessage({ id: IntlKeys.shareContentFolder }),
    [NotificationActionEnum.ShareContentList]: formatMessage({ id: IntlKeys.shareContentList }),
    [NotificationActionEnum.SharedRecord]: formatMessage({ id: IntlKeys.shareRecord }),
    [NotificationActionEnum.NewCoursePublished]: formatMessage({ id: IntlKeys.newCoursePublished }),
    [NotificationActionEnum.UserAttachedToCourse]: formatMessage({ id: IntlKeys.userAttachedToCourse }),
  };

  return NotificationActionEnumMappings;
};

export type Notification = {
  id: number;
  action: NotificationActionEnum;
  status: NotificationStatusEnum;
  createdAt: string;
  updatedAt: string;
  userId: number;
  creatorId: number;
  modelId: number;
  creator: Creator;
  workspaceId?: number;
};

type Creator = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  timezone: string;
  language: string;
  jobTitle: string;
  company: string;
  avatar: string;
  createdAt: string;
  updatedAt: string;
  role: string;
  workspaceId: number;
  fullName: string;
};
