import { combineReducers } from '@reduxjs/toolkit';
//
import { pitchBaseReducer } from './base/reducer';
import { pitchSlidesReducer } from './slides/reducer';
import { evaluationCriteriasReducer } from './evaluationCriterias/reducer';

export const pitchesReducer = combineReducers({
  base: pitchBaseReducer,
  slides: pitchSlidesReducer,
  evaluationCriterias: evaluationCriteriasReducer,
});
