import { Reducer } from 'redux';
import { createReducer, createSelector, createAction } from '@reduxjs/toolkit';
import { LOCATION_CHANGE, connectRouter, LocationChangeAction } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import qs from 'qs';

import routes from 'portal/backoffice/routes';

export const history = createBrowserHistory();

const initialState = {
  get router() {
    return {
      location: {
        ...history.location,
        query: {},
      },
      action: history.action,
      routeKey: '',
      prevPath: '',
      isMounted: true,
      query: {},
    };
  },
};

export const notifyRouteMountedAction = createAction<string>('@@router/notifyRouteMounted');

const connectedRouterReducer = connectRouter(history);

const reducer = createReducer(initialState.router, {
  [LOCATION_CHANGE]: (draft, reduxAction) => {
    const { location, action: routerAction } = connectedRouterReducer(draft, reduxAction as LocationChangeAction);

    draft.location = location;
    draft.action = routerAction;

    if (draft.prevPath !== location.pathname) {
      draft.prevPath = location.pathname;
      draft.isMounted = false;
    }
  },

  [notifyRouteMountedAction.type]: (draft, { payload }) => {
    // lazy components cannot load if store here the route object itself since it becomes immutable
    draft.routeKey = payload;
    draft.isMounted = true;
  },
});

export const routerReducer: Reducer<typeof initialState.router> = (draft = initialState.router, action) => {
  return reducer(draft, action);
};

type PartialState = { router: typeof initialState.router };
const routerSelector = (state: PartialState) => state.router;

export const currentRouteKeySelector = createSelector(routerSelector, ({ routeKey }) => routeKey);
export const currentRouteSelector = createSelector(
  currentRouteKeySelector,
  (routeKey) => routes.find(({ key }) => key === routeKey) || routes[0],
);
export const routerLocationSelector = createSelector(routerSelector, ({ location }) => location);
export const routerLocationSearchSelector = createSelector(routerLocationSelector, ({ search }) => search);
export const routerLocationSearchParsedSelector = createSelector(routerLocationSearchSelector, (search) =>
  qs.parse(search, { ignoreQueryPrefix: true }),
);
export const isRouteMountedSelector = createSelector(routerSelector, ({ isMounted }) => isMounted);

export default routerReducer;
